/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import { useEffect, useRef, useState } from 'react';
import LocalstorageService from '../../helpers/localstorage-service';
import { AuthUserInfo, CompanyId } from '../../constants';
import { Button, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';
import MachineService from '../../services/machines';
import { useOutletContext } from 'react-router-dom';
import { t } from 'i18next';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';
import ReactDatePicker from 'react-datepicker';
import NoRecords from '../../components/Table/NoRecords';
import SortableHeader from '../../components/SortableHeader';
import AlertModal from '../../components/AlertModal';
import CommonField from '../../components/CommonField';
import AgentService from '../../services/agent';
import reportService from '../../services/report';
import LocationService from '../../services/location';
// import { PaginationControl } from 'react-bootstrap-pagination-control';

const TerminalReport = () => {
  const dispatch = useDispatch();
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState('');
  const reqPayload = {};
  const formRef = useRef();
  let companyId = LocalstorageService.getItem(CompanyId);
  const { company } = LocalstorageService.getItem(AuthUserInfo)?.data;

  const previousMonth = new Date().getMonth() - 1;
  const prviouusMonthDate = new Date();
  prviouusMonthDate.setMonth(previousMonth);
  const [dateState, setDateState] = useState({ start: prviouusMonthDate, end: new Date() });
  const dateRef = useRef();
  const dateRef2 = useRef();
  const [terminalList, setTerminalList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [terminalNumber, setTerminalNumber] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);
  const [downloading, setDownloading] = useState({ export: false, exportExcel: false });

  const InitialValues = {
    company,
    agent: '',
    location: '',
    terminal: '',
    dateStart: '',
    dateEnd: '',
  };
  const [compConf, setCompConf] = useState({
    shown: false,
    msg: t('ALERT.EMPLOYEE_ZERO_AMOUNT_LOAD'),
    confirmFn: () => {
      setCompConf((pre) => {
        return {
          ...pre,
          shown: false,
        };
      });
    },
  });
  useEffect(() => {
    setPageTitle(t('REPORT.TERMINAL_REPORT'));
    setCrumbItem([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('REPORT.TERMINAL_REPORT'), link: '#' },
    ]);
    listFn();
  }, []);

  useEffect(() => {
    const mathched = terminalList.find((val) => val.id === selectedLocation);
    if (mathched && mathched.label !== terminalNumber) {
      setTerminalNumber(mathched.label);
      formRef?.current?.setFieldValue('terminal', mathched.label);
    }
  }, [selectedLocation]);

  const clearDate = () => {
    setDateState({ start: '', end: '' });
  };

  useEffect(() => {
    // const { terminal } = formRef?.current?.values;
    const mathched = locationList.find((val) => val.terminalNumber === terminalNumber);
    if (mathched && mathched.value !== selectedLocation) {
      setSelectedLocation(mathched.value);
      formRef?.current?.setFieldValue('location', mathched.value);
      // if (agentId && mathched.value && terminal) {
      //   getLocationlist({ agentId, location: mathched.value, terminal });
      // }
    }
  }, [terminalNumber]);

  // useEffect(() => {
  //   const { location, terminal } = formRef?.current?.values;

  //   if (terminal && agentId && location) {
  //     getLocationlist({ agentId, location, terminal });
  //   }
  // }, [dateState, agentId]);

  const HeaderList = [
    {
      title: t('REPORT.TABLE_FIELDS.DATE'),
      value: 'locationName',
      style: { minWidth: '150px', width: '18%' },
      sortable: false,
    },

    {
      title: t('REPORT.TABLE_FIELDS.BRANCH'),
      value: 'remark',
      style: { minWidth: '150px', width: '18%' },
      sortable: false,
    },

    {
      title: t('REPORT.TABLE_FIELDS.BANK_ACC'),
      value: 'remark',
      style: { minWidth: '150px', width: '18%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.REF.'),
      value: 'remark',
      style: { minWidth: '150px', width: '18%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.BATCH'),
      value: 'remark',
      style: { minWidth: '150px', width: '18%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.LOAD_AMT'),
      value: 'remark',
      style: { minWidth: '150px', width: '18%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.DEPOSIT_AMT'),
      value: 'remark',
      style: { minWidth: '150px', width: '18%' },
      sortable: false,
    },
  ];

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await AgentService.listAgent({ ...reqPayload, companyId: companyId })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        const arr = [];
        if (dataList.length > 0) {
          dataList.forEach((val) => {
            arr.push({ value: val.id, label: val.firstName });
          });
        }
        setAgentList(arr || []);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  async function listTerminal() {
    reqPayload['companyId'] = companyId;
    // reqPayload['allList'] = true;
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.listMachines({ ...reqPayload, allList: true })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        const arr = [];
        if (dataList.length > 0) {
          dataList.forEach((val) => {
            arr.push({ value: val.locationId, label: val.terminalNumber });
          });
        }

        setTerminalList(arr || []);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  async function listLocation(agentId) {
    reqPayload['companyId'] = companyId;
    reqPayload['agentId'] = agentId;
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    // await LocationService.listLocation({ ...reqPayload, allList: true })
    await LocationService.getLocationByAgent({ ...reqPayload })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        const arr = [];
        const arr2 = []
        if (dataList.length > 0) {
          dataList.forEach((val) => {
            arr.push({ value: val.id, label: val.locationName, terminalNumber: val.terminalNumber });
          });

          dataList.forEach((val) => {
            arr2.push({id:val.id, value: val.terminalNumber, label: val.terminalNumber });
          });
        }

        setLocationList(arr || []);
        setTerminalList(arr2 || []);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }
  // const handlePageClick = (currentPage) => {
  //   const { location, terminal } = formRef?.current?.values
  //   setCurrentPage(currentPage);
  //   getLocationlist({ agentId, location, terminal, currentPage });
  // };

  async function getLocationlist({ agentId, location = 0, terminal = '', currentPage = 1 }) {
    reqPayload.companyId = companyId;
    reqPayload.locationId = location ? location : 0;
    reqPayload.terminalNumber = terminal ? terminal?.toString() : '';
    reqPayload.agentId = agentId;
    reqPayload.startDate = dateState.start;
    reqPayload.endDate = dateState.end;
    reqPayload.export = false;
    reqPayload.exportExcel = false;
    // reqPayload.PageNo = currentPage
    // reqPayload.PageSize= 10
    // reqPayload.AllList= false
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await reportService
      .getReportByLocations({
        ...reqPayload,
        startDate: reqPayload.startDate ? new Date(reqPayload.startDate).toLocaleDateString('en-us') : '',
        endDate: reqPayload.endDate ? new Date(reqPayload.endDate).toLocaleDateString('en-us') : '',
      })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
        // if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;
        setList(dataList || []);

        // setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const handleSearch = async () => {
    const { location, terminal } = formRef?.current?.values;
    // if (terminal && agentId && location) {
    if (agentId) {
      getLocationlist({ agentId, location, terminal });
    }
  };

  function handleDownload(selectedType) {
    setDownloading({ export: selectedType.export, exportExcel: selectedType.exportExcel });
    reqPayload.locationId = selectedLocation;
    reqPayload.companyId = companyId;
    reqPayload.terminalNumber = terminalNumber ? terminalNumber?.toString() : '';
    reqPayload.agentId = agentId;
    reqPayload.startDate = dateState.start;
    reqPayload.endDate = dateState.end;
    reqPayload.export = selectedType.export;
    reqPayload.exportExcel = selectedType.exportExcel;
    // reqPayload.AllList = true
    downloadReport();
  }

  async function downloadReport() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await reportService
      .getReportByLocations({
        ...reqPayload,
        startDate: reqPayload.startDate ? new Date(reqPayload.startDate).toLocaleDateString('en-us') : '',
        endDate: reqPayload.endDate ? new Date(reqPayload.endDate).toLocaleDateString('en-us') : '',
      })
      .then((response) => {
        const { status, message, filePath } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        if (filePath) {
          const pdfUrl = filePath;
          const link = document.createElement('a');
          link.target = 'blank';
          link.href = pdfUrl;
          link.download = 'TerminalReport';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
        setDownloading({ export: false, exportExcel: false });
      });
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        enableReinitialize={true}
        // validationSchema={ValidationSchema}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row className="mb-2">
              <Col sm={3}>
                <div className="mb-3 mt-2">
                  <CommonField
                    fieldType="string"
                    fieldName="company"
                    label={t('REPORT.COMPANY_LABEL')}
                    isDisabled={true}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className=" mb-3">
                  <label>{t('REPORT.AGENT_LABEL')}</label>
                  <ReactSelect
                    name={'agent'}
                    value={!agentId ? '' : agentList.filter(({ value }) => value === agentId)}
                    options={agentList}
                    className="react-select"
                    onChange={(option) => {
                      setFieldValue('agent', option?.value);
                      setAgentId(option?.value);
                      listLocation(option?.value)
                    }}
                    isClearable
                    onBlur={() => setFieldTouched('agent', true)}
                    placeholder={`-- ${t('REPORT.AGENT_PLACEHOLDER')} --`}
                    noOptionsMessage={() => t('RSEL_NO_OPT')}
                    isSearchable={true}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className=" mb-3">
                  <label>{t('REPORT.LOCATION_LABEL')}</label>
                  <ReactSelect
                    name={'location'}
                    value={!selectedLocation ? '' : locationList.filter(({ value }) => value === selectedLocation)}
                    options={locationList}
                    className="react-select"
                    onChange={(option) => {
                      setFieldValue('location', option?.value);
                      setSelectedLocation(option?.value);
                    }}
                    isClearable
                    onBlur={() => setFieldTouched('location', true)}
                    placeholder={`-- ${t('REPORT.LOCATION_PLACEHOLDER')} --`}
                    noOptionsMessage={() => t('RSEL_NO_OPT')}
                    isSearchable={true}
                    isDisabled={!agentId}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className=" mb-3">
                  <label>{t('REPORT.TABLE_FIELDS.TERMINAL_NUMBER')}</label>
                  <ReactSelect
                    name={'terminal'}
                    value={!terminalNumber ? '' : terminalList.filter(({ label }) => label === terminalNumber)}
                    options={terminalList}
                    className="react-select"
                    onChange={(option) => {
                      setFieldValue('terminal', option?.label);
                      setTerminalNumber(option?.label);
                    }}
                    isClearable
                    onBlur={() => setFieldTouched('terminal', true)}
                    placeholder={`-- ${t('REPORT.TERMINAL_PLACEHOLDER')} --`}
                    noOptionsMessage={() => t('RSEL_NO_OPT')}
                    isSearchable={true}
                    isDisabled={!agentId}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={3}>
                <div className="d-flex justify-content-around align-items-center mb-3">
                  <label htmlFor="dateStart" className="text-nowrap" style={{ width: '40%' }}>
                    {t(`LOAD_REMARKS.FORM_DATE`)}
                  </label>
                  <ReactDatePicker
                    ref={dateRef}
                    name="dateStart"
                    id="dateStart"
                    className="form-control"
                    selected={dateState.start}
                    onChange={(dateArr) =>
                      setDateState((prev) => {
                        return { ...prev, start: dateArr };
                      })
                    }
                    highlightDates={[new Date()]}
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                  ></ReactDatePicker>
                </div>
              </Col>
              <Col sm={3}>
                <div className="d-flex justify-content-around align-items-center mb-3">
                  <label htmlFor="dateEnd" style={{ width: '40%' }}>
                    {t(`LOAD_REMARKS.TO_DATE`)}
                  </label>
                  <ReactDatePicker
                    ref={dateRef2}
                    name="dateEnd"
                    id="dateEnd"
                    className="form-control"
                    selected={dateState.end}
                    onChange={(dateArr) =>
                      setDateState((prev) => {
                        return { ...prev, end: dateArr };
                      })
                    }
                    highlightDates={[new Date()]}
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                  ></ReactDatePicker>
                  <Button
                    className="ms-2 "
                    id="clearBtn"
                    color="danger"
                    disabled={!dateState.start && !dateState.end}
                    type="button"
                    onClick={() => clearDate()}
                  >
                    {'X'}
                    <UncontrolledTooltip placement="right" target={`clearBtn`}>
                      {t(`REPORT.CLEAR_DATE`)}
                    </UncontrolledTooltip>
                  </Button>
                </div>
              </Col>
              <Col lg={5}>
                <div className=" gap-2 d-flex align-items-end mb-2">
                  <Button
                    color={isLoading || list.length === 0 ? 'secondary' : 'primary'}
                    type="button"
                    className="btn  btn-m"
                    disabled={isLoading || list.length === 0}
                    onClick={() => handleDownload({ export: true, exportExcel: false })}
                  >
                    {downloading.export && !downloading.exportExcel
                      ? t('REPORT.DOWNLOADING')
                      : t('REPORT.DOWNLOAD_PDF')}
                  </Button>
                  <Button
                    color={isLoading || list.length === 0 ? 'secondary' : 'primary'}
                    type="button"
                    className="btn btn-m"
                    disabled={isLoading || list.length === 0}
                    onClick={() => handleDownload({ export: true, exportExcel: true })}
                  >
                    {downloading.export && downloading.exportExcel ? t('REPORT.DOWNLOADING') : t('REPORT.DOWNLOAD_XLX')}
                  </Button>
                  <Button
                    className="ms-1 btn  btn-m"
                    id="find_terminal"
                    color="primary"
                    type="button"
                    onClick={() => handleSearch()}
                  >
                    <UncontrolledTooltip placement="right" target={`find_terminal`}>
                      {t(`SEARCH`)}
                    </UncontrolledTooltip>
                    <i className="ri-search-line"></i>
                  </Button>
                </div>
              </Col>
              {/* <Col sm={1}>
              </Col> */}
            </Row>
            <div className="dataMin employeeLoads reportTable">
              <Table
                className="fixTh dataTable overflow-visible"
                striped
                bordered
                responsive
                size="sm"
                style={{ minWidth: '650px', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr>
                    <SortableHeader showActionCol={false} headers={HeaderList} isDisabled={list.length === 0} />
                  </tr>
                </thead>

                <tbody>
                  {list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-nowrap">{item?.date ? new Date(item.date)?.toLocaleDateString('en-GB') : ''}</td>
                        <td className="">{item.branchName}</td>

                        <td className="">{item.accountNumber}</td>
                        <td className="">{item.reference}</td>
                        <td className="">{item.batch}</td>
                        <td dir="ltr">{item.loadingAmount?.toLocaleString('en-US')}</td>
                        <td dir="ltr">{item.returnFromTerminal?.toLocaleString('en-US')}</td>
                      </tr>
                    );
                  })}

                  {(isLoading || list.length === 0) && <NoRecords colSpan={HeaderList.length + 1} />}
                </tbody>
              </Table>
            </div>
          </Form>
        )}
      </Formik>
      {/* <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div> */}

      <AlertModal {...compConf} />
    </>
  );
};

export default TerminalReport;
