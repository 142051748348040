/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthService from '../../services/auth';

function VerifyEmail() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    confirmVerify();
  }, []);

  async function confirmVerify() {
    setIsLoading(true);
    await AuthService.verifyEmail({ userId: params.userId })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setIsLoading(false);
        setEmailVerified(true);

        setTimeout(() => {
          navigate('/login');
        }, 5000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <h4>{t('VERIFYING_YOUR_EMAIL')}</h4>
      ) : emailVerified ? (
        <React.Fragment>
          <h3>{t('YOUR_EMAIL_VERIFIIED')}</h3>
          <h4>{t('REDIRECT_TO_LOGIN')}</h4>
        </React.Fragment>
      ) : (
        !(isLoading || emailVerified) && <h4>{t('SOMETHING_WENT_WRONG')}</h4>
      )}
    </React.Fragment>
  );
}

export default VerifyEmail;
