/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { pick } from 'lodash';

import LocationService from '../../services/location';

import NumericFormatField from '../../components/NumericFormatField';
import CommonField from '../../components/CommonField';

const InitialValues = {
  payperAtt: 0,
  fixedprice: 0,
  casponetpayperAtt: 0,
  casponetfixedprice: 0,
  casponetremarks: '',
  // modemnumber: "",
};

/* const ValidationSchema = AmountSchema("payperAtt")
  .concat(AmountSchema("fixedprice"))
  .concat(AmountSchema("casponetpayperAtt"))
  .concat(AmountSchema("casponetfixedprice"))
  .shape(); */

const PaymentDetails = ({ formData, getDataFn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    const initData = pick(formData, ['id', ...Object.keys(InitialValues)]);
    setFormValues(initData);
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      payperAtt: parseFloat(values.payperAtt),
      fixedprice: parseFloat(values.fixedprice),
      casponetpayperAtt: parseFloat(values.casponetpayperAtt),
      casponetfixedprice: parseFloat(values.casponetfixedprice),
    };

    setIsLoading(true);
    await LocationService[formData.locationPaymentId === 0 ? 'createLocPayment' : 'updateLocPayment'](payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        getDataFn();
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...InitialValues, ...formValues }}
        enableReinitialize={true}
        // validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form id="loc-payment-det" noValidate>
            <Row>
              <Col sm={6} className="border-sep">
                <h4>{t('LOC_PAYMENT.PAY_TO_LOC_INFO')}</h4>
              </Col>

              <Col sm={6} className="d-none d-sm-block">
                <h4>{t('LOC_PAYMENT.PAY_TO_CASP')}</h4>
              </Col>
            </Row>

            <Row>
              {/* Payment to Location Information */}
              <Col sm={6} className="border-sep">
                <Row>
                  {/* Pay Per Attraction */}
                  <Col xs={6}>
                    <div className="mb-3">
                      <NumericFormatField
                        fieldName="payperAtt"
                        fieldValue={values.payperAtt}
                        fieldDecimalScale={2}
                        fieldLabel={t('LOC_PAYMENT.PAY_PER_ATT')}
                        fieldPlaceholder={t('LOC_PAYMENT.PAY_PER_ATT_PLCH')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isRequired={false}
                        isDisabled={!showEditForm}
                      />
                    </div>
                  </Col>

                  {/* Fixed Price */}
                  <Col xs={6}>
                    <div className="mb-3">
                      <NumericFormatField
                        fieldName="fixedprice"
                        fieldValue={values.fixedprice}
                        fieldDecimalScale={2}
                        fieldLabel={t('LOC_PAYMENT.FIXED_PRICE')}
                        fieldPlaceholder={t('LOC_PAYMENT.FIXED_PRICE_PLCH')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isRequired={false}
                        isDisabled={!showEditForm}
                      />
                    </div>
                  </Col>
                </Row>

                {/* <Row>
                  {/* Modem Number *-/}
                  <Col md={6}>
                    <div className="mb-3">
                      <CommonField
                        fieldName="modemnumber"
                        label="LOC_PAYMENT.MODEM_NO"
                        placeholder="LOC_PAYMENT.MODEM_NO_PLCH"
                      />
                    </div>
                  </Col>
                </Row> */}

                <hr className="d-block d-sm-none mt-0" />
              </Col>

              {/* Payment to Casponet */}
              <Col sm={6}>
                <h4 className="d-block d-sm-none">{t('LOC_PAYMENT.PAY_TO_CASP')}</h4>

                <Row>
                  {/* Pay Per Attraction */}
                  <Col xs={6}>
                    <div className="mb-3">
                      <NumericFormatField
                        fieldName="casponetpayperAtt"
                        fieldValue={values.casponetpayperAtt}
                        fieldDecimalScale={2}
                        fieldLabel={t('LOC_PAYMENT.PAY_PER_ATT')}
                        fieldPlaceholder={t('LOC_PAYMENT.PAY_PER_ATT_PLCH')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isRequired={false}
                        isDisabled={!showEditForm}
                      />
                    </div>
                  </Col>

                  {/* Fixed Price */}
                  <Col xs={6}>
                    <div className="mb-3">
                      <NumericFormatField
                        fieldName="casponetfixedprice"
                        fieldValue={values.casponetfixedprice}
                        fieldDecimalScale={2}
                        fieldLabel={t('LOC_PAYMENT.FIXED_PRICE')}
                        fieldPlaceholder={t('LOC_PAYMENT.FIXED_PRICE_PLCH')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isRequired={false}
                        isDisabled={!showEditForm}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* Remarks */}
                  <Col md={8}>
                    <div className="mb-3">
                      <Label htmlFor="remarksField">{t('EMPLOYEE.REMARKS_LABEL')}</Label>

                      <Field
                        id="remarksField"
                        name="casponetremarks"
                        component="textarea"
                        className="form-control"
                        placeholder={t('EMPLOYEE.REMARKS_PLACEHOLDER')}
                        disabled={!showEditForm}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default PaymentDetails;
