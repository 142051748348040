import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";

function configStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
  return store;
}

export default configStore;
