/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';
import { useOutletContext, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import LocationService from '../../services/location';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import LoadingOverlay from '../../components/LoadingOverlay';
import UpsertHeader from '../../components/UpsertHeader';
import UpdateLocation from './Update';
import OwnerDetails from './OwnerDetails';
import PaymentDetails from './PaymentDetails';
import MaintenanceDetails from './Maintenance';
import CommunicationDetails from './Communication';
import Withdrawal from './Withdrawal';
import RemarkList from './RemarkList';
import CashExpense from './CashExpense';

const InitialValues = {
  locationName: '',
  terminalNumber: '',
  employeeId: 0,
  city: '',
  street: '',
  agentId: '',
  entry: '',
  houseNumber: '',
  zip: '',
  remark: '',
};

let roleId;

const EditLocation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { state } = useLocation();
  const { companyId, terminalNumber, locationId } = state;
  const [setPageTitle, setCrumbItem] = useOutletContext();

  const [activeTab, setActiveTab] = useState('1');
  const [formData, setFormData] = useState(InitialValues);
  const [updateModal, setUpdateModal] = useState(false);
  const [isShowAddCashExenseModel, setIsShowAddCashExenseModel] = useState(false);
  useEffect(() => {
    getEditDataFn();
    roleId = LocalstorageService.getItem(JobRole);
  }, []);

  useEffect(() => {
    const pageTitle = `${t('LOCATION.TITLE')}: ${formData.locationName}`;
    setPageTitle(pageTitle);
  }, [formData]);

  async function getEditDataFn() {
    dispatch(setLoadingOverlay(true));
    await LocationService.listLocation({ id: params.locationId })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        data && setFormData(data);
      })
      .finally(() => {
        activeTab !== 1 && dispatch(setLoadingOverlay(false));
      });
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <UpsertHeader
        setUpdateModal={setUpdateModal}
        updateModal={updateModal}
        setShowCashExenseModel={setIsShowAddCashExenseModel}
        activeTab={activeTab}
      />
      <Col lg="12">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '1',
              })}
              onClick={() => {
                toggle('1');
              }}
            >
              {t('EMPLOYEE.INFORMATION')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '2',
              })}
              onClick={() => {
                toggle('2');
              }}
            >
              {t('LOC_TABS.OWNER_DETAILS')}
            </NavLink>
          </NavItem>

          {roleId !== USER_ROLE.EMPLOYEE && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '3',
                })}
                onClick={() => {
                  toggle('3');
                }}
              >
                {t('LOC_TABS.PAYMENTS')}
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '4',
              })}
              onClick={() => {
                toggle('4');
              }}
            >
              {t('LOC_TABS.MAINTENANCE')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '5',
              })}
              onClick={() => {
                toggle('5');
              }}
            >
              {t('LOC_TABS.COMM_PLACEMENT')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '6',
              })}
              onClick={() => {
                toggle('6');
              }}
            >
              {t('LOC_TABS.WITHDRAWAL')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '7',
              })}
              onClick={() => {
                toggle('7');
              }}
            >
              {t('LOC_TABS.REVIEW')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '8',
              })}
              onClick={() => {
                toggle('8');
              }}
            >
              {t('CASHEXPENSE.TITLE')}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {roleId === USER_ROLE.EMPLOYEE && <LoadingOverlay message="" style={{ opacity: 0 }} />}

            <div className="p-2 p-sm-3">
              <UpdateLocation formData={formData} />
            </div>
          </TabPane>
        </TabContent>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
            {roleId === USER_ROLE.EMPLOYEE && <LoadingOverlay message="" style={{ opacity: 0 }} />}

            <div className="p-2 p-sm-3">
              {activeTab === '2' && <OwnerDetails formData={formData} getDataFn={getEditDataFn} />}
            </div>
          </TabPane>
        </TabContent>

        {roleId !== USER_ROLE.EMPLOYEE && (
          <TabContent activeTab={activeTab}>
            <TabPane tabId="3" className="p-2 p-sm-3">
              {activeTab === '3' && <PaymentDetails formData={formData} getDataFn={getEditDataFn} />}
            </TabPane>
          </TabContent>
        )}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="4">
            {roleId === USER_ROLE.EMPLOYEE && <LoadingOverlay message="" style={{ opacity: 0 }} />}

            <div className="p-2 p-sm-3">
              {activeTab === '4' && (
                <MaintenanceDetails
                  terminalNumber={terminalNumber}
                  companyId={companyId}
                  locationId={Number(params?.locationId) || 0}
                  formData={formData}
                  getDataFn={getEditDataFn}
                />
              )}
            </div>
          </TabPane>
        </TabContent>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="5">
            {roleId === USER_ROLE.EMPLOYEE && <LoadingOverlay message="" style={{ opacity: 0 }} />}

            <div className="p-2 p-sm-3">
              {activeTab === '5' && <CommunicationDetails formData={formData} getDataFn={getEditDataFn} />}
            </div>
          </TabPane>
        </TabContent>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="6">
            {roleId === USER_ROLE.EMPLOYEE && <LoadingOverlay message="" style={{ opacity: 0 }} />}

            <div className="p-2 p-sm-3">
              {activeTab === '6' && <Withdrawal formData={formData} getDataFn={getEditDataFn} />}
            </div>
          </TabPane>
        </TabContent>

        {/* {roleId !== USER_ROLE.EMPLOYEE && (
          <TabContent activeTab={activeTab}>
            <TabPane tabId="7" className="p-2 p-sm-3">
              {activeTab === "7" && (
                <RemarkList
                  updateModal={updateModal}
                  setUpdateModal={setUpdateModal}
                />
              )}
            </TabPane>
          </TabContent>
        )} */}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="7" className="p-2 p-sm-3">
            {activeTab === '7' && <RemarkList updateModal={updateModal} setUpdateModal={setUpdateModal} />}
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="8" className="p-2 p-sm-3">
            {activeTab === '8' && (
              <CashExpense
                setShowAddCashExenseModel={setIsShowAddCashExenseModel}
                showAddCashExenseModel={isShowAddCashExenseModel}
              />
            )}
          </TabPane>
        </TabContent>
      </Col>
    </React.Fragment>
  );
};

export default EditLocation;
