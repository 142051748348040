import React from 'react';
import BackButton from './BackBtn';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function UpsertHeader({
  navTo = '../list',
  headerTitle = '',
  setUpdateModal,
  updateModal,
  activeTab,
  setShowCashExenseModel,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-start">
          <BackButton navTo={navTo} />
          {activeTab === '7' && (
            <div className="d-flex w-full  ">
              <Button
                className="editBtn ms-2"
                style={{ marginLeft: 'auto' }}
                // onClick={() => setUpdateModal(!updateModal)}
                onClick={() => setUpdateModal(true)}
              >
                {t(`LOAD_REMARKS.ADD`)}
              </Button>
            </div>
          )}
          {activeTab === '8' && (
            <div className="d-flex w-full  ">
              <Button
                className="editBtn ms-2"
                style={{ marginLeft: 'auto' }}
                onClick={() => setShowCashExenseModel(true)}
              >
                {t('CASHEXPENSE.ADD_BUTTON')}
              </Button>
            </div>
          )}
        </div>

        <h4 className="card-title mb-0">{headerTitle}</h4>
      </div>

      <hr className="mt-2" />
    </React.Fragment>
  );
}

export default UpsertHeader;
