/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import BankService from '../../services/bank';

import CommonField from '../../components/CommonField';
import UpsertHeader from '../../components/UpsertHeader';
import NumericFormatField from '../../components/NumericFormatField';

const InitialValues = {
  bankName: '',
  bankNumber: '',
  displayOrder: '',
};

const ValidationSchema = Yup.object().shape({
  bankName: Yup.string().required(t('REQUIRED')).trim(),
  bankNumber: Yup.string().required(t('REQUIRED')).trim(),
  displayOrder: Yup.string().required(t('REQUIRED')).trim(),
});

function AddBank() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCrumbItem([
      { title: t('BANK.TITLE'), link: 'list' },
      { title: t('ADD_TITLE'), link: '#' },
    ]);
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setIsLoading(true);
    await BankService.createBank({ ...values })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('BANK.ADD_BANK')} />

      <Formik initialValues={InitialValues} validationSchema={ValidationSchema} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
              {/* Bank Name */}
              <Col lg={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="bankName"
                    label="BANK.NAME_LABEL"
                    placeholder="BANK.NAME_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Bank Number */}
              <Col lg={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="bankNumber"
                    fieldValue={values.bankNumber}
                    fieldLabel={t('BANK.NUMBER_LABEL')}
                    useThousandSeparator={false}
                    fieldPlaceholder={t('BANK.NUMBER_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Display Order */}
              <Col lg={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="displayOrder"
                    fieldValue={values.displayOrder}
                    fieldLabel={t('BANK.DISPLAY_ORDER_LABEL')}
                    useThousandSeparator={false}
                    fieldPlaceholder={t('BANK.DISPLAY_ORDER_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default AddBank;
