/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputGroup, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import debounce from 'lodash.debounce';
import LocalstorageService from '../../helpers/localstorage-service';
import { CompanyId } from '../../constants';
import { t, dir } from 'i18next';

import { ITEMS_PER_PAGE } from '../../constants';
import { numberFormatter } from '../../helpers';
import EmployeeService from '../../services/employee';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import NoRecords from '../../components/Table/NoRecords';
import SortableHeader from '../../components/SortableHeader';
import ActionBtns from '../../components/ActionBtns';
import WithdrawalCharges from './WithdrawalCharges.modal';

const HeaderList = [
  {
    title: t('DATE'),
    value: 'date',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('CHEQUE_NUMBER'),
    value: 'reference',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMPLOYEE.AMT_OF_WITHDRAWAL'),
    value: 'remainedAfterSplit',
    isNumeric: true,
    style: { minWidth: '200px', width: '30%', textAlign: 'right' },
  },
  {
    title: t('EMPLOYEE.EMP_BALANCE'),
    value: 'employeeBalance',
    isNumeric: true,
    style: { minWidth: '150px', width: '30%', textAlign: 'right' },
  },
];

function Withdrawal() {
  const dispatch = useDispatch();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeData, setEmployeeData] = useState([]);
  const [withdrawalModal, setWithdrawalModal] = useState({
    shown: false,
    data: {},
    showWithdrawal: {},
  });
  const [chargesSearch, setChargesSearch] = useState();
  const modelHeaderMarginStyle = {
    marginRight: dir() === 'rtl' ? '50px' : 'initial', // For RTL, use marginRight
    marginLeft: dir() === 'ltr' ? '50px' : 'initial', // For LTR, use marginLeft
  };
  const reqPayload = { pageNo: currentPage };
  useEffect(() => {
    reqPayload['employeeId'] = params.employeeId;
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    getWithdrawal();
  }, [search]);

  async function getWithdrawal() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listWithdrawal({
      ...reqPayload,
      employeeId: reqPayload.employeeId ? reqPayload.employeeId : params.employeeId,
    })
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (dataList && dataList.length) {
          dataList.map((item) => {
            item.formattedRemainedAmount = numberFormatter(item.amountemployeehave);
            item.formattedEmpBal = numberFormatter(item.employeeBalance);
            return item;
          });

          setEmployeeData(dataList);
          setTotalCount(totalCount);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const viewWithdrawalDetailsFn = (item, l0Idx) => {
    setWithdrawalModal({
      shown: true,
      data: employeeData[l0Idx],
      showWithdrawal: item,
    });
  };

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    getWithdrawal();
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
      reqPayload['employeeId'] = params.employeeId;
      reqPayload['search'] = null;
      reqPayload['companyId'] = LocalstorageService.getItem(CompanyId) ? LocalstorageService.getItem(CompanyId) : '';
    }
    getWithdrawal();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  const debouncedChargesChangeHandler = useCallback(debounce(updateChargesSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  function updateChargesSearch(e) {
    setChargesSearch(e.target.value?.trim());
  }

  const onClosed = () => {
    setWithdrawalModal({
      shown: false,
      data: {},
      showWithdrawal: {},
    });
    getWithdrawal();
  }
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
          <InputGroup>
            <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
            <Button color="primary" type="button">
              <i className="ri-search-line"></i>
            </Button>
          </InputGroup>
        </Form>
      </div>

      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={HeaderList} isDisabled={employeeData.length === 0} sortClick={sortBy} />
          </tr>
        </thead>

        <tbody>
          {employeeData.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className="actionCol">
                <ActionBtns
                  viewTitle={t('EMPLOYEE.VIEW_CHARGES_DETAIL')}
                  viewFn={() => viewWithdrawalDetailsFn(item, l0Idx)}
                />
              </td>
              <td>{item.date}</td>
              <td>{item.reference}</td>
              <td align="right">{item.formattedRemainedAmount}</td>
              <td align="right">{item.formattedEmpBal}</td>
            </tr>
          ))}

          {!isLoading && employeeData.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>

      <Modal
        id="emp-charges-modal"
        isOpen={withdrawalModal.shown}
        onClosed={() => setChargesSearch('')}
        backdrop="static"
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="modalHead"
          toggle={onClosed}
        >
          <Row className="d-flex align-items-center" style={{ width: '100%' }}>
            <Col sm={3} className="mb-2 ">
              <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
                <InputGroup>
                  <Input onChange={debouncedChargesChangeHandler} placeholder={t('SEARCH')} />
                  <Button color="primary" type="button">
                    <i className="ri-search-line"></i>
                  </Button>
                </InputGroup>
              </Form>
            </Col>
            <Col sm={3}>
              <div className="d-flex ">
                <h5>
                  {t('DATE')} - {withdrawalModal.showWithdrawal.date} |
                </h5>
              </div>
            </Col>
            <Col sm={4}>
              <div className="d-flex align-items-center">
                <h5>
                  {t('CHEQUE_NUMBER')} - {withdrawalModal.showWithdrawal.reference} |
                </h5>
              </div>
            </Col>

            <Col sm={2}>
              <div className="d-flex align-items-center">
                <h5>
                  {t('AMOUNT')} - {numberFormatter(withdrawalModal.showWithdrawal.amount)}
                </h5>
              </div>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <WithdrawalCharges onClosed={onClosed} data={withdrawalModal.data} search={chargesSearch} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Withdrawal;
