import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

// Flags
import israel from "../../assets/images/flags/israel.png";
import usFlag from "../../assets/images/flags/us.jpg";

import { changeAppLanguage } from "../../helpers";

function LanguageDropdown() {
  const { t } = useTranslation();

  const [menu, setMenu] = useState(false);
  const [lng, setLng] = useState("en");
  const [flag, setFlag] = useState();

  useEffect(() => {
    const resolvedLng = i18n.resolvedLanguage;
    setLng(resolvedLng);
    if (resolvedLng === "he") setFlag(israel);
    else if (resolvedLng === "en") setFlag(usFlag);
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-sm-inline-block"
        title={t("CHANGE_LANGUAGE")}
      >
        <DropdownToggle tag="button" className="btn header-item waves-effect">
          <img className="" src={flag} alt="Header Language" height="16" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            active={lng === "en"}
            href=""
            onClick={() => changeAppLanguage("en")}
            className="notify-item"
          >
            <img src={usFlag} alt="user" className="me-1" height="12" />{" "}
            <span className="align-middle">English</span>
          </DropdownItem>

          <DropdownItem
            href=""
            active={lng === "he"}
            onClick={() => changeAppLanguage("he")}
            className=" notify-item"
          >
            <img src={israel} alt="user" className="me-1" height="12" />{" "}
            <span className="align-middle">עברית</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default LanguageDropdown;
