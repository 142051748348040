import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import BranchService from "../../../services/branch";

import CommonField from "../../../components/CommonField";
import NumericFormatField from "../../../components/NumericFormatField";

const InitialValues = {
  branchName: "",
  branchNumber: "",
  displayOrder: "",
};

const ValidationSchema = Yup.object().shape({
  branchName: Yup.string().required(t("REQUIRED")).trim(),
  branchNumber: Yup.string().required(t("REQUIRED")).trim(),
  displayOrder: Yup.string().required(t("REQUIRED")).trim(),
});

function Upsert({ isOpened = false, data = InitialValues, bankData, toggle }) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setIsLoading(true);

    const payload = { ...values, bankId: bankData?.id };

    await BranchService[data.id ? "updateBranch" : "createBranch"]({
      ...payload,
    })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        closeModal(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = (isSubmitted = false) => {
    toggle(false, isSubmitted);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpened}
        toggle={() => closeModal(false)}
        backdrop="static"
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
            <Form>
              <ModalHeader toggle={() => toggle(false)}>
                {t(`BRANCH.${data.id ? "UPDATE" : "ADD"}_BRANCH`)}
              </ModalHeader>

              <ModalBody>
                <Row>
                  {/* Branch Name */}
                  <Col>
                    <div className="mb-4">
                      <CommonField
                        fieldName="branchName"
                        label="BRANCH.NAME_LABEL"
                        placeholder="BRANCH.NAME_PLACEHOLDER"
                        required={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* Branch Number */}
                  <Col>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="branchNumber"
                        fieldValue={values.branchNumber}
                        fieldLabel={t("BRANCH.NUMBER_LABEL")}
                        useThousandSeparator={false}
                        fieldPlaceholder={t("BRANCH.NUMBER_PLACEHOLDER")}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        disabled={!!data.id}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* Display Order */}
                  <Col>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="displayOrder"
                        fieldValue={values.displayOrder}
                        fieldLabel={t("BANK.DISPLAY_ORDER_LABEL")}
                        useThousandSeparator={false}
                        fieldPlaceholder={t("BANK.DISPLAY_ORDER_PLACEHOLDER")}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>

              <ModalFooter>
                <Button color="light" onClick={() => closeModal(false)}>
                  {t("CANCEL")}
                </Button>

                {/* Submit Button */}
                <div className="">
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                    disabled={isSubmitting || isLoading}
                  >
                    {isSubmitting || isLoading ? t("PLEASE_WAIT") : t("SUBMIT")}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
}

export default Upsert;
