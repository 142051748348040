/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable array-callback-return*/
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { t } from 'i18next';

import { CompanyId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import DashboardService from '../../services/dashboard';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import Breadcrumbs from '../../components/Breadcrumb';
import LoadingOverlay from '../../components/LoadingOverlay';
import MiniWidgets from './MiniWidgets';

const TOTAL_COUNT_REPORTS = [
  {
    icon: 'ri-team-line',
    title: t('DASHBOARD.TOTAL_EMPLOYEES'),
    key: 'totalEmployees',
    value: '0',
  },
  {
    icon: 'ri-safe-2-line',
    title: t('DASHBOARD.TOTAL_TERMINALS'),
    key: 'totalMachines',
    value: '0',
  },
  {
    icon: 'ri-currency-line',
    title: t('DASHBOARD.TOTAL_DEPOSITS'),
    key: 'totalDeposit',
    value: '0',
  },
  {
    icon: 'ri-briefcase-4-line',
    title: t('DASHBOARD.TOTAL_WITHDRAWAL'),
    key: 'totalWithDrawan',
    value: '0',
  },
];

const DATA_SETS = {
  series: [],
  options: {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: [],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
    ],
    borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
    borderWidth: 1,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    noData: {
      text: '-- No Data --',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: 'black',
        fontSize: '18px',
      },
    },
  },
};

let reqPayload = { companyId: 0 };

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);

  const [breadcrumbItems] = useState([
    { title: t('APPLICATION_TITLE'), link: '#' },
    { title: t('DASHBOARD.TITLE'), link: '#' },
  ]);
  const [dataSets, setDataSets] = useState(DATA_SETS);
  const [dataList, setDatalist] = useState();

  useEffect(() => {
    reqPayload['companyId'] = LocalstorageService.getItem(CompanyId);
    listCountsFn();
  }, []);

  async function listCountsFn() {
    dispatch(setLoadingOverlay(true));
    await DashboardService.listCounts(reqPayload)
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        const reportCounts = (dataList && dataList[0]) || {};

        DATA_SETS.series = [];
        DATA_SETS.labels = [];
        let count = 0;
        TOTAL_COUNT_REPORTS.map((item) => {
          const val = reportCounts[item.key];
          if (val === 0) ++count;

          item.value = val;
          DATA_SETS.series.push(val);
          DATA_SETS.labels.push(item.title);
        });

        if (count === 4) {
          DATA_SETS.series = [];
          DATA_SETS.labels = [];
        }

        const dataSetPayload = {
          series: DATA_SETS.series,
          options: {
            ...dataSets.options,
            labels: DATA_SETS.labels,
          },
        };
        setDataSets(dataSetPayload);
        setDatalist(dataList);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  return (
    <React.Fragment>
      {showLoadingOverlay && <LoadingOverlay />}

      <Breadcrumbs title={t('DASHBOARD.TITLE')} breadcrumbItems={breadcrumbItems} />

      <Row>
        <Col lg={6}>
          <Card className="card-box">
            <CardBody>
              <ReactApexChart
                options={dataSets.options}
                series={dataSets.series}
                type="pie"
                width="100%"
                height="300"
              />
            </CardBody>
          </Card>
        </Col>

        <Col lg={6}>
          <Row>
            <MiniWidgets reports={TOTAL_COUNT_REPORTS} />
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Dashboard;
