/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Form, Input, InputGroup, Row, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { pick, debounce } from 'lodash';

import { ITEMS_PER_PAGE } from '../../constants';
import CompanyService from '../../services/company';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import Breadcrumbs from '../../components/Breadcrumb';
import LoadingOverlay from '../../components/LoadingOverlay';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import ActionBtns from '../../components/ActionBtns';
import Upsert from './Upsert';

const HeaderList = [
  {
    title: t('COMPANY.NAME_LABEL'),
    value: 'companyName',
    style: { minWidth: '200px' },
  },
];

const reqPayload = { pageNo: 1 };

function Company() {
  const dispatch = useDispatch();

  const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);

  const [breadcrumbItems] = useState([
    { title: t('APPLICATION_TITLE'), link: '#' },
    { title: t('COMPANY.TITLE'), link: '#' },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const [companyInfo, setCompanyInfo] = useState();
  const [showUpsertModal, setShowUpsertModal] = useState(false);

  const [deleteItem, setDeleteItem] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  }, [search]);

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await CompanyService.listCompany(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setList(dataList || []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const closeUpsertModal = (shown = false, isSubmitted = false) => {
    setCompanyInfo();
    setShowUpsertModal(false);
    isSubmitted && listFn();
  };

  const deleteModal = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };

  const resetModal = () => {
    setDeleteItem();
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    setIsLoading(true);

    await CompanyService.deleteCompany({ id: deleteItem.id })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        toast.success(message);
        listFn();
      })
      .finally(() => {
        setIsLoading(false);
      });

    resetModal();
  };

  const editFn = (item) => {
    setCompanyInfo(pick(item, ['id', 'companyName']));
    setShowUpsertModal(true);
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }
    listFn();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    listFn();
  };

  return (
    <React.Fragment>
      <Breadcrumbs title={t('COMPANY.TITLE')} breadcrumbItems={breadcrumbItems} />

      <Row>
        <Col lg="12">
          <Card className="card-box">
            {showLoadingOverlay && <LoadingOverlay />}

            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
                  <InputGroup>
                    <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
                    <Button color="primary" type="button">
                      <i className="ri-search-line"></i>
                    </Button>
                  </InputGroup>
                </Form>

                <Button color="primary" className="float-end" onClick={() => setShowUpsertModal(true)}>
                  <span className="d-none d-sm-block">{t('COMPANY.ADD')}</span>
                  <i className="ri-add-line d-block d-sm-none" title={t('COMPANY.ADD')}></i>
                </Button>
              </div>

              <Table striped bordered responsive size="sm">
                <thead>
                  <tr>
                    <SortableHeader headers={HeaderList} isDisabled={list.length === 0} sortClick={sortBy} />
                  </tr>
                </thead>

                <tbody>
                  {list.map((item, l0Idx) => (
                    <tr key={l0Idx}>
                      <td className="actionCol">
                        <ActionBtns
                          deleteTitle={t('COMPANY.DELETE')}
                          deleteFn={() => deleteModal(item)}
                          updateTitle={t('COMPANY.UPDATE')}
                          updateFn={() => editFn(item)}
                        />
                      </td>
                      <td>{item.companyName}</td>
                    </tr>
                  ))}

                  {!isLoading && list.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
                </tbody>
              </Table>

              <div className="custom-pagination mt-2">
                <PaginationControl
                  page={currentPage}
                  total={totalCount}
                  limit={ITEMS_PER_PAGE}
                  changePage={handlePageClick}
                  next={true}
                  last={true}
                  ellipsis={1}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Upsert isOpened={showUpsertModal} toggle={closeUpsertModal} data={companyInfo} />

      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        confirmFn={confirmDelete}
        cancelFn={resetModal}
      />
    </React.Fragment>
  );
}

export default Company;
