import i18n from '../i18n';
import { startCase } from 'lodash';
import { AuthUserInfo, IsLockScreen, AppTitle } from '../constants';
import LocalstorageService from './localstorage-service';

function isMobileDevice() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

function formatDate(inputDateString, dateFormate = '') {
  if (!inputDateString) {
    return '-';
  }
  const originalDate = new Date(inputDateString);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');
  let formattedDateString = '';
  if (dateFormate === 'dd-mm-yyyy') {
    formattedDateString = `${day}-${month}-${year}`;
  } else if (dateFormate === 'dd/mm/yyyy') {
    formattedDateString = `${day}/${month}/${year}`;
  } else if (dateFormate === 'yyyy/mm/dd') {
    formattedDateString = `${year}/${month}/${day}`;
  } else {
    formattedDateString = `${year}-${month}-${day}`;
  }
  //const formattedDateString = `${year}-${month}-${day}`;
  //const formattedDateString = `${day}/${month}/${year}`;

  return formattedDateString;
}

function updateAppTitle(pathname) {
  window.scrollTo(0, 0);
  const currentPage = startCase(pathname).replaceAll('-', ' ');
  document.title = `${currentPage} | ${AppTitle}`;
}

function validateEmail(email = '') {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

function changeAppLanguage(lang = 'en') {
  i18n.changeLanguage(lang);
  window.location.reload();
}

function checkAuthToken() {
  const authInfo = LocalstorageService.getItem(AuthUserInfo);
  return !!authInfo?.token;
}

// Prevent non-english characters.
function isValidPasswordChar(value) {
  return /^[A-Za-z\d#$@!%&*?]*$/.test(value.toString());
}

function removeAuthUserInfo() {
  LocalstorageService.removeItem(AuthUserInfo);
  LocalstorageService.removeItem(IsLockScreen);
}

function numberFormatter(value, decimalScale = 0) {
  if (!value) return value;

  const parts = value.toString().split('.');
  const numberPart = parts[0];
  let decimalPart = parts[1] || '';

  if (decimalScale !== 0) {
    decimalPart = decimalPart.padEnd(decimalScale, 0);
  }

  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  const formattedValue = numberPart.replace(thousands, ',') + (decimalPart ? '.' + decimalPart : '');

  return formattedValue;
}
function reverseNumberFormatter(value) {
  if (!value) return value;

  const parts = value.toString().split('.');
  const numberPartWithComma = parts[0];
  let numberpart = numberPartWithComma.toString().split(',');
  const formattedValue = numberpart.join('');
  return Number(formattedValue);
}
export {
  isMobileDevice,
  formatDate,
  updateAppTitle,
  validateEmail,
  changeAppLanguage,
  checkAuthToken,
  isValidPasswordChar,
  removeAuthUserInfo,
  numberFormatter,
  reverseNumberFormatter,
};
