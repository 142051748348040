///cashexpense/create-cash-expense/
import { post } from '../AxiosConfig';

const URI = '/cashexpense';

const createCashExpense = (payload) => {
  const URL = `${URI}/create-cash-expense`;
  return post(URL, payload);
};

const updateCashExpense = (payload) => {
  const URL = `${URI}/update-delete-cash-expense`;
  return post(URL, payload);
};
const cashExpenseList = (payload) => {
  const URL = `${URI}/list-cash-expense`;
  return post(URL, payload);
};
const getRemainCashAmount = (payload) => {
  const URL = `/loadingterminal/get-cash-remain`;
  return post(URL, payload);
};

export const CashExpenseService = {
  createCashExpense,
  cashExpenseList,
  getRemainCashAmount,
  updateCashExpense,
};
