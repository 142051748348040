import { post } from "../AxiosConfig";

const URI = "/company";

const listCompany = (payload) => {
  const URL = `${URI}/list-company`;
  return post(URL, payload);
};

const createCompany = (payload, config) => {
  const URL = `${URI}/create-company`;
  return post(URL, payload , config);
};

const updateCompany = (payload) => {
  const URL = `${URI}/update-company`;
  return post(URL, payload);
};

const deleteCompany = (payload) => {
  const URL = `${URI}/delete-company`;
  return post(URL, payload);
};

const CompanyService = {
  listCompany,
  createCompany,
  updateCompany,
  deleteCompany,
};

export default CompanyService;
