import React from "react";
import { Button, Label, UncontrolledTooltip } from "reactstrap";
import { PatternFormat } from "react-number-format";
import { ErrorMessage, Field } from "formik";
import { t } from "i18next";

function PhoneNumber({
  fieldName = "phoneNumber",
  fieldId = "phoneNumberField",
  fieldLabel = t("PHONE_NO_LABEL"),
  confirmedPhone = false,
  showVerification = false,
  toggleOtpModal,
  required = false,
  isDisabled = false,
}) {
  return (
    <React.Fragment>
      <Label htmlFor={fieldId} className="d-block mb-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {required && "*"}
            {fieldLabel}&nbsp;
            {showVerification && (
              <React.Fragment>
                <i
                  id="phoneVerified"
                  className={`ri-${
                    confirmedPhone ? "checkbox" : "close"
                  }-circle-fill text-${confirmedPhone ? "success" : "danger"}`}
                ></i>
                <UncontrolledTooltip placement="auto" target="phoneVerified">
                  {t(
                    `TOOLTIP.PHONE_NO_${
                      confirmedPhone ? "VERIFIED" : "UNVERIFIED"
                    }`
                  )}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </div>

          {showVerification && !confirmedPhone && (
            <Button
              type="button"
              color="link"
              className="p-0"
              onClick={toggleOtpModal}
            >
              {t("VERIFY_PHONE")}
            </Button>
          )}
        </div>
      </Label>

      <Field id={fieldId} name={fieldName}>
        {({ field }) => (
          <PatternFormat
            {...field}
            className="form-control"
            format="###-###-####"
            placeholder="XXX-YYY-YYYY"
            mask="_"
            disabled={isDisabled}
            autoComplete="off"
          />
        )}
      </Field>

      <ErrorMessage name={fieldName} className="text-danger" component="div" />
    </React.Fragment>
  );
}

export default PhoneNumber;
