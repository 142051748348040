import i18n from '../i18n';

const RouteTitles = {
  '/login': 'Login',
  '/forgot-password': 'Forgot Password?',
  '/reset-password': 'Reset Password',
  '/lock-screen': 'Lock Screen',

  '/dashboard': 'Dashboard',
  '/bank/*': 'Bank',
  '/company/*': 'Company',
  '/agent/*': 'Agents',
  '/agent-activity/bank-withdrawal': 'Bank Withdrawal',
  '/employee/*': 'Employees',
  '/agent-activity/split-money': 'Split Money',
  '/employee-activity/bank-withdrawal': 'Bank Withdrawal',
  '/employee-activity/charging-terminal': 'Charging Terminal',
  '/employee-activity/split-money': 'Split Money',
  '/employee-activity/withdrawal-terminal': 'Withdrawal Terminal',
  '/employee-activity/re-calculate': 'Re-calculate',
  '/locations/*': 'Locations',
  '/terminal/*': 'Terminal',
  '/cash-expense/*': 'Cash Expense',
  '/loading-terminal/*': 'Loading Terminal',
  '/special-days/*': 'Special Days',
  '/report/*': 'Reports',

  '/account/profile': 'Profile',
  '/account/users/*': 'Users',

  '/verify-email/*': 'Verify Email',

  '/': 'Natanel',
};

const AppTitle = 'ATM Management';
const AuthToken = 'auth-token';
const AuthUserInfo = 'auth-user';
const JobRole = 'job-role';
const UserId = 'user-id';
const CompanyId = 'company-id';
const IsLockScreen = 'screen-lock';
const INSERT_LOADING_TERMINAL_DATE_KEY = 'last-loading-terminal-date-key';

const WAIT_FOR_NEXT_OTP = 30;
const WAIT_FOR_NEXT_MAIL = 30;
const ITEMS_PER_PAGE = 10;

const USER_ROLE = {
  SUPER_ADMIN: 1,
  COMPANY_ADMIN: 2,
  AGENT: 3,
  EMPLOYEE: 4,
};

const USER_ROLES = [
  {
    id: 'SUPER_ADMIN',
    label: 'Super Admin',
    value: USER_ROLE.SUPER_ADMIN,
  },
  {
    id: 'COMPANY_ADMIN',
    label: 'Company Admin',
    value: USER_ROLE.COMPANY_ADMIN,
  },
  { id: 'AGENT', label: 'Agent', value: USER_ROLE.AGENT },
  { id: 'EMPLOYEE', label: 'Employee', value: USER_ROLE.EMPLOYEE },
];

const PREFIX_PHONE = ['02', '03', '04', '08', '09', '050', '052', '053', '054', '055', '058', '077'];

const WEEKS = {
  SUN: 'SUNDAY',
  MON: 'MONDAY',
  TUE: 'TUESDAY',
  WED: 'WEDNESDAY',
  THU: 'THURSDAY',
  FRI: 'FRIDAY',
  SAT: 'SATURDAY',
};
const SELECTED_LANG = i18n.language;
export {
  RouteTitles,
  AppTitle,
  AuthToken,
  JobRole,
  UserId,
  CompanyId,
  AuthUserInfo,
  IsLockScreen,
  WAIT_FOR_NEXT_OTP,
  WAIT_FOR_NEXT_MAIL,
  ITEMS_PER_PAGE,
  USER_ROLE,
  USER_ROLES,
  PREFIX_PHONE,
  WEEKS,
  INSERT_LOADING_TERMINAL_DATE_KEY,
  SELECTED_LANG,
};
