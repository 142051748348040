/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'reactstrap';
import { t } from 'i18next';

import { numberFormatter } from '../../helpers';
import EmployeeService from '../../services/employee';

import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import ActionBtns from '../../components/ActionBtns';
import EmployeeChargingTerminal from './EmployeeChargingTerminal';
import { SELECTED_LANG } from '../../constants';
const WithdrawalChargesHeaderList = [
  {
    title: t('DATE'),
    style: { minWidth: '100px', width: '20%' },
  },
  {
    title: t('LOCATION.TITLE'),
    style: { minWidth: '150px', width: '20%' },
  },
  {
    title: t('MACHINE.NUMBER'),
    style: { minWidth: '150px', width: '20%', textAlign: 'right' },
  },
  {
    title: t('BATCH_LABEL'),
    style: { minWidth: '100px', width: '20%', textAlign: 'right' },
  },
  {
    title: t('DRAW_BEFORE_FILLING'),
    style: { minWidth: '150px', width: '20%', textAlign: 'right' },
  },
  {
    title: t('CHARGE_TERMINAL.ADD_BILLS_LABEL'),
    style: { minWidth: '100px', width: '20%', textAlign: 'right' },
  },
  {
    title: t('TOTAL'),
    style: { minWidth: '100px', width: '20%', textAlign: 'right' },
  },
  {
    title: t('EMPLOYEE.EMP_BALANCE'),
    style: { minWidth: '150px', width: '20%', textAlign: 'right' },
  },
  {
    title: t('EMPLOYEE.COUNTED'),
    style: { minWidth: '100px', width: '20%', textAlign: 'center' },
  },
];

function WithdrawalCharges({ data, search, onClosed }) {
  const [isLoading, setIsLoading] = useState(true);
  const [chargesList, setChargesList] = useState([]);
  const [isOpenChargingEditModal, setIsOpenChargingEditModal] = useState(false);
  const [chargingTerminalInfo, setChargingTerminalInfo] = useState({
    employeeId: data.employeeId,
    // accountId: "",
    amountemployeehave: '0',
    locationId: '',
    terminalNumber: '',
    manuallyCounted: false,
    drawer: '1',
    wasIndrawer: '',
    addBills: '',
    leadingBatch: '',
    batch: '',
    returnFromTerminal: '',
  });
  const employeebalance = chargesList[chargesList.length - 1]?.empBal;

  useEffect(() => {
    setChargesList([]);
    getChargesDataFn(search);
  }, [search]);

  async function getChargesDataFn(search) {
    setIsLoading(true);

    const payload = {
      employeeId: data.employeeId,
      withdrawalId: data.withdrawId,
      search,
    };

    await EmployeeService.chargesList(payload)
      .then((response) => {
        const { dataList } = response.data;

        if (dataList && dataList.length) {
          dataList.map((item, index) => {
            if (index === 0) {
              item.empBal = dataList[index].amount - item.addbills;
            } else {
              item.empBal = dataList[index - 1].empBal - item.addbills;
            }

            item.drawBeforeFilling = numberFormatter(item.drawBeforeFilling);
            item.addbills = numberFormatter(item.addbills);
            item.total = numberFormatter(item.total);
            item.empBalFormatted = numberFormatter(item.empBal);
            return item;
          });
        }

        setChargesList(dataList || []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function editChargeInfo(data) {
    setChargingTerminalInfo((pre) => {
      return {
        ...pre,
        amountemployeehave: employeebalance,
        locationId: data.locationId,
        locationName: data.locationName,
        terminalNumber: data.terminalNumber,
        manuallyCounted: data.manuallyCounted,
        drawer: '1',
        wasIndrawer: data.wasIndrawer,
        addBills: data.addbills,
        // leadingBatch: data.terminalNumber
        //   ? data.terminalNumber.substring(data.terminalNumber.length - 4, data.terminalNumber.length)
        //   : '',
        leadingBatch:
          data.batch !== '0000'
            ? data.terminalNumber
              ? data.terminalNumber.substring(data.terminalNumber.length - 4, data.terminalNumber.length)
              : ''
            : '0000',
        batch: data.batch ? data.batch.substring(data.batch.length - 4, data.batch.length) : '',
        existingBatch: data.batch,
        returnFromTerminal: data.returnFromTerminal,
        employeeCashAmountLoadId: data.employeeCashAmountLoadId,
        employeeLoadingTerminalId: data.employeeLoadingTerminalId,
      };
    });
    setIsOpenChargingEditModal(true);
  }
  function handleEditCancelAction() {
    setIsOpenChargingEditModal(false);
  }
  return (
    <React.Fragment>
      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={WithdrawalChargesHeaderList} isDisabled={true} showActionCol={true} />
          </tr>
        </thead>

        <tbody>
          {chargesList.length > 0 &&
            chargesList.map((item, l0Idx) => (
              <tr key={l0Idx}>
                <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                  <ActionBtns
                    updateTitle={t('EMPLOYEE_LOADS.EDIT_BTN')}
                    updateFn={() => {
                      editChargeInfo(item);
                    }}
                  />
                </td>
                <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>{item.date}</td>
                <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>{item.locationName}</td>
                <td align="right" className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                  {item.terminalNumber}
                </td>
                <td align="right" className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                  {item.batch}
                </td>
                <td align="right" className={`${parseInt(item.addbills) < 0 ? 'bg-danger align-left' : ''}`}>
                  {item.drawBeforeFilling}
                </td>
                <td align="right" className={`${parseInt(item.addbills) < 0 ? 'bg-danger align-left' : ''}`}>
                  {item.addbills}
                </td>
                <td align="right" className={`${parseInt(item.addbills) < 0 ? 'bg-danger align-left' : ''}`}>
                  {item.total}
                </td>
                <td align="right" className={`${parseInt(item.addbills) < 0 ? 'bg-danger align-left' : ''}`}>
                  {item.empBalFormatted}
                </td>
                <td align="center" className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                  {item.manuallyCounted ? (
                    <i className="ri-check-fill text-success"></i>
                  ) : (
                    <i className={`ri-close-fill ${parseInt(item.addbills) < 0 ? 'text-dark' : 'text-danger'}`}></i>
                  )}
                </td>
              </tr>
            ))}

          {!isLoading && chargesList.length === 0 && <NoRecords colSpan={WithdrawalChargesHeaderList.length + 1} />}

          {isLoading && chargesList.length === 0 && (
            <NoRecords colSpan={WithdrawalChargesHeaderList.length + 1} msg={t('LOADING')} />
          )}
        </tbody>
      </Table>
      {isOpenChargingEditModal && (
        <EmployeeChargingTerminal
          isOpenChargingEditModal={isOpenChargingEditModal}
          handleEditCancelAction={handleEditCancelAction}
          InitialValues={chargingTerminalInfo}
          onClosed={onClosed}
        />
      )}
    </React.Fragment>
  );
}

export default WithdrawalCharges;
