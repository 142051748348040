import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "reactstrap";

// Import images
import errorImg from "../../assets/images/error-img.png";

import { checkAuthToken } from "../../helpers";

function Error404() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = checkAuthToken();

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center my-5">
                <h1 className="fw-bold text-error">
                  4{" "}
                  <span className="error-text">
                    0<img src={errorImg} alt="" className="error-img" />
                  </span>{" "}
                  4
                </h1>
                <h3 className="text-uppercase">
                  {t("ERROR_404.PAGE_NOT_FOUND")}
                </h3>
                <div className="mt-5 text-center">
                  <Button
                    onClick={() => {
                      navigate(token ? -1 : "/login");
                    }}
                  >
                    {t(`${token ? "GO_BACK" : "BACK_TO_LOGIN"}`)}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Error404;
