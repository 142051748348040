import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import warning from '../assets/images/Warning.png';

function AlertModal({ shown, msg = '', confirmFn, btnLabel = 'Close' }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Modal isOpen={shown} backdrop="static" className="modal-sm modal-dialog-centered">
        <ModalBody>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={warning} alt="" height="50" className="mb-4" />
            <h5 className="mx-2 mb-0 text-center">{msg}</h5>
          </div>

          <br className="d-none d-sm-block" />
          <br />
          <div className="d-flex justify-content-end gap-2">
            <Button color="primary" className="waves-effect me-1" onClick={confirmFn}>
              {btnLabel ? btnLabel : t('CHANGE')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default AlertModal;
