import React from "react";
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";

function Sidebar(props) {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? (
            <SimpleBar style={{ height: "100%", maxHeight: "100%" }}>
              <SidebarContent toggleMenuCallback={props.toggleMenuCallback} />
            </SimpleBar>
          ) : (
            <SidebarContent toggleMenuCallback={props.toggleMenuCallback} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;
