/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, InputGroup, Table } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import debounce from 'lodash.debounce';
import { t } from 'i18next';

import { CompanyId, ITEMS_PER_PAGE, SELECTED_LANG } from '../../constants';
import { numberFormatter } from '../../helpers';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import NoRecords from '../../components/Table/NoRecords';
import SortableHeader from '../../components/SortableHeader';
import LocationService from '../../services/location';
import LocalstorageService from '../../helpers/localstorage-service';

const HeaderList = [
  {
    title: t('DATE'),
    value: 'createDate',
    style: { minWidth: '150px', width: '15%' },
  },
  {
    title: t('BATCH_LABEL'),
    value: 'Batch',
    style: { minWidth: '150px', width: '20%' },
  },
  {
    title: t('DRAW_BEFORE_FILLING'),
    value: 'DrawBefore',
    style: { minWidth: '150px', width: '20%' },
  },
  {
    title: t('CHARGE_TERMINAL.ADD_BILLS_LABEL'),
    value: 'AddBills',
    style: { minWidth: '150px', width: '20%' },
  },
  {
    title: t('TOTAL'),
    value: 'Total',
    style: { minWidth: '150px', width: '15%' },
  },
  {
    title: t('EMPLOYEE.COUNTED'),
    style: { width: '10%' },
    sortable: false,
  },
];

const reqPayload = {
  pageNo: 1,
  allList: false,
  sort: [{ value: 'createDate', asc: false }],
};

function Withdrawal() {
  const dispatch = useDispatch();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [locationData, setLocationData] = useState([]);

  const companyId = LocalstorageService.getItem(CompanyId);

  useEffect(() => {
    reqPayload['locationId'] = params.locationId;
    reqPayload['companyId'] = companyId;
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    getWithdrawal();
  }, [search]);

  async function getWithdrawal() {
    dispatch(setLoadingOverlay(true));

    await LocationService.listWithdrawal(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (dataList && dataList.length) {
          dataList.map((item) => {
            item.formattedAmount = numberFormatter(item.amount);
            item.formattedEmpBal = numberFormatter(item.employeeBalance);
            return item;
          });

          setLocationData(dataList);
          setTotalCount(totalCount);
        }
        if (dataList.length === 0) {
          setLocationData([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    getWithdrawal();
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    } else {
      reqPayload['sort'] = [{ value: 'createDate', asc: false }];
    }
    getWithdrawal();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
          <InputGroup>
            <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
            <Button color="primary" type="button">
              <i className="ri-search-line"></i>
            </Button>
          </InputGroup>
        </Form>
      </div>

      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader
              headers={HeaderList}
              isDisabled={locationData.length === 0}
              sortClick={sortBy}
              showActionCol={false}
            />
          </tr>
        </thead>

        <tbody>
          {locationData.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>{item.date}</td>
              <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>{item.batch}</td>
              <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                {item.drawBeforeFilling}
              </td>
              <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                {item.addbills.toLocaleString(SELECTED_LANG === 'en' ? 'en-US' : 'he-IL')}
              </td>
              <td className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                {item.total.toLocaleString(SELECTED_LANG === 'en' ? 'en-US' : 'he-IL')}
              </td>
              <td align="center" className={`${parseInt(item.addbills) < 0 ? 'bg-danger' : ''}`}>
                {item.manuallyCounted ? (
                  <i className="ri-check-fill text-success"></i>
                ) : (
                  <i className={`ri-close-fill ${parseInt(item.addbills) < 0 ? 'text-dark' : 'text-danger'}`}></i>
                )}
              </td>
            </tr>
          ))}

          {!isLoading && locationData.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>
    </React.Fragment>
  );
}

export default Withdrawal;
