/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE, UserId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';
import EmployeeService from '../../services/employee';
import EmpActivityService from '../../services/emp_activity';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import SelectField from '../../components/SelectField';
import NumericFormatField from '../../components/NumericFormatField';
import ConfirmSaveDataSplitMoney from './ConfirmSaveDataSplitMoney.modal';
import { numberFormatter, reverseNumberFormatter } from '../../helpers';
const InitialValues = {
  employeeId: '',
  accountId: '',
  totalAmount: '0.00',
  withdrawtoEmployeeId: '',
  amount: '',
  reference: '',
};

const ValidationSchema = AmountSchema().shape({
  employeeId: Yup.string().required(t('REQUIRED')).trim(),
  // accountId: Yup.string().required(t("REQUIRED")).trim(),
  withdrawtoEmployeeId: Yup.string().required(t('REQUIRED')).trim(),
  // reference: Yup.string().required(t("REQUIRED")).trim(),
});

let companyId = 0;

function SplitMoney() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [vSchema, setVSchema] = useState(ValidationSchema);
  const [roleId, setRoleId] = useState();
  const [empList, setEmpList] = useState([]);
  const [empAcctList, setEmpAcctList] = useState([]);
  const [empAcctOptList, setEmpAcctOptList] = useState([]);
  const [transEmpOptList, setTransEmpOptList] = useState([]);
  const [confirmSaveData, setConfirmSaveData] = useState({});

  const loginUserId = LocalstorageService.getItem(UserId);

  useEffect(() => {
    setPageTitle(t('SPLIT_MONEY.TITLE'));
    setCrumbItem([
      { title: t('EMP_ACTIVITY'), link: '#' },
      { title: t('SPLIT_MONEY.TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);

    const userRole = LocalstorageService.getItem(JobRole);
    setRoleId(userRole);

    setVSchema(
      ValidationSchema.concat(
        Yup.object().shape({
          accountId: Yup.string().required(t('REQUIRED')).trim(),
          reference: Yup.string().required(t('REQUIRED')).trim(),
        }),
      ),
    );

    getEmployeeFn(loginUserId);
  }, []);

  async function getEmployeeFn(loginUserId) {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ companyId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            companyId: item.companyId,
          }));

          setEmpList(data);

          if (roleId === USER_ROLE.EMPLOYEE) {
            const transEmpList = data.filter((v) => v.value !== loginUserId);
            setTransEmpOptList(transEmpList);
          } else {
            setTransEmpOptList(data);
          }
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function amountEmployeeHaveFn(employeeId) {
    const { setFieldValue } = formRef.current;

    dispatch(setLoadingOverlay(true));
    await EmpActivityService.amtEmpHave({
      companyId,
      employeeId,
    })
      .then((response) => {
        const { status, data } = response.data;

        if (status === 400) {
          setFieldValue('totalAmount', 0.0);
          return;
        }
        data && setFieldValue('totalAmount', numberFormatter(data.amountemployeehave || 0, 2));
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getAccountNumbersFn(employeeId) {
    dispatch(setLoadingOverlay(true));
    await EmpActivityService.bankAcctInfo({ companyId, employeeId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          setEmpAcctList(dataList);

          const data = dataList.map((item) => ({
            id: item.bankAccountId,
            label: `${item.bankName} / ${item.branchName} / ${item.accountNumber}`,
            value: item.bankAccountId,
          }));

          setEmpAcctOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  function createTransEmpList(empId) {
    const transEmpList = empList.filter((v) => v.value !== empId);
    setTransEmpOptList(transEmpList);
  }

  function handleToggleModal(isCancelled = true) {
    setConfirmSaveData({
      ...confirmSaveData,
      formData: {},
      isOpened: false,
      isCancelled,
    });

    !isCancelled && formRef.current.resetForm();
  }

  async function confirmDetailsFn() {
    const { values, setSubmitting, setTouched, validateForm } = formRef.current;
    setSubmitting(false);
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }

    const payload = {
      ...values,
      companyId,
      employeeId: parseInt(values.employeeId),
      ...(roleId === USER_ROLE.EMPLOYEE ? { accountId: undefined } : { accountId: parseInt(values.accountId) }),
      totalAmount: reverseNumberFormatter(values.totalAmount),
      amount: parseFloat(values.amount),
    };

    setConfirmSaveData({
      roleId,
      formData: { ...payload },
      transEmpOptList,
      isOpened: true,
      toggleModal: handleToggleModal,
    });
  }

  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        // validationSchema={ValidationSchema}
        validationSchema={vSchema}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Employee */}
              <Col xs={12} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="employeeId"
                    fieldLabel={t('ROLES.EMPLOYEE')}
                    fieldValue={values.employeeId}
                    fieldOptions={empList}
                    fieldChange={(option) => {
                      setEmpAcctOptList([]);
                      setFieldValue('accountId', '');
                      setFieldTouched('accountId', false);

                      setTransEmpOptList([]);
                      setFieldValue('withdrawtoEmployeeId', '');
                      setFieldTouched('withdrawtoEmployeeId', false);

                      if (option) {
                        companyId = option.companyId;
                        getAccountNumbersFn(option.value);
                        createTransEmpList(option.value);

                        amountEmployeeHaveFn(option.value);
                      } else {
                        amountEmployeeHaveFn(option);
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>

              {/* Account Number */}
              <Col xs={12} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="accountId"
                    fieldLabel={t('EMPLOYEE.ACC_NO_LABEL')}
                    fieldValue={values.accountId}
                    fieldOptions={empAcctOptList}
                    fieldChange={(option) => {
                      if (option) {
                        const actInfo = empAcctList.find((eal) => eal.bankAccountId === option.value) || {};

                        setFieldValue('agentId', actInfo.agentId);
                        setFieldValue('bankId', actInfo.bankId);
                        setFieldValue('bankBranchId', actInfo.bankBranchId);
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!values.employeeId}
                  />
                </div>
              </Col>

              {/* Total Amount */}
              <Col {...(roleId === USER_ROLE.EMPLOYEE ? { sm: 8 } : { sm: 4 })} md={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="totalAmount"
                    label={t('TOTAL_AMOUNT')}
                    placeholder={t('TOTAL_AMOUNT')}
                    required={true}
                    isDisabled={true}
                  />
                  {/* <NumericFormatField
                    fieldName="totalAmount"
                    fieldValue={values.totalAmount}
                    fieldDecimalScale={2}
                    fieldLabel={t("TOTAL_AMOUNT")}
                    fieldPlaceholder={t("TOTAL_AMOUNT")}
                    required={true}
                    isDisabled={true}
                  /> */}
                </div>
              </Col>

              {/* Transfer to Employee */}
              <Col {...(roleId === USER_ROLE.EMPLOYEE ? { sm: 12 } : { sm: 4 })} md={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="withdrawtoEmployeeId"
                    fieldLabel={t('SPLIT_MONEY.TRANS_TO_EMP')}
                    fieldValue={values.withdrawtoEmployeeId}
                    fieldOptions={transEmpOptList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!values.employeeId}
                  />
                </div>
              </Col>

              {/* Cheque Amount */}
              <Col
                {...(roleId === USER_ROLE.EMPLOYEE ? {} : { xs: 6 })}
                {...(roleId === USER_ROLE.EMPLOYEE ? { sm: 8 } : { sm: 4 })}
                md={4}
              >
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="amount"
                    fieldValue={values.amount}
                    fieldDecimalScale={2}
                    fieldLabel={t('SPLIT_AMT')}
                    fieldPlaceholder={t('BANK_WITHDRAWAL.SPLIT_AMT_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>

              {/* Cheque Number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="reference"
                    fieldValue={values.reference}
                    fieldLabel={t('CHEQUE_NUMBER')}
                    fieldPlaceholder={t('BANK_WITHDRAWAL.CHEQUE_NUM_PLACEHOLDER')}
                    allowLeadingZeros={true}
                    useThousandSeparator={false}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button color="primary" className="w-md waves-effect waves-light" onClick={() => confirmDetailsFn()}>
                {t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      {confirmSaveData.isOpened && <ConfirmSaveDataSplitMoney {...confirmSaveData} />}
    </React.Fragment>
  );
}

export default SplitMoney;
