import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationHE from "./locales/he/translation.json";

// Translations
const resources = {
  en: {
    translation: translationEN,
  },
  he: {
    translation: translationHE,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available
    detection: {
      order: ["localStorage", "sessionStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
    },
    keySeparator: ".", // we do not use keys in form messages.welcome
    returnObjects: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
