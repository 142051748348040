import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import Breadcrumbs from "../../components/Breadcrumb";
import LoadingOverlay from "../../components/LoadingOverlay";
import ChangePassword from "./ChangePassword";
import ChangeProfile from "./ChangeProfile";

function UserProfile() {
  const { t } = useTranslation();
  const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);
  const changeProfileFormEl = useRef(null);
  const changePassFormEl = useRef(null);

  const [breadcrumbItems] = useState([
    { title: t("ACCOUNT.TITLE"), link: "#" },
    { title: t("PROFILE"), link: "#" },
  ]);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumbs title={t("PROFILE")} breadcrumbItems={breadcrumbItems} />

      <Row>
        <Col>
          <Card className="card-box">
            {showLoadingOverlay && <LoadingOverlay />}

            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                      changePassFormEl.current?.resetForm();
                    }}
                  >
                    {t("PROFILE")}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                      changeProfileFormEl.current?.resetForm();
                    }}
                  >
                    {t("ACCOUNT.CHANGE_PASSWORD")}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="p-2 p-sm-3">
                  <ChangeProfile ref={changePassFormEl} />
                </TabPane>
              </TabContent>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="2" className="p-2 p-sm-3">
                  <Row>
                    <Col sm="8" md="6" lg="4">
                      <ChangePassword ref={changePassFormEl} />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default UserProfile;
