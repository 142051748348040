import { post } from "../AxiosConfig";

const URI = "/branch";

const listBranches = (payload) => {
  const URL = `${URI}/list-branch`;
  return post(URL, payload);
};

const createBranch = (payload) => {
  const URL = `${URI}/create-branch`;
  return post(URL, payload);
};

const updateBranch = (payload) => {
  const URL = `${URI}/update-branch`;
  return post(URL, payload);
};

const deleteBranch = (payload) => {
  const URL = `${URI}/delete-branch`;
  return post(URL, payload);
};

const getAllBanks = (payload) => {
  const URL = `${URI}/get-banks`;
  return post(URL, payload);
};

const getAllBranches = (payload) => {
  const URL = `${URI}/get-branches`;
  return post(URL, payload);
};

const BranchService = {
  listBranches,
  createBranch,
  updateBranch,
  deleteBranch,
  getAllBanks,
  getAllBranches,
};

export default BranchService;
