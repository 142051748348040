import React from "react";
import { Button, Label, UncontrolledTooltip } from "reactstrap";
import { ErrorMessage, Field } from "formik";
import { t } from "i18next";

function EmailField({
  fieldName = "mail",
  fieldLabel = t("EMAIL_LABEL"),
  fieldId = "emailField",
  fieldPlaceholder = t("EMAIL_PLACEHOLDER"),
  confirmedMail = false,
  showVerification = false,
  toggleEmailModal,
  isRequired = false,
  isDisabled = false,
}) {
  return (
    <React.Fragment>
      <Label htmlFor={fieldId} className="d-block mb-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {isRequired && "*"}
            {fieldLabel}&nbsp;
            {showVerification && (
              <React.Fragment>
                <i
                  id={`${fieldId}_verified`}
                  className={`ri-${
                    confirmedMail ? "checkbox" : "close"
                  }-circle-fill text-${confirmedMail ? "success" : "danger"}`}
                ></i>
                <UncontrolledTooltip
                  placement="auto"
                  target={`${fieldId}_verified`}
                >
                  {t(
                    `TOOLTIP.EMAIL_${confirmedMail ? "VERIFIED" : "UNVERIFIED"}`
                  )}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </div>

          {showVerification && !confirmedMail && (
            <Button
              type="button"
              color="link"
              className="p-0"
              onClick={toggleEmailModal}
            >
              {t("VERIFY_EMAIL")}
            </Button>
          )}
        </div>
      </Label>

      <Field
        name={fieldName}
        id={fieldId}
        type="email"
        className="form-control"
        placeholder={fieldPlaceholder}
        required={isRequired}
        disabled={isDisabled}
      />

      <ErrorMessage name={fieldName} className="text-danger" component="div" />
    </React.Fragment>
  );
}

export default EmailField;
