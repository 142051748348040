import axios from "axios";
import i18next, { t } from "i18next";
import { toast } from "react-toastify";

import { AuthUserInfo } from "./constants";
import LocalstorageService from "./helpers/localstorage-service";
import envConfig from '../src/config'
// Apply base url for axios
//const API_URL = process.env.REACT_APP_API_URL || ""; //old settings
const API_URL = envConfig.API_BASE_URL || "";  //after setup seprat environment configuration

const axiosApi = axios.create({
  baseURL: API_URL,
});

const ERR_500MSG = t("ERR_500MSG");

axiosApi.interceptors.request.use(
  (config) => {
    const userInfo = LocalstorageService.getItem(AuthUserInfo);
    if (userInfo?.token && !config.url.endsWith("/verify-otp")) {
      config.headers["Authorization"] = `Bearer ${userInfo?.token}`;
    }
    config.headers["I18nlng"] = i18next.resolvedLanguage;
    config.headers["Accept-Language"] =
      i18next.resolvedLanguage === "en" ? "en-US" : "he-IL";
    return config;
  },
  (error) => {
    console.log(">REQ_ERR: ", error?.message);
    toast.error(ERR_500MSG, { toastId: "REQ_ERR" });
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(">RES_ERR: ", error?.message);
    toast.error(ERR_500MSG, { toastId: "RES_ERR" });
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config });
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config });
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config });
}

export async function delPayload(url, data = {}, config = {}) {
  return await axiosApi.delete(url, {
    Authorization: { ...config },
    data: { ...data },
  });
}

export async function postFormData(url, data, config = {}) {
  return axiosApi.post(url, data, {
    "Content-Type": "multipart/form-data",
    ...config,
  });
}

export async function putFormData(url, data, config = {}) {
  return axiosApi.put(url, data, {
    "Content-Type": "multipart/form-data",
    ...config,
  });
}
