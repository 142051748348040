/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AddCashExpense from '../../components/CashExpense/Add';
import CashExpenseList from '../../components/CashExpense/List';
import { CashExpenseService } from '../../services/cash_expense';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import { CompanyId, JobRole, UserId, USER_ROLE, ITEMS_PER_PAGE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { toast } from 'react-toastify';
import MachineService from '../../services/machines';
import { numberFormatter, formatDate } from '../../helpers';

function CashExpense({ setShowAddCashExenseModel, showAddCashExenseModel }) {
  const params = useParams();
  let companyId = LocalstorageService.getItem(CompanyId);
  let role = LocalstorageService.getItem(JobRole);
  let userId = LocalstorageService.getItem(UserId);

  //let employeeId =
  const { t } = useTranslation();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [locationData, setLocationData] = useState([]);
  const [locations, setLocations] = useState([]);
  // const [employeeId, setEmployeeId] = useState(() => (role === USER_ROLE.EMPLOYEE && userId ? userId : 0));
  const [cashExpenseListingPayload, setCashExpenseListingPayload] = useState(() => ({
    companyId: companyId,
    employeeId: role === USER_ROLE.EMPLOYEE && userId ? userId : 0,
    locationId: (params && params.locationId && Number(params.locationId)) || '',
    search: '',
    pageNo: 1,
    pageSize: ITEMS_PER_PAGE,
    allList: false,
    loeListing: role === USER_ROLE.EMPLOYEE && userId ? true : false,
    deletedRecords: false,
    sort: [
      {
        value: 'createDate',
        asc: false,
      },
    ],
  }));
  const [cashExpenseList, setCashExpenseList] = useState([]);
  const [cashExpenseDetail, setCashExpenseDetail] = useState({
    companyId: companyId,
    cashExpenseId: 0,
    employeeId: role === USER_ROLE.EMPLOYEE && userId ? userId : '',
    remainCashBalance: 0,
    locationId: Number(params?.locationId) || 0,
    terminalNumber: '',
    expenseNote: '',
    expenseAmount: null,
    paidTo: '',
    startDate: '',
    endDate: '',
    invoice: false,
  });
  useEffect(() => {
    if (showAddCashExenseModel) {
      getLocationId();
    }
  }, [showAddCashExenseModel]);
  const cancelCashExpense = () => {
    setCashExpenseDetail((pre) => {
      return {
        ...pre,
        companyId: companyId,
        cashExpenseId: 0,
        employeeId: role === USER_ROLE.EMPLOYEE && userId ? true : false,
        remainCashBalance: 0,
        locationId: Number(params?.locationId) || 0,
        terminalNumber: '',
        expenseNote: '',
        expenseAmount: 0,
        paidTo: '',
        startDate: '',
        endDate: '',
        invoice: false,
      };
    });
    setShowAddCashExenseModel(false);
  };

  useEffect(() => {
    getCashExpenseList();
  }, []);
  async function getLocationId() {
    await MachineService.getLocations({ companyId }).then((response) => {
      const { status, dataList } = response.data;
      if (status === 400) {
        return;
      }
      setLocationData(dataList);
      if (dataList && dataList.length) {
        const locationInfo = dataList.filter((data) => Number(data.id) === Number(params?.locationId));
        console.log(locationInfo);
        if (locationInfo.length) {
          setCashExpenseDetail((pre) => {
            return {
              ...pre,
              terminalNumber: locationInfo[0].terminalNumber,
            };
          });
        }
      }
      const data = dataList.map((item) => ({
        id: item.id,
        label: item.locationName,
        value: item.id,
      }));

      setLocations(data);
    });
  }
  const saveCashExpense = async (reqPayload) => {
    if (reqPayload.cashExpenseId) {
      updateCashExpense(reqPayload);
    } else {
      addCashExpense(reqPayload);
    }
  };
  const addCashExpense = async (reqPayload) => {
    if (reqPayload && reqPayload.remainCashBalance) {
      delete reqPayload.remainCashBalance;
    }

    dispatch(setLoadingOverlay(true));
    await CashExpenseService.createCashExpense({
      ...reqPayload,
      locationId: reqPayload.locationId === '' ? 0 : reqPayload.locationId,
      startDate: reqPayload.startDate === '' ? '' : formatDate(reqPayload.startDate, 'yyyy/mm/dd'),
      endDate: reqPayload.endDate === '' ? '' : formatDate(reqPayload.endDate, 'yyyy/mm/dd'),
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        setShowAddCashExenseModel(false);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        getCashExpenseList();
      });
  };
  const updateCashExpense = async (reqPayload) => {
    if (reqPayload && reqPayload.remainCashBalance) {
      delete reqPayload.remainCashBalance;
    }

    dispatch(setLoadingOverlay(true));
    const updateReqPayload = {
      ...reqPayload,
      locationId: reqPayload.locationId === '' ? 0 : reqPayload.locationId,
      startDate: reqPayload.startDate === '' ? '' : formatDate(reqPayload.startDate, 'yyyy/mm/dd'),
      endDate: reqPayload.endDate === '' ? '' : formatDate(reqPayload.endDate, 'yyyy/mm/dd'),
    };
    await CashExpenseService.updateCashExpense(updateReqPayload)
      .then((response) => {
        const { status, message } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        setShowAddCashExenseModel(false);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        getCashExpenseList();
      });
  };
  const getCashExpenseList = async (reqPayload = {}) => {
    dispatch(setLoadingOverlay(true));

    await CashExpenseService.cashExpenseList(!isEmpty(reqPayload) ? reqPayload : cashExpenseListingPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        setCashExpenseList(dataList ? dataList : []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  };
  async function getCashRemainAmount(employeeId) {
    const reqPayload = { employeeId: employeeId };
    dispatch(setLoadingOverlay(true));
    await CashExpenseService.getRemainCashAmount(reqPayload)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        setCashExpenseDetail((pre) => {
          return {
            ...pre,
            remainCashBalance: data.totalCashRemain || 0,
          };
        });
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }
  const sortBy = (sortObj) => {
    delete cashExpenseListingPayload['sort'];
    const reqPayload = { ...cashExpenseListingPayload };
    if (sortObj) {
      setCashExpenseListingPayload((pre) => {
        return {
          ...pre,
          sort: [sortObj],
        };
      });
      reqPayload['sort'] = [sortObj];
      //cashExpenseListingPayload['sort'] = [sortObj];
    }
    getCashExpenseList(reqPayload);
  };
  const editCashExpense = async (data) => {
    await getCashRemainAmount(data.employeeId);

    setCashExpenseDetail((pre) => {
      return {
        ...pre,
        ...data,
        startDate: data.startDate ? new Date(data.startDate) : '',
        endDate: data.endDate ? new Date(data.endDate) : '',
        ud: true,
      };
    });
    setShowAddCashExenseModel(true);
  };

  const handlePageClick = (currentPage) => {
    const reqPayload = { ...cashExpenseListingPayload };
    setCashExpenseListingPayload((pre) => {
      return {
        ...pre,
        pageNo: currentPage,
      };
    });
    reqPayload['pageNo'] = currentPage;
    getCashExpenseList(reqPayload);
  };
  const handleSearch = (e) => {
    const reqPayload = { ...cashExpenseListingPayload };
    setCashExpenseListingPayload((pre) => {
      return {
        ...pre,
        search: e.target.value,
      };
    });
    reqPayload['search'] = e.target.value;
    getCashExpenseList(reqPayload);
  };
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };
  return (
    <React.Fragment>
      {showAddCashExenseModel && (
        <AddCashExpense
          showAddCashExenseModel={showAddCashExenseModel}
          cashExpenseDetail={cashExpenseDetail}
          locations={locations}
          locationData={locationData}
          handleSubmitAction={saveCashExpense}
          handleCancelAction={cancelCashExpense}
        />
      )}
      <CashExpenseList
        cashExpenseList={cashExpenseList}
        sortBy={sortBy}
        handleSearch={handleSearch}
        totalCount={totalCount}
        currentPage={currentPage}
        editCashExpense={editCashExpense}
        removeCashExpense={updateCashExpense}
        handlePageClick={handlePageClick}
      />
    </React.Fragment>
  );
}

export default CashExpense;
