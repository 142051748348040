/*eslint-disable array-callback-return*/
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Col, Container, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import { pick } from 'lodash';
import i18n from '../../i18n';

// Import images
import natanelLg from '../../assets/images/logo/natanel-lg.png';

import { AuthUserInfo, CompanyId, JobRole, USER_ROLE, UserId } from '../../constants';
import { changeAppLanguage } from '../../helpers';
import LocalstorageService from '../../helpers/localstorage-service';
import AuthService from '../../services/auth';
import CompanyService from '../../services/company';

import { setAuthToken, setAuthUser } from '../../store/slices/Auth/authSlice';

import FooterInscription from '../../components/FooterInscription';
import PasswordField from '../../components/PasswordField';
import SelectField from '../../components/SelectField';
import AccountService from '../../services/account';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required(t('REQUIRED')),
  password: Yup.string().required(t('REQUIRED')),
});

const InitCompValues = {
  companyId: '',
};

const CompanyValidationSchema = Yup.object().shape({
  companyId: Yup.string().required(t('REQUIRED')),
});

let loginData = {};
let companyDetails = {};

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [companyOptList] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [openAddCompanyModal, setOpneAddCompanyModal] = useState(false);
  const tokenRef = useRef()

  async function getCompanyFn() {
    await AccountService.getCompanyData()
      .then((response) => {
        const { dataList } = response.data;

        if (dataList && dataList.length) {
          dataList.map((item, index) => {
            companyOptList[index] = {
              id: item.id,
              label: item.companyName,
              value: item.id,
            };
          });
        }
      })
      .finally(() => {});
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    setIsLoading(true);
    await AuthService.login({ ...values })
      .then(async (response) => {
        const { status, message, data, token } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (data) {
          loginData = { data, message, token };
          tokenRef.current = token
          if (data.roleId === USER_ROLE.SUPER_ADMIN) {
            await getCompanyFn();
          }

          if (data.roleId === USER_ROLE.SUPER_ADMIN && companyOptList.length !== 0) {
            setIsOpened(true);
          } else {
            handleLogin();
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddCompany = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setIsLoading(true);

    const payload = {
      ...values, "id": 0,
      "totalCount": 0,
      isLogin: true
    };
    const config = { headers: { Authorization: `Bearer ${tokenRef.current}` } }
    await CompanyService["createCompany"](
      { ...payload },
      config
    )
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
       
        companyDetails.name = payload.companyName
        companyDetails.id = data.id
        LocalstorageService.setItem(CompanyId, data.id);
        toast.success(message);
        setOpneAddCompanyModal(false)
        handleLogin()
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleLogin = () => {
    const { message, token, data } = loginData;

    const pickArr = ['userName', 'firstName', 'lastName', 'knownAs', 'mail', 'company'];

    let payload = pick(data, pickArr);

    if (!!(data.roleId === USER_ROLE.SUPER_ADMIN && companyOptList.length !== 0)) {
      payload = { ...payload, company: companyDetails.name };
    }
    dispatch(setAuthUser(payload));
    dispatch(setAuthToken(token));

    LocalstorageService.setItem(AuthUserInfo, {
      data: payload,
      token,
    });
    LocalstorageService.setItem(JobRole, data.roleId);
    LocalstorageService.setItem(UserId, data.id);

    if (!(data.roleId === USER_ROLE.SUPER_ADMIN && companyOptList.length !== 0)) {
      LocalstorageService.setItem(CompanyId, data.companyId);
    }

    toast.success(message);
    navigate('/dashboard');
  };

  
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={10}>
                      <div>
                        <div className="text-center">
                          <div>
                            <img src={natanelLg} alt="" height="30" className="auth-logo logo-dark mx-auto" />
                          </div>

                          <h4 className="font-size-18 mt-4">{t('LOGIN.WELCOME_BACK')}</h4>
                          <p className="text-muted">
                            {t('LOGIN.SIGN_IN_TO_CONTINUE') + ' '}
                            {t('APPLICATION_TITLE')}.
                          </p>
                        </div>

                        <div className="p-2 mt-5">
                          <Formik
                            className="form-horizontal"
                            initialValues={{ email: '', password: '' }}
                            validationSchema={LoginSchema}
                            onSubmit={handleSubmit}
                          >
                            {(props) => {
                              const { isSubmitting, setFieldValue } = props;

                              return (
                                <Form>
                                  {/* Email */}
                                  <div className="mb-4">
                                    <div className="auth-form-group-custom">
                                      <i className="ri-user-2-line auti-custom-input-icon"></i>
                                      <Label htmlFor="email">
                                        {t('EMAIL_LABEL')}&nbsp;/&nbsp;
                                        {t('USERNAME_LABEL')}
                                      </Label>
                                      <Field
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        placeholder={`${t('EMAIL_PLACEHOLDER')} / ${t(
                                          'USERNAME_LABEL',
                                        )?.toLowerCase()}`}
                                      />
                                    </div>

                                    <ErrorMessage name="email" className="text-danger" component="div" />
                                  </div>

                                  {/* Password */}
                                  <div className="mb-4">
                                    <PasswordField setFieldValue={setFieldValue} />
                                  </div>

                                  {/* Submit Button */}
                                  <div className="mt-4 text-center">
                                    <Button
                                      color="primary"
                                      className="w-md waves-effect waves-light"
                                      type="submit"
                                      disabled={isSubmitting || isLoading}
                                    >
                                      {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('LOGIN.TITLE')}
                                    </Button>
                                  </div>

                                  {/* Forgot Password */}
                                  <div className="mt-4 text-center">
                                    <Link to="/forgot-password" className="text-muted">
                                      <i className="mdi mdi-lock me-1"></i> {t('FORGOT_PASSWORD.TITLE')}
                                    </Link>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>

                        {/* Offered Language */}
                        <div className="mt-3 text-center">
                          <strong>{t('OFFERED_IN')} : </strong>

                          {i18n.resolvedLanguage === 'en' ? (
                            <Link onClick={() => changeAppLanguage('he')}>
                              <strong>עברית</strong>
                            </Link>
                          ) : (
                            <Link onClick={() => changeAppLanguage()}>
                              <strong>English</strong>
                            </Link>
                          )}
                        </div>

                        <div className="mt-3 text-center">
                          <FooterInscription />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            {/* Overlay */}
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={isOpened} backdrop="static" className="modal-sm modal-dialog-centered">
          <ModalHeader toggle={() => setIsOpened(false)}>{t('SELECT_COMPANY')}</ModalHeader>

          <ModalBody>
            <Formik
              initialValues={InitCompValues}
              enableReinitialize={true}
              validationSchema={CompanyValidationSchema}
              onSubmit={handleLogin}
            >
              {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
                <Form noValidate>
                  <div className="mb-4">
                    <SelectField
                      fieldName="companyId"
                      fieldValue={values.companyId}
                      fieldOptions={companyOptList}
                      fieldChange={(option) => {
                        if (option) {
                          companyDetails = {name:option.label,id:option.value};
                          LocalstorageService.setItem(CompanyId, option.value);
                        }
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      disabledOptVal="0"
                    />
                  </div>

                  {/* Submit Button */}
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    onClick={() => setOpneAddCompanyModal(true)}
                    disabled={isSubmitting}
                  >
                    {t('ADD_COMPANY')}
                  </Button>
                  <div className="float-end">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-md waves-effect waves-light"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? t('PLEASE_WAIT') : t('CONFIRM')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>

        <Modal isOpen={openAddCompanyModal} backdrop="static" className="modal-sm modal-dialog-centered">
          <ModalHeader toggle={() => setOpneAddCompanyModal(false)}>{t('ADD_COMPANY')}</ModalHeader>

          <ModalBody>
            <Formik
              initialValues={{ companyName: '' }}
              enableReinitialize={true}
              // validationSchema={CompanyValidationSchema}
              onSubmit={handleAddCompany}
            >
              {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
                <Form noValidate>
                  <div className="mb-4">
                    <Label htmlFor="company">{t('COMPANY_LABEL')}</Label>
                    <Field
                      name="companyName"
                      type="text"
                      className="form-control"
                      id="company"
                      placeholder={t('COMPANY_PLACEHOLDER')}
                    />
                  </div>

                  <ErrorMessage name="company" className="text-danger" component="div" />

                  {/* Submit Button */}

                  <div className="float-end">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-md waves-effect waves-light"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? t('PLEASE_WAIT') : t('CONFIRM')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default Login;
