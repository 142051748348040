import { createSlice } from "@reduxjs/toolkit";
import { AUTH_SLICE } from "../../slice-names";

const initialState = {
  isLoading: false,
  authUser: null,
  authToken: null,
};

export const AuthSlice = createSlice({
  name: AUTH_SLICE,
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      return {
        ...state,
        authUser: action.payload,
      };
    },
    setAuthToken: (state, action) => {
      return {
        ...state,
        authToken: action.payload,
      };
    },
    userLogout: (state, action) => {},
  },
});

// Action creators are generated for each case reducer function
export const { setAuthUser, setAuthToken, userLogout } = AuthSlice.actions;

export default AuthSlice.reducer;
