import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../components/Breadcrumb";
import LoadingOverlay from "../../components/LoadingOverlay";

function Location() {
  const { t } = useTranslation();
  const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);

  const [pageTitle, setPageTitle] = useState(t("LOCATION.TITLE"));
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: t("APPLICATION_TITLE"), link: "#" },
    { title: t("LOCATION.TITLE"), link: "#" },
  ]);

  return (
    <React.Fragment>
      <Breadcrumbs title={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card className="card-box">
        {showLoadingOverlay && <LoadingOverlay />}

        <CardBody>
          <Outlet context={[setPageTitle, setBreadcrumbItems]} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default Location;
