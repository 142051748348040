/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';
import { useFormik, Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalFooter, ModalBody, Input, Row, Col, Button, Label } from 'reactstrap';
//import ReactDatePicker from 'react-datepicker';
import CommonField from '../CommonField';
import ReactDatePicker from '../ReactDatePicker';
import RemarkTextAreaField from './RemarkTextArea';
import InputCheckBox from './InputCheckbox';
import * as Yup from 'yup';
export default function AddRemarkModal({
  updateModal,
  setUpdateModal,
  createRemarks,
  remarkDetail,
  handleRemarkCancelAction,
}) {
  const { t } = useTranslation();
  const updateRemark = (values, { resetForm }) => {
    let payloadRequest = { ...values };
    if (payloadRequest.noEndDate) {
      payloadRequest = {
        ...payloadRequest,
        endDate: new Date(),
      };
    }
    delete payloadRequest.noEndDate;
    createRemarks(payloadRequest);
    resetForm();
    setUpdateModal(false);
  };

  // const handleCancelAction = (formik) => {
  //   formik.resetForm();
  //   setUpdateModal(false);
  //   console.log(formik);

  // };
  const ValidationSchema = Yup.object().shape({
    noEndDate: Yup.boolean(),
    remark: Yup.string().required(t('REMARK_ERROR_MSG')).trim(),
    startDate: Yup.string().required(t('SELECT_FROM_DATE_ERROR_MSG')).trim(),
    endDate: Yup.lazy((value, context) => {
      const { noEndDate } = context.parent;
      if (noEndDate) {
        return Yup.mixed().notRequired();
      }
      return Yup.string().required(t('SELECT_DATE_OR_NO_END_ERROR_MSG')).trim();
    }),
  });

  return (
    <React.Fragment>
      <Formik initialValues={remarkDetail} validationSchema={ValidationSchema} onSubmit={updateRemark}>
        {(formik) => (
          <Form>
            <Modal isOpen={updateModal} backdrop="static" className="modal-lg modal-dialog-centered">
              <ModalHeader>{formik.values.remarkId ? t(`LOAD_REMARKS.UPDATE`) : t(`LOAD_REMARKS.ADD`)}</ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column  gap-2">
                  <RemarkTextAreaField
                    fieldType="textarea"
                    id="remark"
                    fieldName="remark"
                    placeholder={t('LOAD_REMARKS.PLACEHOLDER')}
                    label={t('LOAD_REMARKS.REMARK_LABEL')}
                    required={true}
                    handleOnChange={formik.handleChange}
                    handleOnBlur={formik.handleBlur}
                    values={formik.values.remark}
                    error={formik.errors && formik.touched.remark && formik.errors.remark}
                  />
                  <div className="row gap-2 gap-md-0">
                    <div className="col-md-6 col-12 ">
                      <ReactDatePicker
                        fieldId="startDate"
                        fieldName="startDate"
                        fieldLabel={t(`LOAD_REMARKS.FORM_DATE`)}
                        isRequired={true}
                        showIcon={true}
                        value={formik.values.startDate}
                        handleDateChange={(date) => formik.setFieldValue('startDate', date)}
                        highlightDates={[new Date()]}
                        error={formik.errors && formik.touched.startDate && formik.errors.startDate}
                        handleOnBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <ReactDatePicker
                        fieldId="endDate"
                        fieldName="endDate"
                        fieldLabel={t(`LOAD_REMARKS.TO_DATE`)}
                        isRequired={true}
                        showIcon={true}
                        value={formik.values.noEndDate ? '' : formik.values.endDate}
                        handleDateChange={(date) => formik.setFieldValue('endDate', date)}
                        highlightDates={[new Date()]}
                        error={formik.errors && formik.touched.endDate && formik.errors.endDate}
                        isDisable={formik.values.noEndDate}
                        handleOnBlur={formik.handleBlur}
                        minDate={formik.values.startDate || new Date()}
                      />
                    </div>
                  </div>
                  <InputCheckBox
                    fieldId="noEndDate"
                    fieldName="noEndDate"
                    fieldLabel={t(`LOAD_REMARKS.NO_END_CHECKBOX`)}
                    isRequired={false}
                    fieldValue={formik.values.noEndDate}
                    fieldChecked={formik.values.noEndDate}
                    handleCheckBoxChange={(e) => {
                      e.stopPropagation();
                      formik.setFieldValue('noEndDate', e.target.checked);
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-end gap-3">
                  <Button
                    className="editBtn"
                    onClick={() => {
                      // formik.setFieldTouched('remark', true);
                      // formik.setFieldTouched('startDate', true);
                      formik.handleSubmit();
                    }}
                    disabled={formik.isSubmitting}
                  >
                    {formik.values.remarkId ? t(`LOAD_REMARKS.UPDATE`) : t(`LOAD_REMARKS.ADD`)}
                  </Button>

                  <Button className="submitBtn" onClick={() => handleRemarkCancelAction(formik)}>
                    {t(`LOAD_REMARKS.CANCEL_BTN`)}
                  </Button>
                </div>
                {/* {JSON.stringify(formik, null, 2)} */}
              </ModalFooter>
            </Modal>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
