/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
/* eslint eqeqeq: "off" */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLES } from '../../../constants';
import { ConfirmPasswordSchema, EmailSchema, PhoneNumberSchema, UsernameSchema } from '../../../helpers/yup-schema';
import LocalstorageService from '../../../helpers/localstorage-service';
import AccountService from '../../../services/account';
import AuthService from '../../../services/auth';

import { setLoadingOverlay } from '../../../store/slices/loadingOverlaySlice';

import CommonField from '../../../components/CommonField';
import OtpModal from '../../../components/OtpModal';
import PasswordField from '../../../components/PasswordField';
import PhoneNumber from '../../../components/PhoneNumber';
import UpsertHeader from '../../../components/UpsertHeader';
import SelectField from '../../../components/SelectField';

const InitialValues = {
  firstname: '',
  lastname: '',
  knownAs: '',
  username: '',
  mail: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  roleId: '',
  companyId: '',
  verificationMode: 'phone',
};

const AddUserSchema = EmailSchema('mail')
  .concat(ConfirmPasswordSchema())
  .concat(PhoneNumberSchema())
  .concat(UsernameSchema())
  .shape({
    firstname: Yup.string().required(t('REQUIRED')).trim(),
    roleId: Yup.string().required(t('REQUIRED')).trim(),
    companyId: Yup.string().required(t('REQUIRED')).trim(),
  });

function AddUser() {
  const [setCrumbItem] = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otpToken, setOtpToken] = useState();
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [userRoles, setUserRoles] = useState(USER_ROLES);
  const [companyData, setCompanyData] = useState([]);
  const roleIdFilter = LocalstorageService.getItem(JobRole);

  let filterData = [];
  if (roleIdFilter == 1) {
    filterData = USER_ROLES.filter((item) => item.value == roleIdFilter + 1);
  } else if (roleIdFilter == 2) {
    filterData = USER_ROLES.filter((item) => {
      return item.value == roleIdFilter + 1 || item.value == roleIdFilter + 2;
    });
  }

  useEffect(() => {
    setCrumbItem([
      { title: t('ACCOUNT.TITLE'), link: '#' },
      { title: t('USERS_TITLE'), link: 'list' },
      { title: t('ACCOUNT.TITLE'), link: '#' },
    ]);

    const companyIdFilter = LocalstorageService.getItem(CompanyId);
    InitialValues.companyId = companyIdFilter === 0 ? '' : companyIdFilter;
    if (companyIdFilter !== 0) {
      formRef.current.setFieldTouched('companyId', true);
      formRef.current.setFieldValue('companyId', companyIdFilter === 0 ? '' : companyIdFilter);
    }

    setUserRoles(filterData.map((item) => ({ ...item, label: t(`ROLES.${item.id}`) })));
    getCompanyName();
  }, []);

  async function getCompanyName() {
    dispatch(setLoadingOverlay(true));
    await AccountService.getCompanyData()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.companyName,
            value: item.id,
          }));

          setCompanyData(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function sendOtpFn(phoneNumber) {
    dispatch(setLoadingOverlay(true));
    await AuthService.sendOtp({ phoneNumber })
      .then((response) => {
        const { status, message, token } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setOtpToken(token);
        toast.success(message);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function verifyOtpFn(phoneOtp) {
    setVerifyingOtp(true);

    await AuthService.verifyOtp(
      { phoneOtp },
      {
        headers: {
          Authorization: 'Bearer ' + otpToken,
        },
      },
    )
      .then((response) => {
        const { message, status } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        setOtpToken();
        setOtpModalOpen(false);
        navigate('../list');
      })
      .finally(() => {
        setVerifyingOtp(false);
      });
  }

  const toggleOtpModal = () => setOtpModalOpen(!otpModalOpen);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      phoneNumber: values.phoneNumber.replace(/-|_/g, ''),
    };

    delete payload.confirmPassword;

    setIsLoading(true);
    await AccountService.createUser({ ...payload })
      .then((response) => {
        const { status, message, token } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
        setOtpToken(token);
        if (values.verificationMode === 'phone') {
          // sendOtpFn(payload.phoneNumber);
          toggleOtpModal();
        }
        toast.success(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('USERS.ADD_USER')} />

      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        enableReinitialize={true}
        validationSchema={AddUserSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* First Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="firstname"
                    label="FIRST_NAME_LABEL"
                    placeholder="FIRST_NAME_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col>

              {/* Last Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField fieldName="lastname" label="LAST_NAME_LABEL" placeholder="LAST_NAME_PLACEHOLDER" />
                </div>
              </Col>

              {/* Known As */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField fieldName="knownAs" label="KNOWN_AS_LABEL" placeholder="KNOWN_AS_PLACEHOLDER" />
                </div>
              </Col>

              {/* username */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="username"
                    label="USERNAME_LABEL"
                    placeholder="USERNAME_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col>

              {/* Email */}
              <Col sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="mail"
                    fieldType="email"
                    label="EMAIL_LABEL"
                    placeholder="EMAIL_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col>

              {/* Phone No */}
              <Col sm={4}>
                <div className="mb-4">
                  <PhoneNumber required={true} />
                </div>
              </Col>
            </Row>
            <Row>
              {/* Password */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <PasswordField
                    basic={true}
                    showPasswordCriteriaInfo={true}
                    setFieldValue={setFieldValue}
                    setBaseViewPassword={setViewPassword}
                    required={true}
                  />
                </div>
              </Col>

              {/* Confirm Password */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <PasswordField
                    fieldName="confirmPassword"
                    id="confirmUserpassword"
                    label="RESET_PASSWORD.CONFIRM_PASSWORD_LABEL"
                    placeholder="RESET_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER"
                    setFieldValue={setFieldValue}
                    viewPasswordIndicator={false}
                    isViewPassword={viewPassword}
                    simple={true}
                    basic={true}
                    required={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {/* Job Role */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="roleId"
                    fieldLabel={t('JOB_ROLE')}
                    fieldValue={values.roleId}
                    fieldOptions={userRoles}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>

              {/* Company */}
              {roleIdFilter === 1 && (
                <Col xs={6} sm={4}>
                  <div className="mb-4">
                    <SelectField
                      fieldName="companyId"
                      fieldLabel={t('COMPANY.NAME_LABEL')}
                      fieldValue={values.companyId}
                      fieldOptions={companyData}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isRequired={true}
                    />
                  </div>
                </Col>
              )}
            </Row>
            {/* Verification Mode */}
            <Row>
              <Col>
                <label>{t('USERS.CONFIRM_ACCOUNT')}</label>

                <div className="mb-4">
                  <label className="form-check-label">
                    <Field type="radio" name="verificationMode" className="form-check-input" value="phone" />
                    {t('FORGOT_PASSWORD.SMS_LABEL')}
                  </label>

                  <label className="form-check-label">
                    <Field type="radio" name="verificationMode" className="form-check-input" value="email" />
                    {t('EMAIL_LABEL')}
                  </label>
                </div>
              </Col>
            </Row>
            {/* Submit Button */}
            <div className="">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <OtpModal
        isOpen={otpModalOpen}
        toggleOtpModal={toggleOtpModal}
        resendOtpFn={() => {
          sendOtpFn(formRef.current?.values?.phoneNumber);
        }}
        verifyCodeFn={(phoneOtp) => {
          verifyOtpFn(phoneOtp);
        }}
        verifyingCode={verifyingOtp}
      />
    </React.Fragment>
  );
}

export default AddUser;
