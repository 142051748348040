import React, { useEffect, useRef } from 'react';
import { Label } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { ErrorMessage } from 'formik';

const NumericFormatField = ({
  fieldName = '',
  fieldId = fieldName,
  fieldLabel = '',
  fieldValue = '',
  fieldChange = () => {},
  fieldPlaceholder = '',
  fieldDecimalScale = 0,
  useThousandSeparator = true,
  isRequired = true,
  setFieldValue,
  isDisabled = false,
  setFieldTouched = () => {},
  autoFocus = false,
  allowNegative=false,
  ...rest
}) => {
  const ref = useRef();
  useEffect(() => {
    if (ref?.current) {
      ref?.current.focus();
    }
  }, []);
  return (
    <React.Fragment>
      <Label htmlFor={fieldId}>
        {isRequired && '*'}
        {fieldLabel}
      </Label>

      <NumericFormat
        id={fieldId}
        name={fieldName}
        className="form-control"
        value={fieldValue}
        allowNegative={allowNegative}
        thousandSeparator={useThousandSeparator}
        decimalScale={fieldDecimalScale}
        {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
        placeholder={fieldPlaceholder}
        onValueChange={(values) => {
          const { value } = values;
          setFieldValue(fieldName, value);
          fieldChange(values);
        }}
        onBlur={() => setFieldTouched(fieldName, true)}
        autoComplete="off"
        disabled={isDisabled}
        getInputRef={autoFocus ? ref : null}
        {...rest}
      />

      <ErrorMessage name={fieldName} className="text-danger" component="div" />
    </React.Fragment>
  );
};

export default NumericFormatField;
