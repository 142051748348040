/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Form, Input, InputGroup, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { t } from 'i18next';

import { CompanyId, ITEMS_PER_PAGE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import AgentService from '../../services/agent';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import ActionBtns from '../../components/ActionBtns';

const HeaderList = [
  {
    title: t('FULL_NAME'),
    value: 'fullName',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMAIL_LABEL'),
    value: 'mail',
    style: { minWidth: '200px', width: '30%' },
  },
  {
    title: t('MOBILE_NO_LABEL'),
    value: 'phoneNumber',
    style: { minWidth: '150px', width: '30%' },
  },
];

const reqPayload = { pageNo: 1 };

function AgentList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setPageTitle, setBreadcrumbItems] = useOutletContext();

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setPageTitle(t('AGENT.TITLE'));
    setBreadcrumbItems([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('AGENT.TITLE'), link: '#' },
    ]);

    const companyId = LocalstorageService.getItem(CompanyId);
    reqPayload['companyId'] = companyId;
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  }, [search]);

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await AgentService.listAgent(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        setList(dataList || []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const resetModal = () => {
    setDeleteItem(null);
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    await AgentService.deleteAgent({ id: deleteItem.id })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        setIsLoading(false);
      });

    resetModal();
  };

  const editFn = (item) => {
    const fullName = `${item.firstName} ${item.lastName}`;
    navigate(`../update/${item.id}`, {
      state: { fullName, companyId: item.companyId },
    });
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }
    listFn();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    listFn();
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
          <InputGroup>
            <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
            <Button color="primary" type="button">
              <i className="ri-search-line"></i>
            </Button>
          </InputGroup>
        </Form>
      </div>

      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={HeaderList} isDisabled={list.length === 0} sortClick={sortBy} />
          </tr>
        </thead>

        <tbody>
          {list.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className="actionCol">
                <ActionBtns updateTitle={t('AGENT.UPDATE')} updateFn={() => editFn(item)} />
              </td>
              <td>{item.firstName + ' ' + item.lastName}</td>
              <td>{item.mail}</td>
              <td>{item.phoneNumber}</td>
            </tr>
          ))}

          {!isLoading && list.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>

      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        confirmFn={confirmDelete}
        cancelFn={resetModal}
      />
    </React.Fragment>
  );
}

export default AgentList;
