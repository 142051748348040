import { createSlice } from "@reduxjs/toolkit";
import { LOADING_OVERLAY_SLICE } from "../slice-names";

const initialState = {
  showLoadingOverlay: false,
};

export const LoadingOverlay = createSlice({
  name: LOADING_OVERLAY_SLICE,
  initialState,
  reducers: {
    setLoadingOverlay: (state, action) => {
      return {
        ...state,
        showLoadingOverlay: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingOverlay } = LoadingOverlay.actions;

export default LoadingOverlay.reducer;
