import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { AddBillsSchema, AmountSchema } from '../../helpers/yup-schema';
import EmpActivityService from '../../services/emp_activity';

import CommonField from '../../components/CommonField';
import NumericFormatField from '../../components/NumericFormatField';

const InitialValues = {
  wasIndrawer: '',
  addBills: '',
  leadingBatch: '',
  batch: '',
};

const ValidationSchema = AmountSchema('wasIndrawer')
  .concat(AddBillsSchema('addBills'))
  .shape({
    leadingBatch: Yup.string().required(t('REQUIRED')).trim(),
    batch: Yup.string().length(4, t('CHARGE_TERMINAL.BATCH_ERR')).required(t('REQUIRED')),
  });

function ConfirmSaveDataChargeTerm({
  formData = {},
  isOpened = false,
  toggleModal = () => {},
  terminalWithdraw = false,
  withdrawalTerminal = false,
  onClosed=()=>{}
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitFn = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const payload = {
      ...formData,
      ...values,
      batch: values.leadingBatch === '0000' ? '0000' : `${values.leadingBatch}${values.batch}`,
    };

    delete payload['leadingBatch'];
    setIsLoading(true);
    if (terminalWithdraw) {
      if (withdrawalTerminal) {
        await EmpActivityService.terminalWithdraw(payload)
          .then((response) => {
            const { status, message } = response.data;

            if (status === 400) {
              toast.error(message);
              return;
            }

            toast.success(message);
            onClosed()
            toggleModal(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        await EmpActivityService.withdrawalMoney(payload)
          .then((response) => {
            const { status, message } = response.data;

            if (status === 400) {
              toast.error(message);
              return;
            }

            toast.success(message);
            onClosed()
            toggleModal(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      await EmpActivityService.chargeTerminal(payload)
        .then((response) => {
          const { status, message } = response.data;

          if (status === 400) {
            toast.error(message);
            return;
          }

          toast.success(message);
          onClosed()
          toggleModal(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpened} toggle={toggleModal} backdrop="static">
        <Formik
          initialValues={{ ...InitialValues, ...formData }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmitFn}
        >
          {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
            <Form id="emp-act-charging-terminal">
              <ModalHeader toggle={() => toggleModal()}>{t('CONFIRM_DETAILS')}</ModalHeader>

              <ModalBody>
                <Row>
                  {/* Bills Drawer */}
                  <Col sm={6}>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="wasIndrawer"
                        fieldValue={values.wasIndrawer}
                        fieldLabel={t('CHARGE_TERMINAL.BILLS_DRAWER_LABEL')}
                        fieldPlaceholder={t('CHARGE_TERMINAL.BILLS_DRAWER_PLACEHOLDER')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                  </Col>

                  {/* Add Bills */}
                  <Col sm={6}>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="addBills"
                        fieldValue={values.addBills}
                        fieldLabel={
                          terminalWithdraw
                            ? t('CHARGE_TERMINAL.WITHDRAWAL_BILLS_LABEL')
                            : t('CHARGE_TERMINAL.ADD_BILLS_LABEL')
                        }
                        fieldPlaceholder={
                          terminalWithdraw
                            ? t('CHARGE_TERMINAL.WITHDRAWAL_BILLS_PLACEHOLDER')
                            : t('CHARGE_TERMINAL.ADD_BILLS_PLACEHOLDER')
                        }
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        allowNegative={true}
                        className="form-control align-left"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="batch-row">
                  {/* Leading Batch Number */}
                  <Col sm={6}>
                    <div className="mb-4">
                      <CommonField
                        fieldName="leadingBatch"
                        label={t('CHARGE_TERMINAL.LEAD_BATCH_LABEL')}
                        placeholder={t('CHARGE_TERMINAL.LEAD_BATCH_PLACEHOLDER')}
                        required={true}
                        isDisabled={true}
                      />
                    </div>
                  </Col>

                  {/* End Batch Number */}
                  <Col sm={6}>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="batch"
                        fieldValue={values.batch}
                        fieldLabel={t('CHARGE_TERMINAL.END_BATCH_LABEL')}
                        fieldPlaceholder={t('CHARGE_TERMINAL.END_BATCH_PLACEHOLDER')}
                        maxLength={4}
                        allowLeadingZeros={true}
                        useThousandSeparator={false}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>

              <ModalFooter>
                <Button color="light" onClick={() => toggleModal()}>
                  {t('CANCEL')}
                </Button>

                {/* Submit Button */}
                <div className="">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md waves-effect waves-light"
                    disabled={isSubmitting || isLoading}
                  >
                    {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('CONFIRM')}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmSaveDataChargeTerm;
