/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import MachineService from '../../services/machines';
import { toast } from 'react-toastify';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import edit from '../../assets/images/edit.svg';
import deletes from '../../assets/images/delete.svg';
//import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import ConfirmDelete from './ConfirmDelete';
import AddRemarkModal from '../../components/RemarkModal/AddRemarkModal';
function RemarkModal({ showRemarkModal, setShowRemarkModal }) {
  const { t } = useTranslation();
  // const { id: terminalNumber } = useParams();
  const terminalNumber = showRemarkModal.terminalNumber;
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remarkList, setRemarkList] = useState([]);
  const [remark, setRemark] = useState({ remarkId: '', remark: '', start: '', end: '', noEnd: '' });
  const [updateModal, setUpdateModal] = useState(false);
  const [delecteId, setDeleteId] = useState({ remarkId: '', remark: '' });
  const [showDeleteModal, setDeleteModal] = useState(false);
  const sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
  const [dateForModal, setDateForModal] = useState({ start: sevenDaysBeforeDate, end: new Date() });
  const [noEndCheckbox, setNoEndCheckbox] = useState(false);
  const [error, setError] = useState({ start: false, errorRemark: false, selectAny: false });
  const [remarkDetail, setRemarkDetail] = useState({
    remarkId: 0,
    rowStatus: 0,
    terminalNumber: terminalNumber,
    remark: '',
    startDate: new Date(),
    endDate: '',
    noEndDate: false,
  });
  const dateRef = useRef();
  const dateRef2 = useRef();

  useEffect(() => {
    setPageTitle(t('LOAD_REMARKS.TITLE'));
    setCrumbItem([
      { title: t('LOADING_TERMINAL.TITLE'), link: 'list' },
      { title: t('LOAD_REMARKS.TITLE'), link: '#' },
    ]);
  }, []);

  useEffect(() => {
    if (terminalNumber) {
      getRemarks();
    }
  }, [showRemarkModal, dateForModal]);
  const getRemarks = () => {
    const reqPayload = {};
    if (noEndCheckbox) {
      reqPayload['terminalNumber'] = terminalNumber;
      reqPayload['remarkId'] = 0;
      reqPayload['rowStatus'] = 0;
      // reqPayload['startDate'] = dateForModal.start;
    } else {
      reqPayload['terminalNumber'] = terminalNumber;
      reqPayload['remarkId'] = 0;
      reqPayload['rowStatus'] = 0;
      reqPayload['startDate'] = dateForModal.start;
      reqPayload['endDate'] = dateForModal.end;
    }
    fetchRemarks(reqPayload);
  };

  const deleteRemark = () => {
    const reqPayload = {};
    reqPayload['terminalNumber'] = terminalNumber;
    reqPayload['remarkId'] = delecteId.remarkId;
    reqPayload['rowStatus'] = -1;
    reqPayload['remark'] = delecteId.remark;
    reqPayload['startDate'] = dateForModal.start;
    reqPayload['endDate'] = dateForModal.end;
    createRemarks(reqPayload);
    setDeleteModal(false);
    // getRemarks();
  };

  useEffect(() => {
    if (delecteId?.remarkId) {
      setDeleteModal(true);
    }
  }, [delecteId.remarkId]);

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleUpdateModal = (open, values = '') => {
    if (open) {
      setUpdateModal(open);
    }
    if (values && values.remarkId) {
      setRemarkDetail({
        ...values,
        rowStatus: 1,
        startDate: values.startDate ? new Date(values.startDate) : new Date(),
        endDate: values.endDate ? new Date(values.endDate) : '',
      });
    }
    // if (open) {
    //   setUpdateModal(open);
    //   //setRemark({ remarkId: values.remarkId, remark: values.remark });
    //   setRemarkDetail({
    //     ...values,
    //     startDate: new Date(values.startDate),
    //     endDate: new Date(values.endDate),
    //   });
    // } else if (!values) {
    //   setUpdateModal(open);
    // }
  };

  async function fetchRemarks(reqPayload) {
    dispatch(setLoadingOverlay(true));
    await MachineService.getRemarks(reqPayload)
      .then((response) => {
        const { dataList } = response.data;

        setRemarkList(dataList);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function createRemarks(reqPayload) {
    dispatch(setLoadingOverlay(true));
    await MachineService.addRemarks(reqPayload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        getRemarks();
      });
  }
  const handleRemarkCancelAction = (form) => {
    form.resetForm();
    setRemarkDetail({
      remarkId: 0,
      rowStatus: 0,
      terminalNumber: terminalNumber,
      remark: '',
      startDate: new Date(),
      endDate: '',
      noEndDate: false,
    });
    setUpdateModal(false);
  };
  return (
    <React.Fragment>
      {showRemarkModal.shown && (
        <Modal isOpen={showRemarkModal.shown} backdrop="static" className="modal-lg modal-dialog-centered">
          <ModalHeader>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start">
                <Button onClick={() => setShowRemarkModal({ shown: false, remarkId: '', terminalNumber: '' })}>
                  {t(`LOAD_REMARKS.CLOSE_BTN`)}
                </Button>

                <div className="d-flex w-full  ">
                  <Button
                    className="editBtn ms-2"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => setUpdateModal(!updateModal)}
                  >
                    {t(`LOAD_REMARKS.ADD`)}
                  </Button>
                </div>
              </div>

              {/* <h4 className="card-title mb-0">remarks</h4> */}
            </div>

            <hr className="mt-2" />
          </ModalHeader>
          <ModalBody>
            <div className="border">
              {remarkList?.length > 0 ? (
                remarkList?.map((value, index) => {
                  return (
                    <div className="remarkList">
                      <span>
                        {' '}
                        <i>{`${index + 1}).`}</i> {value.remark}
                      </span>
                      <div>
                        <button
                          id={`editBtn${index}`}
                          className="border-0 bg-transparent"
                          onClick={() => handleUpdateModal(true, value)}
                        >
                          <UncontrolledTooltip placement="right" target={`editBtn${index}`}>
                            {t('LOAD_REMARKS.UPDATE')}
                          </UncontrolledTooltip>
                          <img src={edit} alt="" />
                        </button>
                        <button
                          id={`dltBtn${index}`}
                          className="border-0 bg-transparent"
                          onClick={() => setDeleteId({ remark: value.remark, remarkId: value.remarkId })}
                        >
                          <UncontrolledTooltip placement="right" target={`dltBtn${index}`}>
                            {t('LOAD_REMARKS.DELETE_BTN')}
                          </UncontrolledTooltip>
                          <img src={deletes} alt="" />
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h4 className="text-center p-4">{t('TABLE_NO_RECORDS')}</h4>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col sm={4}>
                <div className="d-flex justify-content-around align-items-center">
                  <label htmlFor="dateStart" style={{ width: '50%' }}>
                    {t(`LOAD_REMARKS.FORM_DATE`)}
                  </label>
                  <ReactDatePicker
                    ref={dateRef}
                    name="dateStart"
                    id="dateStart"
                    className="form-control"
                    selected={noEndCheckbox ? '' : dateForModal.start}
                    onChange={(dateArr) =>
                      setDateForModal((prev) => {
                        return { ...prev, start: dateArr };
                      })
                    }
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                    disabled={noEndCheckbox}
                  ></ReactDatePicker>
                </div>
              </Col>
              <Col sm={4}>
                <div className="d-flex justify-content-around align-items-center">
                  <label htmlFor="dateEnd" style={{ width: '50%' }}>
                    {t(`LOAD_REMARKS.TO_DATE`)}
                  </label>
                  <ReactDatePicker
                    ref={dateRef2}
                    name="dateEnd"
                    id="dateEnd"
                    className="form-control"
                    selected={noEndCheckbox ? '' : dateForModal.end}
                    onChange={(dateArr) =>
                      setDateForModal((prev) => {
                        return { ...prev, end: dateArr };
                      })
                    }
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                    disabled={noEndCheckbox}
                  ></ReactDatePicker>
                </div>
              </Col>
              <Col sm={4} className="d-none d-sm-flex align-items-center justify-content-center">
                <div className="d-flex">
                  <Input
                    type="checkbox"
                    name="noEnd"
                    id="noEnd"
                    style={{ marginRight: '5px', width: '1.26em' }}
                    onChange={(e) => {
                      setNoEndCheckbox(e.target.checked);
                    }}
                  />
                  <Label htmlFor="noEnd">{t(`LOAD_REMARKS.NO_END_CHECKBOX`)}</Label>
                </div>
              </Col>
            </Row>
          </ModalFooter>

          {/* <div className="d-flex justify-content-around"> */}
        </Modal>
      )}

      {updateModal && (
        <AddRemarkModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          createRemarks={createRemarks}
          remarkDetail={remarkDetail}
          handleRemarkCancelAction={handleRemarkCancelAction}
        />
      )}

      {showDeleteModal && (
        // <ConfirmDeleteModal
        //   isOpen={showDeleteModal}
        //   // isLoading={isLoading}
        //   confirmFn={deleteRemark}
        //   cancelFn={closeDeleteModal}
        // />
        <ConfirmDelete
          showDeleteModal={showDeleteModal}
          deleteRemark={deleteRemark}
          closeDeleteModal={closeDeleteModal}
          deleteMessage={t(`LOAD_REMARKS.DELETE_MSG`)}
        />
      )}
    </React.Fragment>
  );
}

export default RemarkModal;
