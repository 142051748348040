import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { checkAuthToken, removeAuthUserInfo } from "../helpers";
import { userLogout } from "../store/slices/Auth/authSlice";

const RouteAuthGuard = ({ isAuthProtected, isLockScreen, children }) => {
  const token = checkAuthToken();
  const dispatch = useDispatch();

  if (!isAuthProtected && token) {
    return <Navigate to="/dashboard" replace />;
  } else if (isAuthProtected && !token) {
    removeAuthUserInfo();
    dispatch(userLogout());
    return <Navigate to="/login" replace />;
  } else if (isAuthProtected && token && isLockScreen) {
    return <Navigate to="/lock-screen" replace />;
  }

  return children;
};

export default RouteAuthGuard;
