/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Button, Form, Input, InputGroup, Table, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

import { CompanyId, ITEMS_PER_PAGE } from '../../../constants';
import LocalstorageService from '../../../helpers/localstorage-service';
import AccountService from '../../../services/account';

import { setLoadingOverlay } from '../../../store/slices/loadingOverlaySlice';

import SortableHeader from '../../../components/SortableHeader';
import NoRecords from '../../../components/Table/NoRecords';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import ActionBtns from '../../../components/ActionBtns';

const HeaderList = [
  {
    title: t('USERNAME_LABEL'),
    value: 'userName',
    style: { minWidth: '150px', width: '20%' },
  },
  {
    title: t('ROLE'),
    value: 'role',
    style: { minWidth: '150px', width: '20%' },
  },
  {
    title: t('FIRST_NAME_LABEL'),
    value: 'fullName',
    style: { minWidth: '150px', width: '20%' },
  },
  {
    title: t('EMAIL_LABEL'),
    value: 'mail',
    style: { minWidth: '200px', width: '30%' },
  },
  {
    title: t('PHONE_NO_LABEL'),
    value: 'phoneNumber',
    style: { minWidth: '150px', width: '20%' },
  },
];

const reqPayload = { pageNo: 1 };

function UserList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setCrumbItem] = useOutletContext();

  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setCrumbItem([
      { title: t('ACCOUNT.TITLE'), link: '#' },
      { title: t('USERS_TITLE'), link: '#' },
    ]);

    const companyId = LocalstorageService.getItem(CompanyId);
    reqPayload['companyId'] = companyId;
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listUsers();
  }, [search]);

  async function listUsers() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await AccountService.listUsers(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setUserList(dataList || []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const deleteModal = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };

  const resetModal = () => {
    setDeleteItem(null);
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    await AccountService.deleteUser({ id: deleteItem.id })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (userList.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        toast.success(message);
        listUsers();
      })
      .finally(() => {
        setIsLoading(false);
      });

    resetModal();
  };

  const editUser = (user) => {
    navigate(`../update/${user.id}`);
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }
    listUsers();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value);
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    listUsers();
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
          <InputGroup>
            <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
            <Button color="primary" type="button">
              <i className="ri-search-line"></i>
            </Button>
          </InputGroup>
        </Form>

        <Button color="primary" className="float-end" onClick={() => navigate('../add')}>
          <span className="d-none d-sm-block">{t('USERS.ADD_USER')}</span>
          <i className="ri-add-line d-block d-sm-none" title={t('USERS.ADD_USER')}></i>
        </Button>
      </div>

      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={HeaderList} isDisabled={userList.length === 0} sortClick={sortBy} />
          </tr>
        </thead>

        <tbody>
          {userList.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className="actionCol">
                <ActionBtns
                  deleteTitle={t('USERS.DELETE_USER')}
                  deleteFn={() => deleteModal(item)}
                  updateTitle={t('USERS.UPDATE_USER')}
                  updateFn={() => editUser(item)}
                />
              </td>
              <td>{item.userName}</td>
              <td>{item.role}</td>
              <td>{item.firstName}</td>
              <td>
                <div className="d-flex align-items-center">
                  <i
                    id={`emailVerified_${l0Idx}`}
                    className={`ri-${item.confirmedMail ? 'checkbox' : 'close'}-circle-fill text-${
                      item.confirmedMail ? 'success' : 'danger'
                    }`}
                  ></i>
                  <UncontrolledTooltip placement="auto" target={`emailVerified_${l0Idx}`}>
                    {t(item.confirmedMail ? 'VERIFIED' : 'UNVERIFIED')}
                  </UncontrolledTooltip>
                  &nbsp;
                  {item.mail}
                </div>
              </td>

              <td>
                <div className="d-flex align-items-center">
                  <i
                    id={`phoneVerified_${l0Idx}`}
                    className={`ri-${item.confirmedPhone ? 'checkbox' : 'close'}-circle-fill text-${
                      item.confirmedPhone ? 'success' : 'danger'
                    }`}
                  ></i>
                  <UncontrolledTooltip placement="auto" target={`phoneVerified_${l0Idx}`}>
                    {t(item.confirmedPhone ? 'VERIFIED' : 'UNVERIFIED')}
                  </UncontrolledTooltip>
                  &nbsp;
                  {item.phoneNumber}
                </div>
              </td>
            </tr>
          ))}

          {!isLoading && userList.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>

      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        confirmFn={confirmDelete}
        cancelFn={resetModal}
      />
    </React.Fragment>
  );
}

export default UserList;
