/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import { Field, Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import { t } from 'i18next';

const EmployeeCancelLoadModal = ({ isOpen, setIsOpen, values, handleSubmit }) => {
  const formRef = useRef();
  const [modalValues, setModalValues] = useState(values);
  const [remark, setRemark] = useState();
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;

    setRemark(value);
  };

  const checkError = () => {
    const error = { remark: false };

    if (!remark) {
      error.remark = true;
    } else {
      error.remark = false;
    }

    setError(error.remark);
    return error;
  };
  const handleCancelSubmit = () => {
    const { remark } = checkError();
    if (!remark) {
      setError(false);
      handleSubmit({ ...modalValues, status: 2 });
      setIsOpen({ shown: false });
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} backdrop="static" className="modal-lg modal-dialog-centered">
        <ModalHeader>{t('EMPLOYEE_LOADS.CANCEL_MODAL')}</ModalHeader>

        <ModalBody style={{ maxHeight: '300px', overflow: 'auto' }}>
          <Formik
            innerRef={formRef}
            initialValues={''}
            // validationSchema={ValidationSchema}
            // onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, setFieldTouched }) => (
              <Form noValidate>
                <Row>
                  {/* <Col sm={5}> */}
                  <div className="gap-3" style={{ display: 'grid', gridTemplateColumns: 'repeat(2,minmax(0,1fr))' }}>
                    <div className="">
                      <label className="">{t(`LOAD_TABS.BUSINESS_NAME_LABEL`)}</label>
                      <Field
                        name={'locationName'}
                        className="form-control "
                        type="text"
                        value={modalValues.locationName}
                        onBlur={() => setFieldTouched('locationName', true)}
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                    <div className="">
                      <label className="">{t(`LOAD_TABS.TERMINAL_NUMBER_LABEL`)}</label>
                      <Field
                        name={'terminalNumber'}
                        className="form-control"
                        value={modalValues.terminalNumber}
                        type="number"
                        onBlur={() => setFieldTouched('terminalNumber', true)}
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                  </div>
                  {/* </Col> */}
                </Row>
                <div className="mt-3">
                  <h4 className="d-flex justify-content-around">{t(`EMPLOYEE_LOADS.REMARK`)}</h4>
                  <div className="border mt-2">
                    <Input
                      type="textarea"
                      id="remark"
                      name="remark"
                      value={remark}
                      onChange={handleChange}
                      placeholder={t('LOAD_REMARKS.PLACEHOLDER')}
                    />
                  </div>
                  {error && <p style={{ color: 'red' }}>{t(`REMARK_ERROR_MSG`)}</p>}
                </div>
                <div className="m-2 d-flex justify-content-center">
                  <Button
                    color="danger"
                    onClick={() => {
                      handleCancelSubmit();
                    }}
                  >
                    {t(`EMPLOYEE_LOADS.CANCEL_LOAD_BTN`)}
                  </Button>
                  <Button className="ms-4" onClick={() => setIsOpen({ shown: false })}>
                    {t(`EMPLOYEE_LOADS.CANCEL_BTN`)}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EmployeeCancelLoadModal;
