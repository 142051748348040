/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';
import { Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import MachineService from '../../services/machines';
import { toast } from 'react-toastify';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import edit from '../../assets/images/edit.svg';
import deletes from '../../assets/images/delete.svg';
import ConfirmDelete from '../EmployeeActivity/ConfirmDelete';
import LocationService from '../../services/location';
import AddRemarkModal from '../../components/RemarkModal/AddRemarkModal';
function RemarkList({ updateModal, setUpdateModal }) {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [terminalNumber, setTerminalNumber] = useState();
  const [remarkList, setRemarkList] = useState([]);
  const [remark, setRemark] = useState({ remarkId: '', remark: '', terminalNumber: '' });
  // const [updateModal, setUpdateModal] = useState(false);
  const [delecteId, setDeleteId] = useState({ remarkId: '', remark: '', terminalNumber: '' });
  const [showDeleteModal, setDeleteModal] = useState(false);
  const sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
  const [dateForModal, setDateForModal] = useState({ start: sevenDaysBeforeDate, end: new Date() });
  const [noEndCheckbox, setNoEndCheckbox] = useState(false);
  const [error, setError] = useState({ start: false, errorRemark: false, selectAny: false });
  const [remarkDetail, setRemarkDetail] = useState({
    remarkId: 0,
    rowStatus: 0,
    terminalNumber: terminalNumber,
    remark: '',
    startDate: new Date(),
    endDate: '',
    noEndDate: false,
  });
  const dateRef = useRef();
  const dateRef2 = useRef();

  const getRemarks = () => {
    const reqPayload = {};
    if (noEndCheckbox) {
      reqPayload['terminalNumber'] = terminalNumber;
      reqPayload['remarkId'] = 0;
      reqPayload['rowStatus'] = 0;
      // reqPayload['startDate'] = dateForModal.start;
    } else {
      reqPayload['terminalNumber'] = terminalNumber;
      reqPayload['remarkId'] = 0;
      reqPayload['rowStatus'] = 0;
      reqPayload['startDate'] = dateForModal.start;
      reqPayload['endDate'] = dateForModal.end;
    }

    fetchRemarks(reqPayload);
  };

  const deleteRemark = () => {
    const reqPayload = {};
    reqPayload['terminalNumber'] = terminalNumber;
    reqPayload['remarkId'] = delecteId.remarkId;
    reqPayload['rowStatus'] = -1;
    reqPayload['remark'] = delecteId.remark;
    reqPayload['startDate'] = dateForModal.start;
    reqPayload['endDate'] = dateForModal.end;
    createRemarks(reqPayload);
    setDeleteModal(false);
    // getRemarks();
  };

  async function getMachine() {
    dispatch(setLoadingOverlay(true));
    await LocationService.listLocation({ id: locationId }).then((response) => {
      const { status, data } = response.data;

      if (status === 400) {
        return;
      }
      dispatch(setLoadingOverlay(false));
      setTerminalNumber(data.terminalNumber);
      setRemarkDetail((pre) => {
        return {
          ...pre,
          terminalNumber: data.terminalNumber,
        };
      });
    });
  }

  useEffect(() => {
    if (terminalNumber) {
      getRemarks();
    } else {
      console.log('here1');
      getMachine();
    }
  }, [dateForModal.end, dateForModal.start, noEndCheckbox, terminalNumber]);

  // useEffect(() => {
  //   if (delecteId?.remarkId) {
  //     setDeleteModal(true);
  //   }
  // }, [delecteId.remarkId]);

  const deleteRemarkById = (data) => {
    setDeleteId({ ...data });
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleRemarkCancelAction = (form) => {
    form.resetForm();
    setRemarkDetail({
      remarkId: 0,
      rowStatus: 0,
      terminalNumber: terminalNumber,
      remark: '',
      startDate: new Date(),
      endDate: '',
      noEndDate: false,
    });
    setUpdateModal(false);
  };
  const handleUpdateModal = (open, values = '') => {
    if (open) {
      setUpdateModal(open);
    }
    if (values && values.remarkId) {
      setRemarkDetail({
        ...values,
        rowStatus: 1,
        startDate: values.startDate ? new Date(values.startDate) : new Date(),
        endDate: values.endDate ? new Date(values.endDate) : '',
      });
    }
    // if (open) {
    //   setUpdateModal(open);
    //   //setRemark({ remarkId: values.remarkId, remark: values.remark });
    //   setRemarkDetail({
    //     ...values,
    //     startDate: new Date(values.startDate),
    //     endDate: new Date(values.endDate),
    //   });
    // } else if (!values) {
    //   setUpdateModal(open);
    // }
  };

  async function fetchRemarks(reqPayload) {
    // setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.getRemarks(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        setRemarkList(dataList);
      })
      .finally(() => {
        // setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  async function createRemarks(reqPayload) {
    // setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.addRemarks(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
      })
      .finally(() => {
        // setIsLoading(false);
        dispatch(setLoadingOverlay(false));
        getRemarks();
      });
  }

  return (
    <React.Fragment>
      {/* <div className="d-flex w-full mb-2 ">
        <Button className="editBtn" style={{ marginLeft: 'auto' }} onClick={() => handleUpdateModal(true)}>
          {t(`LOAD_REMARKS.ADD`)}
        </Button>
      </div> */}

      {remarkList?.length > 0 ? (
        remarkList.map((value, index) => {
          return (
            <>
              <div className="remarkList">
                <span>
                  {' '}
                  <i>{`${index + 1}).`}</i> {value.remark}
                </span>
                <div>
                  <button
                    id={`editBtn${index}`}
                    className="border-0 bg-transparent"
                    onClick={() => handleUpdateModal(true, value)}
                  >
                    <UncontrolledTooltip placement="right" target={`editBtn${index}`}>
                      {t('LOAD_REMARKS.EDIT_BTN')}
                    </UncontrolledTooltip>
                    <img src={edit} alt="" />
                  </button>
                  <button
                    id={`dltBtn${index}`}
                    className="border-0 bg-transparent"
                    // onClick={() => setDeleteId({ remark: value.remark, remarkId: value.remarkId })}
                    onClick={() => deleteRemarkById({ remark: value.remark, remarkId: value.remarkId })}
                  >
                    <UncontrolledTooltip placement="right" target={`dltBtn${index}`}>
                      {t('LOAD_REMARKS.DELETE_BTN')}
                    </UncontrolledTooltip>
                    <img src={deletes} alt="" />
                  </button>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <h4 className="text-center p-4">{t('TABLE_NO_RECORDS')}</h4>
      )}
      <br className="d-none d-sm-block" />
      <br />
      {/* <div className="d-flex justify-content-around"> */}
      <Row>
        <Col sm={4}>
          <div className="d-flex justify-content-around align-items-center">
            <label htmlFor="dateStart" style={{ width: '50%' }}>
              {t(`LOAD_REMARKS.FORM_DATE`)}
            </label>
            <ReactDatePicker
              ref={dateRef}
              name="dateStart"
              id="dateStart"
              className="form-control "
              selected={noEndCheckbox ? '' : dateForModal.start}
              onChange={(dateArr) =>
                setDateForModal((prev) => {
                  return { ...prev, start: dateArr };
                })
              }
               highlightDates={[new Date()]}
              dateFormat={'dd/MM/yyyy'}
              disabled={noEndCheckbox}
            />
          </div>
        </Col>
        <Col sm={4}>
          <div className="d-flex justify-content-around align-items-center">
            <label htmlFor="dateEnd" style={{ width: '50%' }}>
              {t(`LOAD_REMARKS.TO_DATE`)}
            </label>
            <ReactDatePicker
              ref={dateRef2}
              name="dateEnd"
              id="dateEnd"
              className="form-control"
              selected={noEndCheckbox ? '' : dateForModal.end}
              onChange={(dateArr) =>
                setDateForModal((prev) => {
                  return { ...prev, end: dateArr };
                })
              }
              highlightDates={[new Date()]}
              dateFormat={'dd/MM/yyyy'}
              disabled={noEndCheckbox}
            />
          </div>
        </Col>
        <Col sm={4}>
          <div className="d-none d-sm-flex align-items-start justify-content-center ">
            <Input
              type="checkbox"
              name="noEnd"
              id="noEnd"
              style={{ marginRight: '5px', width: '1em' }}
              onChange={(e) => {
                setNoEndCheckbox(e.target.checked);
              }}
            />
            <label htmlFor="noEnd">{t(`LOAD_REMARKS.NO_END_CHECKBOX`)}</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <div className="d-sm-none align-items-start justify-content-center ">
            <Input
              type="checkbox"
              name="noEnd"
              id="noEnd"
              style={{ marginRight: '5px', width: '1em' }}
              onChange={(e) => {
                setNoEndCheckbox(e.target.checked);
              }}
            />
            <label htmlFor="noEnd">{t(`LOAD_REMARKS.NO_END_CHECKBOX`)}</label>
          </div>
        </Col>
      </Row>
      <p className="pt-2">
        {`${t(`SPECIAL_DAYS.NOTE_LABEL`)} :`}
        <span style={{ color: 'red' }}> {t(`REMARK_NOTE`)}</span>
      </p>
      {/* </div> */}
      {/* <div className="d-flex justify-content-center ">
                <button

                    className="editBtn"
                    onClick={() => navigate('/locations/update')}
                >
                    {t(`LOAD_REMARKS.SUBMIT_BTN`)}
                </button>
            </div> */}

      {updateModal && (
        <AddRemarkModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          createRemarks={createRemarks}
          remarkDetail={remarkDetail}
          handleRemarkCancelAction={handleRemarkCancelAction}
        />
      )}

      {showDeleteModal && (
        <ConfirmDelete
          showDeleteModal={showDeleteModal}
          deleteRemark={deleteRemark}
          closeDeleteModal={closeDeleteModal}
          deleteMessage={t(`LOAD_REMARKS.DELETE_MSG`)}
        />
      )}
    </React.Fragment>
  );
}

export default RemarkList;
