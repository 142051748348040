import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "formik";

import NumericFormatField from "./NumericFormatField";

function OtpField({
  fieldName = "otp",
  fieldValue = "",
  setFieldValue,
  setFieldTouched,
  btnType = "submit",
  verifyingCode,
  verifyCodeFn = () => {},
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="input-group">
        <NumericFormatField
          maxLength="10"
          fieldName={fieldName}
          fieldValue={fieldValue}
          useThousandSeparator={false}
          fieldPlaceholder={t("FORGOT_PASSWORD.ENTER_CODE")}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          allowLeadingZeros={true}
          isRequired={false}
        />

        <Button
          color="primary"
          className="w-md waves-effect waves-light"
          type={btnType}
          onClick={() => verifyCodeFn(fieldValue)}
          disabled={verifyingCode}
        >
          {verifyingCode ? t("PLEASE_WAIT") : t("FORGOT_PASSWORD.VERIFY_CODE")}
        </Button>
      </div>

      <ErrorMessage name={fieldName} className="text-danger" component="div" />
    </React.Fragment>
  );
}

export default OtpField;
