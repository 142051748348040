/*eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import { useEffect, useRef, useState } from 'react';
import LocalstorageService from '../../helpers/localstorage-service';
import { AuthUserInfo, CompanyId } from '../../constants';
import { Button, Col, Input, Label, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { useOutletContext } from 'react-router-dom';
import { t } from 'i18next';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';
import ReactDatePicker from 'react-datepicker';
import NoRecords from '../../components/Table/NoRecords';
import SortableHeader from '../../components/SortableHeader';
import CommonField from '../../components/CommonField';
import AgentService from '../../services/agent';
import reportService from '../../services/report';
// import { PaginationControl } from 'react-bootstrap-pagination-control';
import * as Yup from 'yup';

const AgentCashExpense = () => {
  const dispatch = useDispatch();
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState('');
  const reqPayload = {};
  const formRef = useRef();
  let companyId = LocalstorageService.getItem(CompanyId);
  const { company } = LocalstorageService.getItem(AuthUserInfo)?.data;
  const previousMonth = new Date().getMonth() - 1;
  const prviouusMonthDate = new Date();
  prviouusMonthDate.setMonth(previousMonth);
  const [dateState, setDateState] = useState({ start: prviouusMonthDate, end: new Date() });
  const dateRef = useRef();
  const dateRef2 = useRef();
  const [reference, setReference] = useState('');
  const [checkBox, setCheckbox] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);
  const [downloading, setDownloading] = useState({ export: false, exportExcel: false });

  const InitialValues = {
    agent: '',
    company,
    search: '',
  };

  const clearDate = () => {
    setDateState({ start: '', end: '' });
  };
  const ValidationSchema = Yup.object().shape({
    agent: Yup.string().required(t(`REPORT.REQUIRED_AGENT`)),
    // search: Yup.string().required(),
  });
  const [compConf, setCompConf] = useState({
    shown: false,
    msg: t('ALERT.EMPLOYEE_ZERO_AMOUNT_LOAD'),
    confirmFn: () => {
      setCompConf((pre) => {
        return {
          ...pre,
          shown: false,
        };
      });
    },
  });
  useEffect(() => {
    setPageTitle(t('REPORT.AGENTCASHEXPENSE'));
    setCrumbItem([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('REPORT.AGENTCASHEXPENSE'), link: '#' },
    ]);
    listFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchExpenseList = () => {
    if (agentId) {
      // if (agentId && reference) {
      getExpenselist(1);
    }
  };

  function updateSearch(e) {
    setReference(e.target.value?.trim());
  }

  const HeaderList = [
    {
      title: t('REPORT.TABLE_FIELDS.DATE'),
      value: 'locationName',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },

    {
      title: t('REPORT.TABLE_FIELDS.BANK'),
      value: 'loadingAmount',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },

    {
      title: t('REPORT.TABLE_FIELDS.BRANCH'),
      value: 'remark',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.ACC_NUMBER'),
      value: 'remark',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.CHQ_REF'),
      value: 'remark',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.CHQ_AMOUNT'),
      value: 'remark',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.RETURN_AMOUNT'),
      value: 'remark',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.CAHS_EXPENSE'),
      value: 'remark',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.MISS_AMOUNT'),
      value: 'remark',
      style: { minWidth: '95px', width: '14%' },
      sortable: false,
    },
  ];

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await AgentService.listAgent({ ...reqPayload, companyId: companyId })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        // if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;
        const arr = [];
        if (dataList.length > 0) {
          dataList.forEach((val) => {
            arr.push({ value: val.id, label: val.firstName });
          });
        }
        setAgentList(arr || []);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  // const handlePageClick = (currentPage) => {
  //   setCurrentPage(currentPage);
  //   getExpenselist(currentPage);
  // };

  async function getExpenselist(pageNo) {
    reqPayload.companyId = companyId;
    reqPayload.agentId = agentId;
    if (checkBox) {
      reqPayload.referenceAmount = parseInt(reference);
      reqPayload.reference = '';
    } else {
      reqPayload.referenceAmount = 0;
      reqPayload.reference = reference;
    }
    reqPayload.export = false;
    reqPayload.exportExcel = false;
    // reqPayload.PageNo = pageNo;
    // reqPayload["PageSize"] = 10
    // reqPayload["AllList"] = false
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await reportService
      .getAgentExpenses({
        ...reqPayload,
        startDate: dateState.start ? new Date(dateState.start).toLocaleDateString('en-us') : '',
        endDate: dateState.end ? new Date(dateState.end).toLocaleDateString('en-us') : '',
      })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
        // if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        setList(dataList || []);

        // setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }
  function handleDownload(selectedType) {
    setDownloading({ export: selectedType.export, exportExcel: selectedType.exportExcel });
    reqPayload.companyId = companyId;
    reqPayload.agentId = agentId;
    reqPayload.startDate = dateState.start;
    reqPayload.endDate = dateState.end;
    if (checkBox) {
      reqPayload.referenceAmount = parseInt(reference);
      reqPayload.reference = '';
    } else {
      reqPayload.referenceAmount = 0;
      reqPayload.reference = reference;
    }
    reqPayload.export = selectedType.export;
    reqPayload.exportExcel = selectedType.exportExcel;
    // reqPayload.AllList = true
    downloadReport();
  }

  async function downloadReport() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await reportService
      .getAgentExpenses({
        ...reqPayload,
        startDate: reqPayload.startDate ? new Date(reqPayload.startDate).toLocaleDateString('en-us') : '',
        endDate: reqPayload.endDate ? new Date(reqPayload.endDate).toLocaleDateString('en-us') : '',
      })
      .then((response) => {
        const { status, message, filePath } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        if (filePath) {
          const pdfUrl = filePath;
          const link = document.createElement('a');

          link.href = pdfUrl;
          if (reqPayload.exportExcel === true) {
            link.download = 'expenseReport.xlsx';
          } else {
            link.download = 'expenseReport.pdf';
          }
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
        setDownloading({ export: false, exportExcel: false });
      });
  }
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={fetchExpenseList}
      >
        {({ values, setFieldValue, setFieldTouched, handleSubmit, errors }) => (
          <Form noValidate>
            <Row className=" gap-2">
              <Col sm={3}>
                <div className="mb-3 mt-2">
                  <CommonField
                    fieldType="string"
                    fieldName="company"
                    label={t('REPORT.COMPANY_LABEL')}
                    isDisabled={true}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className=" mb-3">
                  <label htmlFor="agent">{t('REPORT.AGENT_LABEL') + '*'}</label>
                  <ReactSelect
                    name={'agent'}
                    id="agent"
                    value={!agentId ? '' : agentList.filter(({ value }) => value === agentId)}
                    options={agentList}
                    className="react-select"
                    onChange={(option) => {
                      setFieldValue('agent', option?.value);
                      setAgentId(option?.value);
                    }}
                    onBlur={() => setFieldTouched('agent', true)}
                    placeholder={`-- ${t('REPORT.AGENT_PLACEHOLDER')} --`}
                    noOptionsMessage={() => t('RSEL_NO_OPT')}
                    isSearchable={true}
                  />
                </div>
                {errors.agent && (
                  <div className="errorMSG" style={{ color: 'red' }}>
                    {errors.agent}
                  </div>
                )}
              </Col>
              <Col sm={5} className="d-flex mb-1">
                <div></div>
                <div className="mb-3 gap-2 d-flex align-items-end">
                  <Button
                    color={isLoading || list.length === 0 ? 'secondary' : 'primary'}
                    type="button"
                    disabled={isLoading || list.length === 0}
                    onClick={() => handleDownload({ export: true, exportExcel: false })}
                  >
                    {downloading.export && !downloading.exportExcel
                      ? t('REPORT.DOWNLOADING')
                      : t('REPORT.DOWNLOAD_PDF')}
                  </Button>
                  <Button
                    color={isLoading || list.length === 0 ? 'secondary' : 'primary'}
                    type="button"
                    disabled={isLoading || list.length === 0}
                    onClick={() => handleDownload({ export: true, exportExcel: true })}
                  >
                    {downloading.export && downloading.exportExcel ? t('REPORT.DOWNLOADING') : t('REPORT.DOWNLOAD_XLX')}
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="mb-4 ">
              <Col sm={3} className="mt-3">
                <div className="d-flex justify-content-around align-items-center">
                  <label htmlFor="dateStart" className="text-nowrap" style={{ width: '40%' }}>
                    {t(`LOAD_REMARKS.FORM_DATE`)}
                  </label>
                  <ReactDatePicker
                    ref={dateRef}
                    name="dateStart"
                    id="dateStart"
                    className="form-control"
                    selected={dateState.start}
                    onChange={(dateArr) =>
                      setDateState((prev) => {
                        return { ...prev, start: dateArr };
                      })
                    }
                    highlightDates={[new Date()]}
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                  ></ReactDatePicker>
                </div>
              </Col>
              <Col sm={3} className="mb-6 mt-3">
                <div className="d-flex justify-content-around align-items-center">
                  <label htmlFor="dateEnd" style={{ width: '40%' }}>
                    {t(`LOAD_REMARKS.TO_DATE`)}
                  </label>
                  <ReactDatePicker
                    ref={dateRef2}
                    name="dateEnd"
                    id="dateEnd"
                    className="form-control"
                    selected={dateState.end}
                    onChange={(dateArr) =>
                      setDateState((prev) => {
                        return { ...prev, end: dateArr };
                      })
                    }
                    highlightDates={[new Date()]}
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                  ></ReactDatePicker>
                  <Button
                    className="ms-2 "
                    id="clearBtn"
                    color="danger"
                    disabled={!dateState.start && !dateState.end}
                    type="button"
                    onClick={() => clearDate()}
                  >
                    {'X'}
                    <UncontrolledTooltip placement="right" target={`clearBtn`}>
                      {t(`REPORT.CLEAR_DATE`)}
                    </UncontrolledTooltip>
                  </Button>
                </div>
              </Col>
              <Col sm={3} className="d-flex mt-3 ">
                <div style={{ width: '50%' }}></div>
                <div className="d-flex gap-2 my-2">
                  <Input
                    id="selectSearchType"
                    type="checkbox"
                    onChange={(e) => {
                      setFieldValue('search', '');
                      setCheckbox(e.target.checked);
                    }}
                    checked={checkBox}
                  />

                  <Label htmlFor="selectSearchType" className="mb-0 ">
                    {t('REPORT.SEARCH_PLACEHOLDER2')}
                  </Label>
                </div>
              </Col>
              <Col sm={3}>
                <div className="d-flex mt-3">
                  <Input
                    name="search"
                    type="number"
                    onChange={(e) => {
                      updateSearch(e);
                      setFieldValue('search', e.target.value.trim());
                    }}
                    value={values.search}
                    onKeyUp={({ code }) => {
                      if (code === 'Enter') {
                        handleSubmit();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    placeholder={checkBox ? t(`REPORT.SEARCH_PLACEHOLDER2`) : t('REPORT.SEARCH_PLACEHOLDER')}
                  />
                  <Button className="ms-2" color="primary" type="button" onClick={() => handleSubmit()}>
                    <i className="ri-search-line"></i>
                  </Button>
                </div>
                {errors.search && (
                  <div style={{ color: 'red' }}>
                    {checkBox ? t('REPORT.REQUIRED_PLACE2') : t('REPORT.REQUIRED_PLACE1')}
                  </div>
                )}
              </Col>
            </Row>
            <div className="dataMin employeeLoads reportTable">
              <Table
                className="fixTh dataTable "
                striped
                bordered
                responsive
                size="sm"
                style={{ minWidth: '650px', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr>
                    <SortableHeader showActionCol={false} headers={HeaderList} isDisabled={list.length === 0} />
                  </tr>
                </thead>

                <tbody>
                  {list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-nowrap">{item?.date ? new Date(item.date)?.toLocaleDateString('en-GB') : ''}</td>
                        <td className="" dir="ltr">
                          {item.bankName}
                        </td>
                        <td className="">{item.branchName}</td>
                        <td className="">{item.accountNumber}</td>
                        <td className="">{item.reference}</td>
                        <td dir="ltr">{item.referenceAmount?.toLocaleString('en-US')}</td>
                        <td dir="ltr">{item.returnFromTerminal?.toLocaleString('en-US')}</td>
                        <td dir="ltr">{item.expenseAmount?.toLocaleString('en-US')}</td>
                        <td dir="ltr">{item.missingAmount?.toLocaleString('en-US')}</td>
                      </tr>
                    );
                  })}

                  {(isLoading || list.length === 0) && <NoRecords colSpan={HeaderList.length + 1} />}
                </tbody>
              </Table>
            </div>
          </Form>
        )}
      </Formik>
      {/* <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div> */}
    </>
  );
};

export default AgentCashExpense;
