/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import { pick } from 'lodash';

import { CompanyId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import UpsertHeader from '../../components/UpsertHeader';
import SelectField from '../../components/SelectField';
import DatePickerField from '../../components/DatePicker';
import NumericFormatField from '../../components/NumericFormatField';
import SpecialDaysService from '../../services/specialdays';
import LocationService from '../../services/location';
import { formatDate } from '../../helpers';

const InitialValues = {
  referenceDay: '',
  terminalNumber: '',
  factorToAverage: '',
  note: '',
  locationId: '',
};

const ValidationSchema = Yup.object().shape({
  referenceDay: Yup.date().required(t('REQUIRED')),
  terminalNumber: Yup.string().required(t('REQUIRED')),
  factorToAverage: Yup.string().required(t('REQUIRED')).factToAvg(t('INVALID_AVG_FACTOR')),
});

let companyId = 0;

function UpdateSpecialDays() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(InitialValues);
  const [showEditForm, setShowEditForm] = useState(false);
  // const [machineData, setMachineData] = useState([]);

  const [locationList, setLocationList] = useState([]);
  const [locOptList, setLocOptList] = useState([]);

  const [terminalList, setTerminalList] = useState([]);
  const [termOptList, setTermOptList] = useState([]);

  useEffect(() => {
    setCrumbItem([
      { title: t('SPECIAL_DAYS.TITLE'), link: 'list' },
      { title: t('UPDATE_TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);

    getDataFn();
    getMachine();
    getLocationsFn();
  }, []);

  useEffect(() => {
    if (initApiCount === 2) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  async function getMachine() {
    dispatch(setLoadingOverlay(true));
    await SpecialDaysService.listMachines({ companyId, allList: true })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          setTerminalList(dataList);
          const data = dataList.map((item) => ({
            id: item.terminalNumber,
            label: item.terminalNumber,
            value: item.terminalNumber,
          }));

          //setMachineData(data);
          setTermOptList(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }
  async function getLocationsFn() {
    dispatch(setLoadingOverlay(true));
    await LocationService.listLocation({
      companyId: companyId,
      allList: true,
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          setLocationList(dataList);

          const data = dataList.map((item) => ({
            id: item.id,
            label: item.locationName,
            value: item.id,
          }));

          setLocOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }
  async function getDataFn() {
    dispatch(setLoadingOverlay(true));
    await SpecialDaysService.listSpecialDay({ id: params.dayId })
      .then((response) => {
        const { status, message, dataList } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        if (dataList && dataList.length) {
          const data = dataList[0];
          const payload = pick(data, ['id', ...Object.keys(InitialValues)]);
          // payload.referenceDay = formatDate(payload.referenceDay);
          setFormData(payload);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      referenceDay: formatDate(values.referenceDay),
      factorToAverage: values.factorToAverage ? parseFloat(values.factorToAverage) : 0,
      companyId,
    };

    setIsLoading(true);
    await SpecialDaysService.updateMachine(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('SPECIAL_DAYS.UPDATE')} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Factor To Average */}
              <Col xs={12} sm={4}>
                <div className="mb-4">
                  <Label htmlFor="referenceDay">*{t('SPECIAL_DAYS.REFERENCE_DAY_LABEL')}</Label>
                  {/* <Field
                    name="referenceDay"
                    component="input"
                    type="date"
                    value={values.referenceDay}
                    className="form-control"
                    placeholder={t("SPECIAL_DAYS.REFERENCE_DAY_PLACEHOLDER")}
                    required={true}
                    disabled={!showEditForm}
                  /> */}
                  <DatePickerField
                    fieldName="referenceDay"
                    fieldPlaceholder={t('SPECIAL_DAYS.REFERENCE_DAY_PLACEHOLDER')}
                    filedFormate="dd/MM/yyyy"
                    className="form-control"
                    fieldValue={values.referenceDay}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={!showEditForm}
                    isRequired={true}
                  ></DatePickerField>
                  <ErrorMessage name="referenceDay" className="text-danger" component="div" />
                </div>
              </Col>

              {/* Factor To Average */}
              {/* <Col xs={12} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="terminalNumber"
                    fieldLabel={`${t("SPECIAL_DAYS.TERMINAL_NUMBER_LABEL")}`}
                    fieldValue={values.terminalNumber}
                    fieldOptions={machineData}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col> */}

              {/* Factor To Average */}
              <Col xs={12} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="factorToAverage"
                    fieldValue={values.factorToAverage}
                    fieldLabel={t('SPECIAL_DAYS.FACTOR_TO_AVERAGE_LABEL')}
                    fieldDecimalScale={2}
                    fieldPlaceholder={`${t('SPECIAL_DAYS.FACTOR_TO_AVERAGE_PLACEHOLDER')}`}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {/* Location */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="locationId"
                    fieldLabel={t('LOCATION.TITLE')}
                    fieldValue={values.locationId}
                    fieldOptions={locOptList}
                    fieldChange={(option) => {
                      setFieldValue('terminalNumber', '');
                      setFieldTouched('terminalNumber', false);

                      if (option) {
                        const locObj = locationList.find((lo) => lo.id === option.value) || {};

                        setFieldValue('terminalNumber', locObj.terminalNumber || '');
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={false}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
              {/* terminal number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="terminalNumber"
                    fieldLabel={`${t('SPECIAL_DAYS.TERMINAL_NUMBER_LABEL')}`}
                    fieldValue={values.terminalNumber}
                    fieldOptions={termOptList}
                    fieldChange={(option) => {
                      setFieldValue('locationId', '');
                      setFieldTouched('locationId', false);

                      if (option) {
                        const termObj = terminalList.find((tl) => tl.terminalNumber === option.value) || {};

                        setFieldValue('locationId', termObj.locationId || '');
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {/* Note */}
              <Col xs={12} sm={6}>
                <div className="mb-4">
                  <Label htmlFor="note">{t('SPECIAL_DAYS.NOTE_LABEL')}</Label>

                  <Field
                    name="note"
                    component="textarea"
                    className="form-control"
                    placeholder={t('SPECIAL_DAYS.NOTE_PLACEHOLDER')}
                    disabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

export default UpdateSpecialDays;
