/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import EmployeeService from '../../services/employee';
import MachineService from '../../services/machines';
import LocationService from '../../services/location';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import UpsertHeader from '../../components/UpsertHeader';
import SelectField from '../../components/SelectField';
import AlertModal from '../../components/AlertModal';

const InitialValues = {
  locationName: '',
  terminalNumber: '',
  employeeId: 0,
  city: '',
  street: '',
  agentId: '',
  entry: '',
  houseNumber: '',
  zip: '',
  remark: '',
};

const ValidationSchema = Yup.object().shape({
  locationName: Yup.string().required(t('REQUIRED')).trim(),
  city: Yup.string().required(t('REQUIRED')).trim(),
  street: Yup.string().required(t('REQUIRED')).trim(),
  agentId: Yup.string().required(t('REQUIRED')).trim(),
  houseNumber: Yup.string().required(t('REQUIRED')).trim(),
});

let companyId = 0;

function AddLocation() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locFormRef = useRef();

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [roleID, setRoleID] = useState('');
  const [machineData, setMachineData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [agent, setAgent] = useState([]);
  const [confLocChange, setConfLocChange] = useState({ shown: false });
  const [compConf, setCompConf] = useState({
    shown: false,
    confirmFn: () => {},
  });

  useEffect(() => {
    setCrumbItem([
      { title: t('LOCATION.TITLE'), link: 'list' },
      { title: t('ADD_TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);
    const roleId = LocalstorageService.getItem(JobRole);
    setRoleID(roleId);

    getMachine();
    getEmployee();
    getAgent();
  }, []);

  useEffect(() => {
    if (initApiCount === 3) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  async function getMachine() {
    dispatch(setLoadingOverlay(true));
    await MachineService.listMachines({ companyId, allList: true })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.terminalNumber,
            label: item.terminalNumber,
            value: item.terminalNumber,
          }));

          setMachineData(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getEmployee() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({
      companyId,
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.firstName,
            value: item.id,
          }));

          setEmployee(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getAgent() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listAgent({
      companyId,
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.firstName,
            value: item.id,
          }));

          setAgent(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  function handleConfirmation() {
    setCompConf({ shown: false, confirmFn: () => {} });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    if (roleID === USER_ROLE.SUPER_ADMIN) {
      companyId = LocalstorageService.getItem(CompanyId);

      if (companyId === 0) {
        setCompConf({
          shown: true,
          msg: t('PLEASE_SEL_COMP'),
          confirmFn: handleConfirmation,
        });
        return;
      }
    }

    const payload = {
      ...values,
      employeeId: parseInt(values.employeeId),
      agentId: parseInt(values.agentId),
      companyId,
    };

    setIsLoading(true);
    await LocationService.createLocation(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        } else if (status === 201) {
          setConfLocChange({
            shown: true,
            message,
          });
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
        confLocChange.shown && setConfLocChange({ shown: false });
      });
  };

  const confirmLocChangeFn = () => {
    locFormRef.current.setFieldValue('wantToChange', true);
    locFormRef.current.submitForm();
  };

  return (
    <React.Fragment>
      <UpsertHeader navTo="../list" headerTitle={t('LOCATION.ADD')} />

      <Formik
        innerRef={locFormRef}
        initialValues={InitialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Business Name */}
              <Col sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="locationName"
                    label={t('LOCATION.BUSINESS_NAME_LABEL')}
                    placeholder={t('LOCATION.BUSINESS_NAME_PLACEHOLDER')}
                    required={true}
                  />
                </div>
              </Col>

              {/* Machine Number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="terminalNumber"
                    fieldLabel={t('MACHINE.NUMBER')}
                    fieldValue={values.terminalNumber}
                    fieldOptions={machineData}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>

              {/* Employee */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="employeeId"
                    fieldLabel={t('ROLES.EMPLOYEE')}
                    fieldValue={values.employeeId}
                    fieldOptions={employee}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabledOptVal={0}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* City */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="city"
                    label={t('EMPLOYEE.CITY_LABEL')}
                    placeholder={t('EMPLOYEE.CITY_PLACEHOLDER')}
                    required={true}
                  />
                </div>
              </Col>

              {/* Street */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="street"
                    label={t('EMPLOYEE.STREET_LABEL')}
                    placeholder={t('EMPLOYEE.STREET_PLACEHOLDER')}
                    required={true}
                  />
                </div>
              </Col>

              {/* Agent */}
              {roleID !== USER_ROLE.AGENT && (
                <Col xs={6} sm={4}>
                  <div className="mb-4">
                    <SelectField
                      fieldName="agentId"
                      fieldLabel={t('ROLES.AGENT')}
                      fieldValue={values.agentId}
                      fieldOptions={agent}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isRequired={true}
                    />
                  </div>
                </Col>
              )}

              {/* Entry */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="entry"
                    label={t('LOCATION.ENTRY_LABEL')}
                    placeholder={t('LOCATION.ENTRY_PLACEHOLDER')}
                  />
                </div>
              </Col>

              {/* House Number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="houseNumber"
                    label={t('LOCATION.HOUSE_NUMBER_LABEL')}
                    placeholder={t('LOCATION.HOUSE_NUMBER_PLACEHOLDER')}
                    required={true}
                  />
                </div>
              </Col>

              {/* Zip code */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="zip"
                    label={t('EMPLOYEE.ZIPCODE_LABEL')}
                    placeholder={t('EMPLOYEE.ZIPCODE_PLACEHOLDER')}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Remarks */}
              <Col sm={6}>
                <div className="mb-4">
                  <Label htmlFor="remarksField">{t('EMPLOYEE.REMARKS_LABEL')}</Label>

                  <Field
                    id="remarksField"
                    name="remark"
                    component="textarea"
                    className="form-control"
                    placeholder={t('EMPLOYEE.REMARKS_PLACEHOLDER')}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <Modal isOpen={confLocChange.shown} backdrop="static" className="modal-dialog-centered">
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <h3 className="mx-2 mb-0">{confLocChange.message}</h3>
          </div>

          <br className="d-none d-sm-block" />
          <br />
          <div className="d-flex justify-content-end gap-2">
            <Button color="primary" onClick={() => setConfLocChange({ shown: false })}>
              {t('CANCEL')}
            </Button>

            <Button color="link" className="waves-effect me-1" onClick={confirmLocChangeFn} disabled={isLoading}>
              {isLoading ? t('PLEASE_WAIT') : t('OK')}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <AlertModal {...compConf} />
    </React.Fragment>
  );
}

export default AddLocation;
