/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';
import { numberFormatter } from '../../helpers';
import BankService from '../../services/bank';
import BranchService from '../../services/branch';
import EmployeeService from '../../services/employee';
import AgentService from '../../services/agent';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SelectField from '../../components/SelectField';
import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import ActionBtns from '../../components/ActionBtns';
import AlertModal from '../../components/AlertModal';
const HeaderList = [
  {
    title: t('BANK.TITLE'),
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('BRANCH.TITLE'),
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMPLOYEE.ACC_NO_LABEL'),
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMPLOYEE.MAX_WITHDRAWAL_LABEL'),
    style: { minWidth: '150px', width: '30%', textAlign: 'right' },
  },
];

const InitialValues = {
  bankId: '',
  bankBranchId: '',
  accountNumber: '',
  amount: '',
};

const ValidationSchema = AmountSchema().shape({
  bankId: Yup.string().required(t('REQUIRED')),
  bankBranchId: Yup.string().required(t('REQUIRED')),
  accountNumber: Yup.string().required(t('REQUIRED')),
});

let companyId = 0,
  roleId = USER_ROLE.EMPLOYEE;

function BankAffiliation({ formData }) {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const [compConf, setCompConf] = useState({
    shown: false,
    confirmFn: () => {},
  });
  const dispatch = useDispatch();
  const params = useParams();

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [bank, setBank] = useState([]);
  const [bankBranch, setBankBranch] = useState([]);
  const [acctOptList, setAcctOptList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const formikRef = useRef();
  useEffect(() => {
    setCrumbItem([
      { title: t('EMPLOYEE.TITLE'), link: 'list' },
      { title: t('UPDATE_TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);
    roleId = LocalstorageService.getItem(JobRole);

    getBank();
    // getBankBranch(formData.bankId);
    getBankInfoFn();
  }, []);

  useEffect(() => {
    if (initApiCount === 2) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  function handleBranchId({ value, label }) {
    setBankName(label);
    setBankBranch([]);
    getBankBranch(value);
  }

  async function getBank() {
    dispatch(setLoadingOverlay(true));
    await BankService.listBanks()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.bankName,
            value: item.id,
          }));

          setBank(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getBankBranch(bankId) {
    dispatch(setLoadingOverlay(true));
    await BranchService.listBranches({ bankId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.branchName,
            value: item.id,
          }));

          setBankBranch(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getAccountNumFn(bankBranchId) {
    dispatch(setLoadingOverlay(true));

    await AgentService.bankAffiInfo({
      companyId,
      bankBranchId,
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.accountNumber,
            label: item.accountNumber,
            value: item.accountNumber,
            bankAccountId: item.bankAccountId,
            amount: item.amount,
          }));

          setAcctOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getBankInfoFn() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.bankAffiInfo({
      companyId,
      employeeId: parseInt(params.employeeId),
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          dataList.map((item) => {
            item.formattedAmount = numberFormatter(item.amount);
            return item;
          });

          setTableData(dataList);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
        dispatch(setLoadingOverlay(false));
      });
  }

  const handleAddEntry = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(false);
    const payload = {
      ...values,
      bankId: parseInt(values.bankId),
      bankBranchId: parseInt(values.bankBranchId),
      bankName,
      branchName,
      amount: parseFloat(values.amount),
      formattedAmount: numberFormatter(values.amount),
      rowStatus: 0,
    };

    if (checkeForExistingRecordInTable(tableData, payload)) {
      setCompConf({
        shown: true,
        msg: t('ALERT.DUPLICATE_RECORD'),
        confirmFn: handleConfirmation,
      });
    } else {
      setTableData((pre) => {
        return pre.concat([payload]);
      });
    }

    //resetForm();
    //setShowEditForm(false);
  };
  function checkeForExistingRecordInTable(tableData, record) {
    let isRecordFound = false;
    for (let i = 0; i < tableData.length; i++) {
      if (
        tableData[i]['accountNumber'] === record['accountNumber'] &&
        tableData[i]['bankBranchId'] === record['bankBranchId'] &&
        tableData[i]['bankId'] === record['bankId'] &&
        tableData[i]['rowStatus'] === 0
      ) {
        isRecordFound = true;
        break;
      }
    }
    return isRecordFound;
  }
  function handleConfirmation() {
    setCompConf({ shown: false, confirmFn: () => {} });
  }
  const handleSubmit = async () => {
    const payload = {
      employeeId: parseInt(params.employeeId),
      companyId,
      bankDetails: tableData,
    };

    setIsLoading(true);
    await EmployeeService.bankAffiliation(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
        if (status === 200) {
          setShowEditForm(false);
          formikRef.current?.resetForm();
          getBankInfoFn();
        }

        toast.success(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteHandler = (index) => {
    // const affList = [...tableData];
    // affList.splice(index, 1);
    // setTableData(affList);
    const affList = [...tableData];
    for (var i = 0; i < affList.length; i++) {
      if (index === i) {
        affList[i]['rowStatus'] = -1;
        break;
      }
    }
    setTableData(affList);
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <div>
        <Formik
          innerRef={formikRef}
          initialValues={InitialValues}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={handleAddEntry}
        >
          {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
            <Form noValidate>
              <Row>
                {/* Bank */}
                <Col sm={4}>
                  <div className="mb-4">
                    <SelectField
                      fieldName="bankId"
                      fieldLabel={t('BANK.TITLE')}
                      fieldValue={values.bankId}
                      fieldOptions={bank}
                      fieldChange={(option) => {
                        option && handleBranchId(option);

                        setFieldValue('bankBranchId', '');
                        setFieldTouched('bankBranchId', false);

                        setFieldValue('accountNumber', '');
                        setFieldTouched('accountNumber', false);
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isRequired={true}
                      isDisabled={!showEditForm}
                    />
                  </div>
                </Col>

                {/* Branch */}
                <Col sm={4}>
                  <div className="mb-4">
                    <SelectField
                      fieldName="bankBranchId"
                      fieldLabel={t('BRANCH.TITLE')}
                      fieldValue={values.bankBranchId}
                      fieldOptions={bankBranch}
                      fieldChange={async (option) => {
                        if (option) {
                          await getAccountNumFn(option.value);
                          setBranchName(option.label);
                        }

                        setFieldValue('accountNumber', '');
                        setFieldTouched('accountNumber', false);
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isRequired={true}
                      isDisabled={!values.bankId}
                    />
                  </div>
                </Col>

                {/* Account Number */}
                <Col xs={10} sm={3}>
                  <div className="mb-4">
                    <SelectField
                      fieldName="accountNumber"
                      fieldLabel={t('EMPLOYEE.ACC_NO_LABEL')}
                      fieldValue={values.accountNumber}
                      fieldOptions={acctOptList}
                      fieldChange={(option) => {
                        if (option) {
                          setFieldValue('bankAccountId', option.bankAccountId);
                          setFieldValue('amount', option.amount);
                        }
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isRequired={true}
                      isDisabled={!values.bankBranchId}
                    />
                  </div>
                </Col>

                {/* Add Entry Btn */}
                <Col xs={2} sm={1}>
                  <div className="mb-4">
                    <div className="">
                      <Label>&nbsp;</Label>
                    </div>
                    <Button color="success" type="submit" disabled={!showEditForm}>
                      <i className="ri-add-line"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Table striped bordered responsive size="sm">
          <thead>
            <tr>
              <SortableHeader headers={HeaderList} isDisabled={true} />
            </tr>
          </thead>

          <tbody>
            {tableData.map((item, l0Idx) => {
              if (item.rowStatus === 0) {
                return (
                  <tr key={l0Idx}>
                    <td
                      className="actionCol"
                      style={{
                        pointerEvents: `${!showEditForm ? 'none' : ''}`,
                      }}
                    >
                      <ActionBtns deleteTitle={t('EMPLOYEE.DEL_BANK_AFF')} deleteFn={() => deleteHandler(l0Idx)} />
                    </td>
                    <td>{item.bankName}</td>
                    <td>{item.branchName}</td>
                    <td>{item.accountNumber}</td>
                    <td align="right">{item.formattedAmount}</td>
                  </tr>
                );
              } else {
                return '';
              }
            })}

            {!isLoading && tableData.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
          </tbody>
        </Table>

        {/* Submit Button */}
        {showEditForm && (
          <div className="button-items mt-4">
            <Button
              color="primary"
              className="w-md waves-effect waves-light"
              //type="submit"
              onClick={() => handleSubmit()}
              disabled={isLoading}
            >
              {isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
            </Button>
            <Button
              color="light"
              type="button"
              onClick={() => {
                setShowEditForm(false);
              }}
            >
              {t('CANCEL')}
            </Button>
          </div>
        )}
        {!showEditForm && (
          <div className="button-items mt-4">
            <Button
              color="primary"
              className="w-md waves-effect waves-light"
              type="button"
              onClick={handleShowEditForm}
              disabled={isLoading}
            >
              {t('EDIT')}
            </Button>
          </div>
        )}
      </div>
      <AlertModal {...compConf} />
    </React.Fragment>
  );
}

export default BankAffiliation;
