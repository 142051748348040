/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Row, Col, Form, InputGroup, Input, Button, Label, FormGroup } from 'reactstrap';
import Breadcrumbs from '../../components/Breadcrumb';
import CashExpenseListSideBar from '../../components/CashExpenseSideBar/List';
import LoadingOverlay from '../../components/LoadingOverlay';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import { CashExpenseService } from '../../services/cash_expense';
import LocalstorageService from '../../helpers/localstorage-service';
import { CompanyId, JobRole, UserId, USER_ROLE, ITEMS_PER_PAGE } from '../../constants';
import { toast } from 'react-toastify';
import AddCashExpense from '../../components/CashExpense/Add';
import { numberFormatter, formatDate } from '../../helpers';
import MachineService from '../../services/machines';

function CashExpense() {
  const { t } = useTranslation();
  let companyId = LocalstorageService.getItem(CompanyId);
  let role = LocalstorageService.getItem(JobRole);
  let userId = LocalstorageService.getItem(UserId);
  const dispatch = useDispatch();
  const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);
  const [showAddCashExenseModel, setShowAddCashExenseModel] = useState(false);
  const [pageTitle, setPageTitle] = useState(t('CASHEXPENSE.TITLE'));
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: t('APPLICATION_TITLE'), link: '#' },
    { title: t('CASHEXPENSE.TITLE'), link: '#' },
  ]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [cashExpenseList, setCashExpenseList] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showDelRec, setShowDelRec] = useState(false);
  const [cashExpenseListingPayload, setCashExpenseListingPayload] = useState(() => ({
    companyId: companyId,
    employeeId: role === USER_ROLE.EMPLOYEE && userId ? userId : 0,
    locationId: 0,
    search: '',
    pageNo: 1,
    pageSize: ITEMS_PER_PAGE,
    allList: false,
    loeListing: role === USER_ROLE.EMPLOYEE && userId ? true : false,
    deletedRecords: false,
    sort: [
      {
        value: 'createDate',
        asc: false,
      },
    ],
  }));
  const [cashExpenseDetail, setCashExpenseDetail] = useState({
    companyId: companyId,
    cashExpenseId: 0,
    employeeId: role === USER_ROLE.EMPLOYEE && userId ? userId : '',
    remainCashBalance: 0,
    locationId: '',
    terminalNumber: '',
    expenseNote: '',
    expenseAmount: null,
    paidTo: '',
    startDate: '',
    endDate: '',
    invoice: false,
  });
  useEffect(() => {
    getCashExpenseList();
  }, []);
  useEffect(() => {
    if (showAddCashExenseModel) {
      getLocationId();
    }
  }, [showAddCashExenseModel]);
  async function getLocationId() {
    await MachineService.getLocations({ companyId }).then((response) => {
      const { status, dataList } = response.data;
      if (status === 400) {
        return;
      }
      setLocationData(dataList);
      if (dataList && dataList.length && cashExpenseDetail && cashExpenseDetail.locationId) {
        const locationInfo = dataList.filter((data) => Number(data.id) === Number(cashExpenseDetail.locationId));
        if (locationInfo.length) {
          setCashExpenseDetail((pre) => {
            return {
              ...pre,
              terminalNumber: locationInfo[0].terminalNumber,
            };
          });
        }
      }
      const data = dataList.map((item) => ({
        id: item.id,
        label: item.locationName,
        value: item.id,
      }));

      setLocations(data);
    });
  }
  const getCashExpenseList = async (reqPayload = {}) => {
    dispatch(setLoadingOverlay(true));
    await CashExpenseService.cashExpenseList(!isEmpty(reqPayload) ? reqPayload : cashExpenseListingPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        setCashExpenseList(dataList ? dataList : []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  };
  const updateCashExpense = async (reqPayload) => {
    if (reqPayload && reqPayload.remainCashBalance) {
      delete reqPayload.remainCashBalance;
    }
    dispatch(setLoadingOverlay(true));
    const updateReqPayload = {
      ...reqPayload,
      locationId: reqPayload.locationId === '' ? 0 : reqPayload.locationId,
      startDate: reqPayload.startDate === '' ? '' : formatDate(reqPayload.startDate, 'yyyy/mm/dd'),
      endDate: reqPayload.endDate === '' ? '' : formatDate(reqPayload.endDate, 'yyyy/mm/dd'),
    };
    await CashExpenseService.updateCashExpense(updateReqPayload)
      .then((response) => {
        const { status, message } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        toast.success(message);
        setShowAddCashExenseModel(false);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        getCashExpenseList();
      });
  };
  const addCashExpense = async (reqPayload) => {
    if (reqPayload && reqPayload.remainCashBalance) {
      delete reqPayload.remainCashBalance;
    }
    dispatch(setLoadingOverlay(true));
    await CashExpenseService.createCashExpense({
      ...reqPayload,
      locationId: reqPayload.locationId === '' ? 0 : reqPayload.locationId,
      startDate: reqPayload.startDate === '' ? '' : formatDate(reqPayload.startDate, 'yyyy/mm/dd'),
      endDate: reqPayload.endDate === '' ? '' : formatDate(reqPayload.endDate, 'yyyy/mm/dd'),
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        toast.success(message);
        setShowAddCashExenseModel(false);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        getCashExpenseList();
      });
  };
  const saveCashExpense = async (reqPayload) => {
    if (reqPayload.cashExpenseId) {
      updateCashExpense(reqPayload);
    } else {
      addCashExpense(reqPayload);
    }
  };
  const cancelCashExpense = () => {
    setCashExpenseDetail((pre) => {
      return {
        ...pre,
        companyId: companyId,
        cashExpenseId: 0,
        //employeeId: '',
        remainCashBalance: 0,
        // employeeCashAmountToLoadId: null,
        locationId: '',
        terminalNumber: '',
        expenseNote: '',
        expenseAmount: 0,
        paidTo: '',
        startDate: '',
        endDate: '',
        invoice: false,
      };
    });
    setShowAddCashExenseModel(false);
  };
  const sortBy = (sortObj) => {
    delete cashExpenseListingPayload['sort'];
    const reqPayload = { ...cashExpenseListingPayload };
    if (sortObj) {
      setCashExpenseListingPayload((pre) => {
        return {
          ...pre,
          sort: [sortObj],
        };
      });
      reqPayload['sort'] = [sortObj];
      //cashExpenseListingPayload['sort'] = [sortObj];
    }
    getCashExpenseList(reqPayload);
  };
  async function getCashRemainAmount(employeeId) {
    const reqPayload = { employeeId: employeeId };
    dispatch(setLoadingOverlay(true));
    await CashExpenseService.getRemainCashAmount(reqPayload)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        setCashExpenseDetail((pre) => {
          return {
            ...pre,
            remainCashBalance: data.totalCashRemain || 0,
          };
        });
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }
  const editCashExpense = async (data) => {
    await getCashRemainAmount(data.employeeId);

    setCashExpenseDetail((pre) => {
      return {
        ...pre,
        ...data,
        startDate: data.startDate ? new Date(data.startDate) : '',
        endDate: data.endDate ? new Date(data.endDate) : '',
        ud: true,
      };
    });
    setShowAddCashExenseModel(true);
  };
  const handlePageClick = (currentPage) => {
    const reqPayload = { ...cashExpenseListingPayload };
    setCashExpenseListingPayload((pre) => {
      return {
        ...pre,
        pageNo: currentPage,
      };
    });
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    getCashExpenseList(reqPayload);
  };
  const handleShowDeleteRec = (e) => {
    const reqPayload = { ...cashExpenseListingPayload };
    setCashExpenseListingPayload((pre) => {
      return {
        ...pre,
        deletedRecords: !showDelRec,
        pageNo: 1,
      };
    });
    reqPayload['deletedRecords'] = !showDelRec;
    reqPayload['pageNo'] = 1;
    setShowDelRec(!showDelRec);
    setCurrentPage(1);
    getCashExpenseList(reqPayload);
  };
  const handleSearch = (e) => {
    const reqPayload = { ...cashExpenseListingPayload };
    setCashExpenseListingPayload((pre) => {
      return {
        ...pre,
        search: e.target.value,
      };
    });
    reqPayload['search'] = e.target.value;
    getCashExpenseList(reqPayload);
  };
  const debouncedChangeHandler = useCallback(debounce(handleSearch, 500), []);
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleAddCashExpense = () => {
    setCashExpenseDetail({
      companyId: companyId,
      cashExpenseId: 0,
      employeeId: role === USER_ROLE.EMPLOYEE && userId ? userId : '',
      remainCashBalance: 0,
      locationId: '',
      terminalNumber: '',
      expenseNote: '',
      expenseAmount: null,
      paidTo: '',
      startDate: '',
      endDate: '',
      invoice: false,
    })
    setShowAddCashExenseModel(true)
  }
  return (
    <React.Fragment>
      <Breadcrumbs title={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card className="card-box">
        {showLoadingOverlay && <LoadingOverlay />}
        <CardBody>
          <div className="align-items-end mb-2">
            <Row>
              <Col xs={7} sm={4}>
                <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
                  <InputGroup>
                    <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
                    <Button color="primary" type="button">
                      <i className="ri-search-line"></i>
                    </Button>
                  </InputGroup>
                </Form>
              </Col>
              <Col xs={5} sm={4} className="d-none d-sm-block">
                <FormGroup className="float-end pt-2" check>
                  <Input
                    id="showDeleteRec"
                    title={t('SHOW_DEL_REC')}
                    type="checkbox"
                    onChange={handleShowDeleteRec}
                    checked={showDelRec}
                  />

                  <Label htmlFor="showDeleteRec" className="mb-0 d-sm-block">
                    {t('SHOW_DEL_REC')}
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={5} sm={4}>
                <Button color="primary" className="float-end" onClick={() => handleAddCashExpense()}>
                  <span className="d-none d-sm-block">{t('CASHEXPENSE.ADD_CASHEXPENSE')}</span>
                  <i className="ri-add-line  d-sm-none" title={t('CASHEXPENSE.ADD_CASHEXPENSE')}></i>
                </Button>
              </Col>

              {/* </div> */}
            </Row>
            <Row className="d-sm-none">
              <Col xs={8} sm={4} className="mt-2">
                <FormGroup className="" check>
                  <Input
                    id="showDeleteRec"
                    title={t('SHOW_DEL_REC')}
                    type="checkbox"
                    onChange={handleShowDeleteRec}
                    checked={showDelRec}
                  />

                  <Label htmlFor="showDeleteRec" className="mb-0 d-sm-block">
                    {t('SHOW_DEL_REC')}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <CashExpenseListSideBar
            cashExpenseList={cashExpenseList}
            sortBy={sortBy}
            totalCount={totalCount}
            currentPage={currentPage}
            editCashExpense={editCashExpense}
            removeCashExpense={updateCashExpense}
            handlePageClick={handlePageClick}
          />
        </CardBody>
      </Card>
      {showAddCashExenseModel && (
        <AddCashExpense
          showAddCashExenseModel={showAddCashExenseModel}
          cashExpenseDetail={cashExpenseDetail}
          locations={locations}
          locationData={locationData}
          handleSubmitAction={saveCashExpense}
          handleCancelAction={cancelCashExpense}
        />
      )}
    </React.Fragment>
  );
}

export default CashExpense;
