/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable array-callback-return*/
import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';
import { numberFormatter } from '../../helpers';
import BankService from '../../services/bank';
import BranchService from '../../services/branch';
import AgentService from '../../services/agent';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SelectField from '../../components/SelectField';
import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import ActionBtns from '../../components/ActionBtns';
import NumericFormatField from '../../components/NumericFormatField';

const HeaderList = [
  {
    title: t('BANK.TITLE'),
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('BRANCH.TITLE'),
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMPLOYEE.ACC_NO_LABEL'),
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMPLOYEE.MAX_WITHDRAWAL_LABEL'),
    style: { minWidth: '150px', width: '30%', textAlign: 'right' },
  },
];

const InitialValues = {
  bankId: '',
  bankBranchId: '',
  accountNumber: '',
  amount: '',
};

const ValidationSchema = AmountSchema().shape({
  bankId: Yup.string().required(t('REQUIRED')),
  bankBranchId: Yup.string().required(t('REQUIRED')),
  accountNumber: Yup.string().required(t('REQUIRED')),
});

let companyId = 0,
  roleId = USER_ROLE.EMPLOYEE;

function BankAffiliation({ formData }) {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const params = useParams();

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [bank, setBank] = useState([]);
  const [bankBranch, setBankBranch] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [affList, setAffList] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    setCrumbItem([
      { title: t('AGENT.TITLE'), link: 'list' },
      { title: t('UPDATE_TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);
    roleId = LocalstorageService.getItem(JobRole);

    getBank();
    // getBankBranch(formData.bankId);
    getBankInfoFn();
  }, []);

  useEffect(() => {
    if (initApiCount === 2) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  function handleBranchId({ value, label }) {
    setBankName(label);
    setBankBranch([]);
    getBankBranch(value);
  }

  async function getBank() {
    dispatch(setLoadingOverlay(true));
    await BankService.listBanks()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.bankName,
            value: item.id,
          }));

          setBank(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getBankBranch(bankId) {
    dispatch(setLoadingOverlay(true));
    await BranchService.listBranches({ bankId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.branchName,
            value: item.id,
          }));

          setBankBranch(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getBankInfoFn() {
    dispatch(setLoadingOverlay(true));
    await AgentService.bankAffiInfo({
      companyId,
      agentId: parseInt(params.agentId),
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        dataList.map((item) => {
          item.formattedAmount = numberFormatter(item.amount);
          item.isDeleted = false;
          return item;
        });

        setTableData(dataList || []);
        setAffList(dataList || []);
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  const handleAddEntry = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      bankId: parseInt(values.bankId),
      bankBranchId: parseInt(values.bankBranchId),
      bankName,
      branchName,
      amount: parseFloat(values.amount),
      formattedAmount: numberFormatter(values.amount),
      isNew: true,
      isDeleted: false,
    };

    setTableData((pre) => {
      return pre.concat([payload]);
    });
    setAffList((pre) => {
      return pre.concat([payload]);
    });

    resetForm();
  };

  const handleSubmit = async () => {
    const payload = {
      agentId: parseInt(params.agentId),
      companyId,
      bankMappings: affList,
    };

    setIsLoading(true);
    await AgentService.bankAffiliation(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const deleteHandler = (item, index) => {
    const bankAffList = [...tableData];
    bankAffList.splice(index, 1);
    setTableData(bankAffList);

    const tempList = [...affList];
    tempList.map((v, idx) => {
      if (v.bankId === item.bankId && v.bankBranchId === item.bankBranchId && v.accountNumber === item.accountNumber) {
        if (v.isNew) {
          tempList.splice(idx, 1);
        } else {
          v.isDeleted = true;
        }
      }
    });
    setAffList(tempList);
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={InitialValues}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleAddEntry}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Bank */}
              <Col lg={3}>
                <div className="mb-4">
                  <SelectField
                    fieldName="bankId"
                    fieldLabel={t('BANK.TITLE')}
                    fieldValue={values.bankId}
                    fieldOptions={bank}
                    fieldChange={(option) => {
                      option && handleBranchId(option);

                      setFieldValue('bankBranchId', '');
                      setFieldTouched('bankBranchId', false);
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Branch */}
              <Col lg={3}>
                <div className="mb-4">
                  <SelectField
                    fieldName="bankBranchId"
                    fieldLabel={t('BRANCH.TITLE')}
                    fieldValue={values.bankBranchId}
                    fieldOptions={bankBranch}
                    fieldChange={(option) => {
                      option && setBranchName(option.label);
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!values.bankId}
                  />
                </div>
              </Col>

              {/* Account */}
              <Col sm={6} lg={3}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="accountNumber"
                    fieldValue={values.accountNumber}
                    fieldLabel={t('EMPLOYEE.ACC_NO_LABEL')}
                    useThousandSeparator={false}
                    fieldPlaceholder={t('EMPLOYEE.ACC_NO_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Max Withdrawal */}
              <Col xs={10} sm={5} lg={2}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="amount"
                    fieldValue={values.amount}
                    fieldLabel={t('EMPLOYEE.MAX_WITHDRAWAL_LABEL')}
                    fieldPlaceholder={t('EMPLOYEE.MAX_WITHDRAWAL_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              <Col xs={2} sm={1}>
                <div className="mb-4">
                  <div className="">
                    <Label>&nbsp;</Label>
                  </div>
                  <Button color="success" type="submit">
                    <i className="ri-add-line"></i>
                  </Button>
                </div>
              </Col>
            </Row>

            <Table striped bordered responsive size="sm">
              <thead>
                <tr>
                  <SortableHeader headers={HeaderList} isDisabled={true} />
                </tr>
              </thead>

              <tbody>
                {tableData.map((item, l0Idx) => (
                  <tr key={l0Idx}>
                    <td className="actionCol">
                      <ActionBtns
                        deleteTitle={t('EMPLOYEE.DEL_BANK_AFF')}
                        deleteFn={() => deleteHandler(item, l0Idx)}
                      />
                    </td>
                    <td>{item.bankName}</td>
                    <td>{item.branchName}</td>
                    <td>{item.accountNumber}</td>
                    <td align="right">{item.formattedAmount}</td>
                  </tr>
                ))}

                {!isLoading && tableData.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
              </tbody>
            </Table>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items mt-4">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="button"
                  onClick={handleSubmit}
                  disabled={isSubmitting || isLoading || tableData.length === 0}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items mt-4">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

export default BankAffiliation;
