import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";

import { AmountSchema } from "../../helpers/yup-schema";
import AgentActivityService from "../../services/agent_activity";

import NumericFormatField from "../../components/NumericFormatField";
import SelectField from "../../components/SelectField";

const InitialValues = {
  employeeId: "",
  amount: "",
  reference: "",
};

const ValidationSchema = AmountSchema().shape({
  employeeId: Yup.string().required(t("REQUIRED")).trim(),
  reference: Yup.string().required(t("REQUIRED")).trim(),
});

function ConfirmSaveDataSplitMoney({
  formData = {},
  empList = [],
  isOpened = false,
  toggleModal = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitFn = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...formData,
      ...values,
      amount: parseFloat(values.amount),
    };

    setIsLoading(true);
    await AgentActivityService.splitToEmployees(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        toggleModal(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpened} toggle={toggleModal} backdrop="static">
        <Formik
          initialValues={{ ...InitialValues, ...formData }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmitFn}
        >
          {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
            <Form noValidate>
              <ModalHeader toggle={() => toggleModal()}>
                {t("CONFIRM_DETAILS")}
              </ModalHeader>

              <ModalBody>
                <Row>
                  {/* Employee */}
                  <Col>
                    <div className="mb-4">
                      <SelectField
                        fieldName="employeeId"
                        fieldLabel={t("ROLES.EMPLOYEE")}
                        fieldValue={values.employeeId}
                        fieldOptions={empList}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isRequired={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* Cheque Amount */}
                  <Col xs={6}>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="amount"
                        fieldValue={values.amount}
                        fieldDecimalScale={2}
                        fieldLabel={t("CHEQUE_AMT")}
                        fieldPlaceholder={t(
                          "BANK_WITHDRAWAL.CHEQUE_AMT_PLACEHOLDER"
                        )}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                  </Col>

                  {/* Cheque Number */}
                  <Col xs={6}>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="reference"
                        fieldValue={values.reference}
                        fieldLabel={t("CHEQUE_NUMBER")}
                        fieldPlaceholder={t(
                          "BANK_WITHDRAWAL.CHEQUE_NUM_PLACEHOLDER"
                        )}
                        allowLeadingZeros={true}
                        useThousandSeparator={false}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>

              <ModalFooter>
                <Button color="light" onClick={() => toggleModal()}>
                  {t("CANCEL")}
                </Button>

                {/* Submit Button */}
                <div className="">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md waves-effect waves-light"
                    disabled={isSubmitting || isLoading}
                  >
                    {isSubmitting || isLoading
                      ? t("PLEASE_WAIT")
                      : t("CONFIRM")}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmSaveDataSplitMoney;
