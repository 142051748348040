import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import CompanyService from "../../services/company";

import CommonField from "../../components/CommonField";

const InitialValues = {
  companyName: "",
};

const ValidationSchema = Yup.object().shape({
  companyName: Yup.string().required(t("REQUIRED")).trim(),
});

function Upsert({ isOpened = false, data = InitialValues, toggle }) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(data);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    setFormData(data);
    if (data.id) {
      setShowEditForm(true);
    } else {
      setShowEditForm(false);
    }
  }, [data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setIsLoading(true);

    const payload = { ...values };

    await CompanyService[formData.id ? "updateCompany" : "createCompany"]({
      ...payload,
    })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        closeModal(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = (isSubmitted = false) => {
    toggle(false, isSubmitted);
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpened} toggle={() => closeModal()} backdrop="static">
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalHeader toggle={() => closeModal()}>
                {t(`COMPANY.${formData.id ? "UPDATE" : "ADD"}`)}
              </ModalHeader>

              <ModalBody>
                <Row>
                  {/* Company Name */}
                  <Col>
                    <div className="mb-4">
                      <CommonField
                        fieldName="companyName"
                        label="COMPANY.NAME_LABEL"
                        placeholder="COMPANY.NAME_PLACEHOLDER"
                        required={true}
                        isDisabled={showEditForm}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>

              {!showEditForm && (
                <ModalFooter>
                  <div className="button-items">
                  <Button color="light" onClick={() => closeModal()}>
                    {t("CANCEL")}
                  </Button>

                  {/* Submit Button */}
                    <Button
                      color="primary"
                      className="w-md waves-effect waves-light"
                      type="submit"
                      disabled={isSubmitting || isLoading}
                    >
                      {isSubmitting || isLoading
                        ? t("PLEASE_WAIT")
                        : t("SUBMIT")}
                    </Button>
                  </div>
                </ModalFooter>
              )}
            </Form>
          )}
        </Formik>
        {showEditForm && (
          <ModalFooter>
            {/* Edit Button */}
            <div className="button-items">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="button"
                onClick={handleShowEditForm}
                disabled={isLoading}
              >
                {t("EDIT")}
              </Button>
            </div>
          </ModalFooter>
        )}
      </Modal>
    </React.Fragment>
  );
}

export default Upsert;
