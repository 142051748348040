/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/ import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from '../ReactDatePicker';
import SelectField from '../SelectField';
import CommonField from '../CommonField';
import NumericFormatField from '../NumericFormatField';
import EmployeeService from '../../services/employee';
import MachineService from '../../services/machines';
import SpecialDaysService from '../../services/specialdays';
import LocalstorageService from '../../helpers/localstorage-service';
import { CompanyId } from '../../constants';
import { toast } from 'react-toastify';
import { CashExpenseService } from '../../services/cash_expense';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
export default function AddCashExpense({
  showAddCashExenseModel,
  cashExpenseDetail,
  handleSubmitAction,
  handleCancelAction,
  locations,
  locationData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  let companyId = LocalstorageService.getItem(CompanyId);
  const [employeeList, setEmployeeList] = useState([]);
  const [terminals, setTermials] = useState([]);
  const [anotherEmp, setAnotherEmp] = useState(() => (cashExpenseDetail.employeeId ? false : true));
  const [isDisableEmployeeField, setIsDisableEmployeeField] = useState(() =>
    cashExpenseDetail.employeeId ? true : false,
  );
  useEffect(() => {
    getEmployeeFn();
    getMachine();
  }, []);
  useEffect(() => {
    if (formikRef.current) {
      // Check if Formik instance exists
      formikRef.current.setValues(cashExpenseDetail);
    }
  }, [cashExpenseDetail]);
  // useEffect(() => {
  //   if (terminalData && terminalData.length && cashExpenseDetail.locationId) {
  //     const terminalInfo = terminalData.filter(
  //       (data) => Number(data.locationId) === Number(cashExpenseDetail.locationId),
  //     );
  //     if (terminalInfo.length) {
  //       setCashExpenseDetail((pre) => {
  //         return {
  //           ...pre,
  //           terminalNumber: terminalInfo[0].terminalNumber || '',
  //         };
  //       });
  //     }
  //   }
  // }, [terminalData]);
  const CashExpenseValidationSchema = Yup.object().shape({
    employeeId: Yup.string().required().label('Employee id'),
    //  remainCashBalance: Yup.string().required().label('Maxmum amount'),
    // locationId: Yup.string().required().label('Location'),
    // terminalNumber: Yup.string().required().label('Terminal number'),
    expenseNote: Yup.string().required().label('Expense note'),
    expenseAmount: Yup.string().required().label('Amount'),
    // paidTo: Yup.string().required().label('Paid to'),
    // startDate: Yup.string().required().label('Start date'),
    // endDate: Yup.string().required().label('End date'),
  });

  async function getEmployeeFn() {
    await EmployeeService.listEmployee({ companyId }).then((response) => {
      const { status, dataList } = response.data;
      if (status === 400) {
        return;
      }

      if (dataList && dataList.length) {
        const data = dataList?.map((item) => ({
          id: item.id,
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
          companyId: item.companyId,
        }));
        setEmployeeList(data);
        if (cashExpenseDetail.employeeId) {
          getCashRemainAmount({ id: cashExpenseDetail.employeeId }, formikRef.current);
        }
      }
    });
  }

  async function getMachine() {
    await SpecialDaysService.listMachines({ companyId, allList: true }).then((response) => {
      const { status, dataList } = response.data;

      if (status === 400) {
        return;
      }

      if (dataList && dataList.length) {
        const data = dataList.map((item) => ({
          id: item.terminalNumber,
          label: item.terminalNumber,
          value: item.terminalNumber,
        }));

        setTermials(data);
      }
    });
  }
  async function getCashRemainAmount(employeeObj, formik) {
    if (!employeeObj || !employeeObj.id) {
      formik.setFieldValue('remainCashBalance', 0);
      return;
    }
    const reqPayload = { employeeId: employeeObj.id };
    dispatch(setLoadingOverlay(true));
    await CashExpenseService.getRemainCashAmount(reqPayload)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        const { totalCashRemain } = data;
        formik.setFieldValue('remainCashBalance', totalCashRemain);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }
  const handleAnotherEmpChange = (e) => {
    setAnotherEmp((pre) => !pre);
    if (e.target.checked) {
      setIsDisableEmployeeField(false);
    } else {
      setIsDisableEmployeeField(true);
    }
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={cashExpenseDetail}
        validationSchema={CashExpenseValidationSchema}
        onSubmit={handleSubmitAction}
        innerRef={formikRef}
      >
        {(formik) => {
          formikRef.current = formik;
          return (
            <Form>
              <Modal isOpen={showAddCashExenseModel} backdrop="static" className="modal-lg modal-dialog-centered">
                <ModalHeader>
                  {formik.values.cashExpenseId ? t('CASHEXPENSE.EDIT_CASHEXPENSE') : t('CASHEXPENSE.ADD_CASHEXPENSE')}
                </ModalHeader>
                <ModalBody>
                  <div className="d-flex flex-column  gap-2">
                    <div className="row gap-2 gap-md-0 align-items-end">
                      <div className="col-md-6 col-12 ">
                        <SelectField
                          fieldName="employeeId"
                          fieldLabel={`${t('CASHEXPENSE.EMPLOYEE_LABEL')}`}
                          fieldValue={formik.values.employeeId}
                          fieldOptions={employeeList}
                          setFieldValue={formik.setFieldValue}
                          setFieldTouched={formik.setFieldTouched}
                          isRequired={true}
                          fieldChange={(option) => getCashRemainAmount(option, formik)}
                          isDisabled={isDisableEmployeeField}
                        />
                      </div>
                      <div className="col-md-6 col-12 ">
                        {cashExpenseDetail.employeeId ? (
                          <FormGroup
                            className="m-0 align-items-center"
                            style={{ height: '36px', display: 'flex', gap: '10px' }}
                            check
                          >
                            <Input
                              id="anotherEmployee"
                              type="checkbox"
                              onChange={handleAnotherEmpChange}
                              checked={anotherEmp}
                            />

                            <Label htmlFor="anotherEmployee" className="mb-0">
                              {t('EMPLOYEE.ANOTHER_EMP')}
                            </Label>
                          </FormGroup>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="row gap-2 gap-md-0">
                      <div className="col-md-6 col-12 ">
                        <NumericFormatField
                          fieldName="remainCashBalance"
                          fieldValue={formik.values.remainCashBalance}
                          fieldDecimalScale={2}
                          fieldLabel={t('CASHEXPENSE.MAX_AMOUNT_LABEL')}
                          fieldPlaceholder={t('CASHEXPENSE.MAX_AMOUNT_LABEL')}
                          setFieldValue={formik.setFieldValue}
                          setFieldTouched={formik.setFieldTouched}
                          isDisabled={true}
                          // required={true}
                        />
                      </div>
                    </div>
                    <div className="row gap-2 gap-md-0">
                      <div className="col-12 ">
                        <CommonField fieldName="expenseNote" label={t('CASHEXPENSE.EXPENSE_FOR_LABEL')} />
                      </div>
                    </div>

                    <div className="row gap-2 gap-md-0">
                      <div className="col-md-6 col-12 ">
                        <NumericFormatField
                          fieldName="expenseAmount"
                          fieldValue={formik.values.expenseAmount}
                          fieldDecimalScale={2}
                          fieldLabel={t('CASHEXPENSE.AMOUNT_LABEL')}
                          fieldPlaceholder={t('CASHEXPENSE.AMOUNT_LABEL')}
                          setFieldValue={formik.setFieldValue}
                          setFieldTouched={formik.setFieldTouched}
                          required={true}
                        />
                      </div>
                      <div className="col-md-6 col-12 ">
                        <CommonField fieldName="paidTo" label={t('CASHEXPENSE.PAID_TO_LABEL')} />
                      </div>
                    </div>
                    <div className="row gap-2 gap-md-0">
                      <div className="col-md-6 col-12 ">
                        <SelectField
                          fieldName="locationId"
                          fieldLabel={`${t('CASHEXPENSE.LOCATION_LABEL')}`}
                          fieldValue={formik.values.locationId}
                          fieldOptions={locations}
                          fieldChange={(option) => {
                            formik.setFieldValue('terminalNumber', '');
                            formik.setFieldTouched('terminalNumber', false);

                            if (option) {
                              const locObj = locationData.find((lo) => lo.id === option.value) || {};

                              formik.setFieldValue('terminalNumber', locObj.terminalNumber || '');
                            }
                          }}
                          setFieldValue={formik.setFieldValue}
                          setFieldTouched={formik.setFieldTouched}
                        />
                      </div>

                      <div className="col-md-6 col-12 ">
                        <SelectField
                          fieldName="terminalNumber"
                          fieldLabel={`${t('CASHEXPENSE.TERMINAL_NUMBER_LABEL')}`}
                          fieldValue={formik.values.terminalNumber}
                          fieldOptions={terminals}
                          setFieldValue={formik.setFieldValue}
                          setFieldTouched={formik.setFieldTouched}
                        />
                      </div>
                    </div>
                    <div className="row gap-2 gap-md-0">
                      <div className="col-md-6 col-12 ">
                        <ReactDatePicker
                          fieldId="startDate"
                          fieldName="startDate"
                          fieldLabel={t(`LOAD_REMARKS.FORM_DATE`)}
                          showIcon={true}
                          value={formik.values.startDate}
                          handleDateChange={(date) => formik.setFieldValue('startDate', date)}
                          highlightDates={[new Date()]}
                          error={formik.errors && formik.touched.startDate && formik.errors.startDate}
                          handleOnBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <ReactDatePicker
                          fieldId="endDate"
                          fieldName="endDate"
                          fieldLabel={t(`LOAD_REMARKS.TO_DATE`)}
                          showIcon={true}
                          value={formik.values.noEndDate ? '' : formik.values.endDate}
                          handleDateChange={(date) => formik.setFieldValue('endDate', date)}
                          error={formik.errors && formik.touched.endDate && formik.errors.endDate}
                          isDisable={!formik.values.startDate || formik.values.noEndDate}
                          handleOnBlur={formik.handleBlur}
                          minDate={formik.values.startDate}
                          highlightDates={[new Date()]}
                        />
                      </div>
                    </div>
                    <div className="row gap-2 gap-md-0">
                      <div className="d-flex col-6 gap-2">
                        <Label check>{t('CASHEXPENSE.INVOICE_LABEL')}</Label>
                        <FormGroup switch>
                          <Input
                            fieldId="invoice"
                            fieldName="invoice"
                            type="switch"
                            checked={formik.values.invoice}
                            onClick={() => {
                              formik.setFieldValue('invoice', !formik.values.invoice);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex justify-content-end gap-3">
                    <Button
                      className="editBtn"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      disabled={formik.isSubmitting}
                    >
                      {formik.values.cashExpenseId ? t(`CASHEXPENSE.EDIT_BUTTON`) : t(`CASHEXPENSE.ADD_BUTTON`)}
                    </Button>

                    <Button className="submitBtn" onClick={handleCancelAction}>
                      {t(`CASHEXPENSE.CANCEL_BUTTON`)}
                    </Button>
                  </div>
                  {/* {JSON.stringify(formik, null, 2)} */}
                </ModalFooter>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}
