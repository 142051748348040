/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { pick } from 'lodash';

import LocationService from '../../services/location';

import CommonField from '../../components/CommonField';
import PhoneNumber from '../../components/PhoneNumber';

const InitialValues = {
  ownerName: '',
  nameOnTheInvoice: '',
  checkForName: '',
  phone1: '',
  phone2: '',
  vatNumber: '',
  ownerRemark: '',
};

const ValidationSchema = Yup.object().shape({
  ownerName: Yup.string().required(t('REQUIRED')).trim(),
  nameOnTheInvoice: Yup.string().required(t('REQUIRED')).trim(),
  checkForName: Yup.string().required(t('REQUIRED')).trim(),
  vatNumber: Yup.string().required(t('REQUIRED')).trim(),
});

const OwnerDetails = ({ formData, getDataFn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    const initData = pick(formData, ['id', ...Object.keys(InitialValues)]);
    setFormValues(initData);
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      phone1: values.phone1.replace(/-|_/g, ''),
      phone2: values.phone2.replace(/-|_/g, ''),
    };

    setIsLoading(true);
    await LocationService[formData.locationOwnerId === 0 ? 'createLocOwner' : 'updateLocOwner'](payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        getDataFn();
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...InitialValues, ...formValues }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Row>
              {/* Owner Name */}
              <Col sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="ownerName"
                    label={t('LOC_OWNER_DET.OWNER_NAME')}
                    placeholder={t('LOC_OWNER_DET.OWNER_NAME_PLCH')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Invoice Name */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="nameOnTheInvoice"
                    label={t('LOC_OWNER_DET.INVOICE_NAME')}
                    placeholder={t('LOC_OWNER_DET.INVOICE_NAME_PLCH')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Cheque Name */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="checkForName"
                    label={t('LOC_OWNER_DET.CHEQUE_NAME')}
                    placeholder={t('LOC_OWNER_DET.CHEQUE_NAME_PLCH')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Mobile */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <PhoneNumber fieldName="phone1" fieldLabel={t('MOBILE')} isDisabled={!showEditForm} />
                </div>
              </Col>

              {/* Phone */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <PhoneNumber fieldName="phone2" fieldLabel={t('PHONE')} isDisabled={!showEditForm} />
                </div>
              </Col>

              {/* Company CNPJ */}
              <Col sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="vatNumber"
                    label={t('LOC_OWNER_DET.COMPANY_CNPJ')}
                    placeholder={t('LOC_OWNER_DET.COMPANY_CNPJ_PLCH')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Remarks */}
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="remarksField">{t('EMPLOYEE.REMARKS_LABEL')}</Label>

                  <Field
                    id="remarksField"
                    name="ownerRemark"
                    component="textarea"
                    className="form-control"
                    placeholder={t('EMPLOYEE.REMARKS_PLACEHOLDER')}
                    disabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default OwnerDetails;
