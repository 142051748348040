/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
/* eslint eqeqeq: "off" */
import React, { useEffect } from 'react';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { ErrorMessage, Field } from 'formik';

function SelectField({
  fieldName,
  fieldLabel = '',
  fieldId = fieldName,
  fieldValue = null,
  fieldOptions = [],
  disabledOptVal = '',
  setFieldValue = () => {},
  setFieldTouched = () => {},
  fieldChange = () => {},
  isSimpleSelect = false,
  isDisabled = false,
  isRequired = false,
}) {
  const { t } = useTranslation();
  
  useEffect(() => {
    if (fieldValue) {
      setFieldTouched(fieldName, false)
    }
  }, [fieldValue])
  
  return (
    <React.Fragment>
      {fieldLabel && (
        <Label htmlFor={fieldId}>
          {isRequired && '*'}
          {fieldLabel}
        </Label>
      )}

      {isSimpleSelect ? (
        <Field
          component="select"
          name={fieldName}
          id={fieldId}
          className="form-select"
          disabled={isDisabled}
          onChange={(e) => {
            const val = e.target.value;
            setFieldValue(fieldName, val);

            const opObj = fieldOptions.find((item) => item.value == val);
            fieldChange(opObj || { id: parseInt(val), label: '', value: parseInt(val) });
          }}
        >
          <option value={disabledOptVal} disabled={isRequired}>
            -- {t('SELECT')} {fieldLabel} --
          </option>

          {fieldOptions.map((item, index) => (
            <option key={`${item.id || index}_${index}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
      ) : (
        <ReactSelect
          id={fieldId}
          name={fieldName}
          value={fieldOptions.filter(({ value }) => value == fieldValue)}
          options={fieldOptions}
          className="react-select"
          onChange={(option) => {
            const fieldVal = option ? option.value : '';
            setFieldValue(fieldName, fieldVal);
            fieldChange(option);
          }}
          onBlur={() => setFieldTouched(fieldName, true)}
          placeholder={`-- ${t('SELECT')} ${fieldLabel} --`}
          noOptionsMessage={() => t('RSEL_NO_OPT')}
          required={isRequired}
          isDisabled={isDisabled}
          isClearable
        />
      )}

      <ErrorMessage name={fieldName} className="text-danger" component="div" />
    </React.Fragment>
  );
}

export default SelectField;
