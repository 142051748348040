import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { t } from "i18next";

import warning from "../assets/images/Warning.png";

function ConfirmDeleteModal({
  isOpen = false,
  isLoading = false,
  btnName='',
  message = t("ALERT.CONFIRM_DELETE_MSG"),
  modalSize = "modal-m",
  confirmFn,
  cancelFn,
}) {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        backdrop="static"
        className={`modal-dialog-centered ${modalSize}`}
      >
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <img src={warning} alt="" height="50" />
            <h3 className="mx-2 mb-0">{message}</h3>
          </div>

          <br className="d-none d-sm-block" />
          <br />
          <div className="d-flex justify-content-end gap-2">
            <Button
              color={btnName ? 'primary' : "danger"}
              className="waves-effect me-1"
              onClick={confirmFn}
              disabled={isLoading}
            >
              {isLoading ? t("PLEASE_WAIT") : btnName ? btnName : t("DELETE")}
            </Button>
            <Button className="waves-effect me-1" onClick={cancelFn}>
              {t("CANCEL")}
            </Button>

          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmDeleteModal;
