import { combineReducers } from "@reduxjs/toolkit";

import Layout from "./slices/Layout/layoutSlice";
import Auth from "./slices/Auth/authSlice";
import LoadingOverlay from "./slices/loadingOverlaySlice";

const combinedReducer = combineReducers({
  Layout,
  Auth,
  LoadingOverlay,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/userLogout") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
