import React, { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
export default function DatePickerField({
  fieldName = "",
  fieldId = fieldName,
  fieldValue = "",
  fieldPlaceholder = "",
  filedFormate = "",
  isRequired = true,
  setFieldValue,
  isDisabled = false,
  setFieldTouched = () => {},
  className = "",
}) {
  const [startDate, setStartDate] = useState(fieldValue ? fieldValue : "");
  useEffect(() => {
    if (fieldValue) {
      setStartDate(new Date(fieldValue));
    } else {
      setStartDate();
    }
  }, [fieldValue]);
  return (
    <React.Fragment>
      <DatePicker
        todayButton={t("TodayDateButton")}
        dateFormat={filedFormate}
        id={fieldId}
        name={fieldName}
        placeholderText={fieldPlaceholder}
        selected={startDate}
        onChange={(date) =>
          setFieldValue(fieldName, date ? date.toString() : "")
        }
        className={className}
        disabled={isDisabled}
        required={isRequired}
        onBlur={() => setFieldTouched(fieldName, true)}
        autoComplete="off"
      />
    </React.Fragment>
  );
}
