import { post } from '../AxiosConfig';

const URI = '/employee-activity';

const amtEmpHave = (payload) => {
  const URL = `${URI}/amount-employee-have`;
  return post(URL, payload);
};
const totalWithdrawalAmount = (payload) => {
  const URL = `${URI}/get-total-withdrawal-amount`;
  return post(URL, payload);
};

const terminalWithdraw = (payload) => {
  const URL = `${URI}/terminal-withdrawal`;
  return post(URL, payload);
};

const bankAcctInfo = (payload) => {
  const URL = `${URI}/bank-acccount-information`;
  return post(URL, payload);
};

const withdrawnFromBank = (payload) => {
  const URL = `${URI}/withdrawn-frombank`;
  return post(URL, payload);
};

const chargeTerminal = (payload) => {
  const URL = `${URI}/charging-machine`;
  return post(URL, payload);
};

const splitWithdrawalToEmployees = (payload) => {
  const URL = `${URI}/split-withdrawaltoemployee`;
  return post(URL, payload);
};

const getLastBatchNumber = (payload) => {
  const URL = `${URI}/get-last-batch-number`;
  return post(URL, payload);
};

const checkLastBatchNumber = (payload) => {
  const URL = `${URI}/check-batch-number`;
  return post(URL, payload);
};

const getTotalWithdrawalAmount = (payload) => {
  const URL = `${URI}/get-total-withdrawal-amount`;
  return post(URL, payload);
};

const cancelTerminalNumber = (payload) => {
  const URL = `${URI}/get-total-withdrawal-amount`;
  return post(URL, payload);
};

const withdrawalMoney = (payload) => {
  const URL = `${URI}/edit-withdrawal-of-money`;
  return post(URL, payload);
};

const getBalanceBills = (payload) => {
  const URL = `${URI}/get-charging-machine-balance-bills`;
  return post(URL, payload);
};

const EmpActivityService = {
  amtEmpHave,
  bankAcctInfo,
  withdrawnFromBank,
  chargeTerminal,
  splitWithdrawalToEmployees,
  getLastBatchNumber,
  checkLastBatchNumber,
  getTotalWithdrawalAmount,
  withdrawalMoney,
  totalWithdrawalAmount,
  terminalWithdraw,
  cancelTerminalNumber,
  getBalanceBills
};

export default EmpActivityService;
