/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import i18n from './i18n';
import './validators';
// Import scss
import './assets/scss/theme.scss';
import './assets/scss/custom.scss';

// Import css
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import { AuthUserInfo, IsLockScreen, RouteTitles } from './constants';
import { updateAppTitle } from './helpers';
import LocalstorageService from './helpers/localstorage-service';

import { changeRTLLayout } from './store/slices/Layout/layoutSlice';
import { setAuthToken, setAuthUser } from './store/slices/Auth/authSlice';

// Layouts
import NonAuthLayout from './components/Layout/NonAuthLayout';
import VerticalLayout from './components/Layout/VerticalLayout';

// Auth
import RouteAuthGuard from './components/RouteAuthGuard';
import ForgotPassword from './pages/Authentication/ForgotPasword';
import Login from './pages/Authentication/Login';
import ResetPassword from './pages/Authentication/ResetPasword';
import VerifyEmail from './pages/Authentication/VerifyEmail';

// Account
import UserProfile from './pages/Account/UserProfile';
import Lockscreen from './pages/Authentication/Lockscreen';

import Dashboard from './pages/Dashboard';

// Bank
import Bank from './pages/Bank';
import BankList from './pages/Bank/List';
import AddBank from './pages/Bank/Add';
import UpdateBank from './pages/Bank/Update';
import BankBranch from './pages/Bank/Branch';

// Company
import Company from './pages/Company';

// Agent
import Agent from './pages/Agent';
import AgentList from './pages/Agent/List';
import EditAgent from './pages/Agent/EditAgent';

// Agent Activity
import AgentActivity from './pages/AgentActivity';
import AgentBankWithdrawal from './pages/AgentActivity/BankWithdrawal';
import AgentSplitMoney from './pages/AgentActivity/SplitMoney';

// Employee
import Employee from './pages/Employee';
import EmployeeList from './pages/Employee/List';
import AddEmployee from './pages/Employee/Add';
import EditEmployee from './pages/Employee/EditEmployee';

// Employee Activity
import EmployeeActivity from './pages/EmployeeActivity';
import EmpBankWithdrawal from './pages/EmployeeActivity/BankWithdrawal';
import ChargingTerminal from './pages/EmployeeActivity/ChargingTerminal';
import EmpSplitMoney from './pages/EmployeeActivity/SplitMoney';

// Locations
import Location from './pages/Location';
import LocationList from './pages/Location/List';
import AddLocation from './pages/Location/Add';
import EditLocation from './pages/Location/EditLocation';

//Cash expense view
import CashExpense from './pages/CashExpense';
//import CashExpenseList from './pages/CashExpense/List';

// Machines/Terminals
import Machines from './pages/Machines';
import MachinesList from './pages/Machines/List';
import AddMachine from './pages/Machines/Add';
import UpdateMachine from './pages/Machines/Update';

// Users
import Users from './pages/Account/Users';
import UserList from './pages/Account/Users/List';
import AddUser from './pages/Account/Users/Add';
import UpdateUser from './pages/Account/Users/Update';

// Unknown Route
import Error404 from './pages/Utility/Error404';
import SpecialDays from './pages/SpecialDays';
import SpecialDaysList from './pages/SpecialDays/List';
import AddSpecialDays from './pages/SpecialDays/Add';
import UpdateSpecialDays from './pages/SpecialDays/Update';
import ListEmployeeTerminals from './pages/EmployeeActivity/ListEmployeeTerminals';
import RemarkModal from './pages/EmployeeActivity/RemarkModal';
import TerminalPage from './pages/Machines/TerminalPage';
import EmployeeLoadsList from './pages/EmployeeActivity/EmployeeLoadsList';
import WithdrawalTerminal from './pages/EmployeeActivity/WithdrawalTerminal';
import AgentCashExpense from './pages/Report/AgentCashExpense';
import ReportPage from './pages/Report/ReportPage';
import ReferenceReport from './pages/Report/ReferenceReport';
import TerminalReport from './pages/Report/TerminalReport';
// import AgentCashExpense from './pages/Report/AgentCashExpense';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isRTLMode, setIsRTLMode] = useState(false);

  const userInfo = LocalstorageService.getItem(AuthUserInfo) || null;

  // Set direction for LTR/RTL directions.
  useEffect(() => {
    userInfo?.data && dispatch(setAuthUser(userInfo?.data));
    userInfo?.token && dispatch(setAuthToken(userInfo?.token));
    const dir = ['he'].includes(i18n.resolvedLanguage) ? 'rtl' : 'ltr';
    const htmlElem = document.getElementsByTagName('html');
    const bodyElem = document.getElementsByTagName('body');

    // bodyElem && bodyElem[0].setAttribute("data-layout-size", "fluid");
    // bodyElem && bodyElem[0].setAttribute("data-topbar", "light");

    if (htmlElem && bodyElem && dir === 'rtl') {
      htmlElem[0].setAttribute('dir', 'rtl');
      bodyElem[0].style.textAlign = 'right';
      bodyElem[0].classList.add('rtl-mode');
      setIsRTLMode(true);
    } else {
      htmlElem[0].setAttribute('dir', '');
      bodyElem[0].style.textAlign = '';
      bodyElem[0].classList.remove('rtl-mode');
      setIsRTLMode(false);
    }

    dispatch(changeRTLLayout({ isRtlLayout: dir === 'rtl' }));
  }, []);

  // Update document title
  useEffect(() => {
    const matchPath = [
      '/account/users/',
      '/verify-email/',
      '/terminal/',
      '/bank/',
      '/company/',
      '/agent/',
      '/employee/',
      '/locations/',
      '/special-days/',
      '/loading-terminal/',
      '/terminal-loads',
      '/cash-expense/',
      '/report/',
    ];

    let routePath = location.pathname;

    const idx = matchPath.findIndex((v) => routePath.startsWith(v));
    if (idx !== -1) routePath = matchPath[idx] + '*';

    updateAppTitle(RouteTitles[routePath] || '404');
  }, [location]);

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <RouteAuthGuard isAuthProtected={true} isLockScreen={!!LocalstorageService.getItem(IsLockScreen)}>
              <VerticalLayout />
            </RouteAuthGuard>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/bank" element={<Bank />}>
            <Route path="list" element={<BankList />} />
            <Route path="add" element={<AddBank />} />
            <Route path="update/:bankId" element={<UpdateBank />} />

            <Route path=":bankId/branches" element={<BankBranch />} />
          </Route>

          <Route path="/company/list" element={<Company />} />

          <Route path="/agent" element={<Agent />}>
            <Route path="list" element={<AgentList />} />
            <Route path="update/:agentId" element={<EditAgent />} />
          </Route>
          <Route path="/report" element={<ReportPage />}>
            <Route path="agent-cash-expense" element={<AgentCashExpense />} />
            <Route path="reference" element={<ReferenceReport />} />
            <Route path="terminal" element={<TerminalReport />} />
          </Route>
          <Route path="/agent-activity" element={<AgentActivity />}>
            <Route path="bank-withdrawal" element={<AgentBankWithdrawal />} />
            <Route path="split-money" element={<AgentSplitMoney />} />
          </Route>

          <Route path="/employee" element={<Employee />}>
            <Route path="list" element={<EmployeeList />} />
            <Route path="add" element={<AddEmployee />} />
            <Route path="update/:employeeId" element={<EditEmployee />} />
          </Route>

          <Route path="/employee-activity" element={<EmployeeActivity />}>
            <Route path="bank-withdrawal" element={<EmpBankWithdrawal />} />
            <Route path="charging-terminal" element={<ChargingTerminal />} />
            <Route path="split-money" element={<EmpSplitMoney />} />
            <Route path="withdrawal-terminal" element={<WithdrawalTerminal />} />
          </Route>

          <Route path="/locations" element={<Location />}>
            <Route path="list" element={<LocationList />} />
            <Route path="add" element={<AddLocation />} />
            <Route path="update/:locationId" element={<EditLocation />} />
          </Route>
          <Route path="/cash-expense/list" element={<CashExpense />} />
          {/* <Route path="list" element={<CashExpenseList />} /> */}
          {/* <Route path="add" element={<AddCashExpense />} /> */}
          {/* </Route> */}
          <Route path="/terminal" element={<Machines />}>
            <Route path="list" element={<MachinesList />} />

            <Route path="add" element={<AddMachine />} />
            <Route path="update/:machineId" element={<UpdateMachine />} />
          </Route>

          <Route path="/loading-terminal" element={<TerminalPage />}>
            <Route path="list" element={<ListEmployeeTerminals />} />
            <Route path="remark-list/:id" element={<RemarkModal />} />
            <Route path="terminal-loads" element={<EmployeeLoadsList />} />
          </Route>
          <Route path="/special-days" element={<SpecialDays />}>
            <Route path="list" element={<SpecialDaysList />} />
            <Route path="add" element={<AddSpecialDays />} />
            <Route path="update/:dayId" element={<UpdateSpecialDays />} />
          </Route>

          <Route path="/account/profile" element={<UserProfile />} />

          <Route path="/account/users" element={<Users />}>
            <Route path="list" element={<UserList />} />
            <Route path="add" element={<AddUser />} />
            <Route path="update/:userId" element={<UpdateUser />} />
          </Route>

          {/* <Route path="/report" element={''}>
            <Route index path="agent-cash-expense" element={<AgentCashExpense />} />
          </Route>*/}
        </Route>

        <Route
          path="/login"
          element={
            <RouteAuthGuard isAuthProtected={false}>
              <NonAuthLayout>
                <Login />
              </NonAuthLayout>
            </RouteAuthGuard>
          }
        />

        <Route
          path="/lock-screen"
          element={
            <RouteAuthGuard isAuthProtected={true}>
              <NonAuthLayout>
                <Lockscreen />
              </NonAuthLayout>
            </RouteAuthGuard>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <RouteAuthGuard isAuthProtected={false}>
              <NonAuthLayout>
                <ForgotPassword />
              </NonAuthLayout>
            </RouteAuthGuard>
          }
        />

        <Route
          path="/reset-password"
          element={
            <RouteAuthGuard isAuthProtected={false}>
              <NonAuthLayout>
                <ResetPassword />
              </NonAuthLayout>
            </RouteAuthGuard>
          }
        />

        <Route
          path="/verify-email/:userId"
          element={
            <RouteAuthGuard isAuthProtected={false}>
              <NonAuthLayout>
                <VerifyEmail />
              </NonAuthLayout>
            </RouteAuthGuard>
          }
        />

        <Route
          path="*"
          element={
            <NonAuthLayout>
              <Error404 />
            </NonAuthLayout>
          }
        />
      </Routes>

      <ToastContainer
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        theme="colored"
        pauseOnFocusLoss={false}
        rtl={isRTLMode}
      />
    </React.Fragment>
  );
}

export default App;
