import { t } from "i18next";
import React from "react";

function NoRecords({ colSpan = 0, msg = t("TABLE_NO_RECORDS") }) {
  return (
    <React.Fragment>
      <tr>
        <td colSpan={colSpan}>
          <h4 className="text-center p-4">{msg}</h4>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default NoRecords;
