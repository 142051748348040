/*eslint-disable no-unused-vars*/
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import { useEffect, useRef, useState } from 'react';
import LocalstorageService from '../../helpers/localstorage-service';
import { AuthUserInfo, CompanyId, ITEMS_PER_PAGE } from '../../constants';
import { Button, Col, Input, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { useOutletContext } from 'react-router-dom';
import { t } from 'i18next';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';
// import RemarkModal from './RemarkModal';
import NoRecords from '../../components/Table/NoRecords';
import SortableHeader from '../../components/SortableHeader';
import CommonField from '../../components/CommonField';
import AgentService from '../../services/agent';
import reportService from '../../services/report';
// import { PaginationControl } from 'react-bootstrap-pagination-control';
import * as Yup from 'yup';
import ReactDatePicker from 'react-datepicker';

const ReferenceReport = () => {
  const dispatch = useDispatch();
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState('');
  const reqPayload = {};
  const formRef = useRef();
  let companyId = LocalstorageService.getItem(CompanyId);
  const { company } = LocalstorageService.getItem(AuthUserInfo)?.data;
  const previousMonth = new Date().getMonth() - 1;
  const prviouusMonthDate = new Date();
  prviouusMonthDate.setMonth(previousMonth);
  const [dateState, setDateState] = useState({ start: prviouusMonthDate, end: new Date() });
  const dateRef = useRef();
  const dateRef2 = useRef();
  const [search, setSearch] = useState('');
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);
  const [downloading, setDownloading] = useState({ export: false, exportExcel: false });
  const InitialValues = {
    company,
    agent: '',
    search: '',
  };

  const ValidationSchema = Yup.object().shape({
    agent: Yup.string().required(t(`REPORT.REQUIRED_AGENT`)),
    // search: Yup.string().required(),
  });
  const [compConf, setCompConf] = useState({
    shown: false,
    msg: t('ALERT.EMPLOYEE_ZERO_AMOUNT_LOAD'),
    confirmFn: () => {
      setCompConf((pre) => {
        return {
          ...pre,
          shown: false,
        };
      });
    },
  });

  useEffect(() => {
    setPageTitle(t('REPORT.REPORT_REFER'));
    setCrumbItem([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('REPORT.REPORT_REFER'), link: '#' },
    ]);
    listFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearDate = () => {
    setDateState({ start: '', end: '' });
  };
  const fetchReferenceList = () => {
    if (agentId) {
      // if (agentId && search) {
      getReferencelist();
      fetchBankDetails(companyId, agentId, search);
    }
  };

  const HeaderList = [
    {
      title: t('REPORT.TABLE_FIELDS.DATE'),
      value: 'locationName',
      style: { minWidth: '100px', width: '20%' },
      sortable: false,
    },

    {
      title: t('REPORT.TABLE_FIELDS.TERMINAL_NUMBER'),
      value: 'loadingAmount',
      style: { minWidth: '100px', width: '20%' },
      sortable: false,
    },

    {
      title: t('REPORT.TABLE_FIELDS.LOCATION'),
      value: 'remark',
      style: { minWidth: '100px', width: '20%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.BATCH'),
      value: 'remark',
      style: { minWidth: '100px', width: '20%' },
      sortable: false,
    },

    {
      title: t('REPORT.TABLE_FIELDS.LOADING_AMT'),
      value: 'remark',
      style: { minWidth: '100px', width: '20%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.RETURNED_TERMINAL_AMT'),
      value: 'remark',
      style: { minWidth: '100px', width: '20%' },
      sortable: false,
    },
    {
      title: t('REPORT.TABLE_FIELDS.TOTAL_RETURNED_AMT'),
      value: 'remark',
      style: { minWidth: '100px', width: '20%' },
      sortable: false,
    },
  ];

  async function fetchBankDetails(companyId, agentId, reference) {
    // setIsLoading(true);
    // dispatch(setLoadingOverlay(true));
    await reportService
      .getBankDetails({ companyId, agentId, reference })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (dataList && dataList.length > 0) {
          let val = dataList[0];
          formRef.current.values.bankName = val?.bankName;
          formRef.current.values.date = val?.date ? new Date(val?.date)?.toLocaleDateString('en-GB') : '';
          formRef.current.values.branchName = val?.branchName;
          formRef.current.values.accountNumber = val?.accountNumber;
        }
      })
      .finally(() => {
        // setIsLoading(false);
        // dispatch(setLoadingOverlay(false));
      });
  }

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await AgentService.listAgent({ ...reqPayload, companyId: companyId })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;
        const arr = [];
        if (dataList.length > 0) {
          dataList.forEach((val) => {
            arr.push({ value: val.id, label: val.firstName });
          });
        }
        setAgentList(arr || []);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  // const handlePageClick = (currentPage) => {
  //   reqPayload.companyId = companyId;
  //   reqPayload.agentId = agentId;
  //   reqPayload.reference = search;
  //   reqPayload.export = false;
  //   reqPayload.exportExcel = false;
  //   // reqPayload.PageNo = currentPage;
  //   // reqPayload.PageSize = 10;
  //   // reqPayload.AllList = false;
  //   setCurrentPage(currentPage);
  //   getReferencelist(currentPage);
  // };

  async function getReferencelist(currentPage = 1) {
    reqPayload.companyId = companyId;
    reqPayload.agentId = agentId;
    reqPayload.reference = search;
    reqPayload.startDate = dateState.start ? new Date(dateState.start).toLocaleDateString('en-us') : '';
    reqPayload.endDate = dateState.end ? new Date(dateState.end).toLocaleDateString('en-us') : '';
    // reqPayload.PageNo = currentPage;
    reqPayload.export = false;
    reqPayload.exportExcel = false;
    // reqPayload.PageSize = 10;
    // reqPayload.AllList = false;
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await reportService
      .getReportByReference(reqPayload)
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;
        setList(dataList || []);

        // setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }
  function handleDownload(selectedType) {
    setDownloading({ export: selectedType.export, exportExcel: selectedType.exportExcel });
    reqPayload.companyId = companyId;
    reqPayload.agentId = agentId;
    reqPayload.reference = search;
    reqPayload.startDate = dateState.start;
    reqPayload.endDate = dateState.end;
    reqPayload.export = selectedType.export;
    reqPayload.exportExcel = selectedType.exportExcel;
    // reqPayload.AllList = true;
    downloadReport();
  }

  async function downloadReport() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await reportService
      .getReportByReference({
        ...reqPayload,
        startDate: reqPayload.startDate ? new Date(reqPayload.startDate).toLocaleDateString('en-us') : '',
        endDate: reqPayload.endDate ? new Date(reqPayload.endDate).toLocaleDateString('en-us') : '',
      })
      .then((response) => {
        const { status, message, filePath } = response.data;
        if (status === 400) {
          toast.error(message);
          return;
        }
        if (filePath) {
          const pdfUrl = filePath;
          const link = document.createElement('a');

          link.href = pdfUrl;
          if (reqPayload.exportExcel === true) {
            link.download = 'referenceReport.xlsx';
          } else {
            link.download = 'referenceReport.pdf';
          }
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
        setDownloading({ export: false, exportExcel: false });
      });
  }

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={fetchReferenceList}
      >
        {({ values, setFieldValue, setFieldTouched, errors, handleSubmit }) => (
          <Form noValidate>
            <Row>
              <Col sm={3}>
                <div className="mb-3 mt-2">
                  <CommonField
                    fieldType="string"
                    fieldName="company"
                    label={t('REPORT.COMPANY_LABEL')}
                    isDisabled={true}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className=" mb-3">
                  <label>{t('REPORT.AGENT_LABEL') + '*'}</label>
                  <ReactSelect
                    name={'agent'}
                    value={!agentId ? '' : agentList.filter(({ value }) => value === agentId)}
                    options={agentList}
                    className="react-select"
                    onChange={(option) => {
                      setFieldValue('agent', option?.value);
                      setAgentId(option?.value);
                    }}
                    required={true}
                    onBlur={() => setFieldTouched('agent', true)}
                    placeholder={`-- ${t('REPORT.AGENT_PLACEHOLDER')} --`}
                    noOptionsMessage={() => t('RSEL_NO_OPT')}
                    isSearchable={true}
                  />
                  {errors.agent && (
                    <div className="errorMSG" style={{ color: 'red' }}>
                      {errors.agent}
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={3} className="mb-3">
                {/* <div className="d-flex justify-content-around align-items-center"> */}
                <label htmlFor="dateStart" className="text-nowrap" style={{ width: '40%' }}>
                  {t(`LOAD_REMARKS.FORM_DATE`)}
                </label>
                <ReactDatePicker
                  ref={dateRef}
                  name="dateStart"
                  id="dateStart"
                  className="form-control"
                  selected={dateState.start}
                  onChange={(dateArr) =>
                    setDateState((prev) => {
                      return { ...prev, start: dateArr };
                    })
                  }
                  highlightDates={[new Date()]}
                  dateFormat={'dd/MM/yyyy'}
                  shouldCloseOnSelect={true}
                ></ReactDatePicker>
                {/* </div> */}
              </Col>
              <Col sm={3} className="mb-3">
                {/* <div className="d-flex justify-content-around align-items-center"> */}
                <label htmlFor="dateEnd" style={{ width: '40%' }}>
                  {t(`LOAD_REMARKS.TO_DATE`)}
                </label>
                <div className="d-flex">
                  <ReactDatePicker
                    ref={dateRef2}
                    name="dateEnd"
                    id="dateEnd"
                    className="form-control"
                    selected={dateState.end}
                    onChange={(dateArr) =>
                      setDateState((prev) => {
                        return { ...prev, end: dateArr };
                      })
                    }
                    highlightDates={[new Date()]}
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                  ></ReactDatePicker>
                  <Button
                    className="ms-2 "
                    id="clearBtn"
                    color="danger"
                    disabled={!dateState.start && !dateState.end}
                    type="button"
                    onClick={() => clearDate()}
                  >
                    {'X'}
                    <UncontrolledTooltip placement="right" target={`clearBtn`}>
                      {t(`REPORT.CLEAR_DATE`)}
                    </UncontrolledTooltip>
                  </Button>
                </div>
                {/* </div> */}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div className="mb-3 mt-1">
                  <CommonField
                    fieldType="string"
                    fieldName="date"
                    label={t('REPORT.TABLE_FIELDS.DATE')}
                    isDisabled={true}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="mb-3 mt-1">
                  <CommonField
                    fieldType="string"
                    fieldName="bankName"
                    label={t('REPORT.TABLE_FIELDS.BANK')}
                    isDisabled={true}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="mb-3 mt-1">
                  <CommonField
                    fieldType="string"
                    fieldName="branchName"
                    label={t('REPORT.TABLE_FIELDS.BRANCH')}
                    isDisabled={true}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="mb-3 mt-1">
                  <CommonField
                    fieldType="string"
                    fieldName="accountNumber"
                    label={t('REPORT.TABLE_FIELDS.ACC_NUMBER')}
                    isDisabled={true}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={3} className="d-flex flex-column mb-3">
                <div style={{ height: '50%', width: '100%' }}></div>
                <div className="d-flex mb-1">
                  <Input
                    name="search"
                    type="number"
                    onChange={(e) => {
                      updateSearch(e);
                      setFieldValue('search', e.target.value.trim());
                    }}
                    onKeyUp={({ code }) => {
                      if (code === 'Enter') {
                        handleSubmit();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    value={values.search}
                    placeholder={t('REPORT.SEARCH_PLACEHOLDER')}
                  />
                  <Button className="ms-2" color="primary" type="button" onClick={() => handleSubmit()}>
                    <i className="ri-search-line"></i>
                  </Button>
                </div>
                {errors.search && <div className="errorSearchBox">{t('REPORT.REQUIRED_PLACE1')}</div>}
              </Col>
              <Col sm={5}>
                <div className=" mb-3 gap-2 d-flex">
                  <Button
                    color={isLoading || list.length === 0 ? 'secondary' : 'primary'}
                    type="button"
                    disabled={isLoading || list.length === 0}
                    onClick={() => handleDownload({ export: true, exportExcel: false })}
                  >
                    {downloading.export && !downloading.exportExcel
                      ? t('REPORT.DOWNLOADING')
                      : t('REPORT.DOWNLOAD_PDF')}
                  </Button>
                  <Button
                    color={isLoading || list.length === 0 ? 'secondary' : 'primary'}
                    type="button"
                    disabled={isLoading || list.length === 0}
                    onClick={() => handleDownload({ export: true, exportExcel: true })}
                  >
                    {downloading.export && downloading.exportExcel ? t('REPORT.DOWNLOADING') : t('REPORT.DOWNLOAD_XLX')}
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="dataMin employeeLoads reportTable">
              <Table
                className="fixTh dataTable overflow-visible"
                striped
                bordered
                responsive
                size="sm"
                style={{ minWidth: '650px', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr>
                    <SortableHeader showActionCol={false} headers={HeaderList} isDisabled={list.length === 0} />
                  </tr>
                </thead>

                <tbody>
                  {list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-nowrap">{item?.date ? new Date(item.date)?.toLocaleDateString('en-GB') : ''}</td>

                        <td className="">{item.terminalNumber}</td>

                        <td className="">{item.locationName}</td>
                        <td className="">{item.batch}</td>
                        <td dir="ltr">{item.loadingAmount?.toLocaleString('en-US')}</td>
                        <td dir="ltr">{item.returnFromTerminal?.toLocaleString('en-US')}</td>
                        <td dir="ltr">{item.totalReturnFromTerminal?.toLocaleString('en-US')}</td>
                      </tr>
                    );
                  })}

                  {(isLoading || list.length === 0) && <NoRecords colSpan={HeaderList.length + 1} />}
                </tbody>
              </Table>
            </div>
          </Form>
        )}
      </Formik>
      {/* <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div> */}
    </>
  );
};

export default ReferenceReport;
