import { post } from '../AxiosConfig';

const getAgentExpenses = (payload) => {
  const URL = `/reporting/agent-cash-expense-report`;
  return post(URL, payload);
};

const downloadReport = (payload) => {
  const URL = `/reporting/export-agent-cash-expense-report`;
  return post(URL, payload);
};

const getReportByLocations = (payload) => {
  const URL = `/reporting/loading-report-by-terminal-location`;
  return post(URL, payload);
};

const getReportByReference = (payload) => {
  const URL = `/reporting/terminal-report-by-reference`;
  return post(URL, payload);
};

const getBankDetails = (payload) => {
  const URL = `/reporting/bank-details-from-reference`;
  return post(URL, payload);
};

const reportService = {
  getAgentExpenses,
  downloadReport,
  getReportByLocations,
  getReportByReference,
  getBankDetails,
};
export default reportService;
