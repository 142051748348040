/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import natanelLg from '../../../assets/images/logo/natanel-lg.png';
import natanelSm from '../../../assets/images/logo/natanel-sm.png';

import { CompanyId } from '../../../constants';
import LocalstorageService from '../../../helpers/localstorage-service';

import LanguageDropdown from '../../Topbar/LanguageDropdown';
import ProfileMenu from '../../Topbar/ProfileMenu';

let companyId = 0;

function Header(props) {
  const { t } = useTranslation();
  const { leftSideBarType } = useSelector((state) => state.Layout);
  const { authUser } = useSelector((state) => state.Auth);

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect((e) => {
    document.addEventListener('fullscreenchange', (e) => {
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        setIsFullScreen(false);
      }
    });

    companyId = LocalstorageService.getItem(CompanyId);

    return () => {
      document.removeEventListener('fullscreenchange', () => {});
    };
  }, []);

  const toggleFullscreen = () => {
    setIsFullScreen(!isFullScreen);
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={natanelSm} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={natanelLg} alt="" height="30" />
                </span>
              </Link>
            </div>

            <Button
              size="sm"
              color="none"
              type="button"
              onClick={() => props.toggleMenuCallback()}
              className="px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              title={t(`CLICK_TO_${leftSideBarType === 'default' ? 'COLLAPSE' : 'EXPAND'}`)}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </Button>
          </div>

          <h5 className="mb-0">{authUser?.company}</h5>

          <div className="d-flex">
            <div className="ms-2">
              <LanguageDropdown />
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <Button
                color="none"
                type="button"
                className="header-item noti-icon waves-effect"
                onClick={() => toggleFullscreen()}
                title={t(`${isFullScreen ? 'EXIT' : 'ENTER'}_FULLSCREEN`)}
              >
                <i className={`ri-fullscreen${isFullScreen ? '-exit' : ''}-line`}></i>
              </Button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
