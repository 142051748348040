import { post } from "../AxiosConfig";

const URI = "/agent";

const listAgent = (payload) => {
  const URL = `${URI}/list-agent`;
  return post(URL, payload);
};

const updateAgent = (payload) => {
  const URL = `${URI}/update-agent`;
  return post(URL, payload);
};

const deleteAgent = (payload) => {
  const URL = `${URI}/delete-agent`;
  return post(URL, payload);
};

const bankAffiliation = (payload) => {
  const URL = `${URI}/add-agent-bankdetail`;
  return post(URL, payload);
};

const bankAffiInfo = (payload) => {
  const URL = `${URI}/bank-accounts`;
  return post(URL, payload);
};

const AgentService = {
  listAgent,
  updateAgent,
  deleteAgent,
  bankAffiliation,
  bankAffiInfo
};

export default AgentService;
