import { post } from "../AxiosConfig";

const URI = "/SpecialDay";

const listMachines = (payload) => {
  const URL = `terminal/list-terminal`;
  return post(URL, payload);
};

const listSpecialDay = (payload) => {
  const URL = `${URI}/list-specialDay`;
  return post(URL, payload);
};

const listNextWeekSpecialDay = (payload) => {
  const URL = `${URI}/list-NextWeekSpecialDay`;
  return post(URL, payload);
};


const createSpecialDays = (payload) => {
  const URL = `${URI}/Create-SpecialDay`;
  return post(URL, payload);
};

const updateMachine = (payload) => {
  const URL = `${URI}/Create-SpecialDay`;
  return post(URL, payload);
};

const deleteMachine = (payload) => {
  const URL = `${URI}/Delete-SpecialDays?Id=${payload}`;
  return post(URL);
};

const SpecialDaysService = {
  createSpecialDays,
  listMachines,
  listSpecialDay,
  updateMachine,
  deleteMachine,
  listNextWeekSpecialDay
};

export default SpecialDaysService;
