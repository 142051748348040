import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../components/Breadcrumb";
import LoadingOverlay from "../../components/LoadingOverlay";

function Machines() {
    const { t } = useTranslation();
    const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);

    const [pageTitle, setPageTitle] = useState(t("LOADING_TERMINAL.TITLE"));
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: t("LOADING_TERMINAL.TITLE"), link: "#" },
        // { title: "Machines", link: "#" },
    ]);

    return (
        <React.Fragment>
            <Breadcrumbs title={pageTitle} breadcrumbItems={breadcrumbItems} />

            <Card className="card-box">
                {showLoadingOverlay && <LoadingOverlay />}

                <CardBody>
                    <Outlet context={[setPageTitle, setBreadcrumbItems]} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default Machines;
