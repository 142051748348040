import React from "react";
import { useTranslation } from "react-i18next";

// Import images
import regionSEE from "../assets/images/regionSEE.png";

function FooterInscription() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <p className="">
        © {new Date().getFullYear() + " "} {t("APPLICATION_TITLE")}.{" "}
        {t("CRAFTED_WITH")}
        <img
          src={regionSEE}
          alt={process.env.REACT_APP_CRAFTED_BY}
          height="20"
          className=""
        />
      </p>
    </React.Fragment>
  );
}

export default FooterInscription;
