/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { t } from 'i18next';

function LoadingOverlay({ message = t('LOADING'), style }) {
  const loRef = React.createRef();

  useEffect(() => {
    loRef.current.parentElement.style.position = 'relative';
  }, []);

  return (
    <React.Fragment>
      <div ref={loRef} className="loading-overlay" style={style}>
        <h3 className="message">{message}</h3>
      </div>
    </React.Fragment>
  );
}

export default LoadingOverlay;
