import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Container,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import { ErrorMessage, Field, Form, Formik } from "formik";

// Import images
import natanelLg from "../../assets/images/logo/natanel-lg.png";

import { PhoneNumberSchema } from "../../helpers/yup-schema";
import AuthService from "../../services/auth";

import OtpModal from "../../components/OtpModal";

function ForgotPassword() {
  const formRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otpToken, setOtpToken] = useState();
  const [sendingOtp, setSendingOtp] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);

  const onRadioBtnClick = (setTouched, setFieldValue, type = "email") => {
    setTouched({});
    if (type === "phoneNumber") {
      setFieldValue("email", "");
      return;
    }

    setFieldValue("phoneNumber", "");
  };

  async function sendOtpFn(phoneNumber) {
    setSendingOtp(true);
    await AuthService.sendOtp({ phoneNumber })
      .then((response) => {
        const { status, message, token } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setOtpToken(token);
        setOtpModalOpen(true);
        toast.success(message);
      })
      .finally(() => {
        setSendingOtp(false);
      });
  }

  async function verifyOtpFn(phoneOtp) {
    setVerifyingOtp(true);

    await AuthService.verifyOtp(
      { phoneOtp },
      {
        headers: {
          Authorization: "Bearer " + otpToken,
        },
      }
    )
      .then((response) => {
        const { message, status, token } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        setOtpToken();
        setOtpModalOpen(false);
        navigate({
          pathname: "/reset-password",
          search: `?token=${token}`,
        });
      })
      .finally(() => {
        setVerifyingOtp(false);
      });
  }

  async function forgotPasswordFn(formData = null) {
    const payload = formData ? formData : formRef.current.values;

    setIsLoading(true);
    await AuthService.forgotPassword(payload)
      .then(async ({ data }) => {
        const { status, message, token } = data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        if (payload.verificationMode === "email") {
          navigate("/login");
        } else {
          setOtpToken(token);
          setOtpModalOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      phoneNumber: values.phoneNumber.replace(/-|_/g, ""),
    };

    forgotPasswordFn(payload);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col lg={4} className="p-3 card">
                  <div>
                    <div className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link
                          id="backToLogin"
                          to="/login"
                          style={{ position: "absolute", left: "15px" }}
                        >
                          <i className="ri-arrow-left-circle-line font-size-34"></i>
                        </Link>

                        <UncontrolledTooltip
                          placement="right"
                          target="backToLogin"
                        >
                          {t("TOOLTIP.BACK_TO_LOGIN")}
                        </UncontrolledTooltip>

                        <img
                          src={natanelLg}
                          alt=""
                          height="30"
                          className="auth-logo logo-dark mx-auto"
                        />
                      </div>

                      <h4 className="font-size-18 mt-4">
                        {t("FORGOT_PASSWORD.TITLE")}
                      </h4>
                    </div>

                    <div className="mt-2">
                      <Formik
                        innerRef={formRef}
                        className="form-horizontal"
                        initialValues={{
                          verificationMode: "phone",
                          email: "",
                          phoneNumber: "",
                          otp: "",
                        }}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          isSubmitting,
                          setTouched,
                          setFieldValue,
                        }) => (
                          <Form>
                            <div className="mt-4 mb-2">
                              <label className="form-check-label">
                                <Field
                                  type="radio"
                                  name="verificationMode"
                                  className="form-check-input"
                                  onClick={() =>
                                    onRadioBtnClick(
                                      setTouched,
                                      setFieldValue,
                                      "phoneNumber"
                                    )
                                  }
                                  value="phone"
                                  disabled={
                                    isSubmitting || isLoading || sendingOtp
                                  }
                                />
                                {t("PHONE_NO_LABEL")}
                              </label>

                              <label className="form-check-label">
                                <Field
                                  type="radio"
                                  name="verificationMode"
                                  className="form-check-input"
                                  onClick={() =>
                                    onRadioBtnClick(setTouched, setFieldValue)
                                  }
                                  value="email"
                                  disabled={
                                    isSubmitting || isLoading || sendingOtp
                                  }
                                />
                                {t("EMAIL_LABEL")}&nbsp;/&nbsp;
                                {t("USERNAME_LABEL")}
                              </label>
                            </div>

                            {values.verificationMode === "email" ? (
                              <div className="mb-4">
                                <div className="auth-form-group-custom">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="email">
                                    {t("EMAIL_LABEL")}&nbsp;/&nbsp;
                                    {t("USERNAME_LABEL")}
                                  </Label>
                                  <Field
                                    name="email"
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    validate={(v) => !v && t("REQUIRED")}
                                    placeholder={`${t(
                                      "EMAIL_PLACEHOLDER"
                                    )} / ${t("USERNAME_LABEL")?.toLowerCase()}`}
                                  />
                                </div>

                                <ErrorMessage
                                  name="email"
                                  className="text-danger"
                                  component="div"
                                />
                              </div>
                            ) : (
                              <div className="mb-4">
                                <div className="auth-form-group-custom">
                                  <i className="ri-phone-line auti-custom-input-icon"></i>
                                  <Label htmlFor="phoneNumber">
                                    {t("PHONE_NO_LABEL")}
                                  </Label>

                                  <Field
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    validate={async (v) => {
                                      let flag = false;
                                      const ev = await PhoneNumberSchema()
                                        .validate({
                                          phoneNumber: v,
                                        })
                                        .catch((err) => {
                                          flag = true;
                                          return err?.errors[0];
                                        });
                                      return flag ? ev : "";
                                    }}
                                  >
                                    {({ field }) => (
                                      <PatternFormat
                                        {...field}
                                        className="form-control"
                                        format="###-###-####"
                                        mask="_"
                                        placeholder="XXX-YYY-YYYY"
                                        autoComplete="off"
                                      />
                                    )}
                                  </Field>
                                </div>

                                <ErrorMessage
                                  name="phoneNumber"
                                  className="text-danger"
                                  component="div"
                                />
                              </div>
                            )}

                            {/* Submit Link/OTP */}
                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                                disabled={
                                  isSubmitting || isLoading || sendingOtp
                                }
                              >
                                {isSubmitting || isLoading || sendingOtp
                                  ? t("PLEASE_WAIT")
                                  : values.verificationMode === "email"
                                  ? t("FORGOT_PASSWORD.SEND_LINK")
                                  : t("FORGOT_PASSWORD.GET_CODE")}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>

      <OtpModal
        isOpen={otpModalOpen}
        toggleOtpModal={() => setOtpModalOpen(false)}
        resendOtpFn={() => {
          const formValues = formRef.current?.values;
          formValues.phoneNumber = formValues.phoneNumber.replace(/-|_/g, "");
          sendOtpFn(formValues.phoneNumber);
        }}
        verifyCodeFn={(phoneOtp) => {
          verifyOtpFn(phoneOtp);
        }}
        verifyingCode={verifyingOtp}
      />
    </React.Fragment>
  );
}

export default ForgotPassword;
