/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/ import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE, UserId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';
import EmployeeService from '../../services/employee';
import EmpActivityService from '../../services/emp_activity';
import AgentService from '../../services/agent';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SelectField from '../../components/SelectField';
import NumericFormatField from '../../components/NumericFormatField';
import ConfirmSaveData from './ConfirmSaveData.modal';

const InitialValues = {
  agentId: '',
  agentAccountId: '',
  employeeId: '',
  // accountId: "",
  amount: '',
  reference: '',
};

const ValidationSchema = AmountSchema().shape({
  agentId: Yup.string().required(t('REQUIRED')).trim(),
  agentAccountId: Yup.string().required(t('REQUIRED')).trim(),
  employeeId: Yup.string().required(t('REQUIRED')).trim(),
  // accountId: Yup.string().required(t("REQUIRED")).trim(),
  reference: Yup.string().required(t('REQUIRED')).trim(),
});

let companyId = 0;

function BankWithdrawal() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [initApiCount, setInitApiCount] = useState(0);
  const [roleId, setRoleId] = useState(USER_ROLE.EMPLOYEE);
  const [agentOptList, setAgentOptList] = useState([]);
  const [agentAcctOptList, setAgentAcctOptList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [empAcctList, setEmpAcctList] = useState([]);
  const [empAcctOptList, setEmpAcctOptList] = useState([]);
  const [confirmSaveData, setConfirmSaveData] = useState({});

  useEffect(() => {
    setPageTitle(t('BANK_WITHDRAWAL.TITLE'));
    setCrumbItem([
      { title: t('AGENT_ACTIVITY'), link: '#' },
      { title: t('BANK_WITHDRAWAL.TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);

    const userRole = LocalstorageService.getItem(JobRole);
    setRoleId(userRole);

    if (userRole === USER_ROLE.AGENT) {
      const loginUserId = LocalstorageService.getItem(UserId);
      InitialValues.agentId = loginUserId;
      getAgentAcctNumbersFn(loginUserId);
    } else {
      getAgentsFn();
    }

    getEmployeeFn();
  }, []);

  useEffect(() => {
    if (initApiCount === 2) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  async function getAgentsFn() {
    dispatch(setLoadingOverlay(true));
    await AgentService.listAgent({ companyId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            companyId: item.companyId,
          }));

          setAgentOptList(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getAgentAcctNumbersFn(agentId) {
    dispatch(setLoadingOverlay(true));
    await AgentService.bankAffiInfo({ companyId, agentId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          setEmpAcctList(dataList);

          const data = dataList.map((item) => ({
            id: item.bankAccountId,
            label: `${item.bankName} / ${item.branchName} / ${item.accountNumber}`,
            value: item.bankAccountId,
          }));

          setAgentAcctOptList(data);
        }
      })
      .finally(() => {
        roleId === USER_ROLE.AGENT ? setInitApiCount((prev) => prev + 1) : dispatch(setLoadingOverlay(false));
      });
  }

  async function getEmployeeFn() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ companyId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          }));

          setEmpList(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getEmpAcctNumbersFn(employeeId) {
    dispatch(setLoadingOverlay(true));
    await EmpActivityService.bankAcctInfo({ companyId, employeeId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          setEmpAcctList(dataList);

          const data = dataList.map((item) => ({
            id: item.bankAccountId,
            label: `${item.bankName} / ${item.branchName} / ${item.accountNumber}`,
            value: item.bankAccountId,
          }));

          setEmpAcctOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  function handleToggleModal(isCancelled = true) {
    setConfirmSaveData({
      ...confirmSaveData,
      formData: {},
      isOpened: false,
    });

    !isCancelled && formRef.current.resetForm();
  }

  async function confirmDetailsFn() {
    const { values, setSubmitting, setTouched, validateForm } = formRef.current;
    setSubmitting(false);
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }

    const payload = {
      ...values,
      companyId,
      agentId: parseInt(values.agentId),
      accountId: parseInt(values.agentAccountId),
      employeeId: parseInt(values.employeeId),
      amount: parseFloat(values.amount),
    };

    setConfirmSaveData({
      formData: { ...payload },
      isOpened: true,
      toggleModal: handleToggleModal,
    });
  }

  return (
    <React.Fragment>
      <Formik innerRef={formRef} initialValues={InitialValues} validationSchema={ValidationSchema}>
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Agent */}
              {roleId !== USER_ROLE.AGENT && (
                <Col sm={4}>
                  <div className="mb-4">
                    <SelectField
                      fieldName="agentId"
                      fieldLabel={t('ROLES.AGENT')}
                      fieldValue={values.agentId}
                      fieldOptions={agentOptList}
                      fieldChange={(option) => {
                        if (option) {
                          companyId = option.companyId;
                          getAgentAcctNumbersFn(option.value);
                        }

                        setAgentAcctOptList([]);
                        setFieldValue('agentAccountId', '');
                        setFieldTouched('agentAccountId', false);
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isRequired={true}
                    />
                  </div>
                </Col>
              )}

              {/* Agent Account Number */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="agentAccountId"
                    fieldLabel={`${t('ROLES.AGENT')} ${t('EMPLOYEE.ACC_NO_LABEL')}`}
                    fieldValue={values.agentAccountId}
                    fieldOptions={agentAcctOptList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={roleId !== USER_ROLE.AGENT && !values.agentId}
                  />
                </div>
              </Col>

              {/* Employee */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="employeeId"
                    fieldLabel={t('ROLES.EMPLOYEE')}
                    fieldValue={values.employeeId}
                    fieldOptions={empList}
                    fieldChange={(option) => {
                      setEmpAcctOptList([]);
                      option && getEmpAcctNumbersFn(option.value);
                      // setFieldValue("accountId", "");
                      // setFieldTouched("accountId", false);
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Cheque Amount */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="amount"
                    fieldValue={values.amount}
                    fieldDecimalScale={2}
                    fieldLabel={t('CHEQUE_AMT')}
                    fieldPlaceholder={t('BANK_WITHDRAWAL.CHEQUE_AMT_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>

              {/* Cheque Number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="reference"
                    fieldValue={values.reference}
                    fieldLabel={t('CHEQUE_NUMBER')}
                    fieldPlaceholder={t('BANK_WITHDRAWAL.CHEQUE_NUM_PLACEHOLDER')}
                    allowLeadingZeros={true}
                    useThousandSeparator={false}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button color="primary" className="w-md waves-effect waves-light" onClick={() => confirmDetailsFn()}>
                {t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmSaveData {...confirmSaveData} empAcctList={empAcctList} />
    </React.Fragment>
  );
}

export default BankWithdrawal;
