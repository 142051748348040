import { post } from "../AxiosConfig";

const URI = "/agent-activity";

const amountAgentHave = (payload) => {
  const URL = `${URI}/amount-agent-have`;
  return post(URL, payload);
};

const splitToEmployees = (payload) => {
  const URL = `${URI}/split-to-employees`;
  return post(URL, payload);
};

const AgentActivityService = {
  amountAgentHave,
  splitToEmployees,
};

export default AgentActivityService;
