/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, Row, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { t } from 'i18next';

import { CompanyId, ITEMS_PER_PAGE, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import LocationService from '../../services/location';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import ActionBtns from '../../components/ActionBtns';

const HeaderList = [
  {
    title: t('LOCATION.BUSINESS_NAME_LABEL'),
    value: 'business',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('MACHINE.NUMBER'),
    value: 'terminalNumber',
    style: { minWidth: '150px', width: '30%' },
    // sortable: false,
  },
  {
    title: t('EMPLOYEE.CITY_LABEL'),
    value: 'city',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMPLOYEE.STREET_LABEL'),
    value: 'street',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('LOCATION.HOUSE_NUMBER_LABEL'),
    value: 'houseNumber',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('EMPLOYEE.ZIPCODE_LABEL'),
    value: 'zipCode',
    style: { minWidth: '100px', width: '30%' },
  },
];

const reqPayload = { pageNo: 1, companyId: 0 };

function LocationList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setPageTitle, setBreadcrumbItems] = useOutletContext();

  const [roleID, setRoleID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [showDelRec, setShowDelRec] = useState(false);

  const [confDelTerm, setConfDelTerm] = useState({
    shown: false,
    confirmDelTermFn: () => {},
  });

  useEffect(() => {
    setPageTitle(t('LOCATION.TITLE'));
    setBreadcrumbItems([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('LOCATION.TITLE'), link: '#' },
    ]);

    reqPayload['showDelRec'] = showDelRec;
    reqPayload['sort'] = [{ value: 'business', asc: true }];

    const companyId = LocalstorageService.getItem(CompanyId);
    reqPayload['companyId'] = companyId;

    const jobRole = LocalstorageService.getItem(JobRole);
    setRoleID(jobRole);
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  }, [search]);

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await LocationService.listLocation(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        setList(dataList || []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const deleteModal = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };

  const resetModal = () => {
    setDeleteItem(null);
    setShowDeleteModal(false);
    setShowActivateModal(false);
  };

  const confirmDelete = async (e, wantToDelete = false) => {
    const payload = { id: deleteItem.id, wantToDelete };

    let flag = false;
    setIsLoading(true);
    await LocationService.deleteLocation(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        } else if (status === 201) {
          flag = true;
          setConfDelTerm({
            shown: true,
            message,
          });
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        setIsLoading(false);
        !flag && setConfDelTerm({ shown: false, confirmDelTermFn: () => {} });
      });

    flag ? setShowDeleteModal(false) : resetModal();
  };

  const editFn = (item) => {
    navigate(`../update/${item.id}`, {
      state: { companyId: item.companyId, terminalNumber: item.terminalNumber, locationId: item.id },
    });
  };

  const viewFn = (item) => {
    navigate(`../update/${item.id}`, { state: { companyId: item.companyId } });
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    } else {
      reqPayload['sort'] = [{ value: 'business', asc: true }];
    }
    listFn();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    listFn();
  };

  const handleShowDeleteRec = () => {
    delete reqPayload['search'];
    reqPayload['showDelRec'] = !showDelRec;
    setShowDelRec(!showDelRec);
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  };

  const confirmActivate = async () => {
    dispatch(setLoadingOverlay(true));
    await LocationService.activateLocation({
      id: deleteItem.id,
      companyId: deleteItem.companyId,
    })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });

    resetModal();
  };

  return (
    <React.Fragment>
      <div className="align-items-end mb-2">
        <Row>
          <Col xs={7} sm={4}>
            <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
              <InputGroup>
                <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
                <Button color="primary" type="button">
                  <i className="ri-search-line"></i>
                </Button>
              </InputGroup>
            </Form>
          </Col>
          <Col xs={5} sm={4} className="d-none d-sm-block">
            {(roleID === USER_ROLE.SUPER_ADMIN || roleID === USER_ROLE.COMPANY_ADMIN) && (
              <FormGroup className="float-end pt-2" check>
                <Input
                  id="showDeleteRec"
                  title={t('SHOW_DEL_REC')}
                  type="checkbox"
                  onChange={handleShowDeleteRec}
                  checked={showDelRec}
                />

                <Label htmlFor="showDeleteRec" className="mb-0 d-sm-block">
                  {t('SHOW_DEL_REC')}
                </Label>
              </FormGroup>
            )}
          </Col>
          <Col xs={5} sm={4}>
            {roleID !== USER_ROLE.EMPLOYEE && (
              <Button color="primary" className="float-end" onClick={() => navigate('../add')}>
                <span className="d-none d-sm-block">{t('LOCATION.ADD')}</span>
                <i className="ri-add-line  d-sm-none" title={t('LOCATION.ADD')}></i>
              </Button>
            )}
          </Col>

          {/* </div> */}
        </Row>
        <Row className="d-sm-none">
          <Col xs={8} sm={4} className="mt-2">
            {(roleID === USER_ROLE.SUPER_ADMIN || roleID === USER_ROLE.COMPANY_ADMIN) && (
              <FormGroup className="" check>
                <Input
                  id="showDeleteRec"
                  title={t('SHOW_DEL_REC')}
                  type="checkbox"
                  onChange={handleShowDeleteRec}
                  checked={showDelRec}
                />

                <Label htmlFor="showDeleteRec" className="mb-0 d-sm-block">
                  {t('SHOW_DEL_REC')}
                </Label>
              </FormGroup>
            )}
          </Col>
        </Row>
      </div>
      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={HeaderList} isDisabled={list.length === 0} sortClick={sortBy} />
          </tr>
        </thead>

        <tbody>
          {list.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className="actionCol">
                <ActionBtns
                  {...(roleID === USER_ROLE.EMPLOYEE
                    ? {
                        viewTitle: t('LOCATION.VIEW'),
                        viewFn: () => viewFn(item),
                      }
                    : showDelRec
                    ? {
                        activateFn: () => {
                          setDeleteItem(item);
                          setShowActivateModal(true);
                        },
                      }
                    : {
                        deleteTitle: t('LOCATION.DELETE'),
                        deleteFn: () => deleteModal(item),
                        updateTitle: t('LOCATION.UPDATE'),
                        updateFn: () => editFn(item),
                      })}
                />
              </td>
              <td>{item.locationName}</td>
              <td>{item.terminalNumber}</td>
              <td>{item.city}</td>
              <td>{item.street}</td>
              <td>{item.houseNumber}</td>
              <td>{item.zip}</td>
            </tr>
          ))}

          {!isLoading && list.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>

      <Modal isOpen={confDelTerm.shown} backdrop="static" className="modal-dialog-centered">
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <h3 className="mx-2 mb-0">{confDelTerm.message}</h3>
          </div>

          <br className="d-none d-sm-block" />
          <br />
          <div className="d-flex justify-content-end gap-2">
            <Button color="primary" onClick={() => setConfDelTerm({ shown: false })}>
              {t('CANCEL')}
            </Button>

            <Button
              color="link"
              className="waves-effect me-1"
              onClick={(e) => confirmDelete(e, true)}
              disabled={isLoading}
            >
              {isLoading ? t('PLEASE_WAIT') : t('OK')}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <ConfirmDeleteModal
        isOpen={showActivateModal}
        isLoading={isLoading}
        message={t('ALERT.ACT_LOCATION')}
        modalSize=""
        confirmFn={confirmActivate}
        cancelFn={resetModal}
        btnName={t('ACTIVATE')}
      />

      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        confirmFn={confirmDelete}
        cancelFn={resetModal}
      />
    </React.Fragment>
  );
}

export default LocationList;
