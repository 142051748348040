import { createSlice } from "@reduxjs/toolkit";
import { LAYOUT_SLICE } from "../../slice-names";

const initialState = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "light",
  leftSideBarType: "default",
  topbarTheme: "light",
  isPreloader: false,
  isMobile: false,
  isRtlLayout: false,
};

export const LayoutSlice = createSlice({
  name: LAYOUT_SLICE,
  initialState,
  reducers: {
    changeSidebarType: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    changeRTLLayout: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSidebarType, changeRTLLayout } = LayoutSlice.actions;

export default LayoutSlice.reducer;
