import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function BackButton({ navTo }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavLink to={navTo} className="waves-effect d-flex align-items-center">
        <i className="ri-arrow-go-back-line"></i>
        <span className="">{t("BACK")}</span>
      </NavLink>
    </React.Fragment>
  );
}

export default BackButton;
