/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import { Table, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE, UserId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';
import { numberFormatter } from '../../helpers';
import EmployeeService from '../../services/employee';
import EmpActivityService from '../../services/emp_activity';
import LocationService from '../../services/location';
import MachineService from '../../services/machines';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import SelectField from '../../components/SelectField';
import NumericFormatField from '../../components/NumericFormatField';
import ConfirmSaveDataChargeTerm from '../EmployeeActivity/ConfirmSaveDataChargeTerm.modal';
import ConfirmBatchNumber from '../EmployeeActivity/ConfirmBatchNumber';

let companyId = 0,
  loginUserId;

function EmployeeChargingTerminal({ isOpenChargingEditModal, handleEditCancelAction, InitialValues, onClosed }) {
  const dispatch = useDispatch();
  const formRef = useRef();
  
  const bills = typeof InitialValues.addBills === 'string' ? parseInt(InitialValues.addBills?.replace(/,/g, '')) : InitialValues.addBills
  const allowedAmount = InitialValues?.amountemployeehave + bills
  
  const ValidationSchema = AmountSchema('wasIndrawer')
    .concat(AmountSchema('addBills'))
    .shape({
      amountemployeehave: Yup.string().required(t('REQUIRED')),
      locationId: Yup.string().required(t('REQUIRED')),
      terminalNumber: Yup.string().required(t('REQUIRED')),
      drawer: Yup.string().required(t('REQUIRED')).trim(),
      leadingBatch: Yup.string().required(t('REQUIRED')).trim(),
      batch: Yup.string().length(4, t('CHARGE_TERMINAL.BATCH_ERR')).required(t('REQUIRED')),
      addBills: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(bills, `${t(`ADD_BILLS_LESSER_AMOUNT_VALIDATION`)} ${InitialValues.addBills}`)
        .max(
          allowedAmount,
          `${t('ADD_BILLS_HIGHER_AMOUNT_VALIDATION')} ${allowedAmount}`,
        ),
    });

  const [vSchema, setVSchema] = useState(ValidationSchema);
  const [roleId, setRoleId] = useState();

  const [empList, setEmpList] = useState([]);
  const [empOptList, setEmpOptList] = useState([]);
  const [initAmtEmpHave, setInitAmtEmpHave] = useState(null);

  const [locationList, setLocationList] = useState([]);
  const [initLocationList, setInitLocationList] = useState([]);
  const [locOptList, setLocOptList] = useState([]);
  const [initLocOptList, setInitLocOptList] = useState([]);

  const [terminalList, setTerminalList] = useState([]);
  const [initTerminalList, setInitTerminalList] = useState([]);
  const [termOptList, setTermOptList] = useState([]);
  const [initTermOptList, setInitTermOptList] = useState([]);

  const [manuallyCounted, setManuallyCounted] = useState(false);
  const [drawerList] = useState([
    { id: 1, label: 1, value: 1 },
    { id: 2, label: 2, value: 2 },
    { id: 3, label: 3, value: 3 },
  ]);

  const [anotherEmp, setAnotherEmp] = useState(false);
  const [confirmSaveData, setConfirmSaveData] = useState({});
  const [confirmBatchNumberData, setConfirmBatchNumberData] = useState({});
  const [openBatchNumberModal, setOpenBatchNumberModal] = useState(false);

  useEffect(() => {
    companyId = LocalstorageService.getItem(CompanyId);
    // getLocationsFn(InitialValues.employeeId, companyId);
    getEmployeeFn();
    getTotalWithdrawalAmount(formRef.current.setFieldValue);
    setTermOptList([{ value: InitialValues.terminalNumber, label: InitialValues.terminalNumber }])
    setLocOptList([{ value: InitialValues.locationId,label:InitialValues.locationName }])
    formRef.current.setFieldValue('terminalNumber', InitialValues.terminalNumber)
    formRef.current.setFieldValue('locationId', InitialValues.locationId)
  }, []);

  useEffect(() => {
    if (anotherEmp) {
      setVSchema(
        ValidationSchema.concat(
          Yup.object().shape({
            employeeId: Yup.string().required(t('REQUIRED')).trim(),
          }),
        ),
      );
    } else {
      setVSchema(ValidationSchema);
    }
  }, [anotherEmp]);

  async function getEmployeeFn() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ companyId, allList: true })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const EmpList = [...dataList];
          setEmpList(EmpList);
          const data = EmpList.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            companyId: item.companyId,
          }));

          setEmpOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getAmtEmpHaveFn(employeeId, setFieldValue) {
    loginUserId = employeeId;
    dispatch(setLoadingOverlay(true));
    await EmpActivityService.amtEmpHave({
      companyId,
      employeeId,
    })
      .then((response) => {
        const { status, data } = response.data;

        if (status === 400) {
          return;
        }

        if (data) {
          const amtEmpHave = numberFormatter(data.amountemployeehave || 0);
          setFieldValue('amountemployeehave', amtEmpHave);
          initAmtEmpHave === null && setInitAmtEmpHave(amtEmpHave);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getTotalWithdrawalAmount(setFieldValue) {
    dispatch(setLoadingOverlay(true));
    const { terminalNumber } = formRef.current.values;
    await EmpActivityService.getTotalWithdrawalAmount({
      companyId,
      terminalNumber,
    })
      .then((response) => {
        const { status, data } = response.data;

        if (status === 400) {
          return;
        }

        if (data) {
          const amtEmpHave = numberFormatter(data.returnFromTerminal || 0);
          // const amtEmpHave = numberFormatter(data.amountemployeehave || 0);
          setFieldValue('amountemployeehave', amtEmpHave);
          initAmtEmpHave === null && setInitAmtEmpHave(amtEmpHave);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getLocationsFn(employeeId, empCompanyId) {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listAssociate({
      empCompanyId,
      employeeId,
    })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          // toast.error(message);
          return;
        }

        if (dataList && dataList.length) {
          setLocationList(dataList);
          initLocationList.length === 0 && setInitLocationList(dataList);
          const data = dataList.map((item) => ({
            id: item.locationId,
            label: item.locationName,
            value: item.locationId,
          }));

          const data1 = dataList.map((item) => ({
            id: item.terminalNumber,
            label: item.terminalNumber,
            value: item.terminalNumber,
          }));

          setTerminalList(dataList);
          setLocOptList(data);
          setTermOptList(data1);
          initLocOptList.length === 0 && setInitLocOptList(data);

          setTermOptList(data1);
          initTermOptList.length === 0 && setInitTermOptList(data1);
        }
        // setList2(dataList);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  function handleToggleModal(isCancelled = true) {
    setConfirmSaveData({
      ...confirmSaveData,
      formData: {},
      isOpened: false,
    });

    if (!isCancelled) {
      setManuallyCounted(false);
      formRef.current.resetForm();
      getTotalWithdrawalAmount(formRef.current.setFieldValue);
      // getAmtEmpHaveFn(loginUserId, formRef.current.setFieldValue);
    }
  }

  function handleBatchNumberModal(isCancelled = true, updatedBatch = '') {
    if (updatedBatch) {
      formRef.current.setFieldValue('batch', updatedBatch);
      setOpenBatchNumberModal(false);
      confirmDetailsFn(updatedBatch);
    } else {
      setOpenBatchNumberModal(false);
    }
  }

  async function checkLastBatchNumber() {
    const { values } = formRef.current;
    const { terminalNumber, batch, leadingBatch } = values;
    dispatch(setLoadingOverlay(true));
    await EmpActivityService.getLastBatchNumber({
      terminalNumber,
    })
      .then((response) => {
        const { status, data } = response.data;

        if (status === 400) {
          return;
        }
        if (data?.batchNumber) {
          const lastDigits = data?.batchNumber.slice(data?.batchNumber.length - 4);

          if (batch <= lastDigits) {
            setConfirmBatchNumberData({ batch, lastDigits, leadingBatch });
            setOpenBatchNumberModal(true);
          } else {
            confirmDetailsFn();
          }
        } else {
          confirmDetailsFn();
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function confirmDetailsFn(updatedBatch) {
    const { values, setSubmitting, setTouched, validateForm } = formRef.current;
    setSubmitting(false);

    if (updatedBatch) {
      values['batch'] = updatedBatch;
    }
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }

    const amtEmpHave = values.amountemployeehave.toString().replace(/,/g, '') === '0' ? InitialValues.amountemployeehave.toString().replace(/,/g, '') : values.amountemployeehave.toString().replace(/,/g, '');
    let addBills = values.addBills.toString().replace(/,/g, '')
    let { existingBatch, leadingBatch, employeeCashAmountLoadId, employeeLoadingTerminalId } = InitialValues
    
    let { batch, terminalNumber, wasIndrawer, drawer, manuallyCounted } = values
    
    const payload = {
      addBills,
      batch,
      companyId,
      terminalNumber,
      manuallyCounted,
      drawer: parseInt(drawer),
      existingBatch,
      wasIndrawer,
      leadingBatch,
      employeeId: parseInt(values.employeeId),
      employeeCashAmountLoadId,
      employeeLoadingTerminalId,
    };

    setConfirmSaveData({
      formData: payload,
      isOpened: true,
      toggleModal: handleToggleModal,
      terminalWithdraw: true,
      withdrawalTerminal:false
    });
  }

  const handleAnotherEmpChange = () => {
    setAnotherEmp(!anotherEmp);

    const { setFieldValue, setFieldTouched } = formRef.current;

    setFieldValue('employeeId', '');
    setFieldTouched('employeeId', false);

    setFieldTouched('amountemployeehave', false);

    setFieldValue('locationId', '');
    setFieldTouched('locationId', false);

    setFieldValue('terminalNumber', '');
    setFieldTouched('terminalNumber', false);

    setFieldValue('leadingBatch', '');
    setFieldTouched('leadingBatch', false);

    if (!anotherEmp) {
      setFieldValue('amountemployeehave', 0);
    } else {
      setFieldValue('employeeId', loginUserId);
      setFieldValue('amountemployeehave', initAmtEmpHave);

      setLocationList(initLocationList);
      setLocOptList(initLocOptList);

      setTerminalList(initTerminalList);
      setTermOptList(initTermOptList);
    }
  };
  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        // validationSchema={ValidationSchema}
        validationSchema={vSchema}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form id="emp-act-charging-terminal" noValidate>
            <Modal isOpen={isOpenChargingEditModal} backdrop="static" className="modal-lg modal-dialog-centered">
              <ModalHeader>{t('EDIT_TERMINAL')}</ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column  gap-2">
                  <div className="row gap-2 gap-md-0">
                    <div className="col-md-6 col-12 ">
                      <SelectField
                        fieldName="employeeId"
                        fieldLabel={t('ROLES.EMPLOYEE')}
                        fieldValue={formik.values.employeeId}
                        fieldOptions={empOptList}
                        fieldChange={async (option) => {
                          setLocOptList([]);
                          setTermOptList([]);

                          formik.setFieldValue('amountemployeehave', 0);
                          formik.setFieldTouched('amountemployeehave', false);

                          if (option) {
                            companyId = option.companyId;
                            const empData = empList.find((v) => v.id === option.value) || {};

                            await getTotalWithdrawalAmount(formik.setFieldValue);
                            // await getAmtEmpHaveFn(option.value, formik.setFieldValue);

                            getLocationsFn(empData.id, empData.companyId);
                            // getTerminalsFn(empData.id, empData.companyId);
                          }

                          formik.setFieldValue('locationId', '');
                          formik.setFieldTouched('locationId', false);

                          formik.setFieldValue('terminalNumber', '');
                          formik.setFieldTouched('terminalNumber', false);

                          formik.setFieldValue('leadingBatch', '');
                          formik.setFieldTouched('leadingBatch', false);
                        }}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        isRequired={anotherEmp}
                        isDisabled={true}
                        // isDisabled={roleId === USER_ROLE.EMPLOYEE && !anotherEmp ? true : false}
                      />
                    </div>
                    <div className="col-md-6 col-12 ">
                      <NumericFormatField
                        fieldName="amountemployeehave"
                        fieldValue={InitialValues?.amountemployeehave}
                        fieldLabel={t('WITHDRAWAL_TERMINAL_LABELS.EMP_TERMINAL_WITHDRAWABLE_BAL_LABEL')}
                        fieldPlaceholder={t('CHARGE_TERMINAL.AMT_EMP_HAVE_PLACEHOLDER')}
                        useThousandSeparator={true}
                        maxLength={10}
                        isDisabled={true}
                        autoFocus={true}
                      />
                      {/* <CommonField
                        fieldName="amountemployeehave"
                        label={t('WITHDRAWAL_TERMINAL_LABELS.TERMINAL_WITHDRAWABLE_BAL_LABEL')}
                        placeholder={t('CHARGE_TERMINAL.AMT_EMP_HAVE_PLACEHOLDER')}
                        required={true}
                        isDisabled={true}
                        value={InitialValues?.amountemployeehave}
                      /> */}
                    </div>
                  </div>
                  <div className="row gap-2 gap-md-0">
                    {/* Location */}
                    <div className="col-md-4 col-12 ">
                      <SelectField
                        fieldName="locationId"
                        fieldLabel={t('LOCATION.TITLE')}
                        fieldValue={formik.values.locationId}
                        fieldOptions={locOptList}
                        fieldChange={(option) => {
                          formik.setFieldValue('leadingBatch', '');
                          formik.setFieldTouched('leadingBatch', false);

                          formik.setFieldValue('terminalNumber', '');
                          formik.setFieldTouched('terminalNumber', false);

                          if (option) {
                            const locObj = locationList.find((lo) => lo.locationId === option.value) || {};

                            const leadingBatch = locObj.terminalNumber?.slice(3);
                            formik.setFieldValue('leadingBatch', leadingBatch);

                            formik.setFieldValue('terminalNumber', locObj.terminalNumber || '');
                          }
                        }}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        isRequired={true}
                        isDisabled={true}

                        // isDisabled={
                        //   roleId === USER_ROLE.EMPLOYEE
                        //     ? anotherEmp && !formik.values.employeeId
                        //     : !formik.values.employeeId
                        // }
                      />
                    </div>

                    {/* Terminal */}
                    <div className="col-md-4 col-12 ">
                      <SelectField
                        fieldName="terminalNumber"
                        fieldLabel={t('MACHINE.NUMBER')}
                        fieldValue={formik.values.terminalNumber}
                        fieldOptions={termOptList}
                        fieldChange={(option) => {
                          formik.setFieldValue('leadingBatch', '');
                          formik.setFieldTouched('leadingBatch', false);

                          formik.setFieldValue('locationId', '');
                          formik.setFieldTouched('locationId', false);

                          if (option) {
                            const leadingBatch = option.value?.slice(3);
                            formik.setFieldValue('leadingBatch', leadingBatch);
                            const termObj =
                              terminalList.find((tl) => Number(tl.terminalNumber) === Number(option.value)) || {};

                            formik.setFieldValue('locationId', termObj.locationId || '');
                          }
                        }}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        isRequired={true}
                        isDisabled={true}
                        // isDisabled={
                        //   roleId === USER_ROLE.EMPLOYEE
                        //     ? anotherEmp && !formik.values.employeeId
                        //     : !formik.values.employeeId
                        // }
                      />
                    </div>

                    {/* Manually Counted */}
                    <div className="col-md-4 col-12 ">
                      <Label className="d-none d-sm-block">&nbsp;</Label>

                      <FormGroup className="mt-sm-3" check>
                        <Input
                          id="manuallyCounted"
                          type="checkbox"
                          onChange={() => {
                            setManuallyCounted(!manuallyCounted);
                            formik.setFieldValue('manuallyCounted', !manuallyCounted);
                          }}
                          checked={manuallyCounted}
                        />

                        <Label htmlFor="manuallyCounted" className="mb-0">
                          {t('EMPLOYEE.MANUALLY_COUNTED')}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row gap-2 gap-md-0">
                    {/* Drawer */}
                    <div className="col-md-4 col-12 ">
                      <SelectField
                        fieldName="drawer"
                        fieldLabel={t('CHARGE_TERMINAL.DRAWER')}
                        fieldValue={formik.values.drawer}
                        fieldOptions={drawerList}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        isRequired={true}
                      />
                    </div>

                    {/* Bills Drawer */}
                    <div className="col-md-4 col-12 ">
                      <NumericFormatField
                        fieldName="wasIndrawer"
                        fieldValue={formik.values.wasIndrawer}
                        fieldLabel={t('CHARGE_TERMINAL.BILLS_DRAWER_LABEL')}
                        fieldPlaceholder={t('CHARGE_TERMINAL.BILLS_DRAWER_PLACEHOLDER')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                      />
                    </div>

                    {/* Add Bills */}
                    <div className="col-md-4 col-12 ">
                      <NumericFormatField
                        fieldName="addBills"
                        fieldValue={formik.values.addBills}
                        fieldLabel={t('CHARGE_TERMINAL.EMP_WITHDRAWAL_BILLS_LABEL')}
                        fieldPlaceholder={t('CHARGE_TERMINAL.WITHDRAWAL_BILLS_PLACEHOLDER')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        valueIsNumericString={true}
                        allowNegative={true}
                        isDisabled={InitialValues?.amountemployeehave <= 0}
                        className={'form-control align-left'}
                      />
                    </div>
                  </div>

                  <div className="row gap-2 gap-md-0">
                    {/* Leading Batch Number */}
                    <div className="col-md-4 col-12 ">
                      <CommonField
                        fieldName="leadingBatch"
                        label={t('CHARGE_TERMINAL.LEAD_BATCH_LABEL')}
                        placeholder={t('CHARGE_TERMINAL.LEAD_BATCH_PLACEHOLDER')}
                        required={true}
                        isDisabled={true}
                      />
                    </div>

                    {/* End Batch Number */}
                    <div className="col-md-4 col-12 ">
                      <NumericFormatField
                        fieldName="batch"
                        fieldValue={formik.values.batch}
                        fieldLabel={t('CHARGE_TERMINAL.END_BATCH_LABEL')}
                        fieldPlaceholder={t('CHARGE_TERMINAL.END_BATCH_PLACEHOLDER')}
                        maxLength={4}
                        allowLeadingZeros={true}
                        useThousandSeparator={false}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                      />
                    </div>
                  </div>
                </div>
                {/* Submit Button */}
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-end gap-3">
                  <Button className="editBtn" onClick={() => confirmDetailsFn()} disabled={formik.isSubmitting}>
                    {t('UPDATE')}
                  </Button>

                  <Button className="submitBtn" onClick={handleEditCancelAction}>
                    {t(`LOAD_REMARKS.CANCEL_BTN`)}
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </Form>
        )}
      </Formik>
      <ConfirmSaveDataChargeTerm {...confirmSaveData} onClosed={onClosed} />

      <ConfirmBatchNumber
        formData={confirmBatchNumberData}
        isOpened={openBatchNumberModal}
        toggleModal={handleBatchNumberModal}
      />
    </React.Fragment>
  );
}
export default EmployeeChargingTerminal;
