/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import UpsertHeader from '../../components/UpsertHeader';
import SelectField from '../../components/SelectField';
import AlertModal from '../../components/AlertModal';
import NumericFormatField from '../../components/NumericFormatField';
import DatePickerField from '../../components/DatePicker';
import SpecialDaysService from '../../services/specialdays';
import LocationService from '../../services/location';
import { formatDate } from '../../helpers';
const InitialValues = {
  referenceDay: '',
  terminalNumber: '',
  factorToAverage: '',
  note: '',
};
const ValidationSchema = Yup.object().shape({
  referenceDay: Yup.date().required(t('REQUIRED')),
  terminalNumber: Yup.string().required(t('REQUIRED')),
  factorToAverage: Yup.string().required(t('REQUIRED')).factToAvg(t('INVALID_AVG_FACTOR')),
});

let companyId = 0,
  roleId = USER_ROLE.EMPLOYEE;

function AddSpecialDays() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [machineData, setMachineData] = useState([]);

  const [locationList, setLocationList] = useState([]);
  const [locOptList, setLocOptList] = useState([]);

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [compConf, setCompConf] = useState({
    shown: false,
    confirmFn: () => {},
  });

  useEffect(() => {
    setCrumbItem([
      { title: t('SPECIAL_DAYS.TITLE'), link: 'list' },
      { title: t('ADD_TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);
    roleId = LocalstorageService.getItem(JobRole);

    getMachine();
    getLocationsFn();
  }, []);

  useEffect(() => {
    if (initApiCount === 1) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  function handleConfirmation() {
    setCompConf({ shown: false, confirmFn: () => {} });
  }
  async function getLocationsFn() {
    dispatch(setLoadingOverlay(true));
    await LocationService.listLocation({
      companyId: companyId,
      allList: true,
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          setLocationList(dataList);

          const data = dataList.map((item) => ({
            id: item.id,
            label: item.locationName,
            value: item.id,
          }));

          setLocOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }
  async function getMachine() {
    dispatch(setLoadingOverlay(true));
    await SpecialDaysService.listMachines({ companyId, allList: true })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.terminalNumber,
            label: item.terminalNumber,
            value: item.terminalNumber,
          }));

          setMachineData(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    if (roleId === USER_ROLE.SUPER_ADMIN) {
      companyId = LocalstorageService.getItem(CompanyId);

      if (companyId === 0) {
        setCompConf({
          shown: true,
          msg: t('PLEASE_SEL_COMP'),
          confirmFn: handleConfirmation,
        });
        return;
      }
    }

    const payload = {
      ...values,
      referenceDay: formatDate(values.referenceDay),
      factorToAverage: values.factorToAverage ? parseFloat(values.factorToAverage) : 0,
      companyId,
      id: 0,
    };

    setIsLoading(true);
    await SpecialDaysService.createSpecialDays(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('SPECIAL_DAYS.ADD')} />

      <Formik initialValues={InitialValues} validationSchema={ValidationSchema} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* referenceDay */}
              <Col xs={12} sm={4}>
                <div className="mb-4">
                  <Label htmlFor="referenceDay">*{t('SPECIAL_DAYS.REFERENCE_DAY_LABEL')}</Label>

                  {/* <Field
                    name="referenceDay"
                    component="input"
                    type="date"
                    className="form-control"
                    placeholder={t("SPECIAL_DAYS.REFERENCE_DAY_PLACEHOLDER")}
                    required={true}
                  /> */}
                  <DatePickerField
                    fieldName="referenceDay"
                    fieldPlaceholder={t('SPECIAL_DAYS.REFERENCE_DAY_PLACEHOLDER')}
                    filedFormate="dd/MM/yyyy"
                    className="form-control"
                    fieldValue={values.referenceDay}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  ></DatePickerField>
                  <ErrorMessage name="referenceDay" className="text-danger" component="div" />
                </div>
              </Col>

              {/* Factor To Average */}
              <Col xs={12} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="factorToAverage"
                    fieldValue={values.factorToAverage}
                    fieldLabel={t('SPECIAL_DAYS.FACTOR_TO_AVERAGE_LABEL')}
                    fieldDecimalScale={2}
                    fieldPlaceholder={`${t('SPECIAL_DAYS.FACTOR_TO_AVERAGE_PLACEHOLDER')}`}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
                {/* <div className="mb-4">
                  <SelectField
                    fieldName="terminalNumber"
                    fieldLabel={`${t("SPECIAL_DAYS.TERMINAL_NUMBER_LABEL")}`}
                    fieldValue={values.terminalNumber}
                    fieldOptions={machineData}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div> */}
              </Col>
            </Row>
            <Row>
              {/* location */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="locationId"
                    fieldLabel={`${t('SPECIAL_DAYS.LOCATION')}`}
                    fieldValue={values.locationId}
                    fieldOptions={locOptList}
                    fieldChange={(option) => {
                      setFieldValue('terminalNumber', '');
                      setFieldTouched('terminalNumber', false);

                      if (option) {
                        const locObj = locationList.find((lo) => lo.id === option.value) || {};

                        setFieldValue('terminalNumber', locObj.terminalNumber || '');
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={false}
                  />
                </div>
              </Col>
              {/* Terminal number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="terminalNumber"
                    fieldLabel={`${t('SPECIAL_DAYS.TERMINAL_NUMBER_LABEL')}`}
                    fieldValue={values.terminalNumber}
                    fieldOptions={machineData}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {/* Note */}
              <Col xs={12} sm={6}>
                <div className="mb-4">
                  <Label htmlFor="note">{t('SPECIAL_DAYS.NOTE_LABEL')}</Label>

                  <Field
                    name="note"
                    component="textarea"
                    className="form-control"
                    placeholder={t('SPECIAL_DAYS.NOTE_PLACEHOLDER')}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <AlertModal {...compConf} />
    </React.Fragment>
  );
}

export default AddSpecialDays;
