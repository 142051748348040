import { post } from "../AxiosConfig";

const URI = "/auth";

const login = (payload) => {
  const URL = `${URI}/login`;
  return post(URL, payload);
};

const forgotPassword = (payload) => {
  const URL = `${URI}/forgot-password`;
  return post(URL, payload);
};

const resetPassword = (payload) => {
  const URL = `${URI}/reset-password`;
  return post(URL, payload);
};

const changePassword = (payload) => {
  const URL = `${URI}/change-password`;
  return post(URL, payload);
};

const verifyEmail = (payload) => {
  const URL = `${URI}/verify-email`;
  return post(URL, payload);
};

const mailSend = (payload) => {
  const URL = `${URI}/send-mail`;
  return post(URL, payload);
};

const sendOtp = (payload) => {
  const URL = `${URI}/send-otp`;
  return post(URL, payload);
};

const verifyOtp = (payload, headers) => {
  const URL = `${URI}/verify-otp`;
  return post(URL, payload, headers);
};

const AuthService = {
  login,
  forgotPassword,
  resetPassword,
  changePassword,
  verifyEmail,
  mailSend,
  sendOtp,
  verifyOtp,
};

export default AuthService;
