/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE, UserId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';
import MachineService from '../../services/machines';
import AgentService from '../../services/agent';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import UpsertHeader from '../../components/UpsertHeader';
import SelectField from '../../components/SelectField';
import AlertModal from '../../components/AlertModal';
import NumericFormatField from '../../components/NumericFormatField';

const InitialValues = {
  terminalNumber: '',
  locationId: 0,
  agentId: '',
  bankAccountId: '',
  modemType: '',
  terminalType: '',
  upperDoorCode: '',
  safeCode: '',
  serviceFeesRate: '',
  batchNumber: '',
  billDrawer: '',
  billDrawer_2: '',
  billDrawer_3: '',
  remark: '',
};

const ValidationSchema = AmountSchema('serviceFeesRate')
  .concat(AmountSchema('billDrawer'))
  .concat(AmountSchema('billDrawer_2', false))
  .concat(AmountSchema('billDrawer_3', false))
  .shape({
    terminalNumber: Yup.string().length(7, t('MACHINE.SERIAL_NAME_ERR')).required(t('REQUIRED')),
    terminalType: Yup.string().required(t('REQUIRED')).trim(),
    agentId: Yup.string().required(t('REQUIRED')).trim(),
    bankAccountId: Yup.string().required(t('REQUIRED')).trim(),
    modemType: Yup.string().required(t('REQUIRED')).trim(),
    upperDoorCode: Yup.string().required(t('REQUIRED')).trim(),
    safeCode: Yup.string().required(t('REQUIRED')),
    batchNumber: Yup.string().required(t('REQUIRED')),
  });

let companyId = 0,
  userId = 0,
  roleId = USER_ROLE.EMPLOYEE;

function AddMachine() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [terminalType, setTerminalType] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [agentOptList, setAgentOptList] = useState([]);
  const [agentAcctOptList, setAgentAcctOptList] = useState([]);
  const [modemType, setModemType] = useState([]);
  const [compConf, setCompConf] = useState({
    shown: false,
    confirmFn: () => {},
  });

  useEffect(() => {
    setCrumbItem([
      { title: t('MACHINE.TITLE'), link: 'list' },
      { title: t('ADD_TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);
    userId = LocalstorageService.getItem(UserId);
    roleId = LocalstorageService.getItem(JobRole);

    getTerminalType();
    getLocationId();
    getAgentsFn();
    getModemType();
  }, []);

  useEffect(() => {
    if (initApiCount === 4) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  async function getTerminalType() {
    dispatch(setLoadingOverlay(true));
    await MachineService.getTerminal()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.terminalName,
            value: item.id,
          }));

          setTerminalType(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getLocationId() {
    dispatch(setLoadingOverlay(true));
    await MachineService.getLocations()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.locationName,
            value: item.id,
          }));

          setLocationList(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getAgentsFn() {
    dispatch(setLoadingOverlay(true));
    await AgentService.listAgent({ companyId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          }));

          setAgentOptList(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getAgentAcctNumbersFn(agentId) {
    dispatch(setLoadingOverlay(true));
    await AgentService.bankAffiInfo({ companyId, agentId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.bankAccountId,
            label: `${item.bankName} / ${item.branchName} / ${item.accountNumber}`,
            value: item.bankAccountId,
          }));

          setAgentAcctOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getModemType() {
    dispatch(setLoadingOverlay(true));
    await MachineService.getModems()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.modemName,
            value: item.id,
          }));

          setModemType(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  function handleConfirmation() {
    setCompConf({ shown: false, confirmFn: () => {} });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    if (roleId === USER_ROLE.SUPER_ADMIN) {
      companyId = LocalstorageService.getItem(CompanyId);

      if (companyId === 0) {
        setCompConf({
          shown: true,
          msg: t('PLEASE_SEL_COMP'),
          confirmFn: handleConfirmation,
        });
        return;
      }
    }

    const payload = {
      ...values,
      locationId: parseInt(values.locationId),
      agentId: parseInt(values.agentId),
      bankAccountId: parseInt(values.bankAccountId),
      modemType: parseInt(values.modemType),
      serviceFeesRate: parseFloat(values.serviceFeesRate),
      terminalType: parseInt(values.terminalType),
      billDrawer: parseFloat(values.billDrawer),
      billDrawer_2: values.billDrawer_2 ? parseFloat(values.billDrawer_2) : 0,
      billDrawer_3: values.billDrawer_3 ? parseFloat(values.billDrawer_3) : 0,
      companyId,
      userId,
    };

    setIsLoading(true);
    await MachineService.createMachine(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('MACHINE.ADD')} />

      <Formik initialValues={InitialValues} validationSchema={ValidationSchema} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Serial Number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <Label htmlFor="terminalNumber">*{t('MACHINE.SERIAL_NAME_LABEL')}</Label>

                  <Field
                    name="terminalNumber"
                    className="form-control"
                    id="terminalNumber"
                    maxLength={7}
                    placeholder={t('MACHINE.SERIAL_NAME_PLACEHOLDER')}
                    onChange={(e) => {
                      const val = e.target.value;

                      if (/^\d*$/.test(val.toString())) {
                        setFieldValue('batchNumber', val.length > 3 ? val.slice(3) : '');
                        setFieldValue('terminalNumber', val);
                      }
                    }}
                  />

                  <ErrorMessage name="terminalNumber" className="text-danger" component="div" />

                  {/* <NumericFormatField
                    maxLength="7"
                    fieldName="terminalNumber"
                    fieldValue={values.terminalNumber}
                    fieldLabel={t("MACHINE.SERIAL_NAME_LABEL")}
                    fieldChange={({ value }) => {
                      setFieldValue(
                        "batchNumber",
                        value.length > 3 ? value.slice(3) : ""
                      );
                    }}
                    fieldPlaceholder={`${t(
                      "MACHINE.SERIAL_NAME_PLACEHOLDER"
                    )} 1`}
                    useThousandSeparator={false}
                    setFieldValue={setFieldValue}
                  /> */}
                </div>
              </Col>

              {/* Machine Model */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="terminalType"
                    fieldLabel={t('MACHINE.MODEL')}
                    fieldValue={values.terminalType}
                    fieldOptions={terminalType}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>

              {/* Location */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="locationId"
                    fieldLabel={t('LOCATION.TITLE')}
                    fieldValue={values.locationId}
                    fieldOptions={locationList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabledOptVal={0}
                  />
                </div>
              </Col>

              {/* Agent */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="agentId"
                    fieldLabel={t('ROLES.AGENT')}
                    fieldValue={values.agentId}
                    fieldOptions={agentOptList}
                    fieldChange={(option) => {
                      setAgentAcctOptList([]);
                      option && getAgentAcctNumbersFn(option.value);
                      setFieldValue('bankAccountId', '');
                      setFieldTouched('bankAccountId', false);
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>

              {/* Agent Act No */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="bankAccountId"
                    fieldLabel={`${t('ROLES.AGENT')} ${t('EMPLOYEE.ACC_NO_LABEL')}`}
                    fieldValue={values.bankAccountId}
                    fieldOptions={agentAcctOptList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!values.agentId}
                  />
                </div>
              </Col>

              {/* Modem Type */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="modemType"
                    fieldLabel={t('MACHINE.MODEM')}
                    fieldValue={values.modemType}
                    fieldOptions={modemType}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Top door code */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="upperDoorCode"
                    label={t('MACHINE.TOP_DOOR_CODE_LABEL')}
                    placeholder={t('MACHINE.TOP_DOOR_CODE_PLACEHOLDER')}
                    required={true}
                  />
                </div>
              </Col>

              {/* Bottom Door Code */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="safeCode"
                    label={t('MACHINE.BOTTOM_DOOR_CODE_LABEL')}
                    placeholder={t('MACHINE.BOTTOM_DOOR_CODE_PLACEHOLDER')}
                    required={true}
                  />
                </div>
              </Col>

              {/* User Fees */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="serviceFeesRate"
                    fieldValue={values.serviceFeesRate}
                    fieldLabel={t('MACHINE.USER_FEES_LABEL')}
                    fieldDecimalScale={2}
                    fieldPlaceholder={t('MACHINE.USER_FEES_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>

              {/* Batch Leading Number */}
              <Col xs={6} sm={3}>
                <div className="mb-4">
                  <CommonField
                    fieldName="batchNumber"
                    label={t('MACHINE.BETCH_NUMBER_LABEL')}
                    placeholder={t('MACHINE.BETCH_NUMBER_PLACEHOLDER')}
                    required={true}
                    isDisabled={true}
                  />
                </div>
              </Col>

              {/* Bills 1*/}
              <Col xs={4} sm={3}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="billDrawer"
                    fieldValue={values.billDrawer}
                    fieldLabel={t('MACHINE.BILL_1_LABEL')}
                    fieldDecimalScale={2}
                    fieldPlaceholder={`${t('MACHINE.BILL_PLACEHOLDER')} 1`}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>

              {/* Bills 2*/}
              <Col xs={4} sm={3}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="billDrawer_2"
                    fieldValue={values.billDrawer_2}
                    fieldLabel={t('MACHINE.BILL_2_LABEL')}
                    fieldDecimalScale={2}
                    fieldPlaceholder={`${t('MACHINE.BILL_PLACEHOLDER')} 2`}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>

              {/* Bills 3*/}
              <Col xs={4} sm={3}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="billDrawer_3"
                    fieldValue={values.billDrawer_3}
                    fieldLabel={t('MACHINE.BILL_3_LABEL')}
                    fieldDecimalScale={2}
                    fieldPlaceholder={`${t('MACHINE.BILL_PLACEHOLDER')} 3`}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Remarks */}
              <Col sm={6}>
                <div className="mb-4">
                  <Label htmlFor="remarksField">{t('EMPLOYEE.REMARKS_LABEL')}</Label>

                  <Field
                    name="remark"
                    component="textarea"
                    className="form-control"
                    placeholder={t('EMPLOYEE.REMARKS_PLACEHOLDER')}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <AlertModal {...compConf} />
    </React.Fragment>
  );
}

export default AddMachine;
