/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import { omit, pick } from 'lodash';

import { AuthUserInfo, USER_ROLES } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { PhoneNumberSchema } from '../../helpers/yup-schema';
import AccountService from '../../services/account';
import AuthService from '../../services/auth';

import { setAuthUser } from '../../store/slices/Auth/authSlice';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import OtpModal from '../../components/OtpModal';
import PhoneNumber from '../../components/PhoneNumber';
import EmailField from '../../components/EmailField';
import EmailModal from '../../components/EmailModal';

const ChangeProfileSchema = PhoneNumberSchema().shape({
  firstName: Yup.string().required(t('REQUIRED')).trim(),
});

const InitialValues = {
  firstName: '',
  lastName: '',
  knownAs: '',
  userName: '',
  mail: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  confirmedMail: false,
  confirmedPhone: false,
  role: '',
};

const ChangeProfile = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const { authUser } = useSelector((state) => state.Auth);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(InitialValues);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otpToken, setOtpToken] = useState();
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetForm() {
          formRef.current.resetForm();
        },
      };
    },
    [],
  );

  useEffect(() => {
    getUserProfile();
  }, []);

  async function getUserProfile() {
    dispatch(setLoadingOverlay(true));
    await AccountService.getProfile()
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        const formData = pick(data, Object.keys(InitialValues));
        if (formData.role) {
          const obj = USER_ROLES.find(
            (v) =>
              v.label.toLowerCase() === formData.role.toLowerCase() ||
              v.label.replace(/\s/g, '').toLowerCase() === formData.role.toLowerCase(),
          );
          formData.displayRole = t(`ROLES.${obj.id}`);
        }
        setFormData(formData);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = omit(values, [
      'userName',
      'mail',
      'password',
      'confirmPassword',
      'confirmedMail',
      'confirmedPhone',
      'role',
    ]);

    payload.phoneNumber = payload.phoneNumber.replace(/-|_/g, '');

    setIsLoading(true);
    await AccountService.updateProfile({ ...payload })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);

        const newPayload = {
          ...payload,
          mail: values.mail,
          userName: values.userName,
          company: authUser?.company,
        };

        dispatch(setAuthUser(newPayload));
        LocalstorageService.updateItem(AuthUserInfo, 'data', newPayload);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  async function sendOtpFn(phoneNumber) {
    dispatch(setLoadingOverlay(true));
    await AuthService.sendOtp({ phoneNumber })
      .then((response) => {
        const { status, message, token } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setOtpToken(token);
        toast.success(message);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function verifyOtpFn(phoneOtp) {
    setVerifyingOtp(true);

    await AuthService.verifyOtp(
      { phoneOtp },
      {
        headers: {
          Authorization: 'Bearer ' + otpToken,
        },
      },
    )
      .then((response) => {
        const { message, status } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        setOtpToken();
        setOtpModalOpen(false);
        setFormData({
          ...formData,
          confirmedPhone: true,
        });
      })
      .finally(() => {
        setVerifyingOtp(false);
      });
  }

  const toggleOtpModal = async (phoneNumber) => {
    if (!otpModalOpen) {
      await sendOtpFn(phoneNumber);
      setOtpModalOpen(!otpModalOpen);
    } else {
      setOtpModalOpen(!otpModalOpen);
    }
  };

  const toggleEmailModal = () => setEmailModalOpen(!emailModalOpen);

  return (
    <React.Fragment>
      <h5>
        {t('ROLE')}: <label className="text-muted">{formData.displayRole}</label>
      </h5>

      <hr className="mt-0" />

      <Formik
        innerRef={formRef}
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={ChangeProfileSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <Row>
              {/* Username */}
              <Col sm={4}>
                <div className="mb-4">
                  <CommonField fieldName="userName" label="USERNAME_LABEL" isDisabled={true} />
                </div>
              </Col>

              {/* Email */}
              <Col sm={4}>
                <div className="mb-4">
                  <EmailField
                    toggleEmailModal={toggleEmailModal}
                    confirmedMail={formData.confirmedMail}
                    showVerification={true}
                    isDisabled={true}
                  />
                </div>
              </Col>

              {/* Phone Number */}
              <Col sm={4}>
                <div className="mb-4">
                  <PhoneNumber
                    toggleOtpModal={() => toggleOtpModal(values.phoneNumber)}
                    confirmedPhone={formData.confirmedPhone}
                    showVerification={true}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* First Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="firstName"
                    label="FIRST_NAME_LABEL"
                    placeholder="FIRST_NAME_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col>

              {/* Last Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField fieldName="lastName" label="LAST_NAME_LABEL" placeholder="LAST_NAME_PLACEHOLDER" />
                </div>
              </Col>

              {/* Known As */}
              <Col sm={4}>
                <div className="mb-4">
                  <CommonField fieldName="knownAs" label="KNOWN_AS_LABEL" placeholder="KNOWN_AS_PLACEHOLDER" />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <OtpModal
        isOpen={otpModalOpen}
        toggleOtpModal={toggleOtpModal}
        resendOtpFn={() => {
          sendOtpFn(formRef.current?.values?.phoneNumber);
        }}
        verifyCodeFn={(phoneOtp) => {
          verifyOtpFn(phoneOtp);
        }}
        verifyingCode={verifyingOtp}
      />

      <EmailModal
        isOpen={emailModalOpen}
        toggleEmailModal={toggleEmailModal}
        fieldValue={formData.mail}
        isDisabled={true}
      />
    </React.Fragment>
  );
});

export default ChangeProfile;
