/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { pick } from 'lodash';
import SortableHeader from '../../components/SortableHeader';
import NumericFormatField from '../../components/NumericFormatField';
import { ITEMS_PER_PAGE, WEEKS } from '../../constants';
import LocationService from '../../services/location';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import SpecialDaysService from '../../services/specialdays';
import NoRecords from '../../components/Table/NoRecords';
import { formatDate } from '../../helpers';
import SelectField from '../../components/SelectField';
import * as Yup from 'yup';

const InitialValuesForm = {
  minimumPerDevice: '',
  partTimeDay: '',
  factor: '',
};

const InitialValues = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
};

const ValidationSchema = Yup.object().shape({
  minimumPerDevice: Yup.number().required(t('REQUIRED')),
  partTimeDay: Yup.number()
    .min(0, t('LOC_MAINTEN.PART_DAY_MIN_ERR'))
    .max(1.0, t('LOC_MAINTEN.PART_DAY_MAX_ERR'))
    .required(t('REQUIRED')),
  factor: Yup.number().required(t('REQUIRED')),
});
const HeaderList = [
  {
    title: t('SPECIAL_DAYS.TERMINAL_NUMBER_LABEL'),
    value: 'terminalNumber',
    style: { minWidth: '150px', width: '15%' },
    sortable: false,
  },
  {
    title: t('SPECIAL_DAYS.REFERENCE_DAY_LABEL'),
    value: 'referenceDay',
    style: { minWidth: '150px', width: '15%' },
    sortable: false,
  },
  {
    title: t('SPECIAL_DAYS.FACTOR_TO_AVERAGE_LABEL'),
    value: 'factorToAverage',
    style: { minWidth: '150px', width: '20%' },
    sortable: false,
  },
  {
    title: t('SPECIAL_DAYS.NOTE_LABEL'),
    value: 'note',
    style: { minWidth: '350px', width: '60%' },
    sortable: false,
  },
];

const reqPayload = {};

function WeekSelection({ checkList, setCheckList, type = '', isDisabled }) {
  const [dataCheckList, setDataCheckList] = useState(checkList);

  useEffect(() => {
    setDataCheckList(checkList);
  }, [checkList]);

  return (
    <div>
      <div className="dayCheckboxes d-flex flex-wrap ">
        {Object.keys(WEEKS).map((v) => {
          const fieldName = WEEKS[v].toLowerCase();

          return (
            <>
              <span className={type}>
                <Input
                  id={v}
                  type="checkbox"
                  name={fieldName}
                  onChange={() => {
                    setCheckList({
                      ...checkList,
                      [fieldName]: !checkList?.[fieldName],
                    });
                  }}
                  checked={!!dataCheckList?.[fieldName]}
                  disabled={isDisabled}
                />

                <Label htmlFor={v} className="mb-0" check>
                  {t(`WEEKS.${[v]}`)}
                </Label>
              </span>
            </>
          );
        })}
      </div>
    </div>
  );
}

const MaintenanceDetails = ({ terminalNumber, companyId, locationId, formData, getDataFn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [weekCheckList, setWeekCheckList] = useState();
  const [formValues, setFormValues] = useState(InitialValues);
  const [showEditForm, setShowEditForm] = useState(false);

  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const dispatch = useDispatch();
  const [maintenanceData, setMaintenanceData] = useState([]);
  useEffect(() => {
    const initData = pick(formData, ['id', ...Object.keys(InitialValues)]);
    setFormValues(initData);

    let checkList = [];
    // Object.keys(WEEKS).forEach((v, index) => {
    //   checkList.push({
    //     id: index + 1,
    //     label: v,
    //     value: v
    //   })
    // });

    Object.keys(WEEKS).forEach((v) => {
      const fieldName = WEEKS[v].toLowerCase(),
        fieldChecked = formData.hasOwnProperty(fieldName) ? formData[fieldName] : InitialValues[fieldName];

      checkList = {
        ...checkList,
        [fieldName]: fieldChecked,
      };
    });

    setWeekCheckList(checkList);
    listFn();
    getParameters();
  }, []);

  function getDayName(dateStr) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(dateStr);
    return days[date.getDay()];
  }

  async function getParameters() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await LocationService.getCompanyParameters({ locationId, companyId })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (data) {
          setFormValues({
            minimumPerDevice: data?.minimumPerDevice,
            partTimeDay: data?.partTimeDay,
            factor: data?.factor,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  //new api flow(list next week special day) ->start
  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    reqPayload['companyId'] = companyId;
    reqPayload['terminalNumber'] = terminalNumber;
    reqPayload['allList'] = false;
    reqPayload['pageNo'] = 1;
    await SpecialDaysService.listNextWeekSpecialDay(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;
        setMaintenanceData(dataList || []);
        //  setList(dataList || []);

        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }
  //new api flow(list next week special day) ->end
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      locationId,
      companyId,
    };

    setIsLoading(true);
    if (formData.locationLoadingDayId) {
      await LocationService.updateCompanyParameters(payload).then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
      });

      await LocationService.updateLocLoadingDay({
        ...weekCheckList,
        id: locationId,
      })
        .then((response) => {
          const { status, message } = response.data;

          if (status === 400) {
            toast.error(message);
            return;
          }

          toast.success(message);
          getDataFn();
        })
        .finally(() => {
          setIsLoading(false);
          setShowEditForm(false);
        });
    } else {
      const { companyId, locationId, minimumPerDevice, dayFraction, factor, specialDaysAmount, partTimeDay } = payload;

      // const { id, sunday, monday, tuesday, wednesday, thursday, friday, saturday } = payload;
      const companyParameterPayload = {
        companyId,
        locationId,
        minimumPerDevice,
        dayFraction,
        factor,
        specialDaysAmount,
        partTimeDay,
      };
      const loadingDayParameter = { id: locationId, ...weekCheckList };
      await LocationService.createLocLoadingDay(loadingDayParameter).then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
      });
      await LocationService.updateCompanyParameters(companyParameterPayload)
        .then((response) => {
          const { status, message } = response.data;

          if (status === 400) {
            toast.error(message);
            return;
          }
          toast.success(message);
          getDataFn();
        })
        .finally(() => {
          setIsLoading(false);
          setShowEditForm(false);
        });
    }
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialTouched={{
          minimumPerDevice: true,
          partTimeDay: true,
          factor: true,
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
          <Form noValidate>
            <div className="d-flex flex-wrap gap-4 MachineFill mb-3">
              <div className="checkboxes">
                {/* <div className="d-flex "> */}
                <div className="d-none d-sm-block" style={{ fontSize: '15px' }}>
                  <h4>{t('LOC_PAYMENT.MACHINE_FILL_DAYS')}</h4>
                  <WeekSelection checkList={weekCheckList} setCheckList={setWeekCheckList} isDisabled={!showEditForm} />
                </div>

                <div className="d-sm-none" style={{ fontSize: '15px' }}>
                  <h4>{t('LOC_PAYMENT.MACHINE_FILL_DAYS')}</h4>
                  <WeekSelection
                    type="d-block"
                    checkList={weekCheckList}
                    setCheckList={setWeekCheckList}
                    isDisabled={!showEditForm}
                  />
                </div>
              </div>

              <div className="ml-auto">
                <div className="d-sm-flex gap-3">
                  <div className=" text_wrap_fields">
                    <NumericFormatField
                      fieldName="minimumPerDevice"
                      fieldValue={values.minimumPerDevice}
                      fieldLabel={t('LOC_MAINTEN.MINIMUM_AMOUNT')}
                      // fieldDecimalScale={2}
                      // fieldPlaceholder={t("LOC_COMM_PLACEMENT.MONTHLY_PAY_PLCH")}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isDisabled={!showEditForm}
                      isRequired={false}
                    />
                  </div>
                  <div className="" style={{ width: '80px' }}>
                    <NumericFormatField
                      fieldName="factor"
                      fieldValue={values.factor}
                      fieldLabel={t('LOC_MAINTEN.FAC')}
                      fieldDecimalScale={2}
                      // fieldPlaceholder={t("LOC_COMM_PLACEMENT.MONTHLY_PAY_PLCH")}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isDisabled={!showEditForm}
                      isRequired={false}
                    />
                  </div>

                  <div className="" style={{ width: '108px' }}>
                    <NumericFormatField
                      fieldName="partTimeDay"
                      fieldValue={values.partTimeDay}
                      fieldLabel={t('LOC_MAINTEN.PART_DAY')}
                      fieldDecimalScale={2}
                      // fieldPlaceholder={t("LOC_COMM_PLACEMENT.MONTHLY_PAY_PLCH")}

                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isDisabled={!showEditForm}
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className="d-none d-flex flex-column">
                  <div className=" text_wrap_fields">
                    <NumericFormatField
                      fieldName="minimumPerDevice"
                      fieldValue={values.minimumPerDevice}
                      fieldLabel={t('LOC_MAINTEN.MINIMUM_AMOUNT')}
                      // fieldDecimalScale={2}
                      // fieldPlaceholder={t("LOC_COMM_PLACEMENT.MONTHLY_PAY_PLCH")}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isDisabled={!showEditForm}
                      isRequired={false}
                    />
                  </div>
                  <div className="" style={{ width: '80px', textAlign: 'center' }}>
                    <NumericFormatField
                      fieldName="factor"
                      fieldValue={values.factor}
                      fieldLabel={t('LOC_MAINTEN.FAC')}
                      fieldDecimalScale={2}
                      // fieldPlaceholder={t("LOC_COMM_PLACEMENT.MONTHLY_PAY_PLCH")}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isDisabled={!showEditForm}
                      isRequired={false}
                    />
                  </div>

                  <div className=" " style={{ width: '108px' }}>
                    <NumericFormatField
                      fieldName="partTimeDay"
                      fieldValue={values.partTimeDay}
                      fieldLabel={t('LOC_MAINTEN.PART_DAY')}
                      fieldDecimalScale={2}
                      // fieldPlaceholder={t("LOC_COMM_PLACEMENT.MONTHLY_PAY_PLCH")}

                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isDisabled={!showEditForm}
                      isRequired={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Table striped bordered responsive size="sm">
              <thead>
                <tr>
                  <SortableHeader headers={HeaderList} isDisabled={list.length === 0} showActionCol={false} />
                </tr>
              </thead>

              <tbody>
                {/* {console.log("/list", list)} */}
                {list.map((item, l0Idx) => (
                  <tr key={l0Idx}>
                    <td>{item.terminalNumber}</td>
                    <td>{formatDate(item.referenceDay, 'dd/mm/yyyy')}</td>
                    <td>{item.factorToAverage}</td>
                    <td>{item.note}</td>
                  </tr>
                ))}

                {!isLoading && list.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
              </tbody>
            </Table>
            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items mt-4">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items mt-4">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default MaintenanceDetails;
