/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import EmployeeService from '../../services/employee';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import UpsertHeader from '../../components/UpsertHeader';
import InformationEmployee from './Information';
import BankAffiliation from './BankAffiliation';
import Withdrawal from './Withdrawal';
import AffilationtoLocation from './AffilationToLocation';

function EditEmployee() {
  const { t } = useTranslation();
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const params = useParams();

  const [activeTab, setActiveTab] = useState('1');
  const [formData, setFormData] = useState({});

  useEffect(() => {
    getDataFn();
  }, []);

  useEffect(() => {
    const fullName = `${formData.firstName || ''} ${formData.lastName || ''}`;
    const pageTitle = `${t('ROLES.EMPLOYEE')}: ${fullName}`;
    setPageTitle(pageTitle);
  }, [formData]);

  async function getDataFn() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ id: params.employeeId })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setFormData(data || {});
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <UpsertHeader />

      <Row>
        <Col lg="12">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '1',
                })}
                onClick={() => {
                  toggle('1');
                }}
              >
                {t('EMPLOYEE.INFORMATION')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '2',
                })}
                onClick={() => {
                  toggle('2');
                }}
              >
                {t('EMPLOYEE.BANK_AFFILIATION')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '3',
                })}
                onClick={() => {
                  toggle('3');
                }}
              >
                {t('EMPLOYEE.AFFILIATION_TO_LOCATION')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '4',
                })}
                onClick={() => {
                  toggle('4');
                }}
              >
                {t('EMPLOYEE.WITHDRAWAL')}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="p-2 p-sm-3">
              <InformationEmployee formData={formData} getDataFn={getDataFn} />
            </TabPane>
          </TabContent>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="2" className="p-2 p-sm-3">
              {activeTab === '2' && <BankAffiliation formData={formData} />}
            </TabPane>
          </TabContent>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="3" className="p-2 p-sm-3">
              {activeTab === '3' && <AffilationtoLocation formData={formData} />}
            </TabPane>
          </TabContent>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="4" className="p-2 p-sm-3">
              {activeTab === '4' && <Withdrawal />}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default EditEmployee;
