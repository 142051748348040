import { post } from "../AxiosConfig";

const URI = "/location";

const listLocation = (payload) => {
  const URL = `${URI}/list-location`;
  return post(URL, payload);
};

const listWithdrawal = (payload) => {
  const URL = `${URI}/list-withdrawal-bylocationId`;
  return post(URL, payload);
};

const createLocation = (payload) => {
  const URL = `${URI}/create-location`;
  return post(URL, payload);
};

const updateLocation = (payload) => {
  const URL = `${URI}/update-location`;
  return post(URL, payload);
};

const deleteLocation = (payload) => {
  const URL = `${URI}/delete-location`;
  return post(URL, payload);
};

const activateLocation = (payload) => {
  const URL = `${URI}/activate-location`;
  return post(URL, payload);
};

const createLocOwner = (payload) => {
  const URL = `${URI}/create-location-owner`;
  return post(URL, payload);
};

const updateLocOwner = (payload) => {
  const URL = `${URI}/update-location-owner`;
  return post(URL, payload);
};

const createLocPayment = (payload) => {
  const URL = `${URI}/create-location-payment`;
  return post(URL, payload);
};

const updateLocPayment = (payload) => {
  const URL = `${URI}/update-location-payment`;
  return post(URL, payload);
};

const createLocLoadingDay = (payload) => {
  const URL = `${URI}/create-location-loadingday`;
  return post(URL, payload);
};

const updateLocLoadingDay = (payload) => {
  const URL = `${URI}/update-location-loadingday`;
  return post(URL, payload);
};

const getModemsByTerminal = (payload) => {
  const URL = `${URI}/get-modems-by-terminal`;
  return post(URL, payload);
};

const createLocationCommunication = (payload) => {
  const URL = `${URI}/create-location-communication`;
  return post(URL, payload);
};

const getCompanyParameters = (payload) => {
  const URL = `loadingterminal/get-company-parameters`;
  return post(URL, payload);
};

const updateCompanyParameters = (payload) => {
  const URL = `loading-terminal/insert-update-Company-Parameter`;
  return post(URL, payload);
};

const getRemainCash = (payload) => {
  const URL = `/loadingterminal/get-cash-remain`;
  return post(URL, payload);
};

const getLocationByAgent = (payload) => {
  const URL = `${URI}/list-location-by-agent`;
  return post(URL, payload);
};

const LocationService = {
  listLocation,
  listWithdrawal,
  createLocation,
  updateLocation,
  deleteLocation,
  activateLocation,
  createLocOwner,
  updateLocOwner,
  createLocPayment,
  updateLocPayment,
  createLocLoadingDay,
  updateLocLoadingDay,
  getModemsByTerminal,
  createLocationCommunication,
  getCompanyParameters,
  updateCompanyParameters,
  getRemainCash,
  getLocationByAgent
};

export default LocationService;
