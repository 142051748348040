/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { AmountSchema } from '../../helpers/yup-schema';
import EmpActivityService from '../../services/emp_activity';

import CommonField from '../../components/CommonField';
import NumericFormatField from '../../components/NumericFormatField';

const InitialValues = {
  lastDigits: '',
  leadingBatch: '',
  batch: '',
};

function ConfirmBatchNumber({ formData = {}, isOpened = false, toggleModal = () => {} }) {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [batchError, setBatchError] = useState(false);
  const handleSubmitFn = async (values, { setSubmitting }) => {
    // setSubmitting(false);
    checkLastBatchNumber(values.leadingBatch, values.batch);
    // toggleModal(true, values.batch)
    // setIsLoading(true);
  };
  useEffect(() => {
    if (isOpened) {
      ref.current.focus();
    }
  }, []);
  async function checkLastBatchNumber(leadingBatch, batch) {
    let batchNumber = leadingBatch + batch;

    if (batch === '0000') {
      toggleModal(true, batch);
    } else {
      await EmpActivityService.checkLastBatchNumber({
        batchNumber,
      }).then((response) => {
        const { status, data, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toggleModal(true, batch);
      });
    }
  }

  const ValidationSchema = AmountSchema('batch').shape({
    batch: Yup.string().length(4, t('CHARGE_TERMINAL.BATCH_ERR')).required(t('REQUIRED')),
  });

  return (
    <React.Fragment>
      <Modal isOpen={isOpened} toggle={toggleModal} backdrop="static">
        <Formik
          initialValues={{ ...InitialValues, ...formData }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmitFn}
        >
          {({ values, isSubmitting, setFieldValue, setFieldTouched }) => {
            if (values.batch <= values.lastDigits) {
              setBatchError(true);
            } else {
              setBatchError(false);
            }
            return (
              <Form id="emp-act-charging-terminal">
                <ModalHeader
                  toggle={() => toggleModal()}
                  style={{ backgroundColor: '#cb0e0e', fontWeight: 'bolder', color: 'black' }}
                >
                  {t('BATCH_CONFIRM_MODAL_TITLE')}
                </ModalHeader>

                <ModalBody style={{ backgroundColor: '#cb0e0e', fontWeight: 'bolder', color: 'black' }}>
                  <Row>
                    {/* Bills Drawer */}
                    <Col sm={6}>
                      <div className="mb-4 mt-2">
                        <CommonField
                          fieldName="leadingBatch"
                          label={' '}
                          placeholder={t('CHARGE_TERMINAL.LEAD_BATCH_PLACEHOLDER')}
                          isDisabled={true}
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="mb-4 orangeBatchColor">
                        <NumericFormatField
                          fieldName="lastDigits"
                          fieldValue={values.lastDigits}
                          fieldLabel={t('CHARGE_TERMINAL.LAST_BATCH')}
                          fieldPlaceholder={t('CHARGE_TERMINAL.BILLS_DRAWER_PLACEHOLDER')}
                          useThousandSeparator={false}
                          isDisabled={true}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* Leading Batch Number */}
                    <Col sm={6}>
                      <div className="mb-4 mt-2">
                        <CommonField
                          fieldName="leadingBatch"
                          label={' '}
                          placeholder={t('CHARGE_TERMINAL.LEAD_BATCH_PLACEHOLDER')}
                          isDisabled={true}
                        />
                      </div>
                    </Col>

                    {/* End Batch Number */}
                    <Col sm={6}>
                      <div className="mb-4 ">
                        <NumericFormatField
                          fieldName="batch"
                          fieldValue={values.batch}
                          fieldLabel={t('CHARGE_TERMINAL.BATCH_RECEIVED')}
                          fieldPlaceholder={t('CHARGE_TERMINAL.END_BATCH_PLACEHOLDER')}
                          useThousandSeparator={false}
                          maxLength={4}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          isDisabled={false}
                          autoFocus={true}
                        />
                        {batchError && <p>{t('CHARGE_TERMINAL.BATCH_VALUE_ERROR')}</p>}
                      </div>
                    </Col>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Button color="light" onClick={() => toggleModal()}>
                    {t('CANCEL')}
                  </Button>

                  {/* Submit Button */}
                  <div className="">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-md waves-effect waves-light"
                      disabled={isLoading || isSubmitting}
                    >
                      {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('CONFIRM')}
                    </Button>
                  </div>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmBatchNumber;
