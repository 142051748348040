import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '../../components/Breadcrumb';
import LoadingOverlay from '../../components/LoadingOverlay';

function EmployeeActivity() {
  const { t } = useTranslation();
  const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);

  const [pageTitle, setPageTitle] = useState(t('EMP_ACTIVITY'));
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: t('APPLICATION_TITLE'), link: '#' },
    { title: t('EMP_ACTIVITY'), link: '#' },
  ]);

  return (
    <React.Fragment>
      <Breadcrumbs title={pageTitle} breadcrumbItems={breadcrumbItems} />

      <Card className="card-box">
        {showLoadingOverlay && <LoadingOverlay />}

        <CardBody>
          <Outlet context={[setPageTitle, setBreadcrumbItems]} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default EmployeeActivity;
