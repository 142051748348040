/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { ErrorMessage, Field } from 'formik';

function RemarkTextAreaField({
  fieldName = '',
  fieldType = 'text',
  id = fieldName,
  label = '',
  placeholder = '',
  required = false,
  isDisabled = false,
  error = '',
  handleOnChange,
  handleOnBlur,
  values,
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="">
        <Label htmlFor={id}>
          {t(label)}
          {required ? '*' : ''}
        </Label>

        <textarea
          name={fieldName}
          type={fieldType}
          className="form-control"
          id={id}
          placeholder={t(placeholder)}
          disabled={isDisabled}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={values || ''}
        />
        {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
      </div>

      <ErrorMessage name={fieldName} className="text-danger" component="div" />
    </React.Fragment>
  );
}

export default RemarkTextAreaField;
