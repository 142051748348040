import React from 'react';
import { Input, Label } from 'reactstrap';
export default function InputCheckBox({
  fieldLabel,
  fieldId,
  fieldChecked,
  fieldName,
  fieldValue,
  isRequired = false,
  handleCheckBoxChange,
  error,
}) {
  return (
    <React.Fragment>
      <div className="d-flex gap-2">
        <Input type="checkbox" name={fieldName} id={fieldId} onChange={(e) => handleCheckBoxChange(e)} />
        {fieldLabel ? <Label htmlFor={fieldId}>{fieldLabel}</Label> : ''}
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </React.Fragment>
  );
}
