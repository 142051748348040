/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorMessage, Field } from 'formik';
function ReactDatePicker({
  fieldId,
  fieldName,
  fieldLabel,
  isRequired,
  showIcon,
  value,
  handleDateChange,
  isDisable = false,
  error = null,
  handleOnBlur = () => {},
  minDate = null,
  maxDate = null,
  highlightDates =''
}) {
  return (
    <>
      <div className="d-flex flex-column  justify-content-around">
        {fieldLabel ? (
          <label htmlFor={fieldId} style={{ width: '50%' }}>
            {fieldLabel}
            {isRequired ? <span>*</span> : ''}
          </label>
        ) : (
          ''
        )}
        <DatePicker
          id={fieldId}
          name={fieldName}
          showIcon={showIcon}
          className="form-control"
          selected={value}
          onChange={(date) => handleDateChange(date)}
          dateFormat={'dd/MM/yyyy'}
          shouldCloseOnSelect={true}
          disabled={isDisable}
          icon="fa fa-calendar"
          toggleCalendarOnIconClick
          autoComplete="off"
          openToDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
          onBlur={handleOnBlur}
          minDate={minDate}
          maxDate={maxDate}
          highlightDates={highlightDates || ''}
        />
        {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
        <ErrorMessage name={fieldName} className="text-danger" component="div" />
      </div>
    </>
  );
}
export default ReactDatePicker;
