import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";

import { WAIT_FOR_NEXT_OTP } from "../constants";

import OtpField from "./OtpField";

let counterInterval = null;

function OtpModal({
  isOpen = false,
  fieldName = "otp",
  classList = "modal-sm",
  toggleOtpModal,
  verifyingCode = false,
  resendOtpFn,
  verifyCodeFn,
}) {
  const { t } = useTranslation();

  const [startOtpTimer, setStartOtpTimer] = useState(false);
  const [counter, setCounter] = useState(WAIT_FOR_NEXT_OTP);

  function setOtpTimer() {
    setStartOtpTimer(true);

    let count = WAIT_FOR_NEXT_OTP;
    counterInterval = setInterval(() => {
      if (--count <= 0) {
        clearInterval(counterInterval);
        setStartOtpTimer(false);
      }
      setCounter(count);
    }, 1000);
  }

  const resendOtp = () => {
    clearInterval(counterInterval);
    setCounter(WAIT_FOR_NEXT_OTP);
    setOtpTimer();
    resendOtpFn();
  };

  const resetOtpTimer = () => {
    clearInterval(counterInterval);
    setCounter(WAIT_FOR_NEXT_OTP);
    setStartOtpTimer(false);
  };

  // Modal close button
  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        toggleOtpModal();
        resetOtpTimer();
      }}
      type="button"
    ></button>
  );

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggleOtpModal}
        backdrop="static"
        className={classList}
        onClosed={resetOtpTimer}
      >
        <ModalHeader toggle={toggleOtpModal} close={closeBtn}>
          {t("FORGOT_PASSWORD.VERIFY_OTP")}
        </ModalHeader>

        <ModalBody>
          <Formik
            initialValues={{
              [fieldName]: "",
            }}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
              <Form>
                <OtpField
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  isLoading={isSubmitting}
                  fieldValue={values[fieldName]}
                  verifyingCode={verifyingCode}
                  verifyCodeFn={verifyCodeFn}
                />
              </Form>
            )}
          </Formik>

          <br />
          <div className="float-end">
            {startOtpTimer ? (
              <div className="text-muted">
                {t("FORGOT_PASSWORD.PLEASE_WAIT_FOR") +
                  " " +
                  counter +
                  " " +
                  t("SECONDS")}
                .
              </div>
            ) : (
              <Button
                type="button"
                color="link"
                className="p-0"
                onClick={resendOtp}
                disabled={startOtpTimer}
              >
                {t("FORGOT_PASSWORD.RESEND_OTP")}
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default OtpModal;
