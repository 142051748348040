/*eslint-disable no-unused-vars */
/*eslint-disable no-useless-escape */
/*eslint-disable react-hooks/exhaustive-deps*/
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import LocalstorageService from '../../helpers/localstorage-service';
import { CompanyId, INSERT_LOADING_TERMINAL_DATE_KEY, JobRole, USER_ROLE } from '../../constants';
import { Button, Col, FormGroup, Input, InputGroup, Row, Table } from 'reactstrap';
import MachineService from '../../services/machines';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { t } from 'i18next';
import EmployeeService from '../../services/employee';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';
import { NumericFormat } from 'react-number-format';
import ReactDatePicker from 'react-datepicker';
import RemarkModal from './RemarkModal';
import NoRecords from '../../components/Table/NoRecords';
import SortableHeader from '../../components/SortableHeader';
import ActionBtns from '../../components/ActionBtns';
import debounce from 'lodash.debounce';
import LocationService from '../../services/location';

const ListEmployeeTerminals = () => {
  const dispatch = useDispatch();
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [empId, setEmpId] = useState(0);
  const reqPayload = {};
  const formRef = useRef();
  let companyId = LocalstorageService.getItem(CompanyId);
  const [date, setDate] = useState(new Date());
  const [showAllEmployeeRecords, setShowAllEmployeeRecords] = useState(true);
  const [showRemarkModal, setShowRemarkModal] = useState({ shown: false, remarkId: '', terminalNumber: '' });
  const [amountEmployeeHave, setAmountEmployeeHave] = useState('');
  const [totalLoadAmount, setTotalLoadAmount] = useState('');
  const [claimAmount, setClaimAmount] = useState('');
  const [search, setSearch] = useState('');
  const [changeCellValue, setChangeCellValue] = useState({ oldValue: '', newValue: '' });
  const roleId = LocalstorageService.getItem(JobRole);

  const InitialValues = {
    totalLoadAmount: '',
    employee: '',
    amount: '',
    allEmployee: true,
  };

  useEffect(() => {
    setPageTitle(t('LOADING_TERMINAL.TITLE'));
    setCrumbItem([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('LOADING_TERMINAL.TITLE'), link: '#' },
    ]);

    getEmployeeFn();
    moveToNextDayAfterNoon();
  }, []);

  const fetchRecords = () => {
    reqPayload.companyId = companyId;
    reqPayload.dateS = date.toLocaleDateString('en-US');
    reqPayload.employeeId = showAllEmployeeRecords ? 0 : empId;

    listFn();
  };

  async function fetchRemainCash(reqPayload) {
    dispatch(setLoadingOverlay(true));
    await LocationService.getRemainCash(reqPayload)
      .then((response) => {
        const { data } = response.data;
        setAmountEmployeeHave(data.totalCashRemain);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  const moveToNextDayAfterNoon = () => {
    let timerId = null;
    const israelTimeZone = 'Asia/Jerusalem';

    const getNextDate = (currentDate) => {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      return nextDate;
    };

    const callUpdateLoadingTerminalData = (nextDate) => {
      const day = nextDate.getDate();
      const month = nextDate.getMonth();
      const year = nextDate.getFullYear();
      const dateForWhichInsertLoadingTerminalData = parseInt(
        `${year}${month + 1 <= 9 ? '0' : ''}${month + 1}${day <= 9 ? '0' : ''}${day}`,
      );
      try {
        const dtArr = LocalstorageService.getItem(INSERT_LOADING_TERMINAL_DATE_KEY);
        if (dtArr && dtArr[0]) {
          const dtInt = parseInt(dtArr[0]);
          if (dtInt < dateForWhichInsertLoadingTerminalData) {
            updateLoadingTerminalData({ dateS: nextDate.toLocaleDateString('en-US') });
            LocalstorageService.setItem(INSERT_LOADING_TERMINAL_DATE_KEY, [dateForWhichInsertLoadingTerminalData]);
          }
        } else {
          updateLoadingTerminalData({ dateS: nextDate.toLocaleDateString('en-US') });
          LocalstorageService.setItem(INSERT_LOADING_TERMINAL_DATE_KEY, [dateForWhichInsertLoadingTerminalData]);
        }
      } catch (error) {
        updateLoadingTerminalData({ dateS: nextDate.toLocaleDateString('en-US') });
        LocalstorageService.setItem(INSERT_LOADING_TERMINAL_DATE_KEY, [dateForWhichInsertLoadingTerminalData]);
      }
    };

    const checkTime = () => {
      const date = new Date();

      const israelTime = date.toLocaleTimeString('en-US', {
        timeZone: israelTimeZone,
        hour12: false,
      });
      const currentHour = israelTime.split(':')[0];
      if (currentHour >= 17) {
        const nextDate = getNextDate(date);
        // callUpdateLoadingTerminalData(nextDate);
        setDate(nextDate);
        clearInterval(timerId);

        timerId = null;
      }
    };

    timerId = setInterval(checkTime, 1000);
  };

  useEffect(() => {
    delete reqPayload['search'];
    
    if (search) {
      reqPayload['search'] = search;
    }
    if (empId) {
      fetchRemainCash({ employeeId: empId });
    } else {
      setAmountEmployeeHave('');
    }
    getTotalAmountToLoad({
      dateS: date.toLocaleDateString('en-US'),
      companyId: companyId,
      employeeId: empId || 0,
    });
    fetchRecords();
  }, [date, empId, showAllEmployeeRecords, search]);
  
  useEffect(() => {
    delete reqPayload['search'];

    if (search) {
      reqPayload['search'] = search;
    }
    if (empId) {
      fetchRemainCash({ employeeId: empId });
      setEmpId(0)
    } else {
      setAmountEmployeeHave('');
    }
    getTotalAmountToLoad({
      dateS: date.toLocaleDateString('en-US'),
      companyId: companyId,
      employeeId: empId || 0,
    });
    fetchRecords();
  }, [date]);

  async function updateLoadingTerminalData(payload) {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.updateLoadingTerminalData(payload)
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList?.length > 0) {
          if (showAllEmployeeRecords) {
            setClaimAmount('');
          } else {
            let claimAmount = 0;
            dataList?.forEach((value) => {
              claimAmount += value.loadingAmount;
            });
            setClaimAmount(claimAmount);
          }
        }
      })
      .catch(() => {
        LocalstorageService.removeItem(INSERT_LOADING_TERMINAL_DATE_KEY);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const sortBy = (sortObj) => {
    let day = date.toISOString().split('T')[0];
    delete reqPayload.sort;
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }
    reqPayload.companyId = companyId;
    reqPayload.dateS = day;
    reqPayload.employeeId = showAllEmployeeRecords ? 0 : empId;
    listFn();
  };

  async function getTotalAmountToLoad(payload) {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.toAmountToLoad(payload)
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList?.length > 0) {
          if (payload.employeeId <= 0) {
            setTotalLoadAmount(dataList[0].totalAmountToLoad);
            setClaimAmount('');
          } else {
            setClaimAmount(dataList[0].totalAmountToLoad);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getEmployeeFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ companyId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList?.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            companyId: item.companyId,
          }));

          setEmpList(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const HeaderList = [
    {
      title: t(`LOAD_TABS.TERMINAL_NUMBER_LABEL`),
      value: 'terminalNumber',
      style: { width: '150px' },
    },
    {
      title: t(`LOAD_TABS.BUSINESS_NAME_LABEL`),
      value: 'locationName',
      style: { width: '150px' },
    },
    {
      title: t(`LOAD_TABS.AT_THE_TERMINAL_LABEL`),
      value: 'sumOfTerminalDrawer',
      style: { width: '125px' },
      sortable: false,
    },
    {
      title: t(`LOAD_TABS.AMOUNT_PASSED_LABEL`),
      value: 'amountPassed',
      style: { width: '150px' },

      sortable: false,
    },
    {
      title: t(`LOAD_TABS.AMOUNT_TO_LOAD_LABEL`),
      value: 'loadingAmount',
      style: { width: '130px' },
    },
    {
      title: t(`LOAD_TABS.EXCESS_OR_LESS_LABEL`),
      value: 'excess',
      style: { width: '130px' },
      sortable: false,
    },
    {
      title: t(`LOAD_TABS.REMARKS_LABEL`),
      value: 'remark',
      style: { width: '85px' },
      sortable: false,
    },
    // {
    //     title: t(`LOAD_TABS.CHANGE_OR_CANCEL_LABEL`),
    //     value: 'remark',
    //     style: { minWidth: "150px", width: "30%" },

    // },
  ];

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.terminalList(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        // if (status === 400) {
        //   toast.error(message);
        //   return;
        // }

        if (dataList?.length > 0) {
          if (showAllEmployeeRecords) {
            setClaimAmount('');
          } else {
            let claimAmount = 0;
            dataList?.forEach((value) => {
              claimAmount += value.loadingAmount;
            });
            setClaimAmount(claimAmount);
          }
        }

        setList(dataList);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        setIsLoading(false);
      });
  }

  async function recalculateData(loadTotalAmountForAllEmployee = false) {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.reCalculateLoadingTerminalData(reqPayload)
      .then(async (response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        const { dateS, companyId, employeeId } = reqPayload;
        if (loadTotalAmountForAllEmployee) {
          await getTotalAmountToLoad({
            dateS,
            companyId,
            employeeId: 0,
          });
        }

        await getTotalAmountToLoad({
          dateS,
          companyId,
          employeeId,
        });

        // if (dataList?.length > 0) {
        //   let claimAmount = 0;
        //   dataList?.forEach((value) => {
        //     claimAmount += value.loadingAmount;
        //   });

        //   if (showAllEmployeeRecords) {
        //     setTotalAmounts({ claimAmount });
        //   } else {
        //     setTotalAmounts((prev) => {
        //       return { ...prev, claimAmount };
        //     });
        //   }
        // }

        setList(dataList);
      })
      .finally(() => {
        //  fetchRecords();
        dispatch(setLoadingOverlay(false));
        setIsLoading(false);
      });
  }

  let fieldDecimalScale = 0;
  let useThousandSeparator = true;

  const handleSubmit = () => {
    const companyId = LocalstorageService.getItem(CompanyId);
    reqPayload['companyId'] = companyId;
    // reqPayload['dateS'] = date.toISOString().split('T')[0];
    reqPayload['dateS'] = date.toLocaleDateString('en-US');
    reqPayload['employeeId'] = empId;
    reqPayload['terminalNumber'] = '0';
    reqPayload['NewTotalAmount'] = 0;
    recalculateData();
  };

  const handleUpdateCellValue = (value) => {
    if (changeCellValue.oldValue) {
      const companyId = LocalstorageService.getItem(CompanyId);
      const { employeeId, terminalNumber } = changeCellValue.oldValue;
      reqPayload['companyId'] = companyId;
      // reqPayload['dateS'] = date.toISOString().split('T')[0];
      reqPayload['dateS'] = date.toLocaleDateString('en-US');
      reqPayload['employeeId'] = employeeId;
      reqPayload['terminalNumber'] = terminalNumber;
      reqPayload['NewTotalAmount'] = Number(changeCellValue?.newValue?.replace(/\,/g, ''));
      reqPayload['EditRow']=true
      // reqPayload['loadingAmount'] = Number(changeCellValue?.newValue?.replace(/\,/g, ''));
      setChangeCellValue({ oldValue: '', newValue: '' });
      recalculateData(true);
    } else {
      setChangeCellValue({ oldValue: value });
    }
  };

  const handleCancelCellvalue = () => {
    setChangeCellValue({ oldValue: '', newValue: '' });
    fetchRecords();
  };
  const handleRemark = (data) => {
    setShowRemarkModal({ shown: true, remarkId: data.remarkId, terminalNumber: data.terminalNumber });
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        // validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            {roleId === USER_ROLE.COMPANY_ADMIN ? (
              <>
                <Row className="totalWrapper " style={{ fontSize: '15px' }}>
                  {/* Employee */}
                  {/* <div className="mb-4 d-md-flex justify-content-between"> */}
                  <Col sm={4}>
                    <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
                      <FormGroup className="">
                        <InputGroup>
                          <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
                          <Button color="primary" type="button">
                            <i className="ri-search-line"></i>
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col sm={4}>
                    <div className="formColumn mb-3 totalAmount">
                      <label>{t(`LOADING_TERMINAL.EMPLOYEE`)}</label>
                      <ReactSelect
                        name={'employee'}
                        value={showAllEmployeeRecords ? '' : empList.filter(({ value }) => value === empId)}
                        options={empList}
                        className="react-select "
                        onChange={(option) => {
                          setShowAllEmployeeRecords(option ? false : true);
                          setFieldValue('employee', option?.value);
                          setEmpId(option?.value);
                        }}
                        onBlur={() => setFieldTouched('employee', true)}
                        placeholder={`-- ${t('LOADING_TERMINAL.EMPLOYEE')} --`}
                        noOptionsMessage={() => t('RSEL_NO_OPT')}
                        isClearable
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ fontSize: '15px' }} className="totalWrapper">
                  <Col sm={4}>
                    <div className="formColumn mb-3 totalAmount">
                      <label>{t(`LOADING_TERMINAL.REMAINING_LOAD_AMOUNT`)}</label>
                      {/* remaining amount to employee */}
                      <NumericFormat
                        name={'totalLoadAmount'}
                        className="form-control "
                        value={totalLoadAmount}
                        allowNegative={false}
                        thousandSeparator={useThousandSeparator}
                        decimalScale={fieldDecimalScale}
                        {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                        disabled={true}
                        onBlur={() => setFieldTouched('totalLoadAmount', true)}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="formColumn mb-3 totalAmount">
                      <label>{t(`LOADING_TERMINAL.TOTAL_EMPLOYEE_AMOUNT`)}</label>
                      <NumericFormat
                        name={'amountEmployeeHave'}
                        className="form-control "
                        value={amountEmployeeHave}
                        allowNegative={false}
                        thousandSeparator={useThousandSeparator}
                        decimalScale={fieldDecimalScale}
                        {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                        disabled={true}
                        onBlur={() => setFieldTouched('amountEmployeeHave', true)}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="formColumn mb-3 justify-content-end totalAmount">
                      <label>{t(`LOADING_TERMINAL.TOTAL_LOAD_AMOUNT`)}</label>
                      <NumericFormat
                        name={'claimAmount'}
                        className="form-control "
                        value={claimAmount}
                        allowNegative={false}
                        thousandSeparator={useThousandSeparator}
                        decimalScale={fieldDecimalScale}
                        {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                        onBlur={() => setFieldTouched('claimAmount', true)}
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                  </Col>
                  {/* </div> */}
                </Row>
              </>
            ) : (
              <>
                <Row style={{ fontSize: '15px' }}>
                  {/* Employee */}
                  {/* <div className="mb-4 d-md-flex justify-content-between"> */}
                  <Col sm={2}>
                    <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
                      <FormGroup className="">
                        <InputGroup>
                          <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
                          <Button color="primary" type="button">
                            <i className="ri-search-line"></i>
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col sm={4}>
                    <div className="formColumn mb-3 totalAmount">
                      <label className="me-2">{t(`LOADING_TERMINAL.EMPLOYEE`)}</label>

                      <ReactSelect
                        name={'employee'}
                        value={showAllEmployeeRecords ? '' : empList.filter(({ value }) => value === empId)}
                        options={empList}
                        className="react-select "
                        onChange={(option) => {
                          setShowAllEmployeeRecords(false);
                          setFieldValue('employee', option?.value);
                          setEmpId(option?.value);
                        }}
                        onBlur={() => setFieldTouched('employee', true)}
                        placeholder={`-- ${t('LOADING_TERMINAL.EMPLOYEE')} --`}
                        noOptionsMessage={() => t('RSEL_NO_OPT')}
                        isClearable
                      />
                    </div>
                  </Col>

                  <Col sm={3}>
                    <div className="formColumn mb-3 totalAmount">
                      <label className="me-2">{t(`LOADING_TERMINAL.TOTAL_EMPLOYEE_AMOUNT`)}</label>
                      <NumericFormat
                        name={'amountEmployeeHave'}
                        className="form-control "
                        value={amountEmployeeHave}
                        allowNegative={false}
                        thousandSeparator={useThousandSeparator}
                        decimalScale={fieldDecimalScale}
                        {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                        disabled={true}
                        onBlur={() => setFieldTouched('amountEmployeeHave', true)}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="formColumn mb-3 totalAmount">
                      <label className="me-2">{t(`LOADING_TERMINAL.TOTAL_LOAD_AMOUNT`)}</label>
                      <NumericFormat
                        name={'totalLoadAmount'}
                        className="form-control "
                        value={totalLoadAmount}
                        allowNegative={false}
                        thousandSeparator={useThousandSeparator}
                        decimalScale={fieldDecimalScale}
                        {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                        onBlur={() => setFieldTouched('totalLoadAmount', true)}
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                  </Col>
                  {/* </div> */}
                </Row>
              </>
            )}
            <div className="dataMin">
              <Table
                className="fixTh dataTable"
                striped
                bordered
                responsive
                size="sm"
                style={{ minWidth: '1100px ', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr className="TableRow">
                    {/* {HeaderList?.map((heading) => (<th className=" ">{heading.title}</th>))} */}
                    <SortableHeader headers={HeaderList} isDisabled={list?.length === 0} sortClick={sortBy} />
                  </tr>
                </thead>

                <tbody>
                  {!isLoading &&
                    list?.map((item, l0Idx) => (
                      <tr className="TableRow" key={l0Idx}>
                        <td className="actionCol">
                          <ActionBtns
                            {...(changeCellValue.oldValue === item
                              ? {
                                  saveTitle: t(`LOAD_TABS.SAVE`),
                                  saveFn: () => handleUpdateCellValue(item),
                                  cancelTitle: t(`LOAD_TABS.CANCEL_BTN`),
                                  cancelFn: () => handleCancelCellvalue(),
                                }
                              : // {
                                //     activateTitle: t(`LOAD_TABS.EDIT_BTN`),
                                //     activateFn: () => handleUpdateCellValue(item),
                                //     remarkTitle: t(`REMARK`),
                                //     remarkFn: () => handleRemark(item),
                                //   }
                                {
                                  updateTitle: t(`LOAD_TABS.EDIT_BTN`),
                                  updateFn: () => handleUpdateCellValue(item),
                                  remarkTitle: t(`REMARK`),
                                  remarkFn: () => handleRemark(item),
                                })}
                          />
                        </td>
                        <td className="text-center" style={{ width: '150px' }}>
                          {item.terminalNumber}
                        </td>
                        <td className="text-center" style={{ width: '150px' }}>
                          {item.locationName}
                        </td>
                        <td className="text-center" style={{ width: '120px' }} dir="ltr">
                          {item.sumOfTerminalDrawer.toLocaleString('en-US')}
                        </td>
                        <td className="text-center" style={{ width: '130px' }} dir="ltr">
                          {item.amountPassed.toLocaleString('en-US')}
                        </td>
                        <td className="text-center" style={{ width: '150px' }} dir="ltr">
                          {changeCellValue.oldValue === item ? (
                            <NumericFormat
                              name={`amountToLoad${l0Idx}`}
                              className="form-control "
                              value={changeCellValue.newValue}
                              allowNegative={false}
                              thousandSeparator={useThousandSeparator}
                              decimalScale={fieldDecimalScale}
                              {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                              onChange={(e) => {
                                setChangeCellValue((prev) => {
                                  return { ...prev, newValue: e.target.value };
                                });
                              }}
                              autoComplete="off"
                            />
                          ) : (
                            item.loadingAmount.toLocaleString('en-US')
                          )}
                        </td>
                        {/* <td className="text-center" style={{ width: '130px' }} dir="ltr">
                          {item.loadingAmount.toLocaleString('en-US')}
                        </td> */}
                        <td className="text-center" style={{ width: '130px' }} dir="ltr">
                          {item.excess.toLocaleString('en-US')}
                        </td>
                        <td className="" style={{ width: '85px' }}>
                          {item.remark}
                        </td>
                      </tr>
                    ))}

                  {(isLoading || list?.length === 0) && <NoRecords colSpan={list?.length + 1} />}
                </tbody>
              </Table>
            </div>

            <Row className="">
              {/* <div className="d-flex justify-content-between align-items-center"> */}
              <Col sm={4}>
                <div className="formColumn flex-wrap mb-3">
                  <label className="datePickerLabel me-2">{t(`LOADING_TERMINAL.START_DAY`)}</label>
                  <div className="startDay">
                    <ReactDatePicker
                      // ref={dateRef}
                      name="date"
                      className="form-control "
                      selected={date}
                      onChange={(dateArr) => setDate(dateArr)}
                      dateFormat={'dd/MM/yyyy'}
                      shouldCloseOnSelect={true}
                      highlightDates={[new Date()]}
                    />
                  </div>
                </div>
              </Col>

              <Col sm={4}>
                <div className="d-flex  justify-content-center mb-3">
                  <Input
                    type="checkbox"
                    className="me-2"
                    id="allEmployee"
                    checked={showAllEmployeeRecords}
                    disabled={showAllEmployeeRecords}
                    onChange={() => {
                      setShowAllEmployeeRecords(!showAllEmployeeRecords);
                      setEmpId(0);
                    }}
                  />
                  <label htmlFor="allEmployee">{t(`LOADING_TERMINAL.ALL_EMPLOYEES`)}</label>
                </div>
              </Col>
              <Col sm={4}>
                <div className="d-flex  justify-content-center mb-3">
                  <Button
                    disabled={!empId && !showAllEmployeeRecords}
                    color="primary"
                    type="submit"
                    className="recalculateBtn "
                  >
                    {t(`LOADING_TERMINAL.RE_CALCULATE_BTN`)}
                  </Button>
                </div>
              </Col>

              {/* </div> */}
            </Row>
          </Form>
        )}
      </Formik>
      {showRemarkModal.shown && (
        <RemarkModal showRemarkModal={showRemarkModal} setShowRemarkModal={setShowRemarkModal} />
      )}
      {/* <RemarkModal showRemarkModal={showRemarkModal} setShowRemarkModal={setShowRemarkModal} /> */}
    </>
  );
};

export default ListEmployeeTerminals;
