/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MetisMenu from '@metismenu/react';
import classnames from 'classnames';

import { CompanyId, JobRole, USER_ROLE } from '../../../constants';
import LocalstorageService from '../../../helpers/localstorage-service';

let companyId = 0;

function SidebarContent({ toggleMenuCallback }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { leftSideBarType } = useSelector((state) => state.Layout);

  const [roleId, setRoleId] = useState();
  const [routePath, setRoutePath] = useState(location.pathname);
  const [subMenuShown, setSubMenuShown] = useState(false);
  const [subEmpMenuShown, setSubEmpMenuShown] = useState(false);
  const [subReportMenuShown, setSubReportMenuShown] = useState(false);

  useEffect(() => {
    const jobRole = LocalstorageService.getItem(JobRole);
    setRoleId(jobRole);
    setSubMenuShown(routePath.substring(0, '/agent-activity'.length) === '/agent-activity');
    setSubEmpMenuShown(routePath.substring(0, '/employee-activity'.length) === '/employee-activity');
    setSubReportMenuShown(routePath.substring(0, '/report'.length) === '/report');
    companyId = LocalstorageService.getItem(CompanyId);
  }, []);

  useEffect(() => {
    const pathName = location.pathname;
    setRoutePath(pathName);

    if (pathName === '/agent-activity/bank-withdrawal' || pathName === '/agent-activity/split-money') {
      setSubEmpMenuShown(false);
      setSubReportMenuShown(false);
    } else if (
      pathName === '/employee-activity/bank-withdrawal' ||
      pathName === '/employee-activity/charging-terminal' ||
      pathName === '/employee-activity/split-money' ||
      pathName === '/employee-activity/loading-terminal' ||
      pathName === '/employee-activity/withdrawal-terminal'
    ) {
      setSubMenuShown(false);
      setSubReportMenuShown(false);
    } else if (
      pathName === '/report/agent-cash-expense' ||
      pathName === '/report/reference' ||
      pathName === '/report/terminal'
    ) {
      setSubMenuShown(false);
      setSubEmpMenuShown(false);
    } else {
      setSubMenuShown(false);
      setSubEmpMenuShown(false);
      setSubReportMenuShown(false);
    }

    leftSideBarType === 'condensed' && toggleMenuCallback();
  }, [location]);

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <MetisMenu className="list-unstyled" id="side-menu">
          <li className="menu-title">{t('MENU')}</li>

          {/* Dashboard */}
          <li className={routePath.startsWith('/dashboard') ? 'mm-active' : ''}>
            <NavLink to="/dashboard" className="waves-effect">
              <i className="ri-dashboard-line"></i>
              {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
              <span className="ms-1">{t('DASHBOARD.TITLE')}</span>
            </NavLink>
          </li>

          {/* Bank */}
          {(roleId === USER_ROLE.SUPER_ADMIN || roleId === USER_ROLE.COMPANY_ADMIN) && (
            <li className={routePath.startsWith('/bank') ? 'mm-active' : ''}>
              <NavLink to="/bank/list" className="waves-effect">
                <i className="ri-bank-line"></i>
                <span className="ms-1">{t('BANK.TITLE')}</span>
              </NavLink>
            </li>
          )}

          {/* Company */}
          {roleId === USER_ROLE.SUPER_ADMIN && (
            <li className={routePath.startsWith('/company') ? 'mm-active' : ''}>
              <NavLink to="/company/list" className="waves-effect">
                <i className="ri-building-2-line"></i>
                <span className="ms-1">{t('COMPANY.TITLE')}</span>
              </NavLink>
            </li>
          )}

          {/* Agents */}
          {companyId !== 0 && (roleId === USER_ROLE.SUPER_ADMIN || roleId === USER_ROLE.COMPANY_ADMIN) && (
            <li className={routePath.substring(0, '/agent/'.length) === '/agent/' ? 'mm-active' : ''}>
              <NavLink to="/agent/list" className="waves-effect">
                <i className="ri-user-star-line"></i>
                <span className="ms-1">{t('AGENT.TITLE')}</span>
              </NavLink>
            </li>
          )}

          {/* Agent Activity */}
          {companyId !== 0 && roleId !== USER_ROLE.EMPLOYEE && (
            <li
              className={classnames(
                'subMenu',

                routePath.substring(0, '/agent-activity'.length) === '/agent-activity' || subMenuShown
                  ? 'mm-active cust-mm-active'
                  : '',
              )}
            >
              <Link
                to="#"
                className={classnames('has-arrow', subMenuShown ? 'cust-has-arrow' : '')}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSubMenuShown(!subMenuShown);
                }}
              >
                <i className="ri-contacts-fill"></i>
                <span className="ms-1">{t('AGENT_ACTIVITY')}</span>
              </Link>

              <ul className={classnames('mm-collapse', subMenuShown ? 'mm-show cust-mm-show' : '')}>
                <li className={classnames(routePath === '/agent-activity/bank-withdrawal' ? 'mm-active' : '')}>
                  <NavLink to="/agent-activity/bank-withdrawal" className="waves-effect">
                    <i className="ri-uninstall-line"></i>
                    <span className="ms-1">{t('BANK_WITHDRAWAL.TITLE')}</span>
                  </NavLink>
                </li>

                <li className={classnames(routePath === '/agent-activity/split-money' ? 'mm-active' : '')}>
                  <NavLink to="/agent-activity/split-money" className="waves-effect">
                    <i className="ri-refund-2-line"></i>
                    <span className="ms-1">{t('SPLIT_MONEY.TITLE')}</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {/* Employee */}
          {companyId !== 0 && (roleId === USER_ROLE.SUPER_ADMIN || roleId === USER_ROLE.COMPANY_ADMIN) && (
            <li className={routePath.substring(0, '/employee/'.length) === '/employee/' ? 'mm-active' : ''}>
              <NavLink to="/employee/list" className="waves-effect">
                <i className="ri-user-2-line"></i>
                <span className="ms-1">{t('EMPLOYEE.TITLE')}</span>
              </NavLink>
            </li>
          )}

          {/* Employee Activity */}
          {companyId !== 0 && (
            <li
              className={classnames(
                'subMenu',
                routePath.substring(0, '/employee-activity'.length) === '/employee-activity' || subEmpMenuShown
                  ? 'mm-active cust-mm-active'
                  : '',
              )}
            >
              <Link
                to="#"
                className={classnames('has-arrow', subEmpMenuShown ? 'cust-has-arrow' : '')}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSubEmpMenuShown(!subEmpMenuShown);
                }}
              >
                <i className="ri-contacts-line"></i>
                <span className="ms-1">{t('EMP_ACTIVITY')}</span>
              </Link>

              <ul className={classnames('mm-collapse', subEmpMenuShown ? 'mm-show cust-mm-show' : '')}>
                <li className={classnames(routePath === '/employee-activity/bank-withdrawal' ? 'mm-active' : '')}>
                  <NavLink to="/employee-activity/bank-withdrawal" className="waves-effect">
                    <i className="ri-uninstall-line"></i>
                    <span className="ms-1">{t('BANK_WITHDRAWAL.TITLE')}</span>
                  </NavLink>
                </li>

                <li className={classnames(routePath === '/employee-activity/charging-terminal' ? 'mm-active' : '')}>
                  <NavLink to="/employee-activity/charging-terminal" className="waves-effect">
                    <i className="ri-fridge-line"></i>
                    <span className="ms-1">{t('CHARGE_TERMINAL.TITLE')}</span>
                  </NavLink>
                </li>

                <li className={classnames(routePath === '/employee-activity/split-money' ? 'mm-active' : '')}>
                  <NavLink to="/employee-activity/split-money" className="waves-effect">
                    <i className="ri-refund-2-line"></i>
                    <span className="ms-1">{t('SPLIT_MONEY.TITLE')}</span>
                  </NavLink>
                </li>

                <li className={classnames(routePath === '/employee-activity/withdrawal-terminal' ? 'mm-active' : '')}>
                  <NavLink to="/employee-activity/withdrawal-terminal" className="waves-effect">
                    <i className="ri-fridge-line"></i>
                    <span className="ms-1">{t('WITHDRAWAL_TERMINAL')}</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {(roleId === USER_ROLE.EMPLOYEE || roleId === USER_ROLE.COMPANY_ADMIN) && (
            <li className={routePath === '/loading-terminal/terminal-loads' ? 'mm-active' : ''}>
              <NavLink to="/loading-terminal/terminal-loads" className="waves-effect">
                <i className="ri-currency-line"></i>
                <span className="ms-1">{t('EMPLOYEE_LOADS.TITLE')}</span>
              </NavLink>
            </li>
          )}
          {/* Location */}
          {companyId !== 0 && (
            <li className={routePath.startsWith('/locations') ? 'mm-active' : ''}>
              <NavLink to="/locations/list" className="waves-effect">
                <i className="ri-map-pin-5-line"></i>
                <span className="ms-1">{t('LOCATION.TITLE')}</span>
              </NavLink>
            </li>
          )}

          {/* Terminal / Machines */}
          {companyId !== 0 && roleId !== USER_ROLE.EMPLOYEE && (
            <li className={routePath.startsWith('/terminal') ? 'mm-active' : ''}>
              <NavLink to="/terminal/list" className="waves-effect">
                <i className="ri-safe-line"></i>
                <span className="ms-1">{t('MACHINE.TITLE')}</span>
              </NavLink>
            </li>
          )}

          {/* Loading terminal per day */}

          {(roleId === USER_ROLE.COMPANY_ADMIN || roleId === USER_ROLE.SUPER_ADMIN) && (
            <li className={routePath === '/loading-terminal/list' ? 'mm-active' : ''}>
              <NavLink to="/loading-terminal/list" className="waves-effect">
                <i className="ri-currency-line"></i>
                <span className="ms-1">{t('LOADING_TERMINAL.TITLE')}</span>
              </NavLink>
            </li>
          )}
          {/* <li className={routePath === '/cash-expense/list' ? 'mm-active' : ''}>
            <NavLink to="/cash-expense/list" className="waves-effect">
            <i className="ri-currency-line"></i>
            <span className="ms-1">{t('CASHEXPENSE.TITLE')}</span>
            </NavLink>
          </li> */}
          <li className={routePath === '/cash-expense' ? 'mm-active' : ''}>
            <NavLink to="/cash-expense/list" className="waves-effect">
              <i className="ri-currency-line"></i>
              <span className="ms-1">{t('CASHEXPENSE.TITLE')}</span>
            </NavLink>
          </li>

          {/* Report */}
          {companyId !== 0 && (roleId === USER_ROLE.COMPANY_ADMIN || roleId === USER_ROLE.SUPER_ADMIN) && (
            <li
              className={classnames(
                'subMenu',

                routePath.substring(0, '/report'.length) === '/report' || subReportMenuShown
                  ? 'mm-active cust-mm-active'
                  : '',
              )}
            >
              <Link
                to="#"
                className={classnames('has-arrow', subReportMenuShown ? 'cust-has-arrow' : '')}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSubReportMenuShown(!subReportMenuShown);
                }}
              >
                <i className="ri-file-chart-line"></i>
                <span className="ms-1">{t('REPORT.TITLE')}</span>
              </Link>

              <ul className={classnames('mm-collapse', subReportMenuShown ? 'mm-show cust-mm-show' : '')}>
                <li className={classnames(routePath === '/report/agent-cash-expense' ? 'mm-active' : '')}>
                  <NavLink to="/report/agent-cash-expense" className="waves-effect">
                    <i className="ri-wallet-3-line"></i>
                    <span className="ms-1">{t('REPORT.AGENTCASHEXPENSE')}</span>
                  </NavLink>
                </li>

                <li className={classnames(routePath === '/report/reference' ? 'mm-active' : '')}>
                  <NavLink to="/report/reference" className="waves-effect">
                    <i className="ri-survey-line"></i>
                    <span className="ms-1">{t('REPORT.REPORT_REFER')}</span>
                  </NavLink>
                </li>

                <li className={classnames(routePath === '/report/terminal' ? 'mm-active' : '')}>
                  <NavLink to="/report/terminal" className="waves-effect">
                    <i className="ri-list-check-2"></i>
                    <span className="ms-1">{t('REPORT.TERMINAL_REPORT')}</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
          {/* {companyId !== 0 && (roleId === USER_ROLE.COMPANY_ADMIN || roleId === USER_ROLE.SUPER_ADMIN) && (
            <li className={routePath.startsWith('/report') ? 'mm-active' : ''}>
              <NavLink to="/report/agent-cash-expense" className="waves-effect">
                <i className="ri-file-chart-line"></i>
                <span className="ms-1">{t('REPORT.TITLE')}</span>
              </NavLink>
            </li>
          )} */}
          {/* Employee Loading per day */}
          {/* {companyId !== 0 && roleId !== USER_ROLE.EMPLOYEE && (
            <li className={routePath==='/loading-terminal/terminal-loads' ? 'mm-active' : ''}>
              <NavLink to="/loading-terminal/terminal-loads" className="waves-effect">
                <i className="ri-currency-line"></i>
                <span className="ms-1">{t('Employee per day loadings')}</span>
              </NavLink>
            </li>
          )} */}

          {/* Special days */}
          {companyId !== 0 && roleId !== USER_ROLE.EMPLOYEE && (
            <li className={routePath.startsWith('/special-days') ? 'mm-active' : ''}>
              <NavLink to="/special-days/list" className="waves-effect">
                <i className="ri-calendar-2-line"></i>
                <span className="ms-1">{t('SPECIAL_DAYS.TITLE')}</span>
              </NavLink>
            </li>
          )}

          <li className="menu-title">{t('ACCOUNT.TITLE')}</li>

          {/* User Profile */}
          <li className={routePath.substring(0, '/account/profile'.length) === '/account/profile' ? 'mm-active' : ''}>
            <NavLink to="/account/profile" className="waves-effect">
              <i className="ri-profile-line"></i>
              <span className="ms-1">{t('PROFILE')}</span>
            </NavLink>
          </li>

          {/* Users */}
          {(roleId === USER_ROLE.SUPER_ADMIN || roleId === USER_ROLE.COMPANY_ADMIN) && (
            <li className={routePath.substring(0, '/account/users'.length) === '/account/users' ? 'mm-active' : ''}>
              <NavLink to="/account/users/list" className="waves-effect">
                <i className="ri-team-line"></i>
                <span className="ms-1">{t('USERS_TITLE')}</span>
              </NavLink>
            </li>
          )}
        </MetisMenu>
      </div>
    </React.Fragment>
  );
}

export default SidebarContent;
