/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { Button, Form, Input, InputGroup, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { pick, debounce } from 'lodash';

import { ITEMS_PER_PAGE, JobRole, USER_ROLE } from '../../../constants';
import LocalstorageService from '../../../helpers/localstorage-service';
import BranchService from '../../../services/branch';

import { setLoadingOverlay } from '../../../store/slices/loadingOverlaySlice';

import SortableHeader from '../../../components/SortableHeader';
import NoRecords from '../../../components/Table/NoRecords';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import UpsertHeader from '../../../components/UpsertHeader';
import ActionBtns from '../../../components/ActionBtns';
import Upsert from './Upsert';

const HeaderList = [
  {
    title: t('BRANCH.NAME_LABEL'),
    value: 'branchName',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('BRANCH.NUMBER_LABEL'),
    value: 'branchNumber',
    style: { minWidth: '150px', width: '30%' },
  },
];

const reqPayload = { pageNo: 1 };

function BankBranch() {
  const dispatch = useDispatch();
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const location = useLocation();
  const { state } = location;

  const [roleId, setRoleId] = useState(USER_ROLE.EMPLOYEE);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const [branchInfo, setBranchInfo] = useState();
  const [showUpsertModal, setShowUpsertModal] = useState(false);

  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setPageTitle(`${state?.bankInfo.bankName} > ${t('BRANCH.TITLE')}`);
    setCrumbItem([
      { title: t('BANK.TITLE'), link: 'list' },
      { title: t('BRANCH.TITLE'), link: '#' },
    ]);

    const jobRole = LocalstorageService.getItem(JobRole);
    setRoleId(jobRole);

    reqPayload['bankId'] = state?.bankInfo.id;
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  }, [search]);

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));

    await BranchService.listBranches(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        setList(dataList || []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const closeUpsertModal = (shown = false, isSubmitted = false) => {
    setBranchInfo();
    setShowUpsertModal(false);
    isSubmitted && listFn();
  };

  const deleteModal = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };

  const resetModal = () => {
    setDeleteItem(null);
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    await BranchService.deleteBranch({ id: deleteItem.id })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        setIsLoading(false);
      });

    resetModal();
  };

  const editFn = (item) => {
    setBranchInfo(pick(item, ['bankId', 'id', 'branchName', 'branchNumber', 'displayOrder']));
    setShowUpsertModal(true);
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }
    listFn();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value);
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    listFn();
  };

  return (
    <React.Fragment>
      <UpsertHeader navTo="../list" />

      <div className="d-flex justify-content-between align-items-center mb-4">
        <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
          <InputGroup>
            <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
            <Button color="primary" type="button">
              <i className="ri-search-line"></i>
            </Button>
          </InputGroup>
        </Form>
        {roleId === USER_ROLE.SUPER_ADMIN ? (
          <Button color="primary" className="float-end" onClick={() => setShowUpsertModal(true)}>
            <span className="d-none d-sm-block">{t('BRANCH.ADD_BRANCH')}</span>
            <i className="ri-add-line d-block d-sm-none" title={t('BRANCH.ADD_BRANCH')}></i>
          </Button>
        ) : (
          ''
        )}
      </div>

      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader
              headers={HeaderList}
              isDisabled={list.length === 0}
              sortClick={sortBy}
              showActionCol={roleId === USER_ROLE.SUPER_ADMIN}
            />
          </tr>
        </thead>

        <tbody>
          {list.map((item, l0Idx) => (
            <tr key={l0Idx}>
              {roleId === USER_ROLE.SUPER_ADMIN && (
                <td className="actionCol">
                  <ActionBtns
                    deleteTitle={t('BRANCH.DELETE_BRANCH')}
                    deleteFn={() => deleteModal(item)}
                    updateTitle={t('BRANCH.UPDATE_BRANCH')}
                    updateFn={() => editFn(item)}
                  />
                </td>
              )}

              <td>{item.branchName}</td>
              <td>{item.branchNumber}</td>
            </tr>
          ))}

          {!isLoading && list.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>

      <Upsert isOpened={showUpsertModal} toggle={closeUpsertModal} data={branchInfo} bankData={state?.bankInfo} />

      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        confirmFn={confirmDelete}
        cancelFn={resetModal}
      />
    </React.Fragment>
  );
}

export default BankBranch;
