import { post } from "../AxiosConfig";

const URI = "/dashboard";

const listCounts = (payload) => {
  const URL = `${URI}/list-counts`;
  return post(URL, payload);
};

const DashboardService = { listCounts };

export default DashboardService;
