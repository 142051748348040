import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';

function ActionBtns({
  deleteTitle = t('DELETE_TITLE'),
  deleteFn,
  updateTitle = t('UPDATE_TITLE'),
  updateFn,
  viewTitle = t('VIEW_TITLE'),
  viewFn,
  activateTitle = t('ACTIVATE'),
  activateFn,
  remarkTitle = t('REMARK'),
  remarkFn,
  saveTitle = t('LOAD_TABS.SAVE'),
  saveFn,
  cancelTitle = t('LOAD_TABS.CANCEL_BTN'),
  cancelFn,
}) {
  return (
    <React.Fragment>
      <div className="actionBtns">
        {/* Remark */}
        {cancelFn && (
          <Button color="none" title={cancelTitle} onClick={cancelFn}>
            <i className="ri-close-circle-line text-gray "></i>
          </Button>
        )}
        {/* Remark */}
        {saveFn && (
          <Button color="none" title={saveTitle} onClick={saveFn}>
            <i className="ri-save-line text-success"></i>
          </Button>
        )}
        {/* Remark */}
        {remarkFn && (
          <Button color="none" title={remarkTitle} onClick={remarkFn}>
            <i className="ri-message-2-line text-primary"></i>
          </Button>
        )}
        {/* Delete */}
        {deleteFn && (
          <Button color="none" title={deleteTitle} onClick={deleteFn}>
            <i className="ri-close-circle-line text-danger"></i>
          </Button>
        )}

        {deleteFn && updateFn && <span className="separator"></span>}

        {/* Update */}
        {updateFn && (
          <Button color="none" title={updateTitle} onClick={updateFn}>
            <i className="ri-edit-box-line text-primary"></i>
          </Button>
        )}

        {(deleteFn || updateFn) && viewFn && <span className="separator"></span>}

        {/* View */}
        {viewFn && (
          <Button color="none" title={viewTitle} onClick={viewFn}>
            <i className="ri-eye-line text-secondary"></i>
          </Button>
        )}

        {(deleteFn || updateFn || viewFn) && activateFn && <span className="separator"></span>}
        {/* Activate */}
        {activateFn && (
          <Button color="none" title={activateTitle} onClick={activateFn}>
            <i className="ri-checkbox-circle-fill text-danger"></i>
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default ActionBtns;
