import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";

import { WAIT_FOR_NEXT_MAIL } from "../constants";
import AuthService from "../services/auth";
import { EmailSchema } from "../helpers/yup-schema";

let counterInterval = null;

function EmailModal({
  isOpen = false,
  fieldName = "mail",
  classList = "modal-md",
  toggleEmailModal,
  fieldValue = "",
  isDisabled = false,
}) {
  const { t } = useTranslation();

  const [startMailTimer, setStartMailTimer] = useState(false);
  const [counter, setCounter] = useState(WAIT_FOR_NEXT_MAIL);

  function setMailTimer() {
    setStartMailTimer(true);

    let count = WAIT_FOR_NEXT_MAIL;
    counterInterval = setInterval(() => {
      if (--count <= 0) {
        clearInterval(counterInterval);
        setStartMailTimer(false);
      }
      setCounter(count);
    }, 1000);
  }

  const resendMail = () => {
    clearInterval(counterInterval);
    setCounter(WAIT_FOR_NEXT_MAIL);
    setMailTimer();
    console.log("Resending Mail...");
  };

  const resetMailTimer = () => {
    clearInterval(counterInterval);
    setCounter(WAIT_FOR_NEXT_MAIL);
    setStartMailTimer(false);
  };

  // Modal close button
  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        toggleEmailModal();
        resetMailTimer();
      }}
    ></button>
  );

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        backdrop="static"
        className={classList}
        onClosed={resetMailTimer}
      >
        <ModalHeader close={closeBtn}>
          {t("SEND_VERFIICATION_MAIL")}
        </ModalHeader>

        <ModalBody>
          <Formik
            initialValues={{
              [fieldName]: fieldValue,
            }}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
              await AuthService.mailSend(values)
                .then((response) => {
                  const { status, message } = response.data;

                  if (status === 400) {
                    toast.error(message);
                    return;
                  }

                  toast.success(message);
                  resendMail();
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="input-group">
                  <Field
                    name={fieldName}
                    type="email"
                    validate={async (v) => {
                      let flag = false;
                      const ev = await EmailSchema(fieldName)
                        .validate({
                          [fieldName]: v,
                        })
                        .catch((err) => {
                          flag = true;
                          return err?.errors[0];
                        });
                      return flag ? ev : "";
                    }}
                    className="form-control"
                    disabled={isDisabled}
                  />

                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                    disabled={isSubmitting || startMailTimer}
                  >
                    {isSubmitting
                      ? t("PLEASE_WAIT")
                      : t("FORGOT_PASSWORD.SEND_LINK")}
                  </Button>
                </div>

                <ErrorMessage
                  name={fieldName}
                  className="text-danger"
                  component="div"
                />
              </Form>
            )}
          </Formik>

          <br />
          <div className="">
            {startMailTimer && (
              <div className="text-muted">
                {t("FORGOT_PASSWORD.PLEASE_WAIT_FOR") +
                  " " +
                  counter +
                  " " +
                  t("SECONDS")}
                .
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default EmailModal;
