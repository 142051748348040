import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { ErrorMessage, Field } from 'formik';

function CommonField({
  fieldName = '',
  fieldType = 'text',
  id = fieldName,
  label = '',
  placeholder = '',
  required = false,
  isDisabled = false,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="">
        <Label htmlFor={id}>
          {required ? '*' : ''}
          {t(label)}
        </Label>

        <Field
          name={fieldName}
          type={fieldType}
          className="form-control"
          id={id}
          placeholder={t(placeholder)}
          disabled={isDisabled}
          {...rest}
        />
      </div>

      <ErrorMessage name={fieldName} className="text-danger" component="div" />
    </React.Fragment>
  );
}

export default CommonField;
