import { post } from '../AxiosConfig';

const URI = '/terminal';

const listMachines = (payload) => {
  const URL = `${URI}/list-terminal`;
  return post(URL, payload);
};

const createMachine = (payload) => {
  const URL = `${URI}/create-terminal`;
  return post(URL, payload);
};

const updateMachine = (payload) => {
  const URL = `${URI}/update-terminal`;
  return post(URL, payload);
};

const deleteMachine = (payload) => {
  const URL = `${URI}/delete-terminal`;
  return post(URL, payload);
};

const getTerminal = (payload) => {
  const URL = `${URI}/get-terminals`;
  return post(URL, payload);
};

const getLocations = (payload) => {
  const URL = `${URI}/get-locations`;
  return post(URL, payload);
};

const getModems = (payload) => {
  const URL = `${URI}/get-modems`;
  return post(URL, payload);
};

const activateTerminal = (payload) => {
  const URL = `${URI}/activate-terminal`;
  return post(URL, payload);
};

const terminalList = (payload) => {
  const URL = `/loadingterminal/loading-terminal-calculation`;
  return post(URL, payload);
};

const toAmountToLoad = (payload) => {
  const URL = `/loadingterminal/total-amount-to-load`;
  return post(URL, payload);
};

const updateLoadingTerminalData = (payload) => {
  const URL = `/loadingterminal/insert-loading-terminal-per-day-data`;
  return post(URL, payload);
};

const reCalculateLoadingTerminalData = (payload) => {
  const URL = `/loadingterminal/recalculate-loading-terminal`;
  return post(URL, payload);
};

const updateRowOrder = (payload) => {
  const URL = `/loadingterminal/enter-row-order-in-employee-to-location-map`;
  return post(URL, payload);
};

const getRemarks = (payload) => {
  const URL = `/remark/list-remarks`;
  return post(URL, payload);
};

const addRemarks = (payload) => {
  const URL = `/remark/insert-update-remarks`;
  return post(URL, payload);
};

const manageRowOrder = (payload) => {
  const URL = `/loadingterminal/manage-temp-row-order`;
  return post(URL, payload);
};
const manageCancelSubmitRowOrder = (payload) => {
  const URL = `/loadingterminal/update-row-status-for-terminal-calculation`;
  return post(URL, payload);
};
const MachineService = {
  listMachines,
  createMachine,
  updateMachine,
  deleteMachine,
  getTerminal,
  getLocations,
  getModems,
  activateTerminal,
  terminalList,
  toAmountToLoad,
  getRemarks,
  addRemarks,
  updateLoadingTerminalData,
  reCalculateLoadingTerminalData,
  updateRowOrder,
  manageCancelSubmitRowOrder,
  manageRowOrder,
};

export default MachineService;
