/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import classnames from 'classnames';

const Transfer = ({ checkAllId, checkAll = false, dataList, setSelected, isDisabled }) => {
  const { t } = useTranslation();

  const [list, setList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(() => {
    setList(dataList);
    isCheckAll && setIsCheckAll(false);
  }, [dataList]);

  const handleCheckAllChange = ({ target }) => {
    setIsCheckAll(target.checked);
    const newList = [...list];
    newList.map((item) => (item.isChecked = target.checked));
    setSelected(!target.checked);
    setList(newList);
  };

  const handleCheckChange = ({ target }, item) => {
    isCheckAll && setIsCheckAll(false);

    const newList = [...list];
    newList.map((obj) => {
      if (obj.locationId === item.locationId) {
        obj.isChecked = target.checked;
      }
      return obj;
    });

    const checkCount = newList.filter((item) => item.isChecked === true).length;
    checkCount === list.length && setIsCheckAll(true);

    setSelected(checkCount === 0);
    setList(newList);
  };

  return (
    <React.Fragment>
      <div className="check-all p-3 pb-0">
        <FormGroup check>
          <Input
            id={checkAllId}
            type="checkbox"
            onChange={(e) => handleCheckAllChange(e)}
            checked={isCheckAll}
            disabled={isDisabled}
          />

          <Label htmlFor={checkAllId}>{t('CHECK_ALL')}</Label>
        </FormGroup>

        <hr className="border-secondary border-3 m-0" />
      </div>

      {list.map((item, i) => {
        return (
          <div key={item.locationId} className={classnames('px-3 pb-0', i === 0 ? 'pt-2' : '')}>
            <FormGroup check>
              <Input
                id={item.locationId}
                type="checkbox"
                onChange={(e) => handleCheckChange(e, item)}
                checked={!!item.isChecked}
                disabled={isDisabled}
              />
              <Label htmlFor={item.locationId}>{item.locationName}</Label>
            </FormGroup>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Transfer;
