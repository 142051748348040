import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Capitalize } from "react-lodash";
import { t } from "i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";

// Import images
import natanelLg from "../../assets/images/logo/natanel-lg.png";

import { IsLockScreen } from "../../constants";
import AuthService from "../../services/auth";
import { removeAuthUserInfo } from "../../helpers";
import LocalstorageService from "../../helpers/localstorage-service";

import { userLogout } from "../../store/slices/Auth/authSlice";

import FooterInscription from "../../components/FooterInscription";
import PasswordField from "../../components/PasswordField";

const LockscreenSchema = Yup.object().shape({
  password: Yup.string().required(t("REQUIRED")),
});

function Lockscreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.Auth);
  const displayName =
    authUser?.userName ||
    authUser?.knownAs ||
    authUser?.firstName + " " + authUser?.lastName;

  const [isLoading, setIsLoading] = useState(false);

  const logoutUser = () => {
    removeAuthUserInfo();
    dispatch(userLogout());
    navigate("/login");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    setIsLoading(true);
    await AuthService.login({ ...values, email: authUser.mail })
      .then(({ data }) => {
        const { status, message } = data;
        if (status === 400) {
          toast.error(message);
          return;
        }

        LocalstorageService.removeItem(IsLockScreen);
        navigate(-1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col lg={4} className="p-3 card">
                  <div>
                    <div className="text-center">
                      <img
                        src={natanelLg}
                        alt=""
                        height="30"
                        className="auth-logo logo-dark mx-auto"
                      />

                      <h4 className="font-size-18 mt-4">
                        {t("LOCK_SCREEN.TITLE")}
                      </h4>
                      <p className="text-muted">{t("LOCK_SCREEN.SUB_TITLE")}</p>
                    </div>

                    <div className="p-2">
                      <div className="user-thumb text-center mb-5">
                        <h5 className="font-size-15 mt-3">
                          <b>{t("LOCK_SCREEN.LOGGED_IN")}:</b>&nbsp;
                          {<Capitalize string={displayName} />}
                        </h5>
                      </div>

                      <Formik
                        className="form-horizontal"
                        initialValues={{ password: "" }}
                        validationSchema={LockscreenSchema}
                        onSubmit={handleSubmit}
                      >
                        {(props) => {
                          const { setFieldValue, isSubmitting } = props;

                          return (
                            <Form>
                              <div className="mb-4">
                                <PasswordField
                                  id="userpassword"
                                  setFieldValue={setFieldValue}
                                />
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                  disabled={isSubmitting || isLoading}
                                >
                                  {isSubmitting || isLoading
                                    ? t("PLEASE_WAIT")
                                    : t("LOCK_SCREEN.UNLOCK")}
                                </Button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>

                    <div className="mt-5 text-center">
                      <p>
                        {t("LOCK_SCREEN.NOT_YOU")}{" "}
                        <Button
                          className="px-0 fw-medium text-decoration-none"
                          color="link"
                          onClick={() => logoutUser()}
                        >
                          {t("LOGIN.TITLE")}
                        </Button>
                      </p>

                      <FooterInscription />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Lockscreen;
