import React, { useImperativeHandle, useRef, useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import { pick } from "lodash";

import { ConfirmPasswordSchema } from "../../helpers/yup-schema";
import AuthService from "../../services/auth";

import PasswordField from "../../components/PasswordField";

const ChangePasswordSchema = ConfirmPasswordSchema(
  "confirmNewPassword",
  "newPassword"
).shape({
  currentPassword: Yup.string().required(t("REQUIRED")),
});

const ChangePassword = React.forwardRef((props, ref) => {
  const formRef = useRef();

  const [viewPassword, setViewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetForm() {
          setViewPassword(false);
          formRef.current.resetForm();
        },
      };
    },
    []
  );

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(false);
    setIsLoading(true);

    const payload = {
      ...pick(values, ["currentPassword", "newPassword"]),
    };

    await AuthService.changePassword({ ...payload })
      .then(({ data }) => {
        const { status, message } = data;
        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
      })
      .finally(() => {
        resetForm();
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            {/* Current Password */}
            <div className="mb-4">
              <PasswordField
                fieldName="currentPassword"
                id="currentUserpassword"
                label="ACCOUNT.CURRENT_PASSWORD_LABEL"
                placeholder="ACCOUNT.CURRENT_PASSWORD_PLACEHOLDER"
                setFieldValue={setFieldValue}
                viewPasswordIndicator={false}
                simple={true}
              />
            </div>

            {/* New Password */}
            <div className="mb-4">
              <PasswordField
                fieldName="newPassword"
                showPasswordCriteriaInfo={true}
                setFieldValue={setFieldValue}
                setBaseViewPassword={setViewPassword}
                isViewPassword={viewPassword}
                simple={true}
              />
            </div>

            {/* Confirm New Password */}
            <div className="mb-4">
              <PasswordField
                fieldName="confirmNewPassword"
                id="confirmUserpassword"
                label="RESET_PASSWORD.CONFIRM_PASSWORD_LABEL"
                placeholder="RESET_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER"
                setFieldValue={setFieldValue}
                viewPasswordIndicator={false}
                isViewPassword={viewPassword}
                simple={true}
              />
            </div>

            {/* Submit Button */}
            <div className="mt-4">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading
                  ? t("PLEASE_WAIT")
                  : t("ACCOUNT.CHANGE_PASSWORD")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
});

export default ChangePassword;
