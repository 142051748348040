/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Capitalize } from 'react-lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { AuthUserInfo, IsLockScreen } from '../../constants';
import { removeAuthUserInfo } from '../../helpers';
import LocalstorageService from '../../helpers/localstorage-service';

import { userLogout } from '../../store/slices/Auth/authSlice';

function ProfileMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.Auth);

  const [displayName, setDisplayName] = useState({});
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const userInfo = authUser || LocalstorageService.getItem(AuthUserInfo)?.data;

    const dispName =
      userInfo?.userName || userInfo?.knownAs || userInfo?.firstName + ' ' + userInfo?.lastName || 'Anonymous';

    setDisplayName(dispName);
  }, []);

  const lockScreen = () => {
    LocalstorageService.setItem(IsLockScreen, true);
    navigate('/lock-screen');
  };

  const logoutUser = () => {
    removeAuthUserInfo();
    dispatch(userLogout());
    navigate('/login');
    localStorage.clear();
    // window.location.reload();
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block user-dropdown">
        <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
          <span className="d-xl-inline-block ms-1 text-transform">{<Capitalize string={displayName} />}</span>
          <i className="mdi mdi-chevron-down ms-1 d-xl-inline-block"></i>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => navigate('/account/profile')}>
            <i className="ri-user-line align-middle me-1"></i> {t('PROFILE')}
          </DropdownItem>

          <DropdownItem onClick={() => lockScreen()}>
            <i className="ri-lock-unlock-line align-middle me-1"></i> {t('LOCK_SCREEN.TITLE')}
          </DropdownItem>

          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={() => logoutUser()}>
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i> {t('LOGOUT')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default ProfileMenu;
