/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { EmailSchema, PhoneNumberSchema } from '../../helpers/yup-schema';
import EmployeeService from '../../services/employee';

import CommonField from '../../components/CommonField';
import PhoneNumber from '../../components/PhoneNumber';
import EmailField from '../../components/EmailField';

const InitialValues = {
  firstName: '',
  lastName: '',
  mail: '',
  phoneNumber: '',
};

const ValidationSchema = EmailSchema('mail')
  .concat(PhoneNumberSchema())
  .shape({
    firstName: Yup.string().required(t('REQUIRED')).trim(),
    lastName: Yup.string().required(t('REQUIRED')).trim(),
  });

function InformationEmployee({ formData, getDataFn }) {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const [showEditForm, setShowEditForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCrumbItem([
      { title: t('EMPLOYEE.TITLE'), link: 'list' },
      { title: t('UPDATE_TITLE'), link: '#' },
    ]);
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    setIsLoading(true);
    await EmployeeService.updateEmployee({ ...values })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        getDataFn();
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...InitialValues, ...formData }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row>
              {/* First Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="firstName"
                    label="FIRST_NAME_LABEL"
                    placeholder="FIRST_NAME_PLACEHOLDER"
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Last Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="lastName"
                    label="LAST_NAME_LABEL"
                    placeholder="LAST_NAME_PLACEHOLDER"
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Email */}
              <Col sm={4}>
                <div className="mb-4">
                  <EmailField isRequired={true} showVerification={false} isDisabled={true} />
                </div>
              </Col>

              {/* Mobile */}
              <Col sm={4}>
                <div className="mb-4">
                  <PhoneNumber
                    fieldName="phoneNumber"
                    fieldLabel={t('MOBILE_NO_LABEL')}
                    required={true}
                    isDisabled={true}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

export default InformationEmployee;
