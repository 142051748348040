/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Form, Input, InputGroup, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { t } from 'i18next';

import { ITEMS_PER_PAGE, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import BankService from '../../services/bank';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import ActionBtns from '../../components/ActionBtns';

const HeaderList = [
  {
    title: t('BANK.NAME_LABEL'),
    value: 'bankName',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('BANK.NUMBER_LABEL'),
    value: 'bankNumber',
    style: { minWidth: '150px', width: '30%' },
  },
];

const reqPayload = { pageNo: 1 };

function BankList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setPageTitle, setBreadcrumbItems] = useOutletContext();

  const [roleId, setRoleId] = useState(USER_ROLE.EMPLOYEE);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setPageTitle(t('BANK.TITLE'));

    setBreadcrumbItems([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('BANK.TITLE'), link: '#' },
    ]);

    const jobRole = LocalstorageService.getItem(JobRole);
    setRoleId(jobRole);
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  }, [search]);

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await BankService.listBanks(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        setList(dataList || []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const deleteModal = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };

  const resetModal = () => {
    setDeleteItem(null);
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    await BankService.deleteBank({ id: deleteItem.id })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        setIsLoading(false);
      });

    resetModal();
  };

  const editFn = (item) => {
    navigate(`../update/${item.id}`);
  };

  const viewFn = (item) => {
    navigate(`../${item.id}/branches`, { state: { bankInfo: item } });
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }
    listFn();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    listFn();
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
          <InputGroup>
            <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
            <Button color="primary" type="button">
              <i className="ri-search-line"></i>
            </Button>
          </InputGroup>
        </Form>

        {roleId === USER_ROLE.SUPER_ADMIN ? (
          <Button color="primary" className="float-end" onClick={() => navigate('../add')}>
            <span className="d-none d-sm-block">{t('BANK.ADD_BANK')}</span>
            <i className="ri-add-line d-block d-sm-none" title={t('BANK.ADD_BANK')}></i>
          </Button>
        ) : (
          ''
        )}
      </div>

      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={HeaderList} isDisabled={list.length === 0} sortClick={sortBy} />
          </tr>
        </thead>

        <tbody>
          {list.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className="actionCol">
                <ActionBtns
                  {...(roleId === USER_ROLE.SUPER_ADMIN && {
                    deleteTitle: t('BANK.DELETE_BANK'),
                    deleteFn: () => deleteModal(item),
                    updateTitle: t('BANK.UPDATE_BANK'),
                    updateFn: () => editFn(item),
                  })}
                  viewTitle={t('BRANCH.VIEW_BRANCHES')}
                  viewFn={() => viewFn(item)}
                />
              </td>
              <td>{item.bankName}</td>
              <td>{item.bankNumber}</td>
            </tr>
          ))}

          {!isLoading && list.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>

      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        confirmFn={confirmDelete}
        cancelFn={resetModal}
      />
    </React.Fragment>
  );
}

export default BankList;
