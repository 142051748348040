/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import { pick } from 'lodash';

import BankService from '../../services/bank';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import UpsertHeader from '../../components/UpsertHeader';
import NumericFormatField from '../../components/NumericFormatField';

const InitialValues = {
  bankName: '',
  bankNumber: '',
  displayOrder: '',
};

const ValidationSchema = Yup.object().shape({
  bankName: Yup.string().required(t('REQUIRED')).trim(),
  bankNumber: Yup.string().required(t('REQUIRED')).trim(),
  displayOrder: Yup.string().required(t('REQUIRED')).trim(),
});

function UpdateBank() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(InitialValues);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    setCrumbItem([
      { title: t('BANK.TITLE'), link: 'list' },
      { title: t('UPDATE_TITLE'), link: '#' },
    ]);

    getDataFn();
  }, []);

  async function getDataFn() {
    dispatch(setLoadingOverlay(true));
    await BankService.listBanks({ id: params.bankId })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        const paylaod = pick(data, ['id', ...Object.keys(InitialValues)]);
        setFormData(paylaod);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setIsLoading(true);
    await BankService.updateBank({ ...values })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('BANK.UPDATE_BANK')} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
              {/* Bank Name */}
              <Col lg={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="bankName"
                    label="BANK.NAME_LABEL"
                    placeholder="BANK.NAME_PLACEHOLDER"
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Bank Number */}
              <Col lg={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="bankNumber"
                    fieldValue={values.bankNumber}
                    fieldLabel={t('BANK.NUMBER_LABEL')}
                    useThousandSeparator={false}
                    fieldPlaceholder={t('BANK.NUMBER_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabled={true}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Display Order */}
              <Col lg={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="displayOrder"
                    fieldValue={values.displayOrder}
                    fieldLabel={t('BANK.DISPLAY_ORDER_LABEL')}
                    useThousandSeparator={false}
                    fieldPlaceholder={t('BANK.DISPLAY_ORDER_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}

            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <>
          <div className="button-items">
            <Button
              color="primary"
              className="w-md waves-effect waves-light"
              type="button"
              onClick={handleShowEditForm}
              disabled={isLoading}
            >
              {t('EDIT')}
            </Button>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default UpdateBank;
