import local from "./local.config";
import dev from "./dev.config";
import prod from "./prod.config";

const config = {
  ...local,
  ...dev,
  ...prod,
};
export default config[process.env.REACT_APP_ENV];
