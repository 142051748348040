import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';
import { useEffect, useRef, useState } from 'react';
import LocalstorageService from '../../helpers/localstorage-service';
import { CompanyId, JobRole, USER_ROLE } from '../../constants';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  // ModalFooter,
  // ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import MachineService from '../../services/machines';
import { useOutletContext } from 'react-router-dom';
import { t } from 'i18next';
import EmployeeService from '../../services/employee';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';
import { NumericFormat } from 'react-number-format';
import ReactDatePicker from 'react-datepicker';
// import RemarkModal from './RemarkModal';
import NoRecords from '../../components/Table/NoRecords';
import SortableHeader from '../../components/SortableHeader';
// import ActionBtns from '../../components/ActionBtns';
import dragHandler from '../../assets/images/drag-handle.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import EmployeeRemarkModal from '../../components/EmployeeRemarkModal';
import EmployeeCancelLoadModal from '../../components/EmployeeCancelLoadModal';
import warning from '../../assets/images/Warning.png';
import LocationService from '../../services/location';
// import ConfirmBatchNumber from './ConfirmBatchNumber';
import EmpActivityService from '../../services/emp_activity';
import AlertModal from '../../components/AlertModal';
const EmployeeLoadsList = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const login_emp = LocalstorageService.getItem('user-id');
  const roleId = LocalstorageService.getItem(JobRole);
  const selectedEmpId = localStorage.getItem('emp_id');
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [empId, setEmpId] = useState(roleId === USER_ROLE.COMPANY_ADMIN ? selectedEmpId || 0 : login_emp);
  const reqPayload = {};
  const formRef = useRef();
  let companyId = LocalstorageService.getItem(CompanyId);
  const [date, setDate] = useState(new Date());
  const [openModalSavePermanent, setOpenModalSavePermanent] = useState(false);
  const [showRemarkModal, setShowRemarkModal] = useState({ shown: false, values: {} });
  const [showLoadingCancelModal, setShowLoadingCancelModal] = useState({ shown: false, value: {} });
  const [amount, setAmount] = useState('');
  const [totalLoadAmount, setTotalLoadAmount] = useState('');
  const [remainingAmount, setRemainingAmount] = useState(0);
  let role = LocalstorageService.getItem(JobRole);
  
  const body = window.innerWidth < 850
  const InitialValues = {
    totalLoadAmount: '',
    employee: '',
    amount: '',
    allEmployee: true,
  };
  const [compConf, setCompConf] = useState({
    shown: false,
    msg: t('ALERT.EMPLOYEE_ZERO_AMOUNT_LOAD'),
    confirmFn: () => {
      setCompConf((pre) => {
        return {
          ...pre,
          shown: false,
        };
      });
    },
  });
  useEffect(() => {
    setPageTitle(t('EMPLOYEE_LOADS.TITLE'));
    setCrumbItem([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('EMPLOYEE_LOADS.TITLE'), link: '#' },
    ]);

    getEmployeeFn();
    moveToNextDayAfterNoon();
    getTotalAmountToLoad({
      dateS: date.toLocaleDateString('en-US'),
      companyId: companyId,
      employeeId: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchRemainCash(reqPayload) {
    dispatch(setLoadingOverlay(true));
    await LocationService.getRemainCash(reqPayload)
      .then((response) => {
        const { data } = response.data;
        setRemainingAmount(data.totalCashRemain);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  const fetchRecords = () => {
    reqPayload.companyId = companyId;
    reqPayload.dateS = date.toLocaleDateString('en-US');
    reqPayload.employeeId = empId || 0;

    listFn();
  };

  const moveToNextDayAfterNoon = () => {
    let timerId = null;
    const israelTimeZone = 'Asia/Jerusalem';

    const getNextDate = (currentDate) => {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      return nextDate;
    };

    const checkTime = () => {
      const date = new Date();

      const israelTime = date.toLocaleTimeString('en-US', {
        timeZone: israelTimeZone,
        hour12: false,
      });

      const currentHour = israelTime.split(':')[0];

      if (currentHour >= 17) {
        const nextDate = getNextDate(date);

        setDate(nextDate);
        clearInterval(timerId);

        timerId = null;
      }
    };

    timerId = setInterval(checkTime, 1000);
  };

  useEffect(() => {
    const storeList = JSON.parse(localStorage.getItem('dataArray'));
    const storedDate = localStorage.getItem('date');
    const storedEmpiId = localStorage.getItem('emp_id');
    const onlyDate = new Date().toDateString();
    const remondAmount = localStorage.getItem('remindAmount');
    
    if (onlyDate !== storedDate || parseInt(storedEmpiId) !== parseInt(empId)) {
      // console.log('api data setting');
      fetchRecords();
      getTotalAmountToLoad({
        dateS: date.toLocaleDateString('en-US'),
        companyId: companyId,
        employeeId: empId,
      });
    } else {
      // console.log('storeList calling');
      setList(storeList);
      if (storeList?.length > 0) {
        if (remondAmount) {
          setTotalLoadAmount(remondAmount);
        } else {
          let temp = 0;
          storeList?.forEach((value) => {
            temp += value.loadingAmount;
          });

          setTotalLoadAmount(temp);
        }
      }
      getTotalAmountToLoad({
        dateS: date.toLocaleDateString('en-US'),
        companyId: companyId,
        employeeId: storedEmpiId,
      });
    }
    if (empId) {
      fetchRemainCash({ employeeId: empId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, empId]);

  const sortBy = (sortObj) => {
    let day = date.toISOString().split('T')[0];
    delete reqPayload.sort;

    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }

    reqPayload.companyId = companyId;
    reqPayload.dateS = day;
    reqPayload.employeeId = openModalSavePermanent ? 0 : empId;
    listFn();
  };

  async function getEmployeeFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ companyId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            companyId: item.companyId,
          }));
          data.unshift({
            id: 0,
            label: `${t(`LOADING_TERMINAL.ALL_EMPLOYEES`)}`,
            value: 0,
            companyId: companyId,
})
          setEmpList(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getTotalAmountToLoad(payload) {
    const getPayload = {
      ...payload,
      employeeId: payload.employeeId !== 0 ? Number(payload.employeeId) : payload.employeeId,
    };
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.toAmountToLoad(getPayload)
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList.length > 0) {
          setAmount(dataList[0].totalAmountToLoad);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const HeaderList = [
    {
      title: '',
      value: '',
      className: 'tableSbtBtn',
      sortable: false,
    },
    {
      title: t(`LOAD_TABS.BUSINESS_NAME_LABEL`),
      value: 'locationName',
      style: { width: '120px' },
      sortable: false,
    },

    {
      title: t(`LOAD_TABS.AMOUNT_TO_LOAD_LABEL`),
      value: 'loadingAmount',
      style: { width: '120px' },
      sortable: false,
    },

    {
      title: t(`LOAD_TABS.REMARKS_LABEL`),
      value: 'remark',
      style: { width: '120px' },
      sortable: false,
    },
    {
      title: '',
      value: '',
      className: 'tableCnlBtn',
      sortable: false,
    },
  ];

  async function listFn(rerender = 0) {
    if (rerender !== 0) {
      let day = date.toISOString().split('T')[0];
      reqPayload.companyId = companyId;
      reqPayload.dateS = day;
      reqPayload.employeeId = openModalSavePermanent ? 0 : empId;
    }
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    if (empId) {
      fetchRemainCash({ employeeId: empId });
    }
    await MachineService.terminalList({
      ...reqPayload,
      sort: [
        {
          value: 'rowOrder',
          asc: true,
        },
      ],
    })
      .then((response) => {
        const { status, message, dataList } = response.data;
        const dataArr = dataList!==null && dataList?.length > 0 ? dataList : []
        if (status === 400) {
          toast.error(message);
          return;
        }
        let temp = 0;
        // if (dataList.length > 0) {
        //   let temp = 0;
        //   dataList?.forEach((value) => {
        //     temp += value.loadingAmount;
        //   });

        //   setTotalLoadAmount(temp);
        // }
        if (rerender === 0) {
          const newArr =   dataArr 
            ?.map((o) => {
              if (o.rowStatus === 0) {
                temp += Number(o.loadingAmount);
              }
              return { ...o, stats: o.rowStatus };
            })
            .slice()
            .sort((a, b) => {
              // Prioritize objects with loadingAmount greater than zero
              if (a.loadingAmount > 0 && b.loadingAmount === 0) {
                return -1;
              } else if (a.loadingAmount === 0 && b.loadingAmount > 0) {
                return 1;
              }

              // If both have the same loadingAmount (or zero),
              // use the original order (assuming 'rowOrder' represents the order)
              return a.rowOrder - b.rowOrder;
            })
            .sort((a, b) => {
              // Sort by "k" property values in the desired order: null, "submit", "cancel"
              const order = [0, 1, -1];
              // const order = ['null', 'submit', 'cancel'];
              const indexA = order.indexOf(a.stats);
              const indexB = order.indexOf(b.stats);

              // Ensure null values come before non-null values
              if (indexA === -1 && indexB !== -1) return -1;
              if (indexA !== -1 && indexB === -1) return 1;

              // Sort based on the order array
              return indexA - indexB;
            })
          setList(newArr);
          localStorage.setItem('dataArray', JSON.stringify([]));
          localStorage.setItem('emp_id', '');
        } else {
          const sortedArray = dataArr
            ?.map((o) => {
              if (o.rowStatus === 0) {
                temp += Number(o.loadingAmount);
              }
              return { ...o, stats: o.rowStatus };
            })
            .slice()
            .sort((a, b) => {
              // Sort by "k" property values in the desired order: null, "submit", "cancel"
              const order = [0, 1, -1];
              // const order = ['null', 'submit', 'cancel'];
              const indexA = order.indexOf(a.stats);
              const indexB = order.indexOf(b.stats);

              // Ensure null values come before non-null values
              if (indexA === -1 && indexB !== -1) return -1;
              if (indexA !== -1 && indexB === -1) return 1;

              // Sort based on the order array
              return indexA - indexB;
            });
          setList(sortedArray);

          localStorage.setItem('dataArray', JSON.stringify(sortedArray));
        }
        setTotalLoadAmount(temp);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        setIsLoading(false);
      });
  }

  async function sendUpdatedOrder(payload) {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.updateRowOrder({ ...payload, dateS: date.toLocaleDateString('en-US') })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
        //   console.log(message, 'message');
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        setIsLoading(false);
        setOpenModalSavePermanent(false);
      });
  }

  let fieldDecimalScale = 0;
  let useThousandSeparator = true;

  const handleCharginTerminal = async (values) => {
    const {
      employeeId,
      sumOfTerminalDrawer,
      locationId,
      terminalNumber,
      manuallyCounted,
      drawer,
      batch,
      loadingAmount,
      companyId,
    } = values;
    const leadingBatch = terminalNumber?.slice(3);
    const payload = {
      batch,
      employeeId,
      locationId,
      terminalNumber,
      manuallyCounted,
      wasIndrawer: sumOfTerminalDrawer,
      amountemployeehave: remainingAmount,
      drawer,
      leadingBatch,
      companyId,
      // addBills: Number(loadingAmount),
      addBills: Number(loadingAmount) / 100,
    };
    setIsLoading(true);
    await EmpActivityService.chargeTerminal(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          // toast.error(message);
          throw message;
        }
        return response.data;
      })
      .then(async (res) => {
        if (res.status === 200) {
          const submitPayload = {
            employeeId,
            companyId,
            terminalNumber,
            dateS: date.toLocaleDateString('en-US'),
            rowStatus: 1,
          };
          await MachineService.manageCancelSubmitRowOrder(submitPayload).then((response) => {
            const { status, message } = response.data;
            if (status === 400) {
              throw message;
            }
            sortAfterSuccess({ ...values });
            toast.success(message);
          });
          setShowRemarkModal({ shown: false })
        }
      })
      .catch((err) => {
        return toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelTerminal = async (values) => {
    const { employeeId, terminalNumber, companyId } = values;

    const payload = {
      employeeId,
      companyId,
      terminalNumber,
      dateS: date.toLocaleDateString('en-US'),
      rowStatus: -1,
    };

    setIsLoading(true);
    await MachineService.manageCancelSubmitRowOrder(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
        sortAfterSuccess({ ...values });
        toast.success(message);
        // toggleModal(false);
        setShowRemarkModal({ shown: false })
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sortAfterSuccess = (values) => {
    const listArray =
      JSON.parse(localStorage.getItem('dataArray')) && JSON.parse(localStorage.getItem('dataArray')).length > 0
        ? JSON.parse(localStorage.getItem('dataArray'))
        : list;
    const cancelledEntry = listArray.filter((entry) => entry.terminalNumber === values.terminalNumber);
    listArray.splice(listArray.indexOf(cancelledEntry[0]), 1);
    listArray.push({ ...values, stats: values.status === 1 ? 1 : values.status === 2 ? -1 : 0 });
    // listArray.push({ ...values, stats: values.status === 1 ? 'submit' : values.status === 2 ? 'cancel' : null });

    let loadingAmount = values.loadingAmount;
    if (listArray.length > 0 && values.status === 1) {
      let temp = 0;

      if (typeof loadingAmount === 'string') {
        loadingAmount = loadingAmount.replaceAll(',', '');
      }

      temp = Number(totalLoadAmount) - Number(loadingAmount);
      // let remain = temp - Number(amount);
      if (empId) {
        fetchRemainCash({ employeeId: empId });
      }
      setTotalLoadAmount(temp);
      // setRemainingAmount(remain);

      localStorage.setItem('remindAmount', temp);
    }
    const sortedArray = listArray.slice().sort((a, b) => {
      // Sort by "k" property values in the desired order: null, "submit", "cancel"
      const order = [0, 1, -1];
      // const order = ['null', 'submit', 'cancel'];
      const indexA = order.indexOf(a.stats);
      const indexB = order.indexOf(b.stats);

      // Ensure null values come before non-null values
      if (indexA === -1 && indexB !== -1) return -1;
      if (indexA !== -1 && indexB === -1) return 1;

      // Sort based on the order array
      return indexA - indexB;
    });
    localStorage.setItem('date', new Date().toDateString());
    localStorage.setItem('emp_id', empId);
    localStorage.setItem('dataArray', JSON.stringify(sortedArray));

    setList(sortedArray);
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const listArray =
      JSON.parse(localStorage.getItem('dataArray')) && JSON.parse(localStorage.getItem('dataArray')).length > 0
        ? JSON.parse(localStorage.getItem('dataArray'))
        : list;
    let loadingAmount = values.loadingAmount;
    if (listArray.length > 0 && values.status === 1) {
      if (typeof loadingAmount === 'string') {
        loadingAmount = loadingAmount.replaceAll(',', '');
      }
    }
    if (values.status === 1) {
      handleCharginTerminal({ ...values, loadingAmount });
    } else {
      handleCancelTerminal({ ...values });
    }
  };

  const handleDragEnd = async (result) => {
    const listArray =
      JSON.parse(localStorage.getItem('dataArray')) && JSON.parse(localStorage.getItem('dataArray')).length > 0
        ? JSON.parse(localStorage.getItem('dataArray'))
        : list;
    const { destination, source } = result;

    if (source && destination) {
      const removed = listArray.splice(source.index, 1);

      listArray.splice(destination.index, 0, removed[0]);

      let temp = [];
      // let lastValues = [];
      for (let i = 0; i < listArray.length; i++) {
        listArray[i]['index'] = i;
        if (!listArray[i]['status']) {
          // lastValues.push(listArray[i])
          temp.push(listArray[i]);
        } else {
          // temp.push(listArray[i])
        }
      }
      const sortedArray = listArray.slice().sort((a, b) => {
        // Sort by "k" property values in the desired order: null, "submit", "cancel"
        const order = [0, 1, -1];
        // const order = ['null', 'submit', 'cancel'];
        const indexA = order.indexOf(a.stats);
        const indexB = order.indexOf(b.stats);

        // Ensure null values come before non-null values
        if (indexA === -1 && indexB !== -1) return -1;
        if (indexA !== -1 && indexB === -1) return 1;

        // Sort based on the order array
        return indexA - indexB;
      });
      localStorage.setItem('dataArray', JSON.stringify(sortedArray));
      // localStorage.setItem('dataArray', JSON.stringify(listArray));
      localStorage.setItem('date', new Date().toDateString());
      localStorage.setItem('emp_id', empId);

      // const payload = {
      //   employeeId: empId,
      //   companyId,
      //   status: false,
      //   dateS: date.toLocaleDateString('en-US'),
      //   TemprorayRowOrder: listArray.map((o, i) => {
      //     return {
      //       locationId: o.locationId,
      //       terminalNumber: o.terminalNumber,
      //       rowOrder: i + 1,
      //       rowStatus: o.stats,
      //     };
      //   }),
      // };
      setList(sortedArray);
      // await MachineService.manageCancelSubmitRowOrder(payload).then((response) => {
      //   const { status, message } = response.data;

      //   if (status === 400) {
      //     toast.error(message);
      //     return;
      //   }
      //   setList(listArray);
      //   toast.success(message);
      //   // toggleModal(false);
      // });
    }
  };

  const saveListOrder = () => {
    const storeList = JSON.parse(localStorage.getItem('dataArray'));
    let PermanentrowOrder = [];

    storeList.forEach((entry, index) => {
      PermanentrowOrder.push({
        terminalNumber: entry.terminalNumber,
        rowOrder: index + 1,
      });
    });

    let requestObj = {
      companyId: companyId,
      employeeId: empId,
      PermanentrowOrder,
    };

    sendUpdatedOrder(requestObj);
  };
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        // validationSchema={ValidationSchema}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row className="formRow">
              <Col sm={5}>
                <div className="formColumn mb-3">
                  <label className="me-2">{t(`LOADING_TERMINAL.EMPLOYEE`)}</label>
                  <ReactSelect
                    name={'employee'}
                    value={empList.filter(({ value }) => value === parseInt(empId))}
                    options={empList}
                    className="react-select"
                    onChange={(option) => {
                      setOpenModalSavePermanent(false);
                      setFieldValue('employee', option?.value);
                      setEmpId(option?.value);
                    }}
                    onBlur={() => setFieldTouched('employee', true)}
                    placeholder={`-- ${t('LOADING_TERMINAL.EMPLOYEE')} --`}
                    noOptionsMessage={() => t('RSEL_NO_OPT')}
                    isDisabled={roleId !== USER_ROLE.COMPANY_ADMIN}
                  />
                </div>
              </Col>
              <Col sm={5}>
                <div className="formColumn mb-3">
                  <label className="datePickerLabel me-2">{t('EMPLOYEE_LOADS.DATE')}</label>
                  <ReactDatePicker
                    name="date"
                    className="form-control w-50"
                    selected={date}
                    onChange={(dateArr) => setDate(dateArr)}
                    dateFormat={'dd/MM/yyyy'}
                    shouldCloseOnSelect={true}
                    highlightDates={[new Date()]}
                  />
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-end .d-sm-flex justify-content-start">
                  <Button id={`refresh`} className="h-5" color="primary" type="button" onClick={() => fetchRecords()}>
                    <UncontrolledTooltip placement="right" target={`refresh`}>
                      {t('EMPLOYEE_LOADS.REFRESH_BTN')}
                    </UncontrolledTooltip>
                    <i className="ri-refresh-line"></i>
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="dataMin employeeLoads">
              <Table
                className="fixTh dataTable"
                striped
                bordered
                responsive
                size="sm"
                style={{ minWidth: '650px', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr className="TableRow dropable">
                    <SortableHeader headers={HeaderList} isDisabled={list.length === 0} sortClick={sortBy} />
                  </tr>
                </thead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided, snapshot) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        onMouseLeave={(e) => {
                          // const tr = document.getElementById(disableTranform);
                          // console.log(tr, 'tr');
                          // if (tr) {
                          //     tr.style = { 'tranform': 'none', 'transition': 'none' }
                          //     tr.setAttribute('dragable',false)
                          // }
                        }}
                      >
                        {list?.map((item, index) => {
                          return (
                            <Draggable
                              isDragDisabled={item?.stats !== 0 ? true : false}
                              draggableId={item.terminalNumber}
                              index={index}
                              key={item.terminalNumber}
                            >
                              {(provided, snapshot) => (
                                <tr
                                  id={item.terminalNumber}
                                  // onMouseMoveCapture={(e) =>
                                  //     setDisableTranform(e.target.id)
                                  // }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className={`TableRow ${item?.stats === 1 || item?.stats === -1 ? 'disabledRow' : ''}`}
                                  key={item.terminalNumber}
                                >
                                  <td id={item.terminalNumber} style={{ width: '4%' }}>
                                    <img
                                      {...provided.dragHandleProps}
                                      src={dragHandler}
                                      height={'20px'}
                                      width={'20px'}
                                      alt=""
                                    />
                                  </td>
                                  <td className="tableSbtBtn">
                                    <Button
                                      disabled={item?.stats === 1 || item?.stats === -1 ? true : false}
                                      color={`${item?.stats === 1 ? 'success' : 'primary'} `}
                                      // color={`${item?.status !== 1 || item?.stats === 1 ? 'primary' : 'success'} `}
                                      className=" tableBtn"
                                      onClick={() => {
                                        item.loadingAmount >= 0
                                          ? setShowRemarkModal({ shown: true, values: { ...item, index, date } })
                                          : setCompConf((pre) => {
                                              return {
                                                ...pre,
                                                shown: true,
                                              };
                                            });
                                      }}
                                    >
                                      {body ? <i className="ri-check-line"></i> : item?.stats === 0 || item?.stats === -1
                                        ? t(`LOAD_REMARKS.SUBMIT_BTN`)
                                        : t(`EMPLOYEE_LOADS.DONE_BTN`) 
                                     }
                                    </Button>
                                  </td>
                                  <td className="text-wrap" style={{ width: '120px' }}>
                                    {item.locationName}
                                  </td>

                                  <td className="" style={{ width: '120px' }} dir="ltr">
                                    {item.loadingAmount?.toLocaleString('en-US')}
                                  </td>

                                  <td className="" style={{ width: '120px' }}>
                                    {item.remark}
                                  </td>

                                  <td className="tableCnlBtn">
                                    <Button
                                      disabled={item?.stats === 1 || item?.stats === -1 ? true : false}
                                      color="danger"
                                      className=" tableBtn"
                                      onClick={() => setShowLoadingCancelModal({ shown: true, value: item })}
                                    >
                                      {item?.status === -1 || item?.stats === -1
                                        ? t(`EMPLOYEE_LOADS.CANCELED_BTN`)
                                        : t(`EMPLOYEE_LOADS.CANCEL_BTN`)}
                                    </Button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        {(isLoading || list.length === 0) && <NoRecords colSpan={list.length + 1} />}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </div>

            <Row className="TotalCharging">
              {role !== USER_ROLE.EMPLOYEE && <Col sm={3}>
                <div className="formColumn">
                  <label className="">{t(`EMPLOYEE_LOADS.TOTAL_CHARGING`)}</label>
                  <NumericFormat
                    name={'amount'}
                    className="form-control "
                    value={amount}
                    allowNegative={false}
                    thousandSeparator={useThousandSeparator}
                    decimalScale={fieldDecimalScale}
                    {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                    disabled={true}
                    onBlur={() => setFieldTouched('amount', true)}
                    autoComplete="off"
                  />
                </div>
              </Col>}
              <Col sm={3}>
                <div className={`formColumn ${role !== USER_ROLE.EMPLOYEE ? 'emp-col' : ''}`}>
                  <label>{t(`EMPLOYEE_LOADS.REMAINING_AMOUNT_TO_EMPLOYEE`)}</label>
                  {/* remaining amount to employee */}
                  <NumericFormat
                    name={'remaining'}
                    className="form-control"
                    value={remainingAmount}
                    allowNegative={false}
                    thousandSeparator={useThousandSeparator}
                    decimalScale={fieldDecimalScale}
                    {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                    disabled={true}
                    onBlur={() => setFieldTouched('remaining', true)}
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className={`formColumn ${role !== USER_ROLE.EMPLOYEE ? 'emp-col' : ''}`}>
                  <label>{t(`EMPLOYEE_LOADS.REMIND_CHARGING`)}</label>
                  <NumericFormat
                    name={'totalLoadAmount'}
                    className="form-control"
                    value={totalLoadAmount}
                    allowNegative={false}
                    thousandSeparator={useThousandSeparator}
                    decimalScale={fieldDecimalScale}
                    {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                    onBlur={() => setFieldTouched('totalLoadAmount', true)}
                    autoComplete="off"
                    disabled={true}
                  />
                </div>
              </Col>
              <Col sm={role !== USER_ROLE.EMPLOYEE ? 1 : 3} className={'d-flex justify-content-center'}>
                <div className="d-flex justify-content-evenly">
                  <div className='d-flex'>
                    <Input
                      type="checkbox"
                      className="me-2"
                      id="savePermanent"
                      checked={openModalSavePermanent}
                      onChange={() => setOpenModalSavePermanent(!openModalSavePermanent)}
                    />
                    <label htmlFor="savePermanent">{t(`EMPLOYEE_LOADS.SAVE_PERMANENT_LABEL`)}</label>{' '}
                  </div>
                </div>
                {/* <div className="d-flex"> */}
                {/* </div> */}
              </Col>
              <Col sm={role !== USER_ROLE.EMPLOYEE ? 2 : 3} className={`${role !== USER_ROLE.EMPLOYEE ? 'd-flex h-50 justify-content-end' : ''}`}>
                  <Button type="button"  color="secondary" onClick={() => listFn(1)}>
                    {t(`EMPLOYEE_LOADS.RERENDERORDER`)}
                  </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      {showRemarkModal.shown && (
        <EmployeeRemarkModal
          isOpen={showRemarkModal.shown}
          setIsOpen={setShowRemarkModal}
          values={showRemarkModal.values}
          handleSubmit={handleSubmit}
          loading={isLoading}
          setLoading={setIsLoading}
        />
      )}

      {showLoadingCancelModal.shown && (
        <EmployeeCancelLoadModal
          isOpen={showLoadingCancelModal.shown}
          setIsOpen={setShowLoadingCancelModal}
          values={showLoadingCancelModal.value}
          handleSubmit={handleSubmit}
        />
      )}

      {openModalSavePermanent && (
        <Modal isOpen={openModalSavePermanent} backdrop="static" className="modal-sm modal-dialog-centered">
          <ModalBody>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img src={warning} alt="" height="50" />
              <h3 className="mx-2 mb-0">{t(`EMPLOYEE_LOADS.SAVE_PERMANENT_MODAL_MSG`)}</h3>
            </div>

            <br className="d-none d-sm-block" />
            <br />
            <div className="d-flex justify-content-end gap-2">
              <Button color="primary" className="waves-effect me-1" onClick={saveListOrder}>
                {t('EMPLOYEE_LOADS.SAVE')}
              </Button>
              <Button className="waves-effect me-1" onClick={() => setOpenModalSavePermanent(false)}>
                {t(`EMPLOYEE_LOADS.CANCEL_BTN`)}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <AlertModal {...compConf} />
    </>
  );
};

export default EmployeeLoadsList;
