import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Breadcrumbs from "../../../components/Breadcrumb";
import LoadingOverlay from "../../../components/LoadingOverlay";

function Users() {
  const { t } = useTranslation();
  const { showLoadingOverlay } = useSelector((state) => state.LoadingOverlay);

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: t("ACCOUNT.TITLE"), link: "#" },
    { title: t("USERS_TITLE"), link: "#" },
  ]);

  const setCrumbItem = (crumbItems) => {
    setBreadcrumbItems(crumbItems);
  };

  return (
    <React.Fragment>
      <Breadcrumbs title={t("USERS_TITLE")} breadcrumbItems={breadcrumbItems} />

      <Card className="card-box">
        {showLoadingOverlay && <LoadingOverlay />}

        <CardBody>
          <Outlet context={[setCrumbItem]} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default Users;
