/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import EmployeeService from '../../services/employee';
import MachineService from '../../services/machines';
import LocationService from '../../services/location';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import SelectField from '../../components/SelectField';

const InitialValues = {
  locationName: '',
  terminalNumber: '',
  employeeId: 0,
  city: '',
  street: '',
  agentId: '',
  entry: '',
  houseNumber: '',
  zip: '',
  remark: '',
};

const ValidationSchema = Yup.object().shape({
  locationName: Yup.string().required(t('REQUIRED')).trim(),
  city: Yup.string().required(t('REQUIRED')).trim(),
  street: Yup.string().required(t('REQUIRED')).trim(),
  agentId: Yup.string().required(t('REQUIRED')).trim(),
  houseNumber: Yup.string().required(t('REQUIRED')).trim(),
});

let companyId = 0;

function UpdateLocation({ formData }) {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locFormRef = useRef();

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [roleID, setRoleID] = useState('');
  const [machineData, setMachineData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [agent, setAgent] = useState([]);
  const [confLocChange, setConfLocChange] = useState({ shown: false });
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    setCrumbItem([
      { title: t('LOCATION.TITLE'), link: 'list' },
      { title: t('UPDATE_TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);

    const roleId = LocalstorageService.getItem(JobRole);
    setRoleID(roleId);

    getMachine();
    getEmployee();
    getAgent();
  }, []);

  useEffect(() => {
    if (initApiCount === 3) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  async function getMachine() {
    dispatch(setLoadingOverlay(true));
    await MachineService.listMachines({ companyId, allList: true })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.terminalNumber,
            label: item.terminalNumber,
            value: item.terminalNumber,
          }));

          setMachineData(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getEmployee() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ companyId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.firstName,
            value: item.id,
          }));

          setEmployee(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getAgent() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listAgent({
      companyId,
    })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.firstName,
            value: item.id,
          }));

          setAgent(data);
        }
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      agentId: values.agentId ? parseInt(values.agentId) : 0,
      employeeId: values.employeeId ? parseInt(values.employeeId) : 0,
      companyId,
    };

    setIsLoading(true);
    await LocationService.updateLocation(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        } else if (status === 201) {
          setConfLocChange({
            shown: true,
            message,
          });
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
        confLocChange.shown && setConfLocChange({ shown: false });
        setShowEditForm(false);
      });
  };

  const confirmLocChangeFn = () => {
    locFormRef.current.setFieldValue('wantToChange', true);
    locFormRef.current.submitForm();
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Formik
        innerRef={locFormRef}
        initialValues={{ ...InitialValues, ...formData }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Business Name */}
              <Col sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="locationName"
                    label={t('LOCATION.BUSINESS_NAME_LABEL')}
                    placeholder={t('LOCATION.BUSINESS_NAME_PLACEHOLDER')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Machine Number */}
              <Col sm={4}>
                <div className="mb-3">
                  <SelectField
                    fieldName="terminalNumber"
                    fieldLabel={t('MACHINE.NUMBER')}
                    fieldValue={values.terminalNumber}
                    fieldOptions={machineData}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Choose Employee */}
              <Col sm={4}>
                <div className="mb-3">
                  <SelectField
                    fieldName="employeeId"
                    fieldLabel={t('ROLES.EMPLOYEE')}
                    fieldValue={values.employeeId}
                    fieldOptions={employee}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabledOptVal={0}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* City */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="city"
                    label={t('EMPLOYEE.CITY_LABEL')}
                    placeholder={t('EMPLOYEE.CITY_PLACEHOLDER')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Street */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="street"
                    label={t('EMPLOYEE.STREET_LABEL')}
                    placeholder={t('EMPLOYEE.STREET_PLACEHOLDER')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Agent */}
              {roleID !== USER_ROLE.AGENT && (
                <Col sm={4}>
                  <div className="mb-3">
                    <SelectField
                      fieldName="agentId"
                      fieldLabel={t('ROLES.AGENT')}
                      fieldValue={values.agentId}
                      fieldOptions={agent}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isRequired={true}
                      isDisabled={!showEditForm}
                    />
                  </div>
                </Col>
              )}

              {/* Entry */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="entry"
                    label={t('LOCATION.ENTRY_LABEL')}
                    placeholder={t('LOCATION.ENTRY_PLACEHOLDER')}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* House Number */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="houseNumber"
                    label={t('LOCATION.HOUSE_NUMBER_LABEL')}
                    placeholder={t('LOCATION.HOUSE_NUMBER_PLACEHOLDER')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Zip code */}
              <Col xs={6} sm={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="zip"
                    label={t('EMPLOYEE.ZIPCODE_LABEL')}
                    placeholder={t('EMPLOYEE.ZIPCODE_PLACEHOLDER')}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Remarks */}
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="remarksField">{t('EMPLOYEE.REMARKS_LABEL')}</Label>

                  <Field
                    name="remark"
                    component="textarea"
                    className="form-control"
                    placeholder={t('EMPLOYEE.REMARKS_PLACEHOLDER')}
                    disabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>

      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}

      <Modal isOpen={confLocChange.shown} backdrop="static" className="modal-dialog-centered">
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <h3 className="mx-2 mb-0">{confLocChange.message}</h3>
          </div>

          <br className="d-none d-sm-block" />
          <br />
          <div className="d-flex justify-content-end gap-2">
            <Button color="primary" onClick={() => setConfLocChange({ shown: false })}>
              {t('CANCEL')}
            </Button>

            <Button color="link" className="waves-effect me-1" onClick={confirmLocChangeFn} disabled={isLoading}>
              {isLoading ? t('PLEASE_WAIT') : t('CHANGE')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default UpdateLocation;
