import { post } from "../AxiosConfig";

const URI = "/bank";

const listBanks = (payload) => {
  const URL = `${URI}/list-bank`;
  return post(URL, payload);
};

const createBank = (payload) => {
  const URL = `${URI}/create-bank`;
  return post(URL, payload);
};

const updateBank = (payload) => {
  const URL = `${URI}/update-bank`;
  return post(URL, payload);
};

const deleteBank = (payload) => {
  const URL = `${URI}/delete-bank`;
  return post(URL, payload);
};

const BankService = {
  listBanks,
  createBank,
  updateBank,
  deleteBank,
};

export default BankService;
