/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, Col, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CompanyId, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import EmployeeService from '../../services/employee';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import Transfer from './Transfer.component';

let companyId = 0,
  roleId = USER_ROLE.EMPLOYEE;

const NoLocations = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <label className="fw-bold fs-5">-- {t('NO_LOCATIONS')} --</label>
    </div>
  );
};

const AffilationToLocation = ({ formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isRtlLayout } = useSelector((state) => state.Layout);
  const params = useParams();

  const [initApiCount, setInitApiCount] = useState(0);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [isSelList1, setIsSelList1] = useState(true);
  const [isSelList2, setIsSelList2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [checkList, setCheckList] = useState([]);

  useEffect(() => {
    companyId = LocalstorageService.getItem(CompanyId);
    roleId = LocalstorageService.getItem(JobRole);

    getAssociateData();
    getNoAssociateData();
  }, []);

  useEffect(() => {
    if (initApiCount === 2) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  async function getNoAssociateData() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listNotAssociate({
      companyId,
      employeeId: params.employeeId,
    })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setList1(dataList);
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getAssociateData() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listAssociate({
      companyId,
      employeeId: params.employeeId,
    })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        setList2(dataList);
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  const handleSubmit = async () => {
    const payload = {
      employeeId: parseInt(params.employeeId),
      companyId,
      locationDetails: checkList,
    };

    setIsLoading(true);
    await EmployeeService.empAffiliateLocation(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const moveToInBetweenLoc = (isLtr = true) => {
    const newList = isLtr ? [...list1] : [...list2];
    const remList = [];
    const tempList = [];

    newList.forEach((item) => {
      if (item.isChecked) {
        item.isChecked = false;
        tempList.push(item);
        setCheckList(tempList);
      } else {
        remList.push(item);
      }
    });

    if (isLtr) {
      setList1(remList);
      setList2([...list2, ...tempList]);
      setIsSelList1(remList.length === 0 || tempList.length > 0);
      setIsSelList2(remList.length === 0);
    } else {
      setList2(remList);
      setList1([...list1, ...tempList]);
      setIsSelList2(remList.length === 0 || tempList.length > 0);
      setIsSelList1(remList.length === 0);
    }
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={5} lg={4}>
          <Label className="fw-bold fs-5">{t('EMPLOYEE.NO_ASSC_LOC')}</Label>
        </Col>

        <Col sm={2} />

        <Col className="d-none d-sm-block" sm={5} lg={4}>
          <Label className="fw-bold fs-5">{t('EMPLOYEE.ASSC_LOC_TO_EMP')}</Label>
        </Col>
      </Row>

      <Row id="emp-loc-aff">
        <Col sm={5} lg={4}>
          <Card className="loc-box" outline>
            {list1.length > 0 ? (
              <Transfer
                checkAllId="noAssocCheckAll"
                dataList={list1}
                setSelected={(v) => setIsSelList1(v)}
                isDisabled={!showEditForm}
              />
            ) : (
              <NoLocations />
            )}
          </Card>
        </Col>

        <Col sm={2} className="move-btn mb-4 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => moveToInBetweenLoc()}
            // disabled={isSelList1}
          >
            {isRtlLayout ? (
              <React.Fragment>
                <i className="d-none d-sm-block ri-arrow-left-s-line fs-5"></i>
                <i className="d-block d-sm-none ri-arrow-down-s-line fs-5"></i>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i className="d-none d-sm-block ri-arrow-right-s-line fs-5"></i>
                <i className="d-block d-sm-none ri-arrow-down-s-line fs-5"></i>
              </React.Fragment>
            )}
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={() => moveToInBetweenLoc(false)}
            // disabled={isSelList2}
          >
            {isRtlLayout ? (
              <React.Fragment>
                <i className="d-none d-sm-block ri-arrow-right-s-line fs-5"></i>
                <i className="d-block d-sm-none ri-arrow-up-s-line fs-5"></i>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i className="d-none d-sm-block ri-arrow-left-s-line fs-5"></i>
                <i className="d-block d-sm-none ri-arrow-up-s-line fs-5"></i>
              </React.Fragment>
            )}
          </Button>
        </Col>

        <Col className="d-block d-sm-none" sm={5} lg={4}>
          <Label className="fw-bold fs-5">{t('EMPLOYEE.ASSC_LOC_TO_EMP')}</Label>
        </Col>

        <Col sm={5} lg={4}>
          <Card className="loc-box" outline>
            {list2.length > 0 ? (
              <Transfer
                checkAllId="assocCheckAll"
                dataList={list2}
                setSelected={(v) => setIsSelList2(v)}
                isDisabled={!showEditForm}
              />
            ) : (
              <NoLocations />
            )}
          </Card>
        </Col>
      </Row>

      {/* Submit Button */}

      {showEditForm && (
        <div className="button-items">
          <Button color="primary" className="w-md waves-effect waves-light" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
          </Button>
          <Button
            color="light"
            type="button"
            onClick={() => {
              setShowEditForm(false);
            }}
          >
            {t('CANCEL')}
          </Button>
        </div>
      )}
      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default AffilationToLocation;
