/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { EmailSchema, PhoneNumberSchema } from '../../helpers/yup-schema';
import BranchService from '../../services/branch';
import EmployeeService from '../../services/employee';

import CommonField from '../../components/CommonField';
import UpsertHeader from '../../components/UpsertHeader';
import PhoneNumber from '../../components/PhoneNumber';

const InitialValues = {
  firstName: '',
  lastName: '',
  // nickName: "",
  // city: "",
  // street: "",
  // streetNumber: "",
  // zipcode: "",
  mail: '',
  // prefixMobile: "",
  phoneNumber: '',
  // prefixPhone: "",
  // phone: "",
  // bankId: "",
  // bankBranchId: "",
  // branchName: "",
  // accountNumber: "",
  // amount: "",
  // explanation: "",
};

const ValidationSchema = EmailSchema('mail')
  .concat(PhoneNumberSchema())
  .shape({
    firstName: Yup.string().required(t('REQUIRED')).trim(),
    lastName: Yup.string().required(t('REQUIRED')).trim(),
    // city: Yup.string().required(t("REQUIRED")).trim(),
    // street: Yup.string().required(t("REQUIRED")).trim(),
    // streetNumber: Yup.string().required(t("REQUIRED")).trim(),
    // prefixMobile: Yup.string().required(t("REQUIRED")),
    // bankId: Yup.string().required(t("REQUIRED")),
    // bankBranchId: Yup.string().required(t("REQUIRED")),
    // accountNumber: Yup.string().required(t("REQUIRED")),
    // amount: Yup.string().required(t("REQUIRED")),
  });

function AddEmployee() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    setCrumbItem([
      { title: t('EMPLOYEE.TITLE'), link: 'list' },
      { title: t('ADD_TITLE'), link: '#' },
    ]);

    bankListFn();
  }, []);

  async function bankListFn() {
    await BranchService.getAllBanks()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        setBankList(dataList || []);
      })
      .finally(() => {});
  }

  async function branchListFn(bankId) {
    await BranchService.getAllBranches({ bankId })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        setBranchList(dataList || []);
      })
      .finally(() => {});
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    setIsLoading(true);
    await EmployeeService.createEmployee({ ...values })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('EMPLOYEE.ADD')} />

      <Formik initialValues={InitialValues} validationSchema={ValidationSchema} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue, setFieldError }) => (
          <Form>
            <Row>
              {/* First Name */}
              <Col lg={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="firstName"
                    label="FIRST_NAME_LABEL"
                    placeholder="FIRST_NAME_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col>

              {/* Last Name */}
              <Col lg={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="lastName"
                    label="LAST_NAME_LABEL"
                    placeholder="LAST_NAME_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col>

              {/* Nick Name */}
              {/* <Col lg={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="nickName"
                    label="NICK_NAME_LABEL"
                    placeholder="NICK_NAME_PLACEHOLDER"
                  />
                </div>
              </Col> */}
            </Row>

            <Row>
              {/* City */}
              {/* <Col lg={3}>
                <div className="mb-4">
                  <CommonField
                    fieldName="city"
                    label="EMPLOYEE.CITY_LABEL"
                    placeholder="EMPLOYEE.CITY_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col> */}

              {/* Street No */}
              {/* <Col lg={3}>
                <div className="mb-4">
                  <CommonField
                    fieldName="streetNumber"
                    label="EMPLOYEE.STREET_NO_LABEL"
                    placeholder="EMPLOYEE.STREET_NO_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col> */}

              {/* Street */}
              {/* <Col lg={3}>
                <div className="mb-4">
                  <CommonField
                    fieldName="street"
                    label="EMPLOYEE.STREET_LABEL"
                    placeholder="EMPLOYEE.STREET_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col> */}

              {/* Zip Code */}
              {/* <Col lg={3}>
                <div className="mb-4">
                  <CommonField
                    fieldName="zipcode"
                    label="EMPLOYEE.ZIPCODE_LABEL"
                    placeholder="EMPLOYEE.ZIPCODE_PLACEHOLDER"
                  />
                </div>
              </Col> */}
            </Row>

            <Row>
              {/* Email */}
              <Col lg={4}>
                <div className="mb-4">
                  <CommonField fieldName="mail" label="EMAIL_LABEL" placeholder="EMAIL_PLACEHOLDER" required={true} />
                </div>
              </Col>

              {/* Prefix Mobile */}
              {/* <Col lg={1}>
                <div className="mb-4">
                  <PhonePrefixField fieldName="prefixMobile" />
                </div>
              </Col> */}

              {/* Mobile */}
              <Col lg={4}>
                <div className="mb-4">
                  <PhoneNumber fieldName="phoneNumber" fieldLabel={t('MOBILE_NO_LABEL')} required={true} />
                </div>
              </Col>

              {/* Prefix Phone */}
              {/* <Col lg={1}>
                <div className="mb-4">
                  <PhonePrefixField />
                </div>
              </Col> */}

              {/* Phone */}
              {/* <Col lg={3}>
                <div className="mb-4">
                  <PhoneNumber fieldName="phone" />
                </div>
              </Col> */}
            </Row>

            <Row>
              {/* Bank */}
              {/* <Col lg={4}>
                <div className="mb-4">
                  <FormGroup>
                    <label htmlFor="bankField">*{t("BANK.TITLE")}</label>

                    <Field
                      component="select"
                      id="bankField"
                      name="bankId"
                      className="form-control"
                      value={values.bankId}
                      onChange={(e) => {
                        setFieldValue("bankId", e.target.value);
                        setFieldValue("bankBranchId", "");
                        branchListFn(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        -- Select Bank --
                      </option>

                      {bankList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.bankName}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="bankId"
                      className="text-danger"
                      component="div"
                    />
                  </FormGroup>
                </div>
              </Col> */}

              {/* Branch Name*/}
              {/* <Col lg={4}>
                <div className="mb-4">
                  <FormGroup>
                    <label htmlFor="branchField">
                      *{t("BRANCH.NAME_LABEL")}
                    </label>

                    <Field
                      component="select"
                      id="branchField"
                      name="bankBranchId"
                      className="form-control"
                    >
                      <option value="" disabled>
                        -- Select Branch --
                      </option>

                      {branchList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.branchName}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="bankBranchId"
                      className="text-danger"
                      component="div"
                    />
                  </FormGroup>
                </div>
              </Col> */}
            </Row>

            <Row>
              {/* Account Number */}
              {/* <Col lg={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="accountNumber"
                    label="EMPLOYEE.ACC_NO_LABEL"
                    placeholder="EMPLOYEE.ACC_NO_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col> */}

              {/* Maximum Withdrawal */}
              {/* <Col lg={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="amount"
                    label="EMPLOYEE.MAX_WITHDRAWAL_LABEL"
                    placeholder="EMPLOYEE.MAX_WITHDRAWAL_PLACEHOLDER"
                    required={true}
                  />
                </div>
              </Col> */}
            </Row>

            <Row>
              {/* Remarks */}
              {/* <Col lg={6}>
                <div className="mb-4">
                  <Label htmlFor="remarksField">
                    {t("EMPLOYEE.REMARKS_LABEL")}
                  </Label>

                  <textarea
                    name="explanation"
                    id="remarksField"
                    className="form-control"
                    defaultValue={values.explanation}
                    placeholder={t("EMPLOYEE.REMARKS_PLACEHOLDER")}
                  />
                </div>
              </Col> */}
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default AddEmployee;
