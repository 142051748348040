/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import { omit, pick } from 'lodash';

import { USER_ROLES } from '../../../constants';
import AccountService from '../../../services/account';
import { PhoneNumberSchema } from '../../../helpers/yup-schema';

import { setLoadingOverlay } from '../../../store/slices/loadingOverlaySlice';

import CommonField from '../../../components/CommonField';
import OtpModal from '../../../components/OtpModal';
import PhoneNumber from '../../../components/PhoneNumber';
import UpsertHeader from '../../../components/UpsertHeader';
import SelectField from '../../../components/SelectField';

const InitialValues = {
  firstName: '',
  lastName: '',
  knownAs: '',
  userName: '',
  mail: '',
  roleId: '',
  companyId: '',
  phoneNumber: '',
};

const UpdateUserSchema = PhoneNumberSchema().shape({
  firstName: Yup.string().required(t('REQUIRED')).trim(),
});

function UpdateUser() {
  const [setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [initApiCount, setInitApiCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(InitialValues);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [userRoles, setUserRoles] = useState(USER_ROLES);
  const [companyData, setCompanyData] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    setCrumbItem([
      { title: t('ACCOUNT.TITLE'), link: '#' },
      { title: t('USERS_TITLE'), link: 'list' },
      { title: t('UPDATE_TITLE'), link: '#' },
    ]);

    setUserRoles(USER_ROLES.map((item) => ({ ...item, label: t(`ROLES.${item.id}`) })));

    getUserData();
    getCompanyName();
  }, []);

  useEffect(() => {
    if (initApiCount === 2) {
      dispatch(setLoadingOverlay(false));
    }
  }, [initApiCount]);

  async function getUserData() {
    dispatch(setLoadingOverlay(true));
    await AccountService.listUsers({ id: params.userId })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        const paylaod = pick(data, ['id', 'confirmedMail', 'confirmedPhone', ...Object.keys(InitialValues)]);
        setFormData(paylaod);
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  async function getCompanyName() {
    dispatch(setLoadingOverlay(true));
    await AccountService.getCompanyData()
      .then((response) => {
        const { dataList, status } = response.data;

        if (status === 400) {
          return;
        }

        const data = dataList.map((item) => ({
          id: item.id,
          label: item.companyName,
          value: item.id,
        }));
        setCompanyData(data);
      })
      .finally(() => {
        setInitApiCount((prev) => prev + 1);
      });
  }

  const toggleOtpModal = () => setOtpModalOpen(!otpModalOpen);

  const resendOtp = () => {
    console.log('resending....');
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const payload = omit(values, ['userName', 'mail', 'companyId', 'confirmedMail', 'confirmedPhone']);

    payload.phoneNumber = payload.phoneNumber.replace(/-|_/g, '');

    setIsLoading(true);
    await AccountService.updateUser(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('../list');
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });

    // TODO:
    // toggleOtpModal();
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <UpsertHeader headerTitle={t('USERS.UPDATE_USER')} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={UpdateUserSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
              {/* First Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="firstName"
                    label="FIRST_NAME_LABEL"
                    placeholder="FIRST_NAME_PLACEHOLDER"
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Last Name */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="lastName"
                    label="LAST_NAME_LABEL"
                    placeholder="LAST_NAME_PLACEHOLDER"
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Known As */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="knownAs"
                    label="KNOWN_AS_LABEL"
                    placeholder="KNOWN_AS_PLACEHOLDER"
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>

              {/* Username */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="userName"
                    label="USERNAME_LABEL"
                    placeholder="USERNAME_PLACEHOLDER"
                    isDisabled={true}
                  />
                </div>
              </Col>

              {/* Email */}
              <Col sm={4}>
                <div className="mb-4">
                  <CommonField fieldName="mail" fieldType="email" label="EMAIL_LABEL" isDisabled={true} />
                </div>
              </Col>

              {/* Phone No */}
              <Col sm={4}>
                <div className="mb-4">
                  <PhoneNumber showVerification={false} required={true} isDisabled={!showEditForm} />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Job Role */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="roleId"
                    fieldLabel={t('JOB_ROLE')}
                    fieldValue={values.roleId}
                    fieldOptions={userRoles}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={true}
                    isRequired={true}
                  />
                </div>
              </Col>

              {/* Company */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="companyId"
                    fieldLabel={t('COMPANY.NAME_LABEL')}
                    fieldValue={values.companyId}
                    fieldOptions={companyData}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isDisabled={true}
                    isRequired={true}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}

      <OtpModal isOpen={otpModalOpen} toggleOtpModal={toggleOtpModal} resendOtp={resendOtp} />
    </React.Fragment>
  );
}

export default UpdateUser;
