/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
/* eslint eqeqeq: "off" */
import { Field, Form, Formik, setNestedObjectValues } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Col, Row, Button, Modal, ModalBody, ModalHeader, Label, Input, UncontrolledTooltip } from 'reactstrap';
import { t } from 'i18next';
import ReactSelect from 'react-select';
import AddRemarkModal from './RemarkModal/AddRemarkModal';
import MachineService from '../services/machines';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from '../store/slices/loadingOverlaySlice';
import edit from '../assets/images/edit.svg';
import deletes from '../assets/images/delete.svg';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import ConfirmBatchNumber from '../pages/EmployeeActivity/ConfirmBatchNumber';
import EmpActivityService from '../services/emp_activity';
import ConfirmDelete from '../pages/EmployeeActivity/ConfirmDelete';
const drawers = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
];

const EmployeeRemarkModal = ({ isOpen, setIsOpen, values, handleSubmit, loading, setLoading }) => {
  const [modalValues, setModalValues] = useState({ ...values, drawer: 1, batch: '0000', sumOfTerminalDrawer: '0' });
  const [selectedDrawer, setSelectedDrawer] = useState([{ label: 1, value: 1 }]);
  const [remarkList, setRemarkList] = useState([]);
  const [delecteId, setDeleteId] = useState({ remarkId: '', remark: '' });
  // const [loading, setLoading] = useState(false);
  const sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
  const [noEndCheckbox, setNoEndCheckbox] = useState(false);
  const [dateForModal, setDateForModal] = useState({ start: sevenDaysBeforeDate, end: new Date() });
  const [updateModal, setUpdateModal] = useState(false);
  const [remark, setRemark] = useState({ remarkId: '', remark: '' });
  const [confirmBatchNumberData, setConfirmBatchNumberData] = useState({});
  const [openBatchNumberModal, setOpenBatchNumberModal] = useState(false);
  const [confirmSaveData, setConfirmSaveData] = useState({});
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [remarkDetail, setRemarkDetail] = useState({
    remarkId: 0,
    rowStatus: 0,
    terminalNumber: modalValues.terminalNumber,
    remark: '',
    startDate: new Date(),
    endDate: '',
    noEndDate: false,
  });
  const [error, setError] = useState({
    errorRemark: false,
    start: false,
    selectAny: false,
    loadingAmount: false,
    batch: false,
    batchLength: false,
    drawer: false,
    sumOfTerminalDrawer: false,
  });
  const dispatch = useDispatch();
  const formRef = useRef();
  const dateRef = useRef();
  const dateRef2 = useRef();

  const getRemarks = () => {
    const reqPayload = {};

    if (!noEndCheckbox) {
      reqPayload['startDate'] = dateForModal.start;
      reqPayload['endDate'] = dateForModal.end;
    }
    reqPayload['terminalNumber'] = modalValues.terminalNumber;
    reqPayload['remarkId'] = 0;
    reqPayload['rowStatus'] = 0;
    fetchRemarks(reqPayload);
  };

  useEffect(() => {
    if (modalValues.terminalNumber) {
      getRemarks();
    }
  }, [dateForModal, noEndCheckbox, modalValues.terminalNumber]);
  const deleteRemarkById = (data) => {
    setDeleteId({ ...data });
    setDeleteModal(true);
  };
  async function fetchRemarks(reqPayload) {
    dispatch(setLoadingOverlay(true));
    await MachineService.getRemarks(reqPayload)
      .then((response) => {
        const { dataList } = response.data;
        setRemarkList(dataList);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function createRemarks(reqPayload) {
    dispatch(setLoadingOverlay(true));
    await MachineService.addRemarks(reqPayload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
        getRemarks();
      });
  }

  const checkingForError = () => {
    let error = {
      errorRemark: false,
      start: false,
      selectAny: false,
      loadingAmount: false,
      batch: false,
      batchLength: false,
      drawer: false,
      sumOfTerminalDrawer: false,
    };

    if (!remark.remark) {
      error['errorRemark'] = true;
    } else {
      error['errorRemark'] = false;
    }

    if (!remark.start) {
      error['start'] = true;
    } else {
      error['start'] = false;
    }

    if (!remark.end && !remark.noEnd) {
      error['selectAny'] = true;
    } else {
      error['selectAny'] = false;
    }

    if (!modalValues?.loadingAmount) {
      error['loadingAmount'] = true;
    } else {
      error['loadingAmount'] = false;
    }

    if (!modalValues?.batch) {
      error['batch'] = true;
    } else {
      error['batch'] = false;
    }

    if (modalValues?.batch?.length !== 4) {
      error['batchLength'] = true;
    } else {
      error['batchLength'] = false;
    }

    if (!modalValues?.drawer) {
      error['drawer'] = true;
    } else {
      error['drawer'] = false;
    }

    if (!modalValues?.sumOfTerminalDrawer) {
      error['sumOfTerminalDrawer'] = true;
    } else {
      error['sumOfTerminalDrawer'] = false;
    }

    setError({
      start: error?.start,
      errorRemark: error?.errorRemark,
      selectAny: error?.selectAny,
      loadingAmount: error?.loadingAmount,
      batch: error?.batch,
      batchLength: error.batchLength,
      drawer: error?.drawer,
      sumOfTerminalDrawer: error?.sumOfTerminalDrawer,
    });
    return error;
  };

  async function checkLastBatchNumber() {
    const { terminalNumber, batch } = modalValues;
    const leadingBatch = terminalNumber?.slice(3);
    setLoading(true)
    dispatch(setLoadingOverlay(true));
    await EmpActivityService.getLastBatchNumber({
      terminalNumber,
    })
      .then((response) => {
        const { status, data } = response.data;

        // if (status === 400) {
        //   return;
        // }
        if (data?.batchNumber && batch !== '0000') {
          const lastDigits = data?.batchNumber.slice(data?.batchNumber.length - 4);
          if (Number(batch) <= Number(lastDigits)) {
            setConfirmBatchNumberData({ batch, lastDigits, leadingBatch });
            setOpenBatchNumberModal(true);
          } else {
            handleModalSubmit();
          }
        } else {
          handleModalSubmit();
        }
      })
      
  }

  const handleModalSubmit = () => {
    const { loadingAmount, batch, drawer, sumOfTerminalDrawer, batchLength } = checkingForError();

    if (!loadingAmount && !batch && !drawer && !sumOfTerminalDrawer && !batchLength) {
      handleSubmit({ ...modalValues, status: 1 });
      // setIsOpen({ shown: false });
    }
  };

  const handleUpdateModal = (open, values = '') => {
    if (open) {
      setUpdateModal(open);
    }
    if (values && values.remarkId) {
      setRemarkDetail({
        ...values,
        rowStatus: 1,
        startDate: values.startDate ? new Date(values.startDate) : new Date(),
        endDate: values.endDate ? new Date(values.endDate) : '',
      });
    }
    // if (!values) {
    //   setUpdateModal(open);
    //   // setRemark({ remarkId: values.remarkId, remark: values.remark });
    // } else if (!values) {
    //   setUpdateModal(open);
    //   setRemarkDetail({
    //     ...values,
    //     startDate: new Date(values.startDate),
    //     endDate: new Date(values.endDate),
    //   });
    // }
  };

  async function confirmDetailsFn(updatedBatch) {
    const { values, setSubmitting, setTouched, validateForm } = formRef.current;
    setSubmitting(false);

    if (updatedBatch) {
      values['batch'] = updatedBatch;
    }
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }

    const amtEmpHave = values.amountemployeehave.toString().replace(/,/g, '');

    // const payload = {
    //     ...values,
    //     companyId,
    //     employeeId: parseInt(values.employeeId),
    //     amountemployeehave: parseFloat(amtEmpHave),
    //     locationId: parseInt(values.locationId),
    // };
  }

  function handleBatchNumberModal(isCancelled = true, updatedBatch = '') {
    if (updatedBatch) {
      if (!updatedBatch === '0000') {
        formRef.current.setFieldValue('batch', updatedBatch);
      }
      setOpenBatchNumberModal(false);
      handleModalSubmit();
    } else {
      setOpenBatchNumberModal(false);
    }
  }
  const deleteRemark = () => {
    const reqPayload = {};
    reqPayload['terminalNumber'] = modalValues.terminalNumber;
    reqPayload['remarkId'] = delecteId.remarkId;
    reqPayload['rowStatus'] = -1;
    reqPayload['remark'] = delecteId.remark;
    reqPayload['startDate'] = dateForModal.start;
    reqPayload['endDate'] = dateForModal.end;
    createRemarks(reqPayload);
    setDeleteModal(false);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleRemarkCancelAction = (form) => {
    form.resetForm();
    setRemarkDetail({
      remarkId: 0,
      rowStatus: 0,
      terminalNumber: modalValues.terminalNumber,
      remark: '',
      startDate: new Date(),
      endDate: '',
      noEndDate: false,
    });
    setUpdateModal(false);
  };
  let fieldDecimalScale = 0;
  let useThousandSeparator = true;
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} backdrop="static" className="modal-lg modal-dialog-centered">
        <ModalHeader toggle={() => setIsOpen({ shown: false })}>{t('EMPLOYEE_LOADS.MODAL_TITLE')}</ModalHeader>

        <ModalBody style={{}}>
          <Formik
            innerRef={formRef}
            initialValues={''}
            // validationSchema={ValidationSchema}
          >
            {({ values, setFieldValue, setFieldTouched }) => (
              <Form noValidate>
                <Row>
                  {/* <Col sm={5}> */}
                  <div className="gap-3 completeLoad">
                    <div className="">
                      <label className="">{t(`LOAD_TABS.BUSINESS_NAME_LABEL`)}</label>
                      <Field
                        name={'locationName'}
                        className="form-control "
                        type="text"
                        value={modalValues.locationName}
                        onBlur={() => setFieldTouched('locationName', true)}
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                    <div className="">
                      <label className="">{t(`LOAD_TABS.TERMINAL_NUMBER_LABEL`)}</label>
                      <Field
                        name={'terminalNumber'}
                        className="form-control"
                        value={modalValues.terminalNumber}
                        type="number"
                        onBlur={() => setFieldTouched('terminalNumber', true)}
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>

                    <div className="">
                      <label className="">{t(`LOAD_TABS.AMOUNT_TO_LOAD_LABEL`)}</label>
                      <NumericFormat
                        name={'loadingAmount'}
                        className="form-control "
                        value={modalValues.loadingAmount}
                        allowNegative={false}
                        onChange={(e) => {
                          const { name, value } = e.target;

                          setModalValues((prev) => {
                            return { ...prev, [name]: value };
                          });
                        }}
                        thousandSeparator={useThousandSeparator}
                        decimalScale={fieldDecimalScale}
                        {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                        onBlur={() => setFieldTouched('loadingAmount', true)}
                        autoComplete="off"
                        required={true}
                      />

                      {error.loadingAmount && <p style={{ color: 'red' }}>{t(`LOADING_AMOUNT_ERROR_MSG`)}</p>}
                    </div>

                    <div className="">
                      <label className="">{t(`EMPLOYEE_LOADS.BATCH`)}</label>
                      <NumericFormat
                        name={'batch'}
                        className="form-control "
                        allowNegative={false}
                        defaultValue={'0000'}
                        maxLength={4}
                        onBlur={() => setFieldTouched('batch', true)}
                        autoComplete="off"
                        onChange={(e) => {
                          const { name, value } = e.target;

                          setModalValues((prev) => {
                            return { ...prev, [name]: value };
                          });
                        }}
                        // value={modalValues?.batch}
                        required={true}
                      />
                      {error.batch && <p style={{ color: 'red' }}>{t(`BATCH_ERROR_MSG`)}</p>}
                      {error.batchLength && <p style={{ color: 'red' }}>{t(`BATCH_LENGTH_ERROR_MSG`)}</p>}
                    </div>
                    <div>
                      <label className="">{t(`EMPLOYEE_LOADS.DRAWER`)}</label>
                      <ReactSelect
                        name={'drawer'}
                        value={
                          selectedDrawer?.length > 0
                            ? [{ label: 1, value: 1 }]
                            : drawers.filter(({ value }) => value == selectedDrawer.value)
                        }
                        options={drawers}
                        className="react-select"
                        onChange={(option) => {
                          setSelectedDrawer(option);
                          setModalValues((prev) => {
                            return { ...prev, drawer: option?.value };
                          });
                        }}
                        onBlur={() => setFieldTouched('drawer', true)}
                        // placeholder={`-- ${t('LOADING_TERMINAL.EMPLOYEE')} --`}
                        // noOptionsMessage={() => t('RSEL_NO_OPT')}
                        // isClearable
                      />
                      {error.drawer && <p style={{ color: 'red' }}>{t(`SELECT_DRAWER_ERROR_MSG`)}</p>}
                    </div>
                    <div className="">
                      <label className="">{t(`EMPLOYEE_LOADS.IN_DRAWER`)}</label>
                      <div className="d-flex align-items-center">
                        <NumericFormat
                          name={'sumOfTerminalDrawer'}
                          className="form-control"
                          // defaultValue={'0'}
                          onBlur={() => setFieldTouched('sumOfTerminalDrawer', true)}
                          style={{ width: '60%' }}
                          autoComplete="off"
                          onChange={(e) => {
                            const { name, value } = e.target;

                            setModalValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          }}
                          value={modalValues?.sumOfTerminalDrawer}
                          thousandSeparator={useThousandSeparator}
                          decimalScale={fieldDecimalScale}
                          {...(fieldDecimalScale > 0 ? { fixedDecimalScale: true } : {})}
                          // disabled={true}
                          required={true}
                        />
                      </div>
                      {error.sumOfTerminalDrawer && <p style={{ color: 'red' }}>{t(`WAS_IN_DRAWER_ERROR_MSG`)}</p>}
                    </div>
                    <div className="">
                      <Label htmlFor="manuallyCounted" className="mb-0">
                        {t('EMPLOYEE_LOADS.COUNTED')}
                      </Label>
                      <div className="form-check form-switch ms-2">
                        <input
                          className="form-check-input countCheckbox"
                          name="manuallyCounted"
                          type="checkbox"
                          onChange={(e) => {
                            const { name, value, checked } = e.target;

                            setModalValues((prev) => {
                              return { ...prev, [name]: checked };
                            });
                          }}
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={modalValues.manuallyCounted}
                        />
                      </div>
                    </div>
                    {/* <div className="">

                                        <Label className="d-none d-sm-block">&nbsp;</Label>

                                        <FormGroup className="mt-sm-3" check>
                                            <Input
                                                id="manuallyCounted"
                                                type="checkbox"
                                                name="counted"
                                                onChange={(e) => {
                                                    const { name, value, checked } = e.target

                                                    setModalValues((prev) => {
                                                        return { ...prev, [name]: checked }
                                                    })
                                                }}
                                            // checked={manuallyCounted}
                                            />

                                            <Label htmlFor="manuallyCounted" className="mb-0">
                                                {t("EMPLOYEE_LOADS.COUNTED")}
                                            </Label>
                                        </FormGroup>
                                    </div> */}
                    <div className="">
                      <label className="">{t(`EMPLOYEE_LOADS.DISPLAY_ORDER_LABEL`)}</label>
                      <div className="d-flex">
                        <Field
                          name={'index'}
                          className="form-control"
                          // style={{ width: '80%' }}
                          value={modalValues.index}
                          type="number"
                          onBlur={() => setFieldTouched('index', true)}
                          autoComplete="off"
                          disabled={true}
                        />
                        {/* <Button color="primary" style={{ marginLeft: 'auto' }} onClick={() => setIndexEnable(!indexEnable)}>{t(`EMPLOYEE_LOADS.EDIT_BTN`)}</Button> */}
                      </div>
                    </div>
                  </div>
                  {/* </Col> */}
                </Row>
                <div className="mt-3">
                  <h4 className="d-flex justify-content-around">{t(`LOAD_TABS.REMARKS_LABEL`)}</h4>
                  <div className="border mt-2">
                    <Button
                      id={`editBtn001`}
                      color="primary"
                      className="border-0 "
                      onClick={() => handleUpdateModal(true)}
                      disabled={loading}
                    >
                      <UncontrolledTooltip placement="right" target={`editBtn001`}>
                        {t('LOAD_REMARKS.ADD')}
                      </UncontrolledTooltip>
                      {t(`LOAD_REMARKS.ADD`)}
                    </Button>
                    {remarkList?.length > 0 ? (
                      remarkList?.map((value, index) => {
                        return (
                          <div className="remarkList">
                            <span>
                              {' '}
                              <i>{`${index + 1}).`}</i> {value.remark}
                            </span>
                            <div>
                              <button
                                id={`editBtn${index}`}
                                className="border-0 bg-transparent"
                                onClick={() => handleUpdateModal(true, value)}
                              >
                                <UncontrolledTooltip placement="right" target={`editBtn${index}`}>
                                  {t('LOAD_REMARKS.UPDATE')}
                                </UncontrolledTooltip>
                                <img src={edit} alt="" />
                              </button>
                              <button
                                id={`dltBtn${index}`}
                                className="border-0 bg-transparent"
                                onClick={() => deleteRemarkById({ remark: value.remark, remarkId: value.remarkId })}
                              >
                                <UncontrolledTooltip placement="right" target={`dltBtn${index}`}>
                                  {t('LOAD_REMARKS.DELETE_BTN')}
                                </UncontrolledTooltip>
                                <img src={deletes} alt="" />
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h4 className="text-center p-4">{t('TABLE_NO_RECORDS')}</h4>
                    )}
                  </div>
                  <Row>
                    <Col sm={4}>
                      <div className="d-flex justify-content-around align-items-center mt-2">
                        <label htmlFor="dateStart" style={{ width: '50%' }}>
                          {t(`LOAD_REMARKS.FORM_DATE`)}
                        </label>
                        <ReactDatePicker
                          ref={dateRef}
                          name="dateStart"
                          id="dateStart"
                          className="form-control"
                          selected={noEndCheckbox ? '' : dateForModal.start}
                          onChange={(dateArr) =>
                            setDateForModal((prev) => {
                              return { ...prev, start: dateArr };
                            })
                          }
                          dateFormat={'dd/MM/yyyy'}
                          shouldCloseOnSelect={true}
                          disabled={noEndCheckbox}
                        ></ReactDatePicker>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="d-flex justify-content-around align-items-center mt-2">
                        <label htmlFor="dateEnd" style={{ width: '50%' }}>
                          {t(`LOAD_REMARKS.TO_DATE`)}
                        </label>
                        <ReactDatePicker
                          ref={dateRef2}
                          name="dateEnd"
                          id="dateEnd"
                          className="form-control"
                          selected={noEndCheckbox ? '' : dateForModal.end}
                          onChange={(dateArr) =>
                            setDateForModal((prev) => {
                              return { ...prev, end: dateArr };
                            })
                          }
                          dateFormat={'dd/MM/yyyy'}
                          shouldCloseOnSelect={true}
                          disabled={noEndCheckbox}
                        ></ReactDatePicker>
                      </div>
                    </Col>
                    <Col sm={4} className="d-none d-sm-flex align-items-center  justify-content-center mt-2">
                      <div className="d-flex">
                        <Input
                          type="checkbox"
                          name="noEnd"
                          id="noEnd"
                          style={{ marginRight: '5px', width: '1.26em' }}
                          onChange={(e) => {
                            setNoEndCheckbox(e.target.checked);
                          }}
                        />
                        <Label htmlFor="noEnd">{t(`LOAD_REMARKS.NO_END_CHECKBOX`)}</Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4} className="d-sm-none align-items-start justify-content-center ">
                      <div className="d-flex">
                        <Input
                          type="checkbox"
                          name="noEnd"
                          id="noEnd"
                          style={{ marginRight: '5px', width: '1.26em' }}
                          onChange={(e) => {
                            setNoEndCheckbox(e.target.checked);
                          }}
                        />
                        <Label htmlFor="noEnd">{t(`LOAD_REMARKS.NO_END_CHECKBOX`)}</Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="m-2 d-flex btnClass">
                  <Button
                    color="primary"
                    className="loadBtn"
                    onClick={() => {
                      checkLastBatchNumber();
                    }}
                    disabled={loading}
                  >
                    {loading ? t(`PLEASE_WAIT`) : t(`EMPLOYEE_LOADS.LOADS_BTN`)}
                  </Button>
                  <Button className="ms-4" onClick={() => setIsOpen({ shown: false })} disabled={loading}>
                    {t(`EMPLOYEE_LOADS.CANCEL_BTN`)}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      {updateModal && (
        <AddRemarkModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          createRemarks={createRemarks}
          remarkDetail={remarkDetail}
          handleRemarkCancelAction={handleRemarkCancelAction}
        />
      )}
      <ConfirmDelete
        showDeleteModal={showDeleteModal}
        deleteRemark={deleteRemark}
        closeDeleteModal={closeDeleteModal}
        deleteMessage={t(`LOAD_REMARKS.DELETE_MSG`)}
      />
      <ConfirmBatchNumber
        formData={confirmBatchNumberData}
        isOpened={openBatchNumberModal}
        toggleModal={handleBatchNumberModal}
      />
    </React.Fragment>
  );
};

export default EmployeeRemarkModal;
