import { post } from "../AxiosConfig";

const URI = "/modemtype";

const getModelDetail = (payload) => {
  const URL = `${URI}/get-modem-detail`;
  return post(URL, payload);
};

const createModemFee = (payload) => {
  const URL = `${URI}/create-modem-fee`;
  return post(URL, payload);
};

const updateModemFee = (payload) => {
  const URL = `${URI}/update-modem-fee`;
  return post(URL, payload);
};

const ModemTypeService = { getModelDetail, createModemFee, updateModemFee };

export default ModemTypeService;
