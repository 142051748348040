/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';

import { isMobileDevice } from '../../../helpers';
import { changeSidebarType } from '../../../store/slices/Layout/layoutSlice';

import Footer from '../VerticalLayout/Footer';
import Header from './Header';
import Sidebar from './Sidebar';

function VerticalLayout(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { leftSideBarType } = useSelector((state) => state.Layout);

  const [isMobile] = useState(isMobileDevice());

  useEffect(() => {
    if (location.pathname === '/') navigate('/dashboard');
  }, [location]);

  useEffect(() => {
    manageBodyClass('mobile-view', isMobile ? 'add' : 'remove');
  }, [isMobile]);

  const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
  };

  const manageBodyClass = (cssClass, action = 'toggle') => {
    switch (action) {
      case 'add':
        if (document.body) document.body.classList.add(cssClass);
        break;
      case 'remove':
        if (document.body) document.body.classList.remove(cssClass);
        break;
      default:
        if (document.body) document.body.classList.toggle(cssClass);
        break;
    }

    return true;
  };

  const toggleMenuCallback = () => {
    if (leftSideBarType === 'default') {
      dispatch(changeSidebarType({ leftSideBarType: 'condensed', isMobile }));
      manageBodyClass('sidebar-enable', 'add');
      if (!isMobile) manageBodyClass('vertical-collpsed', 'add');
    } else if (leftSideBarType === 'condensed') {
      dispatch(changeSidebarType({ leftSideBarType: 'default', isMobile }));
      changeBodyAttribute('data-sidebar-size', '');
      manageBodyClass('sidebar-enable', 'remove');
      if (!isMobile) manageBodyClass('vertical-collpsed', 'remove');
    }
  };

  const toggleRightSidebar = () => {
    props.toggleRightSidebar();
  };

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} toggleRightSidebar={toggleRightSidebar} />

        <Sidebar
          theme={props.leftSideBarTheme}
          type={props.leftSideBarType}
          isMobile={isMobile}
          toggleMenuCallback={toggleMenuCallback}
        />

        <div className="main-content">
          <div className="sidebar-overlay" onClick={toggleMenuCallback}></div>

          <div className="page-content" style={{ minHeight: 'calc(100vh - 60px)' }}>
            <Container fluid className="px-sm-1 px-0">
              <Outlet />
            </Container>
          </div>

          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}

export default VerticalLayout;
