/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { USER_ROLE } from '../../constants';
import { AmountSchema } from '../../helpers/yup-schema';
import EmpActivityService from '../../services/emp_activity';

import NumericFormatField from '../../components/NumericFormatField';
import SelectField from '../../components/SelectField';

const InitialValues = {
  withdrawtoEmployeeId: '',
  amount: '',
  reference: '',
};

const ValidationSchema = AmountSchema().shape({
  withdrawtoEmployeeId: Yup.string().required(t('REQUIRED')).trim(),
  // reference: Yup.string().required(t("REQUIRED")).trim(),
});

function ConfirmSaveDataSplitMoney({
  roleId,
  formData = {},
  isOpened = false,
  transEmpOptList = [],
  toggleModal = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [vSchema, setVSchema] = useState(ValidationSchema);

  useEffect(() => {
    if (roleId !== USER_ROLE.EMPLOYEE) {
      setVSchema(
        ValidationSchema.concat(
          Yup.object().shape({
            reference: Yup.string().required(t('REQUIRED')).trim(),
          }),
        ),
      );
    }
  }, []);

  const handleSubmitFn = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...formData,
      ...values,
      amount: parseFloat(values.amount),
      amountemployeehave: parseFloat(values.totalAmount),
    };

    setIsLoading(true);
    await EmpActivityService.splitWithdrawalToEmployees(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        toggleModal(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpened} toggle={toggleModal} backdrop="static">
        <Formik
          initialValues={{ ...InitialValues, ...formData }}
          enableReinitialize={true}
          // validationSchema={ValidationSchema}
          validationSchema={vSchema}
          onSubmit={handleSubmitFn}
        >
          {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
            <Form noValidate>
              <ModalHeader toggle={() => toggleModal()}>{t('CONFIRM_DETAILS')}</ModalHeader>

              <ModalBody>
                <Row>
                  {/* Transfer to Employee */}
                  <Col {...(roleId === USER_ROLE.EMPLOYEE ? { sm: 6 } : {})}>
                    <div className="mb-4">
                      <SelectField
                        fieldName="withdrawtoEmployeeId"
                        fieldLabel={t('SPLIT_MONEY.TRANS_TO_EMP')}
                        fieldValue={values.withdrawtoEmployeeId}
                        fieldOptions={transEmpOptList}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isRequired={true}
                        isDisabled={!values.employeeId}
                      />
                    </div>
                  </Col>

                  {roleId !== USER_ROLE.EMPLOYEE && <div className="w-100" />}

                  {/* Cheque Amount */}
                  <Col sm={6}>
                    <div className="mb-4">
                      <NumericFormatField
                        fieldName="amount"
                        fieldValue={values.amount}
                        fieldDecimalScale={2}
                        fieldLabel={t('CHEQUE_AMT')}
                        fieldPlaceholder={t('BANK_WITHDRAWAL.CHEQUE_AMT_PLACEHOLDER')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                  </Col>

                  {/* Cheque Number */}
                  {roleId !== USER_ROLE.EMPLOYEE && (
                    <Col sm={6}>
                      <div className="mb-4">
                        <NumericFormatField
                          fieldName="reference"
                          fieldValue={values.reference}
                          fieldLabel={t('CHEQUE_NUMBER')}
                          fieldPlaceholder={t('BANK_WITHDRAWAL.CHEQUE_NUM_PLACEHOLDER')}
                          allowLeadingZeros={true}
                          useThousandSeparator={false}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </ModalBody>

              <ModalFooter>
                <Button color="light" onClick={() => toggleModal()}>
                  {t('CANCEL')}
                </Button>

                {/* Submit Button */}
                <div className="">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md waves-effect waves-light"
                    disabled={isSubmitting || isLoading}
                  >
                    {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('CONFIRM')}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmSaveDataSplitMoney;
