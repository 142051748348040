/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { CompanyId, JobRole, USER_ROLE, UserId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import { AmountSchema } from '../../helpers/yup-schema';
import { numberFormatter } from '../../helpers';
import EmployeeService from '../../services/employee';
import EmpActivityService from '../../services/emp_activity';
import LocationService from '../../services/location';
import MachineService from '../../services/machines';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import SelectField from '../../components/SelectField';
import NumericFormatField from '../../components/NumericFormatField';
import ConfirmSaveDataChargeTerm from './ConfirmSaveDataChargeTerm.modal';
import ConfirmBatchNumber from './ConfirmBatchNumber';

const InitialValues = {
  employeeId: '',
  // accountId: "",
  amountemployeehave: '0',
  locationId: '',
  terminalNumber: '',
  manuallyCounted: false,
  drawer: '1',
  wasIndrawer: '',
  addBills: '',
  leadingBatch: '',
  batch: '',
};

const ValidationSchema = AmountSchema('wasIndrawer')
  .concat(AmountSchema('addBills'))
  .shape({
    // employeeId: Yup.string().required(t("REQUIRED")).trim(),
    // accountId: Yup.string().required(t("REQUIRED")).trim(),
    amountemployeehave: Yup.string().required(t('REQUIRED')),
    locationId: Yup.string().required(t('REQUIRED')),
    terminalNumber: Yup.string().required(t('REQUIRED')),
    drawer: Yup.string().required(t('REQUIRED')).trim(),
    leadingBatch: Yup.string().required(t('REQUIRED')).trim(),
    batch: Yup.string().length(4, t('CHARGE_TERMINAL.BATCH_ERR')).required(t('REQUIRED')),
  });

let companyId = 0,
  loginUserId;

function ChargingTerminal() {
  const [setPageTitle, setCrumbItem] = useOutletContext();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [vSchema, setVSchema] = useState(ValidationSchema);
  const [roleId, setRoleId] = useState();

  const [empList, setEmpList] = useState([]);
  const [empOptList, setEmpOptList] = useState([]);
  const [initAmtEmpHave, setInitAmtEmpHave] = useState(null);

  const [locationList, setLocationList] = useState([]);
  const [initLocationList, setInitLocationList] = useState([]);
  const [locOptList, setLocOptList] = useState([]);
  const [initLocOptList, setInitLocOptList] = useState([]);

  const [terminalList, setTerminalList] = useState([]);
  const [initTerminalList, setInitTerminalList] = useState([]);
  const [termOptList, setTermOptList] = useState([]);
  const [initTermOptList, setInitTermOptList] = useState([]);

  const [manuallyCounted, setManuallyCounted] = useState(false);
  const [drawerList] = useState([
    { id: 1, label: 1, value: 1 },
    { id: 2, label: 2, value: 2 },
    { id: 3, label: 3, value: 3 },
  ]);

  const [anotherEmp, setAnotherEmp] = useState(false);
  const [confirmSaveData, setConfirmSaveData] = useState({});
  const [confirmBatchNumberData, setConfirmBatchNumberData] = useState({});
  const [openBatchNumberModal, setOpenBatchNumberModal] = useState(false);
  const [balanceBills, setBalanceBills] = useState({ val: 0, error: false })

  useEffect(() => {
    setPageTitle(t('CHARGE_TERMINAL.TITLE'));
    setCrumbItem([
      { title: t('EMP_ACTIVITY'), link: '#' },
      { title: t('CHARGE_TERMINAL.TITLE'), link: '#' },
    ]);

    companyId = LocalstorageService.getItem(CompanyId);

    const userRole = LocalstorageService.getItem(JobRole);
    setRoleId(userRole);

    if (userRole === USER_ROLE.EMPLOYEE) {
      loginUserId = LocalstorageService.getItem(UserId);
      InitialValues.employeeId = loginUserId;
      getAmtEmpHaveFn(loginUserId, formRef.current.setFieldValue);
      getLocationsFn(loginUserId, companyId);
      // getTerminalsFn(loginUserId, companyId);
    }

    getEmployeeFn();
  }, []);

  useEffect(() => {
    const userRole = LocalstorageService.getItem(JobRole);
    if (userRole === USER_ROLE.EMPLOYEE && empOptList) {
      loginUserId = LocalstorageService.getItem(UserId);
      formRef.current.setFieldValue('employeeId', loginUserId);
    }
  }, [empOptList]);

  useEffect(() => {
    if (anotherEmp) {
      setVSchema(
        ValidationSchema.concat(
          Yup.object().shape({
            employeeId: Yup.string().required(t('REQUIRED')).trim(),
          }),
        ),
      );
    } else {
      setVSchema(ValidationSchema);
    }
  }, [anotherEmp]);

  async function getEmployeeFn() {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listEmployee({ companyId, allList: true })
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const EmpList = [...dataList];
          const userRole = LocalstorageService.getItem(JobRole);
          if (userRole === USER_ROLE.EMPLOYEE) {
            const idx = dataList.findIndex((v) => v.id === loginUserId);
            idx !== -1 && EmpList.splice(idx, 1);
          }

          setEmpList(EmpList);

          const data = dataList.map((item) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            companyId: item.companyId,
          }));
          setEmpOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getAmtEmpHaveFn(employeeId, setFieldValue) {
    loginUserId = employeeId;
    dispatch(setLoadingOverlay(true));
    await EmpActivityService.amtEmpHave({
      companyId,
      employeeId,
    })
      .then((response) => {
        const { status, data } = response.data;

        if (status === 400) {
          return;
        }

        if (data) {
          const amtEmpHave = numberFormatter(data.amountemployeehave || 0);
          setFieldValue('amountemployeehave', amtEmpHave);
          initAmtEmpHave === null && setInitAmtEmpHave(amtEmpHave);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getLocationsFn(employeeId, empCompanyId) {
    dispatch(setLoadingOverlay(true));
    await EmployeeService.listAssociate({
      empCompanyId,
      employeeId,
    })
      .then((response) => {
        const { status, message, dataList } = response.data;

        if (status === 400) {
          // toast.error(message);
          return;
        }

        if (dataList && dataList.length) {
          setLocationList(dataList);
          initLocationList.length === 0 && setInitLocationList(dataList);
          const data = dataList.map((item) => ({
            id: item.locationId,
            label: item.locationName,
            value: item.locationId,
          }));

          const data1 = dataList.map((item) => ({
            id: item.terminalNumber,
            label: item.terminalNumber,
            value: item.terminalNumber,
          }));

          setTerminalList(dataList);
          setLocOptList(data);
          setTermOptList(data1);
          initLocOptList.length === 0 && setInitLocOptList(data);

          setTermOptList(data1);
          initTermOptList.length === 0 && setInitTermOptList(data1);
        }
        // setList2(dataList);
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }


  function handleToggleModal(isCancelled = true) {
    setConfirmSaveData({
      ...confirmSaveData,
      formData: {},
      isOpened: false,
    });

    if (!isCancelled) {
      setManuallyCounted(false);
      formRef.current.resetForm();
      getAmtEmpHaveFn(loginUserId, formRef.current.setFieldValue);
    }
  }

  function handleBatchNumberModal(isCancelled = true, updatedBatch = '') {
    if (updatedBatch) {
      formRef.current.setFieldValue('batch', updatedBatch);
      setOpenBatchNumberModal(false);
      confirmDetailsFn(updatedBatch);
    } else {
      setOpenBatchNumberModal(false);
    }
  }

  async function checkLastBatchNumber() {
    const { values } = formRef.current;
    const { terminalNumber, batch, leadingBatch } = values;
    dispatch(setLoadingOverlay(true));
    await EmpActivityService.getLastBatchNumber({
      terminalNumber,
    })
      .then((response) => {
        const { status, data } = response.data;

        // if (status === 400) {
        //   return;
        // }
        if (data?.batchNumber) {
          const lastDigits = data?.batchNumber.slice(data?.batchNumber.length - 4);

          if (batch <= lastDigits) {
            setConfirmBatchNumberData({ batch, lastDigits, leadingBatch });
            setOpenBatchNumberModal(true);
          } else {
            confirmDetailsFn();
          }
        } else {
          confirmDetailsFn();
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function confirmDetailsFn(updatedBatch) {
    const { values, setSubmitting, initialValues, setTouched, validateForm } = formRef.current;
    setSubmitting(false);
    const userId = localStorage.getItem('user-id');
    if (updatedBatch) {
      values['batch'] = updatedBatch;
    }
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }

    const amtEmpHave = values.amountemployeehave.toString().replace(/,/g, '');
    const payload = {
      ...values,
      companyId,
      employeeId: parseInt(values.employeeId),
      amountemployeehave: parseFloat(amtEmpHave),
      locationId: parseInt(values.locationId),
    };

    setConfirmSaveData({
      formData: payload,
      isOpened: true,
      toggleModal: handleToggleModal,
    });
  }

  async function getBalanceBills(employeeId, terminalNumber,drawer) {
    const payload = {}
    if (employeeId && terminalNumber && drawer) {
      payload.employeeId = employeeId
      payload.terminalNumber = terminalNumber
      payload.companyId = LocalstorageService.getItem(CompanyId)
      payload.drawer = drawer || formRef.current.values?.drawer
      await EmpActivityService.getBalanceBills(payload)
        .then((response) => {
          const { status, data } = response.data;
          if (status === 400) {
            setBalanceBills((prev) => {
              return { ...prev, val: 0 }
            })
            return;
          }
  
          if (data) {
            
              if (data.balanceBills > formRef.current.values.addBills) {
                setBalanceBills( { val: data.balanceBills ,error:false})
              } else {
                
                setBalanceBills( { error: true, val: data.balanceBills })
              }
            
          }
        })
        .finally(() => {
          // dispatch(setLoadingOverlay(false));
        });
    }
  }

  const handleAnotherEmpChange = () => {
    setAnotherEmp(!anotherEmp);

    const { setFieldValue, setFieldTouched } = formRef.current;

    setFieldValue('employeeId', '');
    setFieldTouched('employeeId', false);

    setFieldTouched('amountemployeehave', false);

    setFieldValue('locationId', '');
    setFieldTouched('locationId', false);

    setFieldValue('terminalNumber', '');
    setFieldTouched('terminalNumber', false);

    setFieldValue('leadingBatch', '');
    setFieldTouched('leadingBatch', false);

    if (!anotherEmp) {
      setFieldValue('amountemployeehave', 0);
      // setFieldValue('employeeId', loginUserId);
    } else {
      loginUserId = localStorage.getItem(UserId);
      setFieldValue('employeeId', loginUserId);
      // setFieldValue('amountemployeehave', initAmtEmpHave);
      getAmtEmpHaveFn(loginUserId, setFieldValue);
      setLocationList(initLocationList);
      setLocOptList(initLocOptList);

      setTerminalList(initTerminalList);
      setTermOptList(initTermOptList);
    }
    // if (anotherEmp === false) {
    //   setFieldValue('employeeId', loginUserId);
    // }
  };

  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        initialValues={InitialValues}
        // validationSchema={ValidationSchema}
        validationSchema={vSchema}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form id="emp-act-charging-terminal" noValidate>
            <Row>
              {/* Employee */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="employeeId"
                    fieldLabel={t('ROLES.EMPLOYEE')}
                    fieldValue={values.employeeId}
                    fieldOptions={empOptList}
                    fieldChange={async (option) => {
                      setLocOptList([]);
                      setTermOptList([]);

                      setFieldValue('amountemployeehave', 0);
                      setFieldTouched('amountemployeehave', false);
                      if (option) {
                        companyId = option.companyId;
                        const empData = empOptList.find((v) => v.id === option.value) || {};

                        await getAmtEmpHaveFn(option.value, setFieldValue);

                        getLocationsFn(empData.id, empData.companyId);
                        // getTerminalsFn(empData.id, empData.companyId);
                      }

                      setFieldValue('locationId', '');
                      setFieldTouched('locationId', false);

                      setFieldValue('terminalNumber', '');
                      setFieldTouched('terminalNumber', false);

                      setFieldValue('leadingBatch', '');
                      setFieldTouched('leadingBatch', false);
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={anotherEmp}
                    isDisabled={roleId === USER_ROLE.EMPLOYEE && !anotherEmp ? true : false}
                  />
                </div>
              </Col>

              {/* Another Employee */}
              {roleId === USER_ROLE.EMPLOYEE && (
                <Col sm={4}>
                  <div className="mb-4">
                    <Label className="d-none d-sm-block">&nbsp;</Label>

                    <FormGroup className="mt-3" check>
                      <Input
                        id="anotherEmployee"
                        type="checkbox"
                        onChange={handleAnotherEmpChange}
                        checked={anotherEmp}
                      />

                      <Label htmlFor="anotherEmployee" className="mb-0">
                        {t('EMPLOYEE.ANOTHER_EMP')}
                      </Label>
                    </FormGroup>
                  </div>
                </Col>
              )}

              {/* Employee Amount */}
              <Col sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="amountemployeehave"
                    label={t('CHARGE_TERMINAL.AMT_EMP_HAVE_LABEL')}
                    placeholder={t('CHARGE_TERMINAL.AMT_EMP_HAVE_PLACEHOLDER')}
                    required={true}
                    isDisabled={true}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="balanceBills"
                    label={t('EMP_BILLS_LOAD_LABEL')}
                    placeholder={t('CHARGE_TERMINAL.AMT_EMP_HAVE_PLACEHOLDER')}
                    required={false}
                    isDisabled={true}
                    value={balanceBills.val}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Location */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="locationId"
                    fieldLabel={t('LOCATION.TITLE')}
                    fieldValue={values.locationId}
                    fieldOptions={locOptList}
                    fieldChange={(option) => {
                      setFieldValue('leadingBatch', '');
                      setFieldTouched('leadingBatch', false);

                      setFieldValue('terminalNumber', '');
                      setFieldTouched('terminalNumber', false);

                      if (option) {
                        const locObj = locationList.find((lo) => lo.locationId === option.value) || {};
                        const leadingBatch = locObj.terminalNumber?.slice(3);
                        setFieldValue('leadingBatch', leadingBatch);

                        setFieldValue('terminalNumber', locObj.terminalNumber || '');
                        getBalanceBills(values.employeeId, locObj.terminalNumber, values.drawer)
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={roleId === USER_ROLE.EMPLOYEE ? anotherEmp && !values.employeeId : !values.employeeId}
                  />
                </div>
              </Col>

              {/* Terminal */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="terminalNumber"
                    fieldLabel={t('MACHINE.NUMBER')}
                    fieldValue={values.terminalNumber}
                    fieldOptions={termOptList}
                    fieldChange={(option) => {
                      setFieldValue('leadingBatch', '');
                      setFieldTouched('leadingBatch', false);

                      setFieldValue('locationId', '');
                      setFieldTouched('locationId', false);

                      if (option) {
                        const leadingBatch = option.value?.slice(3);
                        setFieldValue('leadingBatch', leadingBatch);
                        const termObj =
                          terminalList.find((tl) => Number(tl.terminalNumber) === Number(option.value)) || {};

                        setFieldValue('locationId', termObj.locationId || '');
                        getBalanceBills(values.employeeId, termObj.terminalNumber, values.drawer)
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={roleId === USER_ROLE.EMPLOYEE ? anotherEmp && !values.employeeId : !values.employeeId}
                  />
                </div>
              </Col>

              {/* Manually Counted */}
              <Col sm={4}>
                <div className="mb-sm-4 d-flex d-sm-block justify-content-center">
                  <Label className="d-none d-sm-block">&nbsp;</Label>

                  <FormGroup className="mt-sm-3" check>
                    <Input
                      id="manuallyCounted"
                      type="checkbox"
                      onChange={() => {
                        setManuallyCounted(!manuallyCounted);
                        setFieldValue('manuallyCounted', !manuallyCounted);
                      }}
                      checked={manuallyCounted}
                    />

                    <Label htmlFor="manuallyCounted" className="mb-0">
                      {t('EMPLOYEE.MANUALLY_COUNTED')}
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <Row>
              {/* Drawer */}
              <Col sm={4}>
                <div className="mb-4">
                  <SelectField
                    fieldName="drawer"
                    fieldLabel={t('CHARGE_TERMINAL.DRAWER')}
                    fieldValue={values.drawer}
                    fieldOptions={drawerList}
                    setFieldValue={(field, val) => {
                      setFieldValue(field, val)
                      getBalanceBills(values.employeeId,values.terminalNumber,val)
                    }}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                  />
                </div>
              </Col>

              {/* Bills Drawer */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="wasIndrawer"
                    fieldValue={values.wasIndrawer}
                    fieldLabel={t('CHARGE_TERMINAL.BILLS_DRAWER_LABEL')}
                    fieldPlaceholder={t('CHARGE_TERMINAL.BILLS_DRAWER_PLACEHOLDER')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>

              {/* Add Bills */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="addBills"
                    fieldValue={values.addBills}
                    fieldLabel={t('CHARGE_TERMINAL.ADD_BILLS_LABEL')}
                    fieldPlaceholder={t('CHARGE_TERMINAL.ADD_BILLS_PLACEHOLDER')}
                    setFieldValue={(field, val) => {
                      setFieldValue(field, val)
                      if (val > balanceBills.val) {
                        setBalanceBills((prev) => {
                          return { ...prev, error: true }
                        })
                      } else {
                        setBalanceBills((prev) => {
                          return { ...prev, error: false }
                        })
                      }
                    }}
                    setFieldTouched={setFieldTouched}
                  />
                  {balanceBills.error && <div className="text-danger position-absolute">{t('EMP_BILLS_LOAD_ERROR_MSG')}</div>}
                </div>
              </Col>
            </Row>

            <Row className="batch-row">
              {/* Leading Batch Number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <CommonField
                    fieldName="leadingBatch"
                    label={t('CHARGE_TERMINAL.LEAD_BATCH_LABEL')}
                    placeholder={t('CHARGE_TERMINAL.LEAD_BATCH_PLACEHOLDER')}
                    required={true}
                    isDisabled={true}
                  />
                </div>
              </Col>

              {/* End Batch Number */}
              <Col xs={6} sm={4}>
                <div className="mb-4">
                  <NumericFormatField
                    fieldName="batch"
                    fieldValue={values.batch}
                    fieldLabel={t('CHARGE_TERMINAL.END_BATCH_LABEL')}
                    fieldPlaceholder={t('CHARGE_TERMINAL.END_BATCH_PLACEHOLDER')}
                    maxLength={4}
                    allowLeadingZeros={true}
                    useThousandSeparator={false}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="">
              <Button color="primary" className="w-md waves-effect waves-light" disabled={(!formRef.current?.values?.employeeId && !formRef.current?.values?.terminalNumber) || balanceBills.error || balanceBills.val===0} onClick={() => checkLastBatchNumber()}>
                {t('SUBMIT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmSaveDataChargeTerm {...confirmSaveData} />

      <ConfirmBatchNumber
        formData={confirmBatchNumberData}
        isOpened={openBatchNumberModal}
        toggleModal={handleBatchNumberModal}
      />
    </React.Fragment>
  );
}

export default ChargingTerminal;
