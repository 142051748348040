const rule = function (errorMessage) {
  return this.test(`test-factor-to-average`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value < 0) {
      return createError({ path, message: errorMessage });
    }
    if (value > 1) {
      return createError({ path, message: errorMessage });
    }
    return true;
  });
};
export const factToavg = {
  name: "factToAvg",
  rule,
};
