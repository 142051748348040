/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SortableHeader from '../../components/SortableHeader';
import ActionBtns from '../../components/ActionBtns';
import { numberFormatter, formatDate } from '../../helpers';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { ITEMS_PER_PAGE } from '../../constants';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import NoRecords from '../../components/Table/NoRecords';
export default function CashExpenseListSideBar({
  cashExpenseList,
  sortBy,
  totalCount,
  currentPage,
  editCashExpense,
  removeCashExpense,
  handlePageClick,
}) {
  const { t } = useTranslation();
  const HeaderList = [
    {
      title: t('CASHEXPENSE.TABLE_DATE'),
      value: 'createDate',
      style: { minWidth: '150px', width: '10%' },
    },
    {
      title: t('CASHEXPENSE.TABLE_BUSINESS_NAME'),
      value: 'expenseNote',
      style: { width: '20%' },
      sortable: true,
    },
    {
      title: t('CASHEXPENSE.TABLE_EXPENSE_NOTE'),
      value: 'expenseNote',
      style: { minWidth: '150px', width: '40%' },
      sortable: false,
    },
    {
      title: t('CASHEXPENSE.TABLE_PAID_TO'),
      value: 'paidTo',
      style: { minWidth: '150px', width: '20%' },
      sortable: false,
    },
    {
      title: t('CASHEXPENSE.TABLE_EXPENSE_AMOUNT'),
      value: 'expenseAmount',
      style: { minWidth: '150px', width: '20%' },
    },
    {
      title: t('CASHEXPENSE.TABLE_FROM_DATE'),
      value: 'startDate',
      style: { minWidth: '150px', width: '10%' },
      sortable: false,
    },
    {
      title: t('CASHEXPENSE.TABLE_TO_DATE'),
      value: 'endDate',
      style: { width: '10%' },
      sortable: false,
    },
    {
      title: t('CASHEXPENSE.TABLE_INVOICE'),
      value: 'invoice',
      style: { width: '10%' },
      sortable: false,
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCashExpenseDetail, setDeleteCashExpenseDetail] = useState({});
  const handleDeleteAction = (data) => {
    setShowDeleteModal(true);
    setDeleteCashExpenseDetail({
      ...data,
      ud: false,
    });
  };
  const handleConfirmDeleteAction = () => {
    removeCashExpense(deleteCashExpenseDetail);
    setShowDeleteModal(false);
  };
  const handleCancelAction = () => {
    setDeleteCashExpenseDetail({});
    setShowDeleteModal(false);
  };
  return (
    <React.Fragment>
      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={HeaderList} isDisabled={cashExpenseList.length === 0} sortClick={sortBy} />
          </tr>
        </thead>
        <tbody>
          {cashExpenseList.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className="actionCol">
                <ActionBtns
                  deleteTitle={t('CASHEXPENSE.LIST_ACTION_REMOVE')}
                  deleteFn={() => handleDeleteAction(item)}
                  updateTitle={t('CASHEXPENSE.LIST_ACTION_EDIT')}
                  updateFn={() => editCashExpense(item)}
                />
              </td>
              <td>{formatDate(item.createdDate, 'dd/mm/yyyy')}</td>
              <td>{item.locationName}</td>
              <td>{item.expenseNote}</td>
              <td>{item.paidTo}</td>
              <td>{numberFormatter(item.expenseAmount)}</td>
              <td>{formatDate(item.startDate, 'dd/mm/yyyy')}</td>
              <td>{formatDate(item.endDate, 'dd/mm/yyyy')}</td>
              <td align="center">
                {item.invoice ? (
                  <i className="ri-check-fill text-success"></i>
                ) : (
                  <i className="ri-close-fill text-danger"></i>
                )}
              </td>
            </tr>
          ))}

          {!isLoading && cashExpenseList.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>
      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        confirmFn={handleConfirmDeleteAction}
        cancelFn={handleCancelAction}
      />
    </React.Fragment>
  );
}
