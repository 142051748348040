import { post } from "../AxiosConfig";

const URI = "/employee";

const listEmployee = (payload) => {
  const URL = `${URI}/list-employee`;
  return post(URL, payload);
};

const listWithdrawal = (payload) => {
  const URL = `${URI}/withdraw-information`;
  return post(URL, payload);
};

const listAgent = (payload) => {
  const URL = `${URI}/list-agent`;
  return post(URL, payload);
};

const listAssociate = (payload) => {
  const URL = `${URI}/location-information`;
  return post(URL, payload);
};

const listNotAssociate = (payload) => {
  const URL = `${URI}/notassociate-location`;
  return post(URL, payload);
};

const createEmployee = (payload) => {
  const URL = `${URI}/create-employee`;
  return post(URL, payload);
};

const updateEmployee = (payload) => {
  const URL = `${URI}/update-employee`;
  return post(URL, payload);
};

const deleteEmployee = (payload) => {
  const URL = `${URI}/delete-employee`;
  return post(URL, payload);
};

const empAffiliateLocation = (payload) => {
  const URL = `${URI}/add-employee-locationdetail`;
  return post(URL, payload);
};

const bankAffiliation = (payload) => {
  const URL = `${URI}/add-employee-bankdetail`;
  return post(URL, payload);
};

const bankAffiInfo = (payload) => {
  const URL = `${URI}/bank-information`;
  return post(URL, payload);
};

const chargesList = (payload) => {
  const URL = `${URI}/loading-machine-information`;
  return post(URL, payload);
};


const EmployeeService = {
  listEmployee,
  listAgent,
  listAssociate,
  listNotAssociate,
  bankAffiliation,
  bankAffiInfo,
  empAffiliateLocation,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  listWithdrawal,
  chargesList,
};

export default EmployeeService;
