/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { pick } from 'lodash';

import { CompanyId } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import MachineService from '../../services/machines';
import ModemTypeService from '../../services/loc_communication';
import LocationService from '../../services/location';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import CommonField from '../../components/CommonField';
import SelectField from '../../components/SelectField';
import NumericFormatField from '../../components/NumericFormatField';

const InitialValues = {
  modemTypeId: '',
  numberofline: '',
  monthlypayment: 0,
};

const ValidationSchema = Yup.object().shape({
  modemTypeId: Yup.string().required(t('REQUIRED')).trim(),
  numberofline: Yup.string().required(t('REQUIRED')).trim(),
});

const CommunicationDetails = ({ formData, getDataFn }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  let [formValues, setFormValues] = useState({});
  const [modemTypeOptList, setModemTypeOptList] = useState([]);
  let [modemDetail, setModemDetail] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    getModemTypeFn();
  }, []);

  useEffect(() => {
    if (formData && modemTypeOptList.length > 0) {
      getModemDetailFn();
    }
  }, [formData, modemTypeOptList]);

  async function getModemTypeFn() {
    dispatch(setLoadingOverlay(true));
    await MachineService.getModems()
      .then((response) => {
        const { status, dataList } = response.data;

        if (status === 400) {
          return;
        }

        if (dataList && dataList.length) {
          const data = dataList.map((item) => ({
            id: item.id,
            label: item.modemName,
            value: item.id,
          }));

          setModemTypeOptList(data);
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  async function getModemDetailFn() {
    dispatch(setLoadingOverlay(true));

    const companyId = LocalstorageService.getItem(CompanyId);
    const payload = {
      terminalNumber: formData?.terminalNumber,
      companyId,
    };

    await LocationService.getModemsByTerminal(payload)
      .then((response) => {
        const { status, data } = response.data;

        let payload = pick(formData, ['id', 'communicationtype']);
        setFormValues({ ...formValues, ...payload });

        if (status === 400) {
          return;
        }

        if (data) {
          setModemDetail(data);

          payload = {
            ...payload,
            ...pick(data, ['modemTypeId', 'numberofline', 'monthlypayment']),
            monthlypayment: typeof data.monthlypayment !== 'number' ? 0 : parseFloat(data.monthlypayment),
          };
        }

        setFormValues({ ...formValues, ...payload });
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  }

  const mTypeChangeGetModemDetailFn = async (id) => {
    dispatch(setLoadingOverlay(true));
    await ModemTypeService.getModelDetail({ id })
      .then((response) => {
        const { status, data } = response.data;

        if (status === 400) {
          return;
        }

        modemDetail = {};
        if (data) {
          modemDetail = { ...data };
        }
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });
  };

  const handleModemTypeChangeFnOld = async (option, modemTypeId) => {
    const { setFieldValue, setFieldTouched } = formRef.current;

    setFieldValue('lineNumber', '');
    setFieldTouched('lineNumber', false);

    setFieldValue('monthlyPayment', '');
    setFieldTouched('monthlyPayment', false);

    if (option || modemTypeId) {
      await getModemDetailFn(option?.value || modemTypeId);

      setFieldValue('lineNumber', modemDetail?.displayOrder || '');
      setFieldValue('monthlyPayment', modemDetail?.modemFee || '');
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const payload = {
      ...values,
      monthlypayment: parseFloat(values.monthlypayment),
    };

    // console.log(">payload", payload);
    // return;

    setIsLoading(true);
    await LocationService.createLocationCommunication(payload)
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        getDataFn();
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditForm(false);
      });
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        initialValues={{ ...InitialValues, ...formValues }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate>
            <Row>
              {/* Communication Type */}
              <Col sm={6} md={4}>
                <div className="mb-3">
                  <SelectField
                    fieldName="modemTypeId"
                    fieldLabel={t('MACHINE.MODEM')}
                    fieldValue={values.modemTypeId}
                    fieldOptions={modemTypeOptList}
                    fieldChange={async (option) => {
                      setFieldTouched('monthlypayment', false);

                      if (option) {
                        await mTypeChangeGetModemDetailFn(option.value);
                        setFieldValue('monthlypayment', modemDetail.modemFee);
                      }
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isRequired={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Line Number */}
              <Col sm={6} md={4}>
                <div className="mb-3">
                  <CommonField
                    fieldName="numberofline"
                    label={t('LOC_COMM_PLACEMENT.LINE_NO')}
                    placeholder={t('LOC_COMM_PLACEMENT.LINE_NO_PLCH')}
                    required={true}
                    isDisabled={!showEditForm}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {/* Monthly Payment */}
              <Col sm={6} md={4}>
                <div className="mb-3">
                  <NumericFormatField
                    fieldName="monthlypayment"
                    fieldValue={values.monthlypayment}
                    fieldLabel={t('LOC_COMM_PLACEMENT.MONTHLY_PAY')}
                    fieldDecimalScale={2}
                    fieldPlaceholder={t('LOC_COMM_PLACEMENT.MONTHLY_PAY_PLCH')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabled={true}
                    isRequired={false}
                  />
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {showEditForm && (
              <div className="button-items">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!showEditForm && (
        <div className="button-items">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="button"
            onClick={handleShowEditForm}
            disabled={isLoading}
          >
            {t('EDIT')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default CommunicationDetails;
