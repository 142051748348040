/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

// Import images
import natanelLg from '../../assets/images/logo/natanel-lg.png';

import { ConfirmPasswordSchema as ResetPasswordSchema } from '../../helpers/yup-schema';
import AuthService from '../../services/auth';

import PasswordField from '../../components/PasswordField';

function ResetPassword() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const params = Object.fromEntries([...searchParams]);

  useEffect(() => {
    if (!params.token) navigate('/login');
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    setIsLoading(true);
    const payload = {
      ...params,
      newPassword: values.newPassword,
    };

    await AuthService.resetPassword({ ...payload })
      .then(({ data }) => {
        const { status, message } = data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        navigate('/login', { replace: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col lg={4} className="p-3 card">
                  <div>
                    <div className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link id="backToLogin" to="/login" style={{ position: 'absolute', left: '15px' }}>
                          <i className="ri-arrow-left-circle-line font-size-34"></i>
                        </Link>

                        <UncontrolledTooltip placement="right" target="backToLogin">
                          {t('TOOLTIP.BACK_TO_LOGIN')}
                        </UncontrolledTooltip>

                        <img src={natanelLg} alt="" height="30" className="auth-logo logo-dark mx-auto" />
                      </div>

                      <h4 className="font-size-18 mt-4">{t('RESET_PASSWORD.TITLE')}</h4>
                    </div>

                    <div className="p-2">
                      <Formik
                        className="form-horizontal"
                        initialValues={{
                          newPassword: '',
                          confirmNewPassword: '',
                        }}
                        validationSchema={ResetPasswordSchema('confirmNewPassword', 'newPassword')}
                        onSubmit={handleSubmit}
                      >
                        {(props) => {
                          const { isSubmitting, setFieldValue } = props;

                          return (
                            <Form>
                              {/* Password */}
                              <div className="mb-4">
                                <PasswordField
                                  fieldName="newPassword"
                                  showPasswordCriteriaInfo={true}
                                  setFieldValue={setFieldValue}
                                  setBaseViewPassword={setViewPassword}
                                />
                              </div>

                              {/* Confirm Password */}
                              <div className="mb-4">
                                <PasswordField
                                  fieldName="confirmNewPassword"
                                  id="confirmUserpassword"
                                  label="RESET_PASSWORD.CONFIRM_PASSWORD_LABEL"
                                  placeholder="RESET_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER"
                                  setFieldValue={setFieldValue}
                                  viewPasswordIndicator={false}
                                  isViewPassword={viewPassword}
                                />
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                  disabled={isSubmitting || isLoading}
                                >
                                  {isSubmitting || isLoading ? t('PLEASE_WAIT') : t('SUBMIT')}
                                </Button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ResetPassword;
