import { post } from "../AxiosConfig";

const URI = "/account";

const getProfile = () => {
  const URL = `${URI}/profile`;
  return post(URL);
};

const updateProfile = (payload) => {
  const URL = `${URI}/update-profile`;
  return post(URL, payload);
};

const listUsers = (payload) => {
  const URL = `${URI}/list-user`;
  return post(URL, payload);
};

const createUser = (payload) => {
  const URL = `${URI}/create-user`;
  return post(URL, payload);
};

const updateUser = (payload) => {
  const URL = `${URI}/update-user`;
  return post(URL, payload);
};

const deleteUser = (payload) => {
  const URL = `${URI}/delete-user`;
  return post(URL, payload);
};

const getCompanyData = () => {
  const URL = `${URI}/get-company`;
  return post(URL);
};

const AccountService = {
  getProfile,
  updateProfile,
  listUsers,
  createUser,
  updateUser,
  deleteUser,
  getCompanyData,
};

export default AccountService;
