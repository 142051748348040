import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import warning from '../../assets/images/Warning.png';
import { useTranslation } from 'react-i18next';

function ConfirmDelete({ showDeleteModal, deleteRemark, closeDeleteModal, deleteMessage }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Modal isOpen={showDeleteModal} backdrop="static" className="modal-md modal-dialog-centered">
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={warning} alt="" height="50" />
            <h3 className="mx-2 mb-0">{deleteMessage}</h3>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end gap-2">
            <Button className="deleteBtn" onClick={() => deleteRemark()}>
              {t(`LOAD_REMARKS.DELETE_BTN`)}
            </Button>
            <Button className="submitBtn" onClick={() => closeDeleteModal()}>
              {t(`LOAD_REMARKS.CANCEL_BTN`)}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmDelete;
